import { toast } from 'react-toastify';
import { ChangeEvent, ReactElement, useState } from 'react';
import { Alert, AlertTitle, Button, Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import GridOnIcon from '@mui/icons-material/GridOn';
import SendIcon from '@mui/icons-material/Send';
import Papa from 'papaparse';
import { transactionsStructure, ManualBankTransaction } from './types';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { BankAccount, Company } from '../../types';
import { BankTransactionsUploadService } from '../../services/bank-transactions/BankTransactionsUploadService';

interface Props {
  company: Company | null;
  bankAccount: BankAccount | null;
}

const columns: GridColDef[] = [
  {
    field: 'date',
    headerName: 'Date',
    width: 120,
    type: 'string',
    sortable: false,
    hideable: false,
    filterable: false,
  },
  {
    field: 'description',
    headerName: 'Description',
    type: 'string',
    sortable: false,
    hideable: false,
    filterable: false,
    flex: 3,
  },
  {
    field: 'moneySpent',
    headerName: 'Money Spent',
    type: 'number',
    sortable: false,
    hideable: false,
    filterable: false,
    flex: 1,
  },
  {
    field: 'moneyReceived',
    headerName: 'Money Received',
    type: 'number',
    sortable: false,
    hideable: false,
    filterable: false,
    flex: 1,
  },
];

const useLoadCsv = ({ company, bankAccount }: Props) => {
  const [isReadingFile, setIsReadingFile] = useState<boolean>(false);
  const [isValidFile, setIsValidFile] = useState<boolean | null>(null);
  const [transactions, setTransactions] = useState<ManualBankTransaction[]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const isDisabled = company == null || bankAccount == null;

  const onFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files == null || event.target.files.length === 0) return;

    const file = event.target.files[0];

    if (file.size > 2e6) {
      toast.error('File size is too big! Please select a file smaller than 3MB.', { position: 'bottom-center' });
      return;
    }

    setIsValidFile(null);
    setTransactions([]);
    setIsReadingFile(true);

    Papa.parse(file as any, {
      skipEmptyLines: true,
      header: false,
      dynamicTyping: true,
      worker: true,
      complete: ({ data }) => {
        const headers = data.shift();
        const { success, error } = transactionsStructure.safeParse(data) as any;
        error && console.log(error);

        const transactions = data.map(([date, description, moneySpent, moneyReceived]: any, index) => ({
          _rowId: index,
          date,
          description,
          moneySpent,
          moneyReceived,
        }));

        setIsValidFile(success && transactions.length > 0);
        setTransactions(transactions);
        setIsReadingFile(false);
        event.target.value = '';
      },
    });
  };

  const onUploadButtonClick = async () => {
    if (!isValidFile) return;

    setIsUploading(true);
    try {
      await BankTransactionsUploadService.getInstance().uploadBankTransactions(
        company!.id,
        bankAccount!.id,
        transactions,
      );
      const transactionsCount = transactions.length;
      setTransactions([]);
      setIsValidFile(null);

      toast.success(`Successfully uploaded ${transactionsCount} transactions!`, {
        position: 'bottom-center',
      });
    } catch (e: any) {
      toast.error('There was an error uploading the CSV! This could be the result of uploading large CSV files.', {
        hideProgressBar: true,
        position: 'bottom-center',
      });
    } finally {
      setIsUploading(false);
    }
  };

  return {
    isReadingFile,
    isValidFile,
    isUploading,
    isDisabled,
    transactions,
    onFileChange,
    onUploadButtonClick,
  };
};

export const LoadCsv = ({ company, bankAccount }: Props): ReactElement => {
  const { isReadingFile, isValidFile, isUploading, isDisabled, transactions, onFileChange, onUploadButtonClick } =
    useLoadCsv({ company, bankAccount });

  return (
    <>
      <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ marginBottom: 2 }}>
        <label htmlFor="csv-select">
          <input
            disabled={isDisabled || isReadingFile || isUploading}
            style={{ display: 'none' }}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            id="csv-select"
            type="file"
            onChange={onFileChange}
          />
          <Button
            disabled={isDisabled || isReadingFile || isUploading}
            variant="contained"
            component="span"
            startIcon={<GridOnIcon />}
          >
            Load CSV
          </Button>
        </label>

        {isValidFile === true && (
          <label htmlFor="upload">
            <LoadingButton
              variant="contained"
              color="success"
              startIcon={<SendIcon />}
              loading={isUploading}
              onClick={onUploadButtonClick}
            >
              Upload
            </LoadingButton>
          </label>
        )}
      </Stack>

      {isValidFile === false && (
        <Alert severity="error" sx={{ marginBottom: 2 }}>
          <AlertTitle>Invalid File</AlertTitle>
          <div>
            Uploaded file has invalid data format. <br /> Expecting (order matters):{' '}
            <strong>Date, Description, Money Spent, Money Received</strong>
          </div>
        </Alert>
      )}

      {!isDisabled && (
        <div style={{ height: 500 }}>
          <DataGrid
            columns={columns}
            rows={transactions}
            loading={isReadingFile}
            disableColumnMenu
            disableSelectionOnClick
            disableColumnSelector
            getRowId={(row) => row._rowId}
          />
        </div>
      )}
    </>
  );
};
