import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { drawerWidth } from '../index';
import { ListItemLink } from './ListItemLink';
import { SideBarHeader } from './SideBarHeader';

export const SideBar = ({
  handleSideBarClick,
  sideBarOpen,
}: {
  handleSideBarClick: () => void;
  sideBarOpen: boolean;
}) => {
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor="left"
      open={sideBarOpen}
    >
      <SideBarHeader>
        <IconButton onClick={handleSideBarClick}>
          <ChevronRightIcon />
        </IconButton>
      </SideBarHeader>
      <Divider />
      <List>
        <ListItemLink to="/" primary="Bank Transactions" />
        <ListItemLink to="/upload-bank-transactions" primary="Upload Bank Transactions" />
        <ListItemLink to="/local-rules" primary="Local Rules" />
        <ListItemLink to="/bank-accounts" primary="Bank Accounts" />
        <ListItemLink to="/bank-account-statements" primary="Bank Account Statements" />
        <ListItemLink to="/bank-accounts-with-statements" primary="Bank Accounts Summary" />
      </List>
      <Divider />
    </Drawer>
  );
};
