import { Box, Button, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { keyBy, uniqBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { BankTransactionBusinessEventClassification } from '../../../../types';
import type { SelectionContentProps } from '../ContentSelector';
import { SplitTransactionClassification } from './SplitTransactionClassification';
import BigNumber from 'bignumber.js';
import { formatMoney, useBusinessMeanings } from '../../../../shared';
import { bankTransactionDisplayColumns } from '../bankTransactionDisplayColumns';

export const SplitTransactionDisplay = ({
  bankTransaction,
  onBusinessEventSelectionChange,
  selectableBusinessMeanings,
  categorizationResult,
  isDisabled,
}: SelectionContentProps) => {
  const { businessMeanings } = useBusinessMeanings();
  const allBusinessMeaningsMap = useMemo(() => keyBy(businessMeanings?.forFiltering, 'id'), [businessMeanings]);
  const [businessEventClassifications, setBusinessEventClassifications] = useState<
    Partial<BankTransactionBusinessEventClassification>[]
  >(bankTransaction.businessEvent?.classifications || []);
  const [classificationError, setClassificationError] = useState<string | undefined>();
  const totalAmount = businessEventClassifications
    .reduce((acc, c) => acc.plus(Math.abs(c.amount || 0)), BigNumber(0))
    .absoluteValue()
    .toNumber();

  const selectableBusinessMeaningsMap = useMemo(
    () => keyBy(selectableBusinessMeanings, 'id'),
    [selectableBusinessMeanings],
  );

  const removeBusinessEventClassification = (index: number) => {
    const newBusinessEventClassifications = [...businessEventClassifications];
    newBusinessEventClassifications.splice(index, 1);
    setBusinessEventClassifications(newBusinessEventClassifications);
  };

  const updateBusinessEventClassification = (
    index: number,
    businessEventClassification: Partial<BankTransactionBusinessEventClassification>,
  ) => {
    const newBusinessEventClassifications = [...businessEventClassifications];
    newBusinessEventClassifications[index] = businessEventClassification;
    setBusinessEventClassifications(newBusinessEventClassifications);
  };

  const addBusinessEventClassification = () => {
    setBusinessEventClassifications((bec) => [...bec, { amount: 0 }]);
  };

  useEffect(() => {
    if (businessEventClassifications.length === 0) {
      setClassificationError('At least one classification is required');
      return;
    }

    if (businessEventClassifications.some((c) => !c.businessEvent)) {
      setClassificationError('All classifications must have a business event');
      return;
    }

    if (businessEventClassifications.some((c) => !c.amount)) {
      setClassificationError('All classifications must have an amount');
      return;
    }

    if (totalAmount !== Math.abs(bankTransaction.amount)) {
      setClassificationError('Sum of classifications must equal transaction amount');
      return;
    }

    if (uniqBy(businessEventClassifications, 'businessEvent').length !== businessEventClassifications.length) {
      setClassificationError('Each classification must have a unique business meaning');
      return;
    }

    console.log('businessEventClassifications', businessEventClassifications);
    console.log('allBusinessMeaningsMap', allBusinessMeaningsMap);

    setClassificationError(undefined);
    onBusinessEventSelectionChange({
      shouldApplyAlways: false,
      businessMeanings: businessEventClassifications.map(({ businessEvent, amount, description }) => ({
        businessMeaning: allBusinessMeaningsMap[businessEvent!]!,
        amount: bankTransaction.amount > 0 ? Math.abs(amount!) : -Math.abs(amount!),
        description: description || undefined,
      })),
    });
  }, [
    allBusinessMeaningsMap,
    businessEventClassifications,
    setClassificationError,
    bankTransaction.amount,
    onBusinessEventSelectionChange,
    totalAmount,
  ]);

  return (
    <>
      <Box display="flex" flexDirection="column" mt={4}>
        <Typography sx={{ fontWeight: 600, marginBottom: 2 }}>Selected Transaction</Typography>
        <div style={{ height: 150, width: '100%' }}>
          <DataGrid
            rows={[bankTransaction]}
            columns={bankTransactionDisplayColumns}
            pageSize={1}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            hideFooter
          />
        </div>
      </Box>
      <Box mt={2}>
        <Button variant="contained" color="success" onClick={addBusinessEventClassification} disabled={isDisabled}>
          Add classification
        </Button>
      </Box>
      <Box display="flex" flexDirection="column" mt={4}>
        {businessEventClassifications.map((businessEventClassification, index) => (
          <SplitTransactionClassification
            key={index}
            index={index}
            bankTransaction={bankTransaction}
            businessMeanings={selectableBusinessMeanings}
            businessMeaningsMap={selectableBusinessMeaningsMap}
            businessEventClassification={businessEventClassification}
            removeSplitTransactionClassification={removeBusinessEventClassification}
            updateBusinessEventClassification={updateBusinessEventClassification}
            selectedMerchant={categorizationResult.merchantResult.merchant}
            isDisabled={isDisabled}
          />
        ))}
      </Box>
      <Box mt={2}>{classificationError && <Typography color="error">{classificationError}</Typography>}</Box>
      <Box mt={2}>
        <Typography color={classificationError ? 'error' : 'green'}>{formatMoney(totalAmount)}</Typography>
      </Box>
    </>
  );
};
