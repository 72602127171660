import { Merchant, MerchantType } from '../__generated-global__';

export * from './axios.util';
export * from './format-date.util';
export * from './format-money.util';
export * from './monetary-column-description';
export * from './resolve-money-direction.util';
export * from './rule-type-content';
export * from './use-business-meanings';
export * from './use-companies';
export * from './wait.util';

export const MerchantTypenameToType: Record<Merchant['__typename'], MerchantType> = {
  CompanyAffiliate: 'COMPANY_AFFILIATE',
  Vendor: 'VENDOR',
  Customer: 'CUSTOMER',
  Institution: 'INSTITUTION',
};

export type ExtractEntityFromFn<T extends (...args: any) => any> = Awaited<ReturnType<T>> extends any[] | []
  ? Awaited<ReturnType<T>>[number]
  : Awaited<ReturnType<T>>;
