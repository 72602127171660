import { MouseEvent, forwardRef, PropsWithChildren, ReactElement, Ref } from 'react';
import {
  AppBar,
  Button,
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material';
import type { TransitionProps } from '@mui/material/transitions';
import { Close } from '@mui/icons-material';

import type { DialogProps } from './props';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function Dialog(props: PropsWithChildren<DialogProps>): ReactElement {
  const handleClose = () => {
    props.onClose?.();
  };

  const handleCloseClick = (event: MouseEvent) => {
    event.stopPropagation();

    props.onClose?.();
  };

  const handleOKClick = (event: MouseEvent) => {
    event.stopPropagation();

    props?.onOK?.();
  };

  return (
    <MUIDialog
      open={!!props.isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      fullWidth
      maxWidth={props.maxWidth ?? 'lg'}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleCloseClick} aria-label="close">
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {props.title}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        {props.cancelHidden ? null : <Button onClick={handleCloseClick}>Cancel</Button>}
        <Button onClick={handleOKClick} disabled={props.isOKDisabled}>
          {props.okText || 'OK'}
        </Button>
      </DialogActions>
    </MUIDialog>
  );
}
