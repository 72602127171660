import { Box } from '@mui/material';
import { DataGrid, GridRowModel } from '@mui/x-data-grid';
import React from 'react';
import { BankAccountsService } from '../../../services/bank-accounts';
import { ReconciliationStatus } from '../../../types';
import { bankAccountsDisplayColumns } from './bankAccountsDisplayColumns';
import { EnrichedBankAccount } from './types';

interface BankAccountsTableProps {
  bankAccounts: EnrichedBankAccount[];
  isLoading: boolean;
}

export const BankAccountsWithStatementsTable = ({ bankAccounts, isLoading }: BankAccountsTableProps) => {
  // @todo add a button to complete all selected rows! (for year) - https://mui.com/x/react-data-grid/row-updates/
  const processRowUpdate = async (
    updatedRow: GridRowModel<EnrichedBankAccount>,
    oldRow: GridRowModel<EnrichedBankAccount>,
  ): Promise<EnrichedBankAccount> => {
    let updatedYear: string;
    let updatedReconciliationStatus: ReconciliationStatus;

    // @todo make something better in a year
    if (updatedRow.reconciliationStatuses2021 !== oldRow.reconciliationStatuses2021) {
      updatedYear = '2021';
      updatedReconciliationStatus = updatedRow.reconciliationStatuses2021;
    } else {
      updatedYear = '2022';
      updatedReconciliationStatus = updatedRow.reconciliationStatuses2022;
    }

    const updatedBankAccountWithStatements =
      await BankAccountsService.getInstance().markBankAccountReconciliationStatus({
        id: updatedRow.id,
        year: updatedYear,
        status: updatedReconciliationStatus,
      });

    console.log(
      updatedBankAccountWithStatements.reconciliationStatuses,
      updatedBankAccountWithStatements.reconciliationStatuses?.['2021'] || ReconciliationStatus.NotReconciled,
    );

    return {
      ...updatedBankAccountWithStatements,
      reconciliationStatuses2021:
        updatedBankAccountWithStatements.reconciliationStatuses?.['2021'] || ReconciliationStatus.NotReconciled,
      reconciliationStatuses2022:
        updatedBankAccountWithStatements.reconciliationStatuses?.['2022'] || ReconciliationStatus.NotReconciled,
    };
  };

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
      }}
    >
      <DataGrid
        rows={bankAccounts}
        columns={bankAccountsDisplayColumns}
        pageSize={50}
        rowsPerPageOptions={[50]}
        disableSelectionOnClick
        checkboxSelection
        loading={isLoading}
        experimentalFeatures={{ newEditingApi: true }}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={(error) => {
          console.error(error);
        }}
      />
    </Box>
  );
};
