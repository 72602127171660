import { useQuery } from '@tanstack/react-query';
import { bankTransactionService } from '../../../services/bank-transactions';
import { findPairedClassification, resolveGeneralPairedEntityFields } from '../../bankTransactionUtils';

export interface PairedEntityRequestProps {
  transactionId: string;
  companyId: string;
}

export interface GeneralPairedEntity {
  id: string;
  type?: string;
  currency?: string;
  amount?: number;
  amountInUsd?: number;
  date?: string;
  description?: string;
  merchantName?: string;
}

export interface UsePairedEntityResult {
  pairedEntity: GeneralPairedEntity | null;
  isLoading: boolean;
}

export const usePairedEntity = (params: PairedEntityRequestProps): UsePairedEntityResult => {
  const { transactionId, companyId } = params;
  const { data: bankTransactionWithPairedEntity, isLoading } = useQuery(
    ['bank-transaction-with-paired-entity', transactionId, companyId],
    () =>
      bankTransactionService.getBankTransactionWithPairedEntity({
        transactionId,
        companyId,
      }),
    {
      select: (data) => data,
      enabled: Boolean(companyId && transactionId),
      refetchOnWindowFocus: false,
      refetchInterval: false,
    },
  );

  // TODO: cache this funciton invication
  const pairedClassification = findPairedClassification(
    bankTransactionWithPairedEntity?.businessEvent?.classifications,
  );
  // ----
  if (!pairedClassification) return { isLoading, pairedEntity: null };

  return {
    isLoading,
    pairedEntity: {
      ...resolveGeneralPairedEntityFields(pairedClassification.pairedEntity!),
      id: pairedClassification.pairedEntityId!,
    },
  };
};
