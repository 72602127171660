import { ExtractEntityFromFn, fetchCompanies } from '../shared';

export type AccountingMethod = 'cash' | 'accrual';

export type TimeInISOString = string;

export enum InventoryManagementMethod {
  PurchaseBased = 'purchase-based',
  SalesBased = 'sales-based',
}

export interface InventorySettings {
  managementMethod: InventoryManagementMethod;
  isEligibleForNoClosingBalancePurchaseBased: boolean;
  openingBalance: number;
  closingBalances: Record<number, number>;
}

export interface CompanyEventsLock {
  minimumAllowedEventTime: number;
  lockHistory: CompanyEventsLockHistory[];
}

export interface CompanyEventsLockHistory {
  year: number;
  lockedAt: string;
  lockingUserEmail: string;
}

export type CompanyConfiguration = {
  isPnlReportReady?: boolean;
  isBalanceSheetReportFinal?: boolean;
  includeCCAccountsInCashFlowBeforePayment?: boolean;
  includeUDFAccountsInCashFlowBeforeDeposit?: boolean;
  booksAreReadyFor22?: boolean;
  cashFlowStartTimeISO?: string;
};

export enum EstimatedProjectedRevenue {
  ZERO_TO_HALF_A_MILLION = 'ZERO_TO_HALF_A_MILLION',
  HALF_A_MILLION_TO_ONE_AND_A_HALF_MILLION = 'HALF_A_MILLION_TO_ONE_AND_A_HALF_MILLION',
  ONE_AND_A_HALF_TO_TEN_MILLION = 'ONE_AND_A_HALF_TO_TEN_MILLION',
  TEN_TO_FIFTY_MILLION = 'TEN_TO_FIFTY_MILLION',
  ABOVE_FIFTY_MILLION = 'ABOVE_FIFTY_MILLION',
}

export type CalculatedTier = {
  annualizedSales: number;
  calculationTime: TimeInISOString;
  dataEndTime: TimeInISOString;
  dataStartTime: TimeInISOString;
  numDays: number;
  sales: number;
  tier: Tier;
};

export type Company = ExtractEntityFromFn<typeof fetchCompanies>;
// export interface Company {
//   logoUrl?: string | null;

//   id: string;
//   name: string;
//   email: string;
//   preloadEmail?: string;
//   userName: string;
//   refferalCode?: string;
//   isManufacturer?: boolean;
//   booksStartTime: Record<'default' | string, TimeInISOString>;
//   dataRestrictionTime?: TimeInISOString;
//   generalDetails?: RawGeneralDetails;
//   parsedGeneralDetails?: GeneralDetails;
//   legalEntityType?: LegalEntityType;
//   taxEntityType?: TaxEntityType;
//   phoneNumber?: string;
//   address?: Address;
//   configuration?: CompanyConfiguration;
//   accountingMethod?: AccountingMethod;
//   customerSelectedAccountingMethod?: AccountingMethod;
//   createdAtMs?: number;
//   canceledAtIso?: string;
//   calculatedTier?: CalculatedTier;
//   isTestCompany: boolean;
//   isArchived?: boolean;
//   inventorySettings: InventorySettings;
//   source?: Record<string, string>;
//   eventsLock?: CompanyEventsLock;
//   tier?: Tier;
//   isPaying: boolean;
//   paymentStatus?: string;
//   taxPlan?: string;
//   stripeCustomerId?: string;
//   trialStartTimeIso?: string;
//   trialStartTimeMs?: number;
//   trialDurationInDays: number;
//   fullUiAccess?: boolean;
//   userRequestedToLeaveAtMs?: number;
//   isParkerReferral?: boolean;
//   estimatedProjectedRevenue: EstimatedProjectedRevenue;
//   onboardingState: {
//     currentPage?: string;
//     signupCompleted?: boolean;
//   };
//   ordersSettings: {
//     isZeroNetOrdersSupported: boolean;
//   };
//   isOnboardingCompleted?: boolean;
// }

export type Tier =
  | 'Tier1'
  | 'Tier2'
  | 'Tier3'
  | 'Tier4'
  | 'Tier1 v3'
  | 'Tier2 v3'
  | 'Tier3 v3'
  | 'Tier4 v3'
  | 'Tier5 v3'
  | 'Tier6 v3'
  | 'Tier7 v3';

export enum LegalEntityType {
  NO_LEGAL_ENTITY = 'No legal entity',
  LLC = 'LLC',
  INC = 'INC',
  LP = 'LP',
}

export enum TaxEntityType {
  SCHEDULE_C = 'Schedule C',
  C_CORP = 'C-Corp',
  S_CORP = 'S-Corp',
  PARTNERSHIP = 'Partnership',
}

export interface RawGeneralDetails {
  LegalEntity?: string;
  CompanyFormationDate?: string;
  ComapnyFormationDate?: string;
  BusinessStartDate?: string;
  TaxEntityINC?: string;
  TaxEntityLLC?: string;
  Partnership?: string;
  YearsInScope?: string;
  formationYear?: string;
  accountingSelectionReason?: string;
  inventoryOnboardingCompletionTimeInMs?: number;
}

export interface GeneralDetails {
  shouldDisplayEntities: boolean;
  hasLegalEntity: boolean;
  taxEntity?: string;
  companyFormationDate?: string;
  entityTypeForLegalPurposes?: string;
  entityTypeForTaxPurposes: string | undefined;
  yearsInScope?: string;
}

export interface Address {
  country?: string;
  state?: string;
  city?: string;
  postalCode?: string;
  line1?: string;
  line2?: string;
}
