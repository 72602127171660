import { MouseEvent, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { IconButton, Popover, Stack, TextField } from '@mui/material';
import { Clear, DateRange } from '@mui/icons-material';
import {
  createStaticRanges,
  DateRangePicker as ReactDateRangePicker,
  defaultStaticRanges,
  Range,
  RangeKeyDict
} from 'react-date-range';
import { addMilliseconds, addYears, endOfDay, endOfYear, format, isToday, startOfDay, startOfYear } from 'date-fns';
import { getTimezoneOffset, utcToZonedTime } from 'date-fns-tz';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { ConfigService } from '../../services/config';
import type { DateRangePickerProps } from './props';

export function DateRangePicker(props: PropsWithChildren<DateRangePickerProps>): ReactElement {
  const { endDate, maxDate, minDate, onChange, startDate, timezone } = props;

  const [innerMaxDate] = useState<Date | undefined>(maxDate != null ? new Date(maxDate) : undefined);
  const [innerEndDate, setInnerEndDate] = useState<Date | undefined>(undefined);
  const [innerMinDate] = useState<Date | undefined>(minDate != null ? new Date(minDate) : undefined);
  const [innerStartDate, setInnerStartDate] = useState<Date | undefined>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [popoverAnchorElement, setPopoverAnchorElement] = useState<any>(null);

  useEffect(() => {
    setInnerStartDate(startDate != null ? new Date(startDate) : undefined);
    setInnerEndDate(endDate != null ? new Date(endDate) : innerMaxDate);
  }, [startDate, endDate, innerMaxDate]);

  const selectedDateRange: Range = {
    endDate: innerEndDate,
    key: 'selection',
    startDate: innerStartDate,
  };

  const convertToTimezone = (date: Date | number): Date => {
    if (!timezone) {
      return new Date(date);
    }

    const { timeZone: userTimezone } = Intl.DateTimeFormat().resolvedOptions();

    const utcDate = addMilliseconds(date, getTimezoneOffset(userTimezone, date));

    if (timezone === 'UTC') {
      return utcDate;
    }

    return utcToZonedTime(utcDate, timezone);
  };

  const closePopover = () => {
    setIsOpen(false);
    setPopoverAnchorElement(null);
  };

  const handlePopoverClose = closePopover;

  const handleTextFieldClick = (event: MouseEvent) => {
    event.stopPropagation();

    setPopoverAnchorElement(!isOpen ? event.currentTarget : null);
    setIsOpen(!isOpen);
  };

  const handleSelectionChange = (change: RangeKeyDict) => {
    let { endDate, startDate } = change.selection;

    if (endDate != null) {
      endDate = endOfDay(endDate);
    }

    if (startDate != null) {
      startDate = startOfDay(startDate);
    }

    console.log('endDate', endDate);
    console.log('startDate', startDate);

    setInnerEndDate(endDate);
    setInnerStartDate(startDate);

    onChange?.(
      startDate != null ? convertToTimezone(startDate) : undefined,
      endDate != null ? convertToTimezone(endDate) : undefined,
    );
  };

  const handleTextFieldClearClick = (event: MouseEvent) => {
    event.stopPropagation();

    setInnerEndDate(innerMaxDate);
    setInnerStartDate(undefined);
    onChange?.(undefined, innerMaxDate);
  };

  const formatDateRange = () => {
    const formattedStartDate = formatDate(innerStartDate);
    const formattedEndDate = formatDate(innerEndDate);

    if (formattedStartDate === formattedEndDate) {
      return formattedStartDate;
    }

    return [formattedStartDate, formattedEndDate].join(' - ');
  };

  const formatDate = (date: Date | undefined | null): string => {
    if (date == null) {
      return '∞';
    }

    if (isToday(date)) {
      return 'Today';
    }

    return format(date, ConfigService.getInstance().getOrFail('DATE_FORMAT'));
  };

  const popoverId = isOpen ? 'date-picker-popover' : undefined;

  const now = new Date();
  const yearAgo = addYears(now, -1);

  const staticRanges = [
    ...defaultStaticRanges,
    ...createStaticRanges([
      // @ts-ignore
      {
        label: 'This Year',
        range: () => ({ endDate: now, startDate: startOfYear(now) }),
      },
      // @ts-ignore
      {
        label: 'Last Year',
        range: () => ({ endDate: endOfYear(yearAgo), startDate: startOfYear(yearAgo) }),
      },
    ]),
  ];

  return (
    <div>
      <TextField
        aria-describedby={popoverId}
        InputProps={{
          endAdornment: (
            <Stack direction="row" spacing={1}>
              {innerStartDate || (innerEndDate && innerEndDate !== innerMaxDate) ? (
                <IconButton onClick={handleTextFieldClearClick}>
                  <Clear />
                </IconButton>
              ) : undefined}
              <IconButton>
                <DateRange />
              </IconButton>
            </Stack>
          ),
          readOnly: true,
        }}
        label={props.label}
        onClick={handleTextFieldClick}
        sx={props.sx}
        value={formatDateRange()}
        variant="filled"
      />
      <Popover
        anchorEl={popoverAnchorElement}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        id={popoverId}
        onClose={handlePopoverClose}
        open={isOpen}
      >
        <ReactDateRangePicker
          calendarFocus="backwards"
          dateDisplayFormat={ConfigService.getInstance().getOrFail('DATE_FORMAT')}
          direction="horizontal"
          displayMode="dateRange"
          editableDateInputs={true}
          endDatePlaceholder="End Date"
          maxDate={innerMaxDate}
          minDate={innerMinDate}
          months={1}
          moveRangeOnFirstSelection={false}
          onChange={handleSelectionChange}
          preventSnapRefocus={true}
          ranges={[selectedDateRange]}
          startDatePlaceholder="Start Date"
          staticRanges={staticRanges}
        />
      </Popover>
    </div>
  );
}
