import { type ReactElement, useMemo } from 'react';
import { IconButton } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { CSVLink } from 'react-csv';
import { uniqBy } from 'lodash';

import { EnrichedBankTransaction } from '../../types';
import { formatMoney } from '../../shared';

export interface BankTransactionsCSVDownloadButtonProps {
  bankTransactions: EnrichedBankTransaction[];
}

export function BankTransactionsCSVDownloadButton(props: BankTransactionsCSVDownloadButtonProps): ReactElement {
  const { bankTransactions } = props;

  const processedBankTransactions = useMemo(
    () =>
      uniqBy(bankTransactions, 'id').map((bankTransaction) => ({
        'Bank Account': bankTransaction.bankAccount.fullName,
        Date: bankTransaction.postedAt,
        'Original Vendor': bankTransaction.sourceSpecificData?.vendorName || '',
        Description: bankTransaction.description,
        'Institution Memo': bankTransaction.institutionMemo || '',
        Spent:
          bankTransaction.amount < 0 ? formatMoney(Math.abs(bankTransaction.amount), bankTransaction.currency) : '',
        Received: bankTransaction.amount > 0 ? formatMoney(bankTransaction.amount, bankTransaction.currency) : '',
        Vendor: bankTransaction.merchant?.name || '',
        BusinessMeaning:
          bankTransaction.businessEvent?.classifications
            ?.map((classification) =>
              [classification.businessMeaning, classification.classificationTagText].filter(Boolean).join(' - '),
            )
            .join('/') || '',
        'Paypal Link': bankTransaction.sourceSpecificData?.transactionId || '',
        id: bankTransaction.id,
        'Company ID': bankTransaction.companyId,
        Source: bankTransaction.source,
      })),
    [bankTransactions],
  );

  return !bankTransactions.length ? (
    <IconButton aria-label="download as csv" color="primary" component="span" disabled>
      <FileDownloadIcon />
    </IconButton>
  ) : (
    <CSVLink
      data={processedBankTransactions}
      filename={`bank-transactions-${bankTransactions[0]?.companyId}-${Date.now()}`}
    >
      <IconButton aria-label="download as csv" color="primary" component="span">
        <FileDownloadIcon />
      </IconButton>
    </CSVLink>
  );
}
