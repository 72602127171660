import { memo, type ReactElement, useMemo, useEffect, useRef } from 'react';
import Fuse from 'fuse.js';
import { debounce, sortBy } from 'lodash';
import { Input, InputLabel } from '@mui/material';
import { InternalTransferCandidate } from '../services/bank-transactions';

export interface InternalTransferCandidatesSearcherProps {
  candidates: InternalTransferCandidate[];
  isLoading?: boolean;
  isMultiple?: boolean;
  onChange?: (value: string) => void;
  onCandidatesFiltered?: (searchResults: InternalTransferCandidate[]) => void;
  value?: string;
}

const fuse = new Fuse<InternalTransferCandidate>([], {
  threshold: 0.3,
  keys: [
    { name: 'description', weight: 100 },
    { name: 'merchant.name', weight: 30 },
  ],
});

export const InternalTransferCandidatesSearcher = memo(
  (props: InternalTransferCandidatesSearcherProps): ReactElement => {
    const { candidates, value } = props;

    const ref = useRef(props.onCandidatesFiltered);
    useEffect(() => {
      // updating ref when state changes
      // now, ref.current will have the latest props.onCandidatesFiltered with access to the latest state
      ref.current = props.onCandidatesFiltered;
    }, [props.onCandidatesFiltered]);

    const findSuggestedOptions = useMemo(
      () =>
        debounce((inputValue) => {
          const searchResults = fuse.search(inputValue, { limit: 30 }).map(({ item }) => item);
          ref.current?.(sortBy(searchResults, 'weight').slice(0, 10));
        }, 400),
      [],
    );

    useEffect(() => {
      return () => {
        findSuggestedOptions.cancel();
      };
    }, []);

    useEffect(() => {
      fuse.setCollection(candidates);
    }, [candidates]);

    return (
      <>
        <InputLabel id="search-label">Search</InputLabel>
        <Input
          style={{ width: '100%' }}
          value={value}
          aria-label="search-label"
          placeholder="Description or merchant"
          onChange={(event) => {
            props.onChange?.(event.target.value);
            findSuggestedOptions(event.target.value);
          }}
        ></Input>
      </>
    );
  },
);
