import type { ChangeEvent, ReactElement } from 'react';
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

import type { MoneyDirection } from '../../types';

export interface MoneyDirectionSelectorProps {
  onChange?: (moneyDirection: MoneyDirection | null | undefined) => void;
  value?: MoneyDirection | null;
}

export function MoneyDirectionSelector(props: MoneyDirectionSelectorProps): ReactElement {
  const labelId = 'money-direction-selector-label';

  const handleRadioGroupChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    props.onChange?.(value === 'ANY' ? undefined : (value as MoneyDirection));
  };

  return (
    <Box sx={{ width: '100%' }}>
      <FormControl>
        <FormLabel id={labelId}>Money Direction</FormLabel>
        <RadioGroup
          row
          aria-labelledby={labelId}
          name="money-direction-selector-radio-group"
          value={props.value || 'ANY'}
          onChange={handleRadioGroupChange}
        >
          <FormControlLabel control={<Radio />} label="All" value="ANY" />
          <FormControlLabel control={<Radio />} label="In" value="IN" />
          <FormControlLabel control={<Radio />} label="Out" value="OUT" />
        </RadioGroup>
      </FormControl>
    </Box>
  );
}
