import { type ChangeEvent, useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel, FormGroup, LinearProgress, Typography, Alert } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';

import { BankTransactionsService } from '../../../../services/bank-transactions';
import { resolveMoneyDirection } from '../../../../shared';
import { BankTransactionClassifierType, BankTransactionSource, EnrichedBankTransaction } from '../../../../types';
import { CustomNoRowsOverlay } from '../../../../ui/NoRowsOverlay/NoRowsOverlay';
import { DateExplorerLink } from '../../../DataExplorerLink';
import { bankTransactionDisplayColumns } from '../bankTransactionDisplayColumns';
import type { ContentProps } from '../ContentSelector';
import { globalRulesService } from '../../../../services/global-rules';
import { merchantService } from '../../../../services/merchants';

const MAX_PREVIEWED_EXAMPLE_APPLY_ALWAYS_BANK_TRANSACTIONS = 25;

export const DefaultDisplay = ({
  bankTransaction,
  onBusinessEventSelectionChange,
  onShouldApplyGlobalRuleChange,
  currentBusinessMeaning,
  categorizationResult,
  isDisabled,
}: ContentProps) => {
  const {
    merchantResult: { merchant },
  } = categorizationResult;

  const isLocalRule =
    bankTransaction.businessEvent?.classifierType === BankTransactionClassifierType.LOCAL_RULE ||
    !!bankTransaction.businessEvent?.classifiedBy?.includes('#vendorToBusinessMeaning');

  const isLocalRuleAllowed = currentBusinessMeaning != null && categorizationResult.merchantResult.merchant?.id != null;
  const [isApplyAlways, setIsApplyAlways] = useState(isLocalRule);
  const [isApplyAlwaysGlobally, setIsApplyAlwaysGlobally] = useState<boolean>();
  const [sampleApplyAlwaysBankTransactions, setSampleApplyAlwaysBankTransactions] = useState<EnrichedBankTransaction[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleApplyAlwaysChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsApplyAlways(event.target.checked);
  };

  const handleCreateGlobalRuleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsApplyAlwaysGlobally(event.target.checked);
  };

  const { data: isGlobalRuleExist, isLoading: isGlobalRuleLoading } = useQuery(
    ['global-rule-exists', merchant?.id, bankTransaction.id],
    () => globalRulesService.isGlobalRuleExist(merchant!.id, 'OUT'),
    { enabled: Boolean(bankTransaction && merchant) },
  );

  const { data: vendor, isLoading: isVendorLoading } = useQuery(
    ['vendor', merchant?.id],
    () => merchantService.getVendorById(merchant!.id, bankTransaction.companyId),
    {
      enabled: Boolean(merchant?.id && merchant.type === 'VENDOR' && bankTransaction.companyId),
    },
  );

  const isGlobalVendor = vendor?.__typename === 'Vendor' && vendor.type === 'GLOBAL' && !vendor.companyId;

  const isGlobalRuleAllowed =
    bankTransaction.amountInUsd < 0 && // money_out only
    !bankTransaction.businessEvent && // unknown business event
    currentBusinessMeaning != null &&
    categorizationResult.merchantResult.merchant?.id != null &&
    isGlobalVendor;

  useEffect(() => {
    onShouldApplyGlobalRuleChange?.(Boolean(isGlobalRuleAllowed && !isGlobalRuleExist && isApplyAlwaysGlobally));
  }, [isGlobalRuleExist, isGlobalRuleAllowed, isApplyAlwaysGlobally]);

  useEffect(() => {
    const businessMeanings = currentBusinessMeaning
      ? [{ businessMeaning: currentBusinessMeaning, amount: bankTransaction.amount }]
      : [];

    onBusinessEventSelectionChange({
      businessMeanings,
      shouldApplyAlways: businessMeanings.length > 0 ? isApplyAlways : false,
    });

    isApplyAlways && getApplyAlwaysSampleBankTransactions();
  }, [isApplyAlways, currentBusinessMeaning]);

  const getApplyAlwaysSampleBankTransactions = async () => {
    setIsLoading(true);

    const sampleApplyAlwaysBankTransactionPage = await BankTransactionsService.getInstance().getBankTransactionPage({
      companyId: bankTransaction.companyId,
      limit: MAX_PREVIEWED_EXAMPLE_APPLY_ALWAYS_BANK_TRANSACTIONS + 1,
      moneyDirection: resolveMoneyDirection(bankTransaction),
      source: bankTransaction.source as unknown as BankTransactionSource,
      vendorId: categorizationResult.merchantResult.merchant?.id,
    });

    const { id: bankTransactionId } = bankTransaction;

    setSampleApplyAlwaysBankTransactions(
      sampleApplyAlwaysBankTransactionPage.items
        .filter((exampleBankTransaction) => exampleBankTransaction.id !== bankTransactionId)
        .slice(0, MAX_PREVIEWED_EXAMPLE_APPLY_ALWAYS_BANK_TRANSACTIONS),
    );
    setIsLoading(false);
  };

  return (
    <Box>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={isLocalRuleAllowed && isApplyAlways}
              disabled={!isLocalRuleAllowed || isLocalRule || isDisabled}
              inputProps={{ 'aria-label': 'controlled' }}
              onChange={handleApplyAlwaysChange}
            />
          }
          label="Always apply classification to selected merchant"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isGlobalRuleAllowed && isApplyAlwaysGlobally && !isGlobalRuleExist}
              disabled={!isGlobalRuleAllowed || isGlobalRuleExist || isGlobalRuleLoading || isVendorLoading || isDisabled}
              inputProps={{ 'aria-label': 'controlled' }}
              onChange={handleCreateGlobalRuleChange}
            />
          }
          label="Create global rule with this classification and this merchant"
        />
        {isGlobalRuleExist && (
          <Alert severity="info">Global rule for money-out direction already exists for this merchant</Alert>
        )}
      </FormGroup>
      {isLocalRule && bankTransaction.businessEvent?.classifiedBy && (
        <Box mb={2}>
          <Typography>
            Base transaction link:{' '}
            <DateExplorerLink bankTransactionId={bankTransaction.businessEvent?.classifiedBy.split('#')[0]} />
          </Typography>
        </Box>
      )}
      {isApplyAlways && (
        <div style={{ height: 250, width: '100%' }}>
          <DataGrid
            components={{
              LoadingOverlay: LinearProgress,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            loading={isLoading}
            rows={sampleApplyAlwaysBankTransactions}
            columns={bankTransactionDisplayColumns}
            disableSelectionOnClick
          />
        </div>
      )}
    </Box>
  );
};
