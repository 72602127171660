import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import { type FetchNextPageOptions, type InfiniteQueryObserverResult, useInfiniteQuery } from '@tanstack/react-query';

import { type LocalRulesApiReadLocalRulesResponseBody, LocalRulesService } from '../../services/local-rules';
import type { Company, LocalRule, LocalRuleType } from '../../types';
import { Merchant } from '../../__genql__/merchants';

export interface UseLocalRulesDataProps {
  company?: Company | null;
  moneyDirection?: 'IN' | 'OUT' | null;
  ruleType?: LocalRuleType | null;
  merchant?: Merchant | undefined;
}

export interface UseLocalRulesDataResult {
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<LocalRulesApiReadLocalRulesResponseBody, unknown>>;
  hasMorePages: boolean;
  isLoading: boolean;
  localRules: LocalRule[];
  setLocalRules: Dispatch<SetStateAction<LocalRule[]>>;
}

export function useLocalRulesData(props: UseLocalRulesDataProps): UseLocalRulesDataResult {
  const { company, moneyDirection, ruleType, merchant } = props;
  const { id: companyId } = company || {};
  const { id: merchantId } = merchant || {};

  const [localRules, setLocalRules] = useState<LocalRule[]>([]);

  const {
    data: { pages } = {},
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: isFetching,
  } = useInfiniteQuery(
    ['local-rules', companyId, moneyDirection, ruleType, merchantId],
    (context) =>
      LocalRulesService.getInstance().getLocalRulePage({
        cursor: context.pageParam,
        limit: 100,
        moneyDirection: moneyDirection || undefined,
        requestedCompanyId: companyId!,
        ruleType: ruleType || undefined,
        vendorId: merchantId,
      }),
    {
      enabled: company != null,
      getNextPageParam: (lastPage) => lastPage.cursor || undefined,
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  );

  useEffect(() => {
    setLocalRules(pages?.flatMap((page) => page.items) || []);
  }, [pages]);

  return {
    fetchNextPage,
    hasMorePages: !!hasNextPage,
    isLoading: isFetchingNextPage || isFetching,
    localRules,
    setLocalRules,
  };
}
