import type { ManualBankTransaction } from '../../components/UploadBankTransactions/types';
import { AxiosInstance } from 'axios';
import { ConfigService } from '../config';
import { addAuthorizationTokenToAxiosRequests, getAxiosInstance, handleAxiosResponses } from '../../shared';

export class BankTransactionsUploadService {
  protected static instance: BankTransactionsUploadService;

  protected constructor(protected readonly config: ConfigService, protected readonly axiosInstance: AxiosInstance) {}

  static getInstance(
    config: ConfigService = ConfigService.getInstance(),
    axiosInstance?: AxiosInstance,
  ): BankTransactionsUploadService {
    if (BankTransactionsUploadService.instance) {
      return BankTransactionsUploadService.instance;
    }

    const apiClient =
      axiosInstance || getAxiosInstance({ baseURL: config.getOrFail('UPLOAD_BANK_TRANSACTIONS_API_URL') });
    addAuthorizationTokenToAxiosRequests(apiClient);
    handleAxiosResponses(apiClient);

    return (BankTransactionsUploadService.instance = new BankTransactionsUploadService(config, apiClient));
  }

  async uploadBankTransactions(
    companyId: string,
    bankAccountId: string,
    transactions: ManualBankTransaction[],
  ): Promise<any> {
    const { data } = await this.axiosInstance.post(
      `bank-accounts/${bankAccountId}/manual-bank-transactions`,
      { transactions },
      { params: { companyId } },
    );

    return data;
  }
}
