import { Typography } from '@mui/material';
import type { GridColDef, GridRenderCellParams, GridRenderEditCellParams } from '@mui/x-data-grid';
import { SelectEditInputCell } from '../../BankAccountStatements/BankAccountStatementsTable/cells/SelectInputCell';
import { reconciliationStatusOptions } from './reconciliationStatusOptions';
import { getBankStatementStatus } from './shared/getBankStatementStatus';
import { EnrichedBankAccount } from './types';

const companyName: GridColDef<EnrichedBankAccount> = {
  field: 'companyName',
  headerName: 'Company name',
  width: 400,
  sortable: true,
  filterable: true,
};

const bankAccountName: GridColDef<EnrichedBankAccount> = {
  field: 'fullName',
  headerName: 'Name',
  width: 400,
  sortable: true,
  filterable: true,
};

const bankAccountType: GridColDef<EnrichedBankAccount> = {
  field: 'type',
  headerName: 'Type',
  width: 150,
  sortable: true,
  filterable: true,
};

const bankAccountSubType: GridColDef<EnrichedBankAccount> = {
  field: 'subtype',
  headerName: 'Sub Type',
  width: 150,
  sortable: true,
  filterable: true,
};

const source: GridColDef<EnrichedBankAccount> = {
  field: 'source',
  headerName: 'Source',
  width: 150,
  sortable: false,
  filterable: false,
};

const bankStatement2021Status: GridColDef<EnrichedBankAccount> = {
  field: 'statementRequests2021',
  headerName: 'Statement YE21',
  width: 150,
  sortable: true,
  filterable: true,
  renderCell: (params: GridRenderCellParams<EnrichedBankAccount['statementRequests']>) => {
    const status = getBankStatementStatus(2021, params.row.statementRequests);

    return status ? <Typography color={status.color}>{status.displayName}</Typography> : undefined;
  },
};

const bankStatement2021FinalStatus: GridColDef<EnrichedBankAccount> = {
  field: 'reconciliationStatuses2021',
  headerName: 'Reconciliation YE21',
  width: 200,
  sortable: true,
  filterable: true,
  editable: true,
  renderCell: (params: GridRenderCellParams<EnrichedBankAccount['reconciliationStatuses2021']>) => {
    return reconciliationStatusOptions.find((option) => option.value === params.value)?.name;
  },
  renderEditCell: (params: GridRenderEditCellParams) => {
    return <SelectEditInputCell options={reconciliationStatusOptions} {...params} />;
  },
};

const bankStatement2022Status: GridColDef<EnrichedBankAccount> = {
  field: 'statementRequests2022',
  headerName: 'Statement YE22',
  width: 150,
  sortable: true,
  filterable: true,
  renderCell: (params: GridRenderCellParams<EnrichedBankAccount['statementRequests']>) => {
    const status = getBankStatementStatus(2022, params.row.statementRequests);

    return status ? <Typography color={status.color}>{status.displayName}</Typography> : undefined;
  },
};

const bankStatement2022FinalStatus: GridColDef<EnrichedBankAccount> = {
  field: 'reconciliationStatuses2022',
  headerName: 'Reconciliation YE22',
  width: 200,
  sortable: true,
  filterable: true,
  editable: true,
  renderCell: (params: GridRenderCellParams<EnrichedBankAccount['reconciliationStatuses2022']>) => {
    return reconciliationStatusOptions.find((option) => option.value === params.value)?.name;
  },
  renderEditCell: (params: GridRenderCellParams<EnrichedBankAccount['reconciliationStatuses']>) => {
    return <SelectEditInputCell options={reconciliationStatusOptions} {...params} />;
  },
};

export const bankAccountsDisplayColumns: GridColDef[] = [
  companyName,
  bankAccountName,
  bankAccountType,
  bankAccountSubType,
  source,
  bankStatement2021Status,
  bankStatement2021FinalStatus,
  bankStatement2022Status,
  bankStatement2022FinalStatus,
];
