import { memo, useState } from 'react';
import { IconButton, Snackbar, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { ConfigService } from '../services/config';

export interface DataExplorerLinkProps {
  bankTransactionId: string;
  label?: string | null;
  companyId?: string | null;
}

export const DateExplorerLink = memo((props: DataExplorerLinkProps) => {
  const [copiedTextOpen, setCopiedTextOpen] = useState(false);

  const handleCopyIdClick = () => {
    setCopiedTextOpen(true);
    navigator.clipboard.writeText(props.bankTransactionId);
  };

  return (
    <>
      <Tooltip title="Copy id to clipboard">
        <IconButton onClick={handleCopyIdClick}>
          <ContentCopyIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={copiedTextOpen}
        onClose={() => setCopiedTextOpen(false)}
        autoHideDuration={2000}
        message="Bank transaction id copied to clipboard"
      />
    </>
  );
});
