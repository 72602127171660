import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { StatementsTypes } from '../../services/bank-accounts/types';
import { useCompanies } from '../../shared';
import { BankAccountType, Company } from '../../types';
import { BankAccountStatementRequestStatus } from '../../types/BankAccountStatementRequest';
import { CompanySelector } from '../CompanySelector';
import { bankAccountStatementRequestStatusOptions } from './bankAccountStatementRequestStatuses';
import { BankAccountStatementsSelectedFilters } from './types';

export interface BankTransactionsFiltersProps {
  selectedFilters: BankAccountStatementsSelectedFilters;
  setSelectedFilters: Dispatch<SetStateAction<BankAccountStatementsSelectedFilters>>;
}

export function BankAccountStatementsFilters({ setSelectedFilters }: BankTransactionsFiltersProps) {
  const { companies, isLoading: isLoadingCompanies } = useCompanies();
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [accountType, setAccountType] = useState<BankAccountType | undefined>(undefined);
  const [isConfirmed, setIsConfirmed] = useState<string | undefined>(undefined);
  const [status, setStatus] = useState<BankAccountStatementRequestStatus | undefined>(undefined);
  const [statementTypes, setStatementTypes] = useState<StatementsTypes>(StatementsTypes.OpeningAndClosingBalances);

  useEffect(() => {
    setSelectedFilters((prev: BankAccountStatementsSelectedFilters) => ({ ...prev, companyId: selectedCompany?.id }));
  }, [setSelectedFilters, selectedCompany]);

  useEffect(() => {
    setSelectedFilters((prev: BankAccountStatementsSelectedFilters) => ({
      ...prev,
      bankAccountType: accountType ? accountType : undefined,
    }));
  }, [setSelectedFilters, accountType]);

  useEffect(() => {
    setSelectedFilters((prev: BankAccountStatementsSelectedFilters) => ({
      ...prev,
      isConfirmed: isConfirmed ? isConfirmed === 'true' : undefined,
    }));
  }, [setSelectedFilters, isConfirmed]);

  useEffect(() => {
    setSelectedFilters((prev: BankAccountStatementsSelectedFilters) => ({
      ...prev,
      status: status ? status : undefined,
    }));
  }, [setSelectedFilters, status]);

  useEffect(() => {
    setSelectedFilters((prev: BankAccountStatementsSelectedFilters) => ({
      ...prev,
      statementTypes
    }))}, [setSelectedFilters, statementTypes]);

  return (
    <Stack spacing={1}>
      <h4>Filtering Options</h4>
      <Stack direction="row" justifyContent="flex-start" spacing={1} maxWidth={500}>
        <CompanySelector
          companies={companies || []}
          isLoading={isLoadingCompanies}
          onChange={(company: Company | null | undefined) => setSelectedCompany(company || null)}
          required
          value={selectedCompany?.id}
        />
        <FormControl fullWidth>
          <InputLabel id="status-select-label">Status</InputLabel>
          <Select
            value={status}
            labelId="status-select-label"
            label="Status"
            onChange={(event: SelectChangeEvent) => setStatus(event.target.value as BankAccountStatementRequestStatus)}
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            {bankAccountStatementRequestStatusOptions.map((option) => (
              <MenuItem value={option.value}>{option.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="status-select-label">Statement Type</InputLabel>
          <Select
            value={statementTypes}
            labelId="statement-types-select-label"
            label="Statement Types"
            onChange={(event: SelectChangeEvent) => setStatementTypes(event.target.value as StatementsTypes)}
          >
            <MenuItem value={StatementsTypes.OpeningAndClosingBalances}>
              <em>Closing & Opening Balances</em>
            </MenuItem>
            <MenuItem value={StatementsTypes.OpeningBalances}>
              <em>Opening Balances</em>
            </MenuItem>
            <MenuItem value={StatementsTypes.ClosingBalances}>
              <em>Closing Balances</em>
            </MenuItem>
            <MenuItem value={StatementsTypes.TransactionsBackfill}>
              <em>Transactions Backfill</em>
            </MenuItem>
            <MenuItem value={StatementsTypes.InternalTransferTransactionMatch}>
              <em>Internal Transfer Transactions Upload</em>
            </MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <Stack direction="row" justifyContent="flex-start" spacing={1} maxWidth={500}>
        <FormControl fullWidth>
          <InputLabel id="user-confirm-select-label">User confirm</InputLabel>
          <Select
            value={isConfirmed}
            labelId="user-confirm-select-label"
            label="User Confirms"
            onChange={(event: SelectChangeEvent) => setIsConfirmed(event.target.value)}
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            <MenuItem value="true">Yes</MenuItem>
            <MenuItem value="false">No</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="bank-account-select-label">Bank Account Type</InputLabel>
          <Select
            value={accountType}
            labelId="bank-account-select-label"
            label="Bank Account Type"
            onChange={(event: SelectChangeEvent) => setAccountType(event.target.value as BankAccountType)}
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            <MenuItem value={BankAccountType.CreditCard}>Credit card</MenuItem>
            <MenuItem value={BankAccountType.Depository}>Bank account</MenuItem>
          </Select>
        </FormControl>
      </Stack>
    </Stack>
  );
}
