import { useEffect, useState } from 'react';
import {
  Box,
  FormControlLabel,
  FormControl,
  FormGroup,
  LinearProgress,
  Switch,
  Typography,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { yellow } from '@mui/material/colors';
import { DataGrid, GridRowId, GridSelectionModel } from '@mui/x-data-grid';

import { InternalTransferCandidatesSearcher } from '../../../InternalTransferCandidatesSearcher';
import { MatchedEntityDetails } from '../MatchedEntityDetails';
import { bankTransactionService, InternalTransferCandidate } from '../../../../services/bank-transactions';
import { BankTransactionBusinessEventClassification, PairedEntityType, PairingType } from '../../../../types';
import { CustomNoRowsOverlay } from '../../../../ui/NoRowsOverlay/NoRowsOverlay';
import {
  bankTransactionDisplayColumns,
  bankTransactionWithPairedColumns,
  bankTransactionWithPaypalPairedColumns,
} from '../bankTransactionDisplayColumns';
import type { ContentProps } from '../ContentSelector';
import { usePairedEntities } from '../../../../shared/usePairedEntities';
import { usePairedEntity } from '../../../BankTransactions/hooks/usePairedEntity';

enum SortType {
  DATE,
  AMOUNT,
}

export const InternalTransferDisplay = ({
  bankTransaction,
  onBusinessEventSelectionChange,
  currentBusinessMeaning,
}: ContentProps) => {
  const { pairedEntities } = usePairedEntities(bankTransaction.id, bankTransaction.companyId);
  const [isExactMatchMode, setIsExactMatchMode] = useState(true);
  const [sortType, setSortType] = useState(SortType.AMOUNT);
  const [internalTransferCandidates, setInternalTransferCandidates] = useState<InternalTransferCandidate[]>([]);
  const [visibleInternalTransferCandidates, setVisibleInternalTransferCandidates] = useState<
    InternalTransferCandidate[]
  >([]);
  const [isLoadingInternalTransferCandidates, setIsLoadingInternalTransferCandidates] = useState<boolean>(false);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [selectedPairedEntityId, setSelectedPairedEntityId] = useState<string | undefined>();
  const [searchValue, setSearchValue] = useState<string>('');
  const isPaypal = internalTransferCandidates[0]?.source === 'PAYPAL';

  const { pairedEntity, isLoading: isPairedEntityLoading } = usePairedEntity({
    transactionId: bankTransaction.id,
    companyId: bankTransaction.companyId,
  });

  useEffect(() => {
    setSelectionModel((pairedEntities || []).map((entity) => entity.pairedEntityId));
  }, [pairedEntities]);

  useEffect(() => {
    loadInternalTransferCandidates();
  }, [bankTransaction?.id, isExactMatchMode, sortType]);

  const loadInternalTransferCandidates = async () => {
    setIsLoadingInternalTransferCandidates(true);

    const closeToDate =
      typeof bankTransaction.postedAt === 'string' ? bankTransaction.postedAt : bankTransaction.postedAt.toISOString();
    const internalTransferCandidates = await bankTransactionService.getInternalTransferCandidates({
      bankTransaction,
      ...(!isExactMatchMode
        ? {
            rangePercentageAmount: 0.05,
            allowNotExactDateMatch: true,
            sort: {
              ...(sortType === SortType.AMOUNT && { closeToAmount: -bankTransaction.amountInUsd }),
              ...(sortType === SortType.DATE && { closeToDate: closeToDate }),
            },
          }
        : null),
    });

    setInternalTransferCandidates(internalTransferCandidates);
    setVisibleInternalTransferCandidates(internalTransferCandidates);
    setIsLoadingInternalTransferCandidates(false);
  };

  useEffect(() => {
    if (selectionModel.length === 0 || setSelectedPairedEntityId == null) return;

    onBusinessEventSelectionChange({
      shouldApplyAlways: false,
      pairedEntityId: selectedPairedEntityId,
      pairedEntityType: PairedEntityType.BankTransaction,
      pairingType: PairingType.INTERNAL_TRANSFER,
      businessMeanings: currentBusinessMeaning
        ? [{ businessMeaning: currentBusinessMeaning, amount: bankTransaction.amount }]
        : [],
    });
  }, [selectionModel, setSelectedPairedEntityId]);

  const onCandidatesFiltered = (candidates: InternalTransferCandidate[]) => {
    setVisibleInternalTransferCandidates(searchValue ? candidates : internalTransferCandidates);
  };

  return (
    <>
      <Box display="flex" flexDirection="column" mt={4} sx={{ marginTop: '10px' }}>
        <Typography sx={{ fontWeight: 600, marginBottom: 2 }}>Selected transaction</Typography>
        <div style={{ height: 110, width: '100%' }}>
          <DataGrid
            rows={[bankTransaction]}
            columns={bankTransactionDisplayColumns}
            pageSize={1}
            rowsPerPageOptions={[1]}
            disableSelectionOnClick
            hideFooter
          />
        </div>
      </Box>
      {pairedEntity && (
        <Box>
          <MatchedEntityDetails pairedEntity={pairedEntity}></MatchedEntityDetails>
        </Box>
      )}
      <Box display="flex" flexDirection="column" mt={4} sx={{ marginTop: '10px' }}>
        <Typography sx={{ fontWeight: 600, marginBottom: 2 }}>
          Please select a transaction to complete the transfer
        </Typography>
        <Box
          sx={{
            height: 250,
            width: '100%',
            '& .internal-transfer-row--paired': {
              bgcolor: () => yellow[200],
              '&:hover': {
                bgcolor: () => yellow[200],
              },
            },
          }}
        >
          <FormGroup style={{ flexDirection: 'row' }}>
            <FormControlLabel
              control={<Switch onChange={() => setIsExactMatchMode(!isExactMatchMode)} />}
              label={`Show not only exact match (fee) transactions`}
            />
            {!isExactMatchMode && (
              <FormControl>
                <InputLabel id="sort-candidates">Sort By</InputLabel>
                <Select<SortType>
                  labelId="sort-candidates"
                  id="demo-simple-select"
                  value={sortType}
                  label="Sort"
                  defaultValue={SortType.AMOUNT}
                  onChange={({ target: { value: selectedSort } }) => setSortType(selectedSort as SortType)}
                >
                  <MenuItem value={SortType.AMOUNT}>Closest amount</MenuItem>
                  <MenuItem value={SortType.DATE}>Closest date</MenuItem>
                </Select>
              </FormControl>
            )}
          </FormGroup>
          <InternalTransferCandidatesSearcher
            candidates={internalTransferCandidates}
            onChange={(value) => setSearchValue(value)}
            onCandidatesFiltered={onCandidatesFiltered}
            value={searchValue}
          ></InternalTransferCandidatesSearcher>
          <DataGrid
            sx={{ height: '650px' }}
            components={{
              LoadingOverlay: LinearProgress,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            loading={isLoadingInternalTransferCandidates}
            rows={visibleInternalTransferCandidates}
            columns={isPaypal ? bankTransactionWithPaypalPairedColumns : bankTransactionWithPairedColumns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            selectionModel={selectionModel}
            disableSelectionOnClick
            checkboxSelection
            hideFooterSelectedRowCount
            getRowClassName={(params) =>
              `internal-transfer-row--${
                params.row.businessEvent?.classifications.some(
                  (classification: BankTransactionBusinessEventClassification) => !!classification.pairedEntityId,
                )
                  ? 'paired'
                  : 'none'
              }`
            }
            onSelectionModelChange={(selection: GridSelectionModel) => {
              let resultId: GridRowId | undefined;
              if (selection.length > 1) {
                const selectionSet = new Set(selectionModel);
                const result = selection.filter((s) => !selectionSet.has(s));
                resultId = result[0];
                setSelectionModel(result[0] ? [result[0]] : []);
              } else {
                resultId = selection[0];
                setSelectionModel(selection);
              }

              setSelectedPairedEntityId(resultId as string | undefined);
            }}
          />
        </Box>
      </Box>
    </>
  );
};
