import { memo, type ReactElement, useMemo } from 'react';
import { keyBy } from 'lodash';

import type { BusinessMeaning } from '../types';
import { Autocomplete } from '../ui/Autocomplete';

export interface BusinessMeaningSelectorProps {
  businessMeanings: BusinessMeaning[];
  isLoading?: boolean;
  isMultiple?: boolean;
  onChange?: (businessMeaning: BusinessMeaning[]) => void;
  value?: string[];
  isDisabled?: boolean;
}

export const BusinessMeaningSelector = memo((props: BusinessMeaningSelectorProps): ReactElement => {
  const { businessMeanings, isMultiple, value, isDisabled = false } = props;

  const businessMeaningDictionary = useMemo(() => keyBy(businessMeanings, 'id'), [businessMeanings]);

  const val = value?.map((item) => businessMeaningDictionary[item]).filter(Boolean);

  return (
    <Autocomplete
      isLoading={props.isLoading}
      isMultiple={isMultiple}
      label="Business Meaning"
      onChange={props.onChange}
      optionEqualityFn={(businessMeaning0: BusinessMeaning, businessMeaning1: BusinessMeaning): boolean =>
        businessMeaning0.id === businessMeaning1.id
      }
      optionKeyFn={(businessMeaning: BusinessMeaning) => businessMeaning.id}
      optionLabelFn={(businessMeaning: BusinessMeaning) => businessMeaning.name.trim()}
      options={businessMeanings}
      value={val}
      isDisabled={isDisabled}
    />
  );
});
