import { gqlClient } from '../../shared/gqlClient';
import type { BusinessMeaning } from '../../types';

export class BusinessMeaningsService {
  protected static instance: BusinessMeaningsService;

  static getInstance(): BusinessMeaningsService {
    if (BusinessMeaningsService.instance) {
      return BusinessMeaningsService.instance;
    }

    return (BusinessMeaningsService.instance = new BusinessMeaningsService());
  }

  async getBusinessMeaningsPage(): Promise<BusinessMeaning[]> {
    try {
      const { classificationBusinessEventDefinitions } = await gqlClient.query({
        classificationBusinessEventDefinitions: {
          id: true,
          hideForManualClassification: true,
          name: true,
          moneyDirection: true,
          vendorRequired: true,
          forceShowInEventsListings: true,
        },
      });

      return classificationBusinessEventDefinitions as BusinessMeaning[];
    } catch (err) {
      console.error(err);
      return [];
    }
  }
}
