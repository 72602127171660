import { useQuery } from '@tanstack/react-query';
import { BankTransactionsService } from '../services/bank-transactions';
import { PairedEntity } from '../types';

export interface UseBankAccountsResult {
  pairedEntities?: PairedEntity[] | null;
  isLoading: boolean;
}

export const usePairedEntities = (
  transactionId: string | undefined,
  companyId: string | undefined,
): UseBankAccountsResult => {
  const { data, isLoading } = useQuery(
    ['pairedEntities', transactionId],
    () => BankTransactionsService.getInstance().getBankTransactionPairedEntities(transactionId!, companyId!),
    { enabled: !!transactionId && !!companyId, refetchOnWindowFocus: false, refetchInterval: false },
  );

  return { pairedEntities: data?.pairedEntities, isLoading };
};
