import { memo, type MouseEvent, type ReactElement } from 'react';
import { Badge, Chip, Tooltip } from '@mui/material';
import _ from 'lodash';

export interface BusinessMeaningChipProps {
  businessEventRuleType?: string | null;
  businessEventRuleId?: string | null;
  description: string;
  onClick?: () => void;
}

export const BusinessMeaningChip = memo((props: BusinessMeaningChipProps): ReactElement => {
  const { businessEventRuleType, businessEventRuleId } = props;

  const handleClick = (event: MouseEvent) => {
    event.stopPropagation();

    props.onClick?.();
  };

  return (
    <Tooltip title={`[${businessEventRuleType}]: ${businessEventRuleId}`}>
      <Badge badgeContent={initials(businessEventRuleType)} color={'primary'}>
        <Chip label={props.description} variant="outlined" color="default" onClick={handleClick} />
      </Badge>
    </Tooltip>
  );
});

const initials = (str: string | null | undefined) =>
  str
    ? _.startCase(str)
        .split(' ')
        .map((word) => word[0])
        .join('')
    : '';
