import { type SyntheticEvent, useCallback } from 'react';
import { createFilterOptions, type FilterOptionsState } from '@mui/material';

import { Vendor } from '../../types';
import type { MerchantSelectorProps } from './types';
import { CompanyVendorsService } from '../../services/company-vendors';
import { Merchant } from '../../__generated-global__';
import { useAuth0 } from '@auth0/auth0-react';

type UseHandleSelectionChangeArgs = Pick<MerchantSelectorProps, 'companyId' | 'canAdd' | 'onChange'> & {
  setIsAdding: (a: boolean) => void;
};

export function useHandleSelectionChange(args: UseHandleSelectionChangeArgs) {
  const { canAdd, onChange } = args;
  const { user } = useAuth0();

  return useCallback(
    async (event: SyntheticEvent<Element, Event>, value: unknown) => {
      if (value == null) {
        onChange?.(undefined);
        return;
      }

      if (typeof value === 'string') {
        const vendor = await addNewLocalVendor(value, args, user?.email);

        return onChange?.({ ...vendor, type: 'VENDOR' });
      }

      const { inputValue } = value as { inputValue: string };

      if (!inputValue) {
        onChange?.(value as any);
        return;
      }

      if (!canAdd) return;

      const vendor = await addNewLocalVendor(inputValue, args);

      return onChange?.({ ...vendor, type: 'VENDOR' });
    },
    [canAdd, onChange],
  );
}

const addNewLocalVendor = async (
  name: string,
  { companyId, setIsAdding }: UseHandleSelectionChangeArgs,
  creatorEmail?: string,
) => {
  if (companyId == null) throw new Error('Cannot add a local vendor without a company id!');

  setIsAdding(true);

  const createdCompanyVendor = await CompanyVendorsService.getInstance().createCompanyVendor(
    name.trim(),
    companyId,
    creatorEmail,
  );

  console.log('createdCompanyVendor', createdCompanyVendor);

  setIsAdding(false);

  return { ...createdCompanyVendor, logo: createdCompanyVendor.logoUrl };
};

const filterOptions = createFilterOptions({ limit: 100 });

export function useFilter(canAdd?: boolean | null) {
  return useCallback(
    (options: unknown[], state: FilterOptionsState<unknown>) => {
      const filtered = filterOptions(options, state);

      const { inputValue } = state;

      if (!inputValue) {
        return filtered;
      }

      const isExisting = options.some((option) => inputValue === (option as any).name);

      if (!isExisting && canAdd) {
        filtered.push({ label: `Add "${inputValue}"`, inputValue });
      }

      return filtered;
    },
    [canAdd],
  );
}

export function useGetOptionLabel() {
  return useCallback((option: unknown) => {
    if (typeof option === 'string') return option;

    if (isInputOption(option)) {
      return option.inputValue;
    }

    const { name } = option as Merchant;

    return name;
  }, []);
}

const isInputOption = (option: unknown): option is { inputValue: string; label: string } =>
  typeof option === 'object' && option != null && 'inputValue' in option;
