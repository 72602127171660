import { useQuery } from '@tanstack/react-query';
import { gqlClient } from './gqlClient';

export const fetchCompanies = async () => {
  const { listCompanies } = await gqlClient.query({
    listCompanies: {
      id: true,
      name: true,
      email: true,
      isArchived: true,
      isTestCompany: true,
      dataRestrictionTime: true,
    },
  });

  return listCompanies;
};

export const useCompanies = () => {
  const { data: companies, isLoading } = useQuery(['companies'], async () => await fetchCompanies(), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return { companies, isLoading };
};
