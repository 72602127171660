import { memo, type ReactElement, useMemo } from 'react';
import { keyBy } from 'lodash';

import { Autocomplete } from '../ui/Autocomplete';

export interface EnrichmentRuleTypeSelectorProps {
  label?: string | null;
  onChange?: (classifierType: any) => void;
  value?: any;
}

interface Option {
  id: string;
  name: string;
}

const types: Option[] = [
  {
    id: '{null}',
    name: '[Unclassified]',
  },
  {
    id: 'finaloop-team',
    name: 'Finaloop Team',
  },
  {
    id: 'finaloop-customer',
    name: 'Finaloop Customer',
  },
  {
    id: 'customer-local-similar-transaction-model',
    name: 'Customer local similar transaction model',
  },
  {
    id: 'global-enrichment-rule',
    name: 'Global enrichment rule',
  },
  {
    id: 'global-paypal-enrichment-rule',
    name: 'Global PayPal enrichment rule',
  },
  {
    id: 'global-vendor-classification',
    name: 'Global vendor classification',
  },
  {
    id: 'local-paypal-similar-transaction-model',
    name: 'Local PayPal similar transaction model',
  },
  {
    id: 'local-similar-transaction-model',
    name: 'Local similar transaction model',
  },
  {
    id: 'local-vendor-extraction',
    name: 'Local vendor extraction',
  },
  {
    id: 'manual-vendor-tagging',
    name: 'Manual vendor tagging',
  },
  {
    id: 'ntropy',
    name: 'Ntropy',
  },
  {
    id: 'paypal-company-vendor',
    name: 'PayPal company vendor',
  },
];

export const EnrichmentRuleTypeSelector = memo((props: EnrichmentRuleTypeSelectorProps): ReactElement => {
  const { value } = props;

  const typeDictionary = useMemo(() => keyBy(types, 'id'), []);

  const innerValue = typeDictionary[value || ''];

  return (
    <Autocomplete
      label={props.label}
      onChange={(value) => props.onChange?.(value[0]?.id)}
      optionEqualityFn={(type0: Option, type1: Option): boolean => type0.id === type1.id}
      optionKeyFn={(type: Option) => type.id}
      optionLabelFn={(type: Option) => type.name.trim()}
      options={types}
      value={innerValue ? [innerValue] : []}
    />
  );
});
