import { LoadingButton } from '@mui/lab';
import { Button, Card, CardActions, CardContent, Popover } from '@mui/material';
import { type ReactElement, useState } from 'react';
import { toast } from 'react-toastify';
import { BankTransactionsService } from '../../services/bank-transactions';
import type { EnrichedBankTransaction } from '../../types';

export interface Props {
  selectedBankTransactions: EnrichedBankTransaction[];
  onBankTransactionsExcluded: (bankTransactions: EnrichedBankTransaction[]) => void;
}

export const BankTransactionsExcludeButton = ({
  selectedBankTransactions,
  onBankTransactionsExcluded,
}: Props): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const closePopover = () => setAnchorEl(null);

  return (
    <>
      <Button
        disabled={selectedBankTransactions.length === 0}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        variant="contained"
        color="error"
      >
        Exclude Transactions
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <ConfirmExclusionContent
          bankTransactions={selectedBankTransactions}
          closePopover={closePopover}
          onBankTransactionsExcluded={onBankTransactionsExcluded}
        />
      </Popover>
    </>
  );
};

const ConfirmExclusionContent = ({
  bankTransactions,
  closePopover,
  onBankTransactionsExcluded,
}: {
  bankTransactions: EnrichedBankTransaction[];
  closePopover: () => void;
  onBankTransactionsExcluded: (bankTransactions: EnrichedBankTransaction[]) => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const excludeTransactions = async () => {
    setIsLoading(true);

    try {
      const { excludedTransactions, notExcludedTransactions } =
        await BankTransactionsService.getInstance().excludeBankTransactions(bankTransactions);

      onBankTransactionsExcluded(excludedTransactions);

      if (notExcludedTransactions.length === 0) {
        return toast.success(`Successfully excluded ${bankTransactions.length} transactions!`);
      }

      if (excludedTransactions.length === 0) {
        return toast.error(`Failed to exclude ${bankTransactions.length} transactions!`);
      }

      toast.warn(
        `Successfully excluded ${excludedTransactions.length} transactions, but failed to exclude ${notExcludedTransactions.length} transactions!`,
      );
    } finally {
      setIsLoading(false);
      closePopover();
    }
  };

  return (
    <Card>
      <CardContent>
        Are you sure you want to exclude {bankTransactions.length} bank transaction
        {bankTransactions.length === 1 ? '' : 's'}?
      </CardContent>

      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button size="small" onClick={closePopover}>
          Cancel
        </Button>
        <LoadingButton size="small" color="error" loading={isLoading} onClick={excludeTransactions}>
          Exclude
        </LoadingButton>
      </CardActions>
    </Card>
  );
};
