import { NewVendor } from './NewVendor';

export enum VendorSource {
  BILL_COM = 'BILL_COM',
  SETTLE = 'SETTLE',
  NTROPY = 'NTROPY',
  FINALOOP = 'FINALOOP',
  FINALOOP_TRANSACTIONS = 'FINALOOP_TRANSACTIONS',
  FINALOOP_TRANSACTIONS_MACHINE = 'FINALOOP_TRANSACTIONS_MACHINE',
  FINALOOP_MACHINE = 'FINALOOP_MACHINE',
  QUICKBOOKS = 'QUICKBOOKS',
  FINALOOP_BILLS = 'FINALOOP_BILLS',
}

export enum VendorType {
  LOCAL = 'LOCAL',
  GLOBAL = 'GLOBAL',
}

export interface Vendor {
  id: string;
  name: string;
  logoUrl?: string | null;
  source: VendorSource;
  type: VendorType;
}

export interface VendorClassification {
  vendor: Vendor;
  isApplyAlways: boolean;
}

export interface VendorDialogResult {
  vendor: Vendor | NewVendor;
  isApplyAlways: boolean;
}
