import { BankAccountStatementRequest } from '../../../../types/BankAccountStatementRequest';
import { statusMap } from '../../../BankAccountStatements/bankAccountStatementRequestStatuses';

export const getBankStatementStatus = (year: number, statementRequests: BankAccountStatementRequest[]) => {
  const statements = statementRequests.filter((statement) => new Date(statement.fromDate).getFullYear() === year);

  if (!statements?.length) {
    return undefined;
  }

  if (!!statements?.length && statements.length > 1) {
    console.log(statements);
    throw new Error('Duplicate statement exists');
  }

  return statusMap[statements[0].status];
};
