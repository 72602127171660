import type { BankTransaction } from './BankTransaction';
import type { BusinessMeaning } from './BusinessMeaning';
import type { VendorSource } from './Vendor';

export enum LocalRuleType {
  Vendor = 'vendor',
  VendorToBusinessMeaning = 'vendorToBusinessMeaning',
}

export type LocalRule = LocalVendorRule | LocalVendorToBusinessMeaningRule;

export interface BaseLocalRule<RuleType extends LocalRuleType> {
  classifierType: string;
  companyId: string;
  id: string;
  updatedAtMS: number;
  ruleType: RuleType;
}

export interface LocalVendorRule extends BaseLocalRule<LocalRuleType.Vendor> {
  bankTransactionId: string;
  bankTransaction: BankTransaction;
  vendorId: string;
  vendorSource: VendorSource;
}

export enum Direction {
  MoneyIn = 'money_in',
  MoneyOut = 'money_out',
}

export type Condition =
  | {
      operator: 'equal';
      path: 'vendorId';
      value: string;
    }
  | {
      operator: 'equal';
      path: 'direction';
      value: Direction;
    };

export interface LocalVendorToBusinessMeaningRule extends BaseLocalRule<LocalRuleType.VendorToBusinessMeaning> {
  classifierType: string;
  logicalOperator: 'AND';
  conditions: Condition[];
  outcome: { business_event: Pick<BusinessMeaning, 'id' | 'name'> };
  metadata: BankTransaction;
}
