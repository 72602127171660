import {
  addMinutes,
  differenceInDays,
  eachDayOfInterval,
  eachMonthOfInterval,
  eachYearOfInterval,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format,
  isValid,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
  subMilliseconds,
  subMonths,
  subYears,
} from 'date-fns';

type DateLike = Date | string | number;

const toDateObject = (date: DateLike): Date => {
  if (typeof date === 'string' && !date.endsWith('Z')) throw new Error('Date must be in UTC format');
  return new Date(date);
};

const toLocalDateObject = (date: DateLike): Date => {
  return utcToLocalTime(toDateObject(date));
};

const withSafeManipulation = (date: DateLike, manipulation: (date: Date) => Date): Date => {
  const localDate = toLocalDateObject(date);
  const result = manipulation(localDate);
  const backToUtc = localTimeToUTC(result);
  return backToUtc;
};

export const utcToLocalTime = (utcDate: Date): Date => {
  return addMinutes(utcDate, utcDate.getTimezoneOffset());
};

export const localTimeToUTC = (localDate: Date): Date => {
  return addMinutes(localDate, -localDate.getTimezoneOffset());
};

export const UTCStartOfDay = (date: DateLike): Date => {
  const dateCopy = toDateObject(date);
  dateCopy.setUTCHours(0, 0, 0, 0);
  return dateCopy;
};

export const UTCStartOfToday = (): Date => {
  return UTCStartOfDay(new Date());
};

export const UTCEndOfDay = (date: DateLike): Date => {
  return withSafeManipulation(date, endOfDay);
};

export const UTCEndOfToday = (): Date => {
  return withSafeManipulation(new Date(), endOfDay);
};

export const UTCEndOfYesterday = (): Date => {
  return new Date(UTCStartOfToday().getTime() - 1);
};

export const UTCStartOfWeek = (date: DateLike): Date => {
  return withSafeManipulation(date, startOfWeek);
};

export const UTCEndOfWeek = (date: DateLike): Date => {
  return withSafeManipulation(date, endOfWeek);
};

export const UTCStartOfMonth = (date: DateLike): Date => {
  return withSafeManipulation(date, startOfMonth);
};

export const UTCEndOfMonth = (date: DateLike): Date => {
  return withSafeManipulation(date, endOfMonth);
};

export const UTCStartOfQuarter = (date: DateLike): Date => {
  return withSafeManipulation(date, startOfQuarter);
};

export const UTCEndOfQuarter = (date: DateLike): Date => {
  return withSafeManipulation(date, endOfQuarter);
};

export const UTCStartOfYear = (date: DateLike): Date => {
  return withSafeManipulation(date, startOfYear);
};

export const UTCEndOfYear = (date: DateLike): Date => {
  return withSafeManipulation(date, endOfYear);
};

export const UTCStartOfPreviousYear = (date: DateLike): Date => {
  return UTCStartOfYear(UTCSubYears(date, 1));
};

export const UTCEndOfPreviousYear = (date: DateLike): Date => {
  return UTCEndOfYear(UTCSubYears(date, 1));
};

export const UTCEachDayOfInterval = (from: DateLike, to: DateLike): Date[] => {
  const [fromDate, toDate] = [toLocalDateObject(from), toLocalDateObject(to)];
  const localEachDayOfInterval = eachDayOfInterval({ start: fromDate, end: toDate });
  return localEachDayOfInterval.map((date) => localTimeToUTC(date));
};

export const UTCEachMonthOfInterval = (from: DateLike, to: DateLike): Date[] => {
  const [fromDate, toDate] = [toLocalDateObject(from), toLocalDateObject(to)];
  const localEachDayOfInterval = eachMonthOfInterval({ start: fromDate, end: toDate });
  return localEachDayOfInterval.map((date) => localTimeToUTC(date));
};

export const UTCEachYearOfInterval = (from: DateLike, to: DateLike): Date[] => {
  const [fromDate, toDate] = [toLocalDateObject(from), toLocalDateObject(to)];
  const localEachDayOfInterval = eachYearOfInterval({ start: fromDate, end: toDate });
  return localEachDayOfInterval.map((date) => localTimeToUTC(date));
};

export const UTCDifferenceInDays = (from: DateLike, to: DateLike) => {
  const localFrom = toLocalDateObject(from);
  const localTo = toLocalDateObject(to);
  return differenceInDays(localFrom, localTo);
};

export const UTCFormat = (date: DateLike, dateFormat: string = 'MMM d, yyyy'): string => {
  const localDate = toLocalDateObject(date);
  return format(localDate, dateFormat);
};

export const UTCSubDays = (date: DateLike, days: number): Date => {
  return withSafeManipulation(date, (d) => subDays(d, days));
};

export const UTCSubMilliseconds = (date: DateLike, amount: number): Date => {
  return withSafeManipulation(date, (d) => subMilliseconds(d, amount));
};

export const UTCSubMonths = (date: DateLike, months: number): Date => {
  return withSafeManipulation(date, (d) => subMonths(d, months));
};

export const UTCSubYears = (date: DateLike, years: number): Date => {
  return withSafeManipulation(date, (d) => subYears(d, years));
};

export const UTCParse = (date: string): Date => {
  const dateObj = new Date(date);
  return date.endsWith('Z') ? dateObj : localTimeToUTC(dateObj);
};

const SEARCH_PARAM_ISO_TIME_SUFFIX = 'T00:00:00.000Z';
export const parseSearchParamIsoDate = (searchParamIsoDateWithoutTime: string, fallbackDate?: Date): Date | null => {
  try {
    const date = UTCParse(searchParamIsoDateWithoutTime + SEARCH_PARAM_ISO_TIME_SUFFIX);

    if (isValid(date)) return date;
    return fallbackDate ? fallbackDate : date;
  } catch {
    return null;
  }
};

export const isOneMonthTimeFrame = (fromTimeInMs: number, toTimeInMs: number): boolean => {
  const fromDate = new Date(fromTimeInMs);
  const toDate = new Date(toTimeInMs);

  return fromDate.getUTCFullYear() === toDate.getUTCFullYear() && fromDate.getUTCMonth() === toDate.getUTCMonth();
};
