import { Typography } from '@mui/material';

export function formatMoney(amount: number, currency: string = 'USD'): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'code',
    currency,
  }).format(amount);
}

export function formatMoneyWithForex(amount: number, amountInUSD: number, currency: string) {
  return (
    <div>
      <div>{formatMoney(amountInUSD)}</div>
      <Typography variant="caption" color="text.secondary" gutterBottom>
        {formatMoney(amount, currency)}
      </Typography>
    </div>
  );
}
