import { LoadingButton } from '@mui/lab';
import { Button, Card, CardActions, CardContent, Popover } from '@mui/material';
import { chunk, uniq } from 'lodash';
import { type ReactElement, useState } from 'react';
import { toast } from 'react-toastify';
import { BankTransactionsService } from '../../services/bank-transactions';
import type { EnrichedBankTransaction } from '../../types';
import { BankTransactionSource } from '../../types';
import { PromisePool } from '@supercharge/promise-pool';

export interface Props {
  selectedBankTransactions: EnrichedBankTransaction[];
  onBankTransactionsDeleted: (bankTransactions: EnrichedBankTransaction[]) => void;
}

const DELETABLE_SOURCES = new Set([
  BankTransactionSource.FinlaoopBankTransactionsUpload,
  BankTransactionSource.ReportedBusinessBankTransactions,
]);

export const BankTransactionsDeleteButton = ({
  selectedBankTransactions,
  onBankTransactionsDeleted,
}: Props): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const canDelete = getUndeletableTransactions(selectedBankTransactions).length === 0;
  const closePopover = () => setAnchorEl(null);

  return (
    <>
      <Button
        disabled={selectedBankTransactions.length === 0}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        variant="contained"
        color="error"
      >
        Delete Transactions
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {canDelete ? (
          <ConfirmDeletionContent
            bankTransactions={selectedBankTransactions}
            closePopover={closePopover}
            onBankTransactionsDeleted={onBankTransactionsDeleted}
          />
        ) : (
          <CannotDeleteContent bankTransactions={selectedBankTransactions} />
        )}
      </Popover>
    </>
  );
};

const getUndeletableTransactions = (bankTransactions: EnrichedBankTransaction[]): EnrichedBankTransaction[] =>
  bankTransactions.filter((bankTransaction) => !DELETABLE_SOURCES.has(bankTransaction.source));

const ConfirmDeletionContent = ({
  bankTransactions,
  closePopover,
  onBankTransactionsDeleted,
}: {
  bankTransactions: EnrichedBankTransaction[];
  closePopover: () => void;
  onBankTransactionsDeleted: (bankTransactions: EnrichedBankTransaction[]) => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const getTransactionsByIds = (bankTransactionIds: string[]) =>
    bankTransactions.filter((bankTransaction) => bankTransactionIds.includes(bankTransaction.id));

  const deleteTransactions = async () => {
    setIsLoading(true);

    const companyId = bankTransactions[0].companyId;
    const chunks = chunk(uniq(bankTransactions.map((bankTransaction) => bankTransaction.id)), 50);

    const allDeletedTransactions: EnrichedBankTransaction[] = [];
    const allNotDeletedTransactions: EnrichedBankTransaction[] = [];

    await PromisePool.withConcurrency(8)
      .for(chunks)
      .process(async (bankTransactionIds) => {
        try {
          const { deletedTransactionIds, notDeletedTransactionIds } =
            await BankTransactionsService.getInstance().deleteBankTransactions({ companyId, bankTransactionIds });

          allDeletedTransactions.push(...getTransactionsByIds(deletedTransactionIds));
          allNotDeletedTransactions.push(...getTransactionsByIds(notDeletedTransactionIds));
        } catch (e) {
          allNotDeletedTransactions.push(...getTransactionsByIds(bankTransactionIds));
        }
      });

    onBankTransactionsDeleted(allDeletedTransactions);

    if (allNotDeletedTransactions.length !== 0) {
      toast.error(`Failed to delete some of the transactions! Please refresh to get the latest data.`);
    }

    toast.success(`Successfully deleted ${allDeletedTransactions.length} transactions!`);

    setIsLoading(false);
    closePopover();
  };

  return (
    <Card>
      <CardContent>
        Are you sure you want to delete {bankTransactions.length} bank transaction
        {bankTransactions.length === 1 ? '' : 's'}?
      </CardContent>

      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button size="small" onClick={closePopover}>
          Cancel
        </Button>
        <LoadingButton size="small" color="error" loading={isLoading} onClick={deleteTransactions}>
          Delete
        </LoadingButton>
      </CardActions>
    </Card>
  );
};

const CannotDeleteContent = ({ bankTransactions }: { bankTransactions: EnrichedBankTransaction[] }) => {
  const undeletableTransactions = getUndeletableTransactions(bankTransactions);
  const invalidSources = uniq(undeletableTransactions.map((transaction) => transaction.source));

  return (
    <Card>
      <CardContent>Cannot delete transactions from the following sources: [{invalidSources.join(', ')}].</CardContent>
    </Card>
  );
};
