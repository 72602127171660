import { useQuery } from '@tanstack/react-query';
import { gqlClient } from './gqlClient';

export function useClassificationTags() {
  const { data, isLoading } = useQuery(
    ['classificationTags'],
    () => gqlClient.query({
        classificationTags: { __scalar: true, classification: { __scalar: true } }}),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: false,
      select: (data) => data.classificationTags,
    },
  );

  return { classificationTags: data, isLoading };
}