import { Box, Checkbox, FormControlLabel, FormGroup, LinearProgress, Tooltip, Typography } from '@mui/material';
import { ChangeEvent, memo, ReactElement, useEffect, useMemo, useState } from 'react';
import { ASK_THE_USER_ALWAYS, BankTransactionClassifierType, EnrichedBankTransaction } from '../../types';
import { MerchantSelector } from '../MerchantSelector';
import { DateExplorerLink } from '../DataExplorerLink';
import { CustomNoRowsOverlay } from '../../ui/NoRowsOverlay/NoRowsOverlay';
import {
  bankTransactionDisplayColumns,
  bankTransactionWithPaypalColumns,
} from './BusinessEventSelection/bankTransactionDisplayColumns';
import { DataGrid } from '@mui/x-data-grid';
import { BankTransactionsService } from '../../services/bank-transactions';
import { CategorizationResult } from './index';
import { MerchantType } from '../../__genql__/banks';
import { CompanyAffiliateType } from '../../__generated-global__';

export interface MerchantSelectionProps {
  companyId: string;
  bankTransaction: EnrichedBankTransaction;
  onMerchantSelectionChange: (result: MerchantSelectionResult) => void;
  categorizationResult: CategorizationResult;
  isDisabled: boolean;
}

export interface MerchantSelectionResult {
  merchant: LeanMerchant | null;
  shouldApplyMerchantToSimilarTransactions: boolean;
}

export interface LeanMerchant {
  id: string;
  name: string;
  type: MerchantType;
  merchantSubtype?: CompanyAffiliateType;
}

export const MerchantSelection = memo(
  ({
    companyId,
    bankTransaction,
    onMerchantSelectionChange,
    categorizationResult: { merchantResult },
    isDisabled,
  }: MerchantSelectionProps): ReactElement => {
    const [currentMerchant, setCurrentMerchant] = useState<LeanMerchant | undefined>(
      merchantResult.merchant || undefined,
    );

    const isClassifiedByLocalRule =
      bankTransaction.merchant?.classifierType === BankTransactionClassifierType.LOCAL_RULE ||
      !!bankTransaction.merchant?.classifiedBy?.includes('#vendor');

    const [isApplyAlways, setIsApplyAlways] = useState<boolean>(isClassifiedByLocalRule);
    const [isLoadingSimilarTransactions, setIsLoadingSimilarTransactions] = useState(false);
    const [similarTransactions, setSimilarTransactions] = useState<EnrichedBankTransaction[]>([]);
    const isPaypal = similarTransactions.length > 0 && similarTransactions[0].source === 'PAYPAL';

    const isLocalRuleAllowed = useMemo(() => {
      const { merchant, businessEvent } = bankTransaction || {};

      // We only allow local rule for VENDOR type merchants for now.
      if (merchantResult.merchant != null && merchantResult.merchant.type !== 'VENDOR') return false;

      return (
        businessEvent?.classifications?.[0]?.businessEvent !== ASK_THE_USER_ALWAYS &&
        (!merchant?.classifierType ||
          ![
            BankTransactionClassifierType.FINALOOP_CUSTOMER,
            BankTransactionClassifierType.FINALOOP_TEAM,
            BankTransactionClassifierType.USER,
          ].includes(merchant.classifierType) ||
          !!bankTransaction?.merchant?.classifiedBy?.includes('#vendor'))
      );
    }, [bankTransaction]);

    const handleMerchantChange = (merchant: LeanMerchant | undefined) => {
      setCurrentMerchant(merchant || undefined);
    };

    const handleApplyAlwaysChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (!isLocalRuleAllowed) {
        setIsApplyAlways(false);
        console.warn('Vendor local rule is not allowed for this transaction!');
      }

      setIsApplyAlways(event.target.checked);
    };

    useEffect(() => {
      isApplyAlways && getSimilarTransactions();
    }, [isApplyAlways]);

    useEffect(() => {
      onMerchantSelectionChange({
        merchant: currentMerchant || null,
        shouldApplyMerchantToSimilarTransactions: currentMerchant != null ? isApplyAlways : false,
      });
    }, [onMerchantSelectionChange, currentMerchant, isApplyAlways]);

    const getSimilarTransactions = async () => {
      setIsLoadingSimilarTransactions(true);
      const transactions = await BankTransactionsService.getInstance().getSimilarBankTransactionsPage(
        bankTransaction!.id,
        bankTransaction!.companyId,
      );

      setSimilarTransactions(transactions.items);
      setIsLoadingSimilarTransactions(false);
    };

    const localRulesNotAllowedTooltipMessage = (
      <Typography fontSize={14}>
        Local rule for this transaction is not allowed as it was previously classified with "Ask the user always"
        classification, the merchant is not of type "vendor", or it was classified by a user or a team.
      </Typography>
    );

    return (
      <>
        <MerchantSelector canAdd onChange={handleMerchantChange} value={currentMerchant} companyId={companyId} isDisabled={isDisabled} />

        <Tooltip title={!isLocalRuleAllowed ? localRulesNotAllowedTooltipMessage : ''}>
          <FormGroup sx={{ display: 'inline-flex', userSelect: 'none' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isLocalRuleAllowed && isApplyAlways}
                  disabled={!isLocalRuleAllowed || !currentMerchant || isDisabled}
                  inputProps={{ 'aria-label': 'controlled' }}
                  onChange={handleApplyAlwaysChange}
                />
              }
              label="Apply merchant to similar transactions"
            />
          </FormGroup>
        </Tooltip>

        {isClassifiedByLocalRule && bankTransaction.merchant?.classifiedBy && (
          <Box my={2}>
            <Typography>
              Base transaction link:{' '}
              <DateExplorerLink bankTransactionId={bankTransaction.merchant!.classifiedBy.split('#')[0]} />
            </Typography>
          </Box>
        )}

        {isApplyAlways && (
          <div style={{ height: 300, width: '100%' }}>
            <DataGrid
              components={{
                LoadingOverlay: LinearProgress,
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              loading={isLoadingSimilarTransactions}
              rows={similarTransactions}
              columns={isPaypal ? bankTransactionWithPaypalColumns : bankTransactionDisplayColumns}
              disableSelectionOnClick
            />
          </div>
        )}
      </>
    );
  },
);
