export default {
    "scalars": [
        1,
        2,
        4,
        5,
        6,
        9,
        19,
        23,
        24,
        25,
        26,
        27
    ],
    "types": {
        "BankTransactionMemo": {
            "content": [
                1
            ],
            "updatedAt": [
                24
            ],
            "__typename": [
                1
            ]
        },
        "String": {},
        "MerchantType": {},
        "BankTransactionMerchant": {
            "id": [
                4
            ],
            "name": [
                1
            ],
            "type": [
                2
            ],
            "logo": [
                1
            ],
            "classifiedAt": [
                24
            ],
            "classifiedBy": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ID": {},
        "PairedEntityType": {},
        "PairingType": {},
        "AskTheUserResult": {
            "type": [
                1
            ],
            "data": [
                27
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionBusinessEventClassification": {
            "businessEvent": [
                1
            ],
            "amount": [
                9
            ],
            "pairedEntityId": [
                1
            ],
            "pairedEntityType": [
                5
            ],
            "pairingType": [
                6
            ],
            "classificationTagId": [
                1
            ],
            "classificationTagText": [
                1
            ],
            "classificationText": [
                1
            ],
            "description": [
                1
            ],
            "extraData": [
                27
            ],
            "askTheUserResult": [
                7
            ],
            "__typename": [
                1
            ]
        },
        "Float": {},
        "BankTransactionBusinessEvent": {
            "classifiedAt": [
                24
            ],
            "classifierType": [
                1
            ],
            "classifiedBy": [
                1
            ],
            "classifications": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "BankAccount": {
            "id": [
                4
            ],
            "integrationAccountId": [
                4
            ],
            "source": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "type": [
                1
            ],
            "subtype": [
                1
            ],
            "currency": [
                1
            ],
            "institutionName": [
                1
            ],
            "name": [
                1
            ],
            "fullName": [
                1
            ],
            "mask": [
                1
            ],
            "connectedAt": [
                24
            ],
            "__typename": [
                1
            ]
        },
        "BankTransaction": {
            "id": [
                4
            ],
            "companyId": [
                4
            ],
            "bankAccount": [
                11
            ],
            "postedAt": [
                24
            ],
            "amount": [
                9
            ],
            "currency": [
                1
            ],
            "amountInUsd": [
                9
            ],
            "merchant": [
                3
            ],
            "businessEvent": [
                10
            ],
            "description": [
                1
            ],
            "userMemo": [
                0
            ],
            "processedByMachineAt": [
                24
            ],
            "lastProcessedByMachineAt": [
                24
            ],
            "applyingClassificationJobId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Query": {
            "doSomething": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Mutation": {
            "bulkCategorizeTransactions": [
                22,
                {
                    "bulkCategorizeTransactionsArgs": [
                        16,
                        "BulkCategorizeTransactionsArgs!"
                    ]
                }
            ],
            "__typename": [
                1
            ]
        },
        "UpdateMemoArgs": {
            "bankTransactionId": [
                4
            ],
            "content": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BulkCategorizeTransactionsArgs": {
            "companyId": [
                26
            ],
            "bankTransactionIds": [
                4
            ],
            "merchant": [
                17
            ],
            "businessEvent": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "MerchantInput": {
            "id": [
                4
            ],
            "type": [
                2
            ],
            "__typename": [
                1
            ]
        },
        "MerchantCategorizationInput": {
            "merchant": [
                17
            ],
            "shouldApplyAlways": [
                19
            ],
            "__typename": [
                1
            ]
        },
        "Boolean": {},
        "BusinessEventCategorizationInput": {
            "classifications": [
                21
            ],
            "shouldApplyAlways": [
                19
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationInput": {
            "businessEvent": [
                1
            ],
            "amount": [
                9
            ],
            "description": [
                1
            ],
            "pairedEntityId": [
                1
            ],
            "pairedEntityType": [
                5
            ],
            "pairingType": [
                6
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionCategorizationResult": {
            "status": [
                23
            ],
            "bankTransaction": [
                12
            ],
            "error": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionCategorizationResultStatus": {},
        "Date": {},
        "UserToken": {},
        "RequestedCompanyIdScalar": {},
        "JSONObject": {}
    }
}