import { Box, LinearProgress, Typography } from '@mui/material';
import { yellow } from '@mui/material/colors';
import { DataGrid, GridRowId, GridRowModel, GridSelectionModel } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { PairedEntityType, PairingType } from '../../../../types';
import { CustomNoRowsOverlay } from '../../../../ui/NoRowsOverlay/NoRowsOverlay';
import { bankTransactionDisplayColumns } from '../bankTransactionDisplayColumns';
import type { ContentProps } from '../ContentSelector';
import { paymentCheckDisplayColumns } from './paymentCheckDisplayColumns';
import { usePairedEntities } from '../../../../shared/usePairedEntities';
import { useQuery } from '@tanstack/react-query';
import { gqlClient } from '../../../../shared/gqlClient';
import { PaymentCheck } from '../../../../__generated-global__';

export const PaymentCheckDisplay = ({
  bankTransaction,
  onBusinessEventSelectionChange,
  currentBusinessMeaning,
}: ContentProps) => {
  const { companyId, amount, currency } = bankTransaction;
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [selectedPairedEntityId, setSelectedPairedEntityId] = useState<string | undefined>();

  const { data: paymentChecks = [], isLoading } = useQuery(['paymentChecks', companyId, amount, currency], async () => {
    const { paymentChecks } = await gqlClient.query({
      __name: 'paymentChecks',
      paymentChecks: {
        __args: { input: { companyId, amount: amount * -1, currency } },
        id: true,
        amount: true,
        currency: true,
        amountInHomeCurrency: true,
        postedAt: true,
        source: true,
        matchedBankTransaction: {
          id: true,
          amount: true,
          currency: true,
          description: true,
          source: true,
        },
      },
    });

    return paymentChecks;
  });

  useEffect(() => {
    const pairedCheck = paymentChecks.find(
      (check) => check.matchedBankTransaction != null && check.matchedBankTransaction.id === bankTransaction.id,
    );
    pairedCheck != null && setSelectionModel([pairedCheck.id]);
  }, [paymentChecks]);

  useEffect(() => {
    if (selectionModel.length === 0 || setSelectedPairedEntityId == null) return;

    onBusinessEventSelectionChange({
      shouldApplyAlways: false,
      pairedEntityId: selectedPairedEntityId,
      pairedEntityType: PairedEntityType.PaymentCheck,
      pairingType: PairingType.MATCH,
      businessMeanings: currentBusinessMeaning
        ? [{ businessMeaning: currentBusinessMeaning, amount: bankTransaction.amount }]
        : [],
    });
  }, [selectionModel, setSelectedPairedEntityId]);

  return (
    <>
      <Box display="flex" flexDirection="column" mt={4}>
        <Typography sx={{ fontWeight: 600, marginBottom: 2 }}>Payment checks for</Typography>
        <div style={{ height: 150, width: '100%' }}>
          <DataGrid
            rows={[bankTransaction]}
            columns={bankTransactionDisplayColumns}
            pageSize={1}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            hideFooter
          />
        </div>
      </Box>
      <Box display="flex" flexDirection="column" mt={4}>
        <Typography sx={{ fontWeight: 600, marginBottom: 2 }}>
          Please select a payment check to complete the manual match
        </Typography>

        <Box
          sx={{
            height: 250,
            width: '100%',
            '& .payment-check-row--paired': {
              bgcolor: () => yellow[200],
              '&:hover': {
                bgcolor: () => yellow[200],
              },
            },
          }}
        >
          <DataGrid
            components={{
              LoadingOverlay: LinearProgress,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            loading={isLoading}
            rows={paymentChecks as any}
            columns={paymentCheckDisplayColumns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            selectionModel={selectionModel}
            disableSelectionOnClick
            checkboxSelection
            hideFooterSelectedRowCount
            onSelectionModelChange={(selection: GridSelectionModel) => {
              let resultId: GridRowId | undefined;
              if (selection.length > 1) {
                const selectionSet = new Set(selectionModel);
                const result = selection.filter((s) => !selectionSet.has(s));
                resultId = result[0];
                setSelectionModel(result[0] ? [result[0]] : []);
              } else {
                resultId = selection[0];
                setSelectionModel(selection);
              }

              setSelectedPairedEntityId(resultId as string | undefined);
            }}
            getRowId={(row: GridRowModel<PaymentCheck>) => row.id}
            getRowClassName={(params) =>
              `payment-check-row--${params.row.matchedBankTransaction != null ? 'paired' : 'none'}`
            }
            isRowSelectable={() => !isLoading}
          />
        </Box>
      </Box>
    </>
  );
};
