import type { ComponentType } from 'react';
import { withAuthenticationRequired, type WithAuthenticationRequiredOptions } from '@auth0/auth0-react';

export interface ProtectedRouteProps extends WithAuthenticationRequiredOptions {
  component: ComponentType<any>;
}

export const ProtectedRoute = ({ component, ...args }: ProtectedRouteProps) => {
  const Component = withAuthenticationRequired(component, args);

  return <Component />;
};
