import Select, { SelectChangeEvent } from '@mui/material/Select';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid';
import * as React from 'react';

type SelectEditInputCellExtraProps = { options: { name: string; value: string }[] };

export const SelectEditInputCell = (props: GridRenderCellParams & SelectEditInputCellExtraProps) => {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = async (event: SelectChangeEvent) => {
    await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
    apiRef.current.stopCellEditMode({ id, field });
  };

  return (
    <Select value={value} onChange={handleChange} size="small" sx={{ height: 1 }} native autoFocus>
      {props.options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.name}
        </option>
      ))}
    </Select>
  );
};
