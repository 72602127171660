import type { AxiosInstance } from 'axios';

import { addAuthorizationTokenToAxiosRequests, getAxiosInstance, handleAxiosResponses } from '../../shared';
import type { LocalRule, Page } from '../../types';
import { ConfigService } from '../config';
import type { GetLocalRulePageParams } from './types';

export type LocalRulesApiReadLocalRulesResponseBody = Page<LocalRule>;

export class LocalRulesService {
  protected static instance: LocalRulesService;

  protected constructor(protected readonly config: ConfigService, protected readonly axiosInstance: AxiosInstance) {}

  static getInstance(
    config: ConfigService = ConfigService.getInstance(),
    axiosInstance?: AxiosInstance,
  ): LocalRulesService {
    if (LocalRulesService.instance) {
      return LocalRulesService.instance;
    }

    const apiClient = axiosInstance || getAxiosInstance({ baseURL: config.getOrFail('LOCAL_RULES_API_URL') });
    addAuthorizationTokenToAxiosRequests(apiClient);
    handleAxiosResponses(apiClient);

    return (LocalRulesService.instance = new LocalRulesService(config, apiClient));
  }

  async deleteLocalRules(companyId: string, localRuleIds: string[]): Promise<void> {
    await this.axiosInstance.post('/deleted-local-rules', {
      companyId,
      localRules: localRuleIds.map((id) => ({ id })),
    });
  }

  async getLocalRulePage(params: GetLocalRulePageParams): Promise<LocalRulesApiReadLocalRulesResponseBody> {
    const response = await this.axiosInstance.get<LocalRulesApiReadLocalRulesResponseBody>('/local-rules', {
      params,
    });

    return response.data;
  }
}
