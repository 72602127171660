import type { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { ReactNode } from 'react';

import { ConfigService } from '../../../../services/config';
import { getMonetaryColumnDescription, memoizedFormatDate } from '../../../../shared';
import { DateExplorerLink } from '../../../DataExplorerLink';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { IconButton, Stack, Tooltip, styled } from '@mui/material';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const bankAffectingDisplayColumns: GridColDef[] = [
  {
    field: 'integrationType',
    headerName: 'Integration Type',
    width: 250,
    sortable: false,
    filterable: false,
  },
  {
    field: 'eventType',
    headerName: 'Event Type',
    width: 250,
    sortable: false,
    filterable: false,
    valueGetter: (params: GridValueGetterParams) => params.row.__typename,
  },
  {
    field: 'eventTime',
    headerName: 'Date',
    width: 110,
    sortable: true,
    filterable: false,
    valueGetter: (params: GridValueGetterParams) => {
      const date = new Date(params.row.eventTime);
      const userTimezoneOffset = date.getTimezoneOffset() * 60000;
      return memoizedFormatDate(
        new Date(date.getTime() + userTimezoneOffset),
        ConfigService.getInstance().getOrFail('DATE_FORMAT'),
      );
    },
  },
  {
    field: 'amount',
    headerName: 'Amount',
    sortable: true,
    filterable: false,
    width: 200,
    renderCell: (params: GridRenderCellParams): ReactNode => {
      const { amount, amountInUsd, currency } = params.row;
      const isForex = currency !== 'USD';
      return getMonetaryColumnDescription(amount, amountInUsd, currency, isForex);
    },
  },
  {
    field: 'link',
    headerName: 'Link',
    sortable: false,
    filterable: false,
    width: 100,
    renderCell: (params: GridRenderCellParams) => {
      return <DateExplorerLink bankTransactionId={params.row.id} />;
    },
  },
  {
    field: 'pairedLink',
    headerName: 'Paired entity',
    sortable: false,
    filterable: false,
    width: 100,
    renderCell: (params: GridRenderCellParams) => {
      const pairedId = params.row.pairedBankTransaction?.id;
      if (!pairedId) return null;
      return (
        <Tooltip
          title={
            <>
              <Stack spacing={2}>
                <Item>
                  paired transaction: <DateExplorerLink bankTransactionId={pairedId} label={''} />
                </Item>
                <Item>amount in usd: ${params.row.pairedBankTransaction.amountInUsd}</Item>
                <Item>description: {params.row.pairedBankTransaction.description}</Item>
                <Item>source: {params.row.pairedBankTransaction.source}</Item>
                <Item>merchant: {params.row.pairedBankTransaction.merchant?.name}</Item>
              </Stack>
            </>
          }
        >
          <IconButton>
            <OpenInNewIcon>
              <DateExplorerLink bankTransactionId={pairedId} label={''} />
            </OpenInNewIcon>
          </IconButton>
        </Tooltip>
      );
    },
  },
];
