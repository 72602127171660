export class ConfigService {
  protected static readonly REACT_ENV_PREFIX = 'REACT_APP_';
  protected static instance: ConfigService;

  static getInstance(): ConfigService {
    return (ConfigService.instance ??= new ConfigService());
  }

  get<DefaultValue extends string | null | undefined>(
    key: string,
    defaultValue?: DefaultValue,
  ): DefaultValue extends string ? string : string | null {
    const fullKey = this.resolveFullKey(key);

    return (process.env[fullKey] ?? defaultValue ?? null) as DefaultValue extends string ? string : string | null;
  }

  getOrFail(key: string): string {
    const value = this.get(key);

    if (value == null) {
      throw new Error(`ConfigService error: missing value for key ${key}!`);
    }

    return value;
  }

  protected resolveFullKey(key: string) {
    if (!key) {
      throw new Error('ConfigService error: missing key!');
    }

    return `${ConfigService.REACT_ENV_PREFIX}${key.toUpperCase()}`;
  }
}
