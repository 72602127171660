import type { PairedEntityType } from './PairedEntityType';

export interface BusinessMeaning {
  id: string;
  moneyDirection: 'IN' | 'OUT' | 'ALL';
  name: string;
  vendorRequired: boolean;
  hideForManualClassification?: boolean;
  forceShowInEventsListings?: boolean;
}

export interface BusinessMeaningDialogResult {
  businessMeanings: { businessMeaning: BusinessMeaning; amount: number; description?: string }[];
  isApplyAlways: boolean;
  pairedEntityId?: string;
  pairedEntityType?: PairedEntityType;
  oldPairedEntityIds?: string[];
}

export interface ClassificationChangeValue {
  businessEventClassifications: {
    businessEvent: string;
    amount: number;
  }[];
  pairedEntity: { id: string; type: PairedEntityType } | null;
  vendor: {
    id: string;
    name: string;
    source: 'local' | 'global';
  } | null;
}

export const ASK_THE_USER_ALWAYS = 'ask-the-user-always';
