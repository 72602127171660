import { Typography } from '@mui/material';
import type {
  GridColDef,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridValueGetterParams,
  GridValueSetterParams,
} from '@mui/x-data-grid';
import { format } from 'date-fns';
import { BankAccountStatementRequest } from '../../types/BankAccountStatementRequest';
import { bankAccountStatementRequestStatusOptions, statusMap } from './bankAccountStatementRequestStatuses';
import { typesMap } from './bankAccountStatementRequestTypes';
import { BankAccountCell } from './BankAccountStatementsTable/cells/BankAccountCell';
import { CompanyCell } from './BankAccountStatementsTable/cells/CompanyCell';
import { CustomerDataCell } from './BankAccountStatementsTable/cells/CustomerDataCell';
import { SelectEditInputCell } from './BankAccountStatementsTable/cells/SelectInputCell';

const bankAccount: GridColDef<BankAccountStatementRequest> = {
  field: 'bankAccount',
  headerName: 'Name',
  width: 400,
  sortable: false,
  filterable: false,
  renderCell: (params: GridRenderCellParams<BankAccountStatementRequest['bankAccount']>) => {
    const bankAccount = params.value!;

    return <BankAccountCell bankAccount={bankAccount} bankAccountStatementRequest={params.row}/>;
  },
};

const fromDate: GridColDef<BankAccountStatementRequest> = {
  field: 'date',
  headerName: 'Statement Date',
  width: 200,
  sortable: false,
  filterable: false,
  valueGetter: (params: GridValueGetterParams) => {
    const { fromDate, toDate } = params.row;

    return fromDate === toDate
      ? format(new Date(fromDate), 'MMMM, yyyy')
      : `${format(new Date(fromDate), 'MMM `yy')} - ${format(new Date(toDate), 'MMM `yy')}`;
  },
};

const status: GridColDef<BankAccountStatementRequest> = {
  field: 'status',
  headerName: 'Status',
  width: 350,
  editable: true,
  sortable: false,
  filterable: false,
  renderCell: (params: GridRenderCellParams<BankAccountStatementRequest['status']>) => {
    return <Typography color={statusMap[params.value!].color}>{statusMap[params.value!].displayName}</Typography>;
  },
  renderEditCell: (params: GridRenderEditCellParams<BankAccountStatementRequest['status']>) => {
    return <SelectEditInputCell options={bankAccountStatementRequestStatusOptions} {...params} />;
  },
};

const type: GridColDef<BankAccountStatementRequest> = {
  field: 'type',
  headerName: 'Type',
  width: 180,
  editable: true,
  sortable: false,
  filterable: false,
  renderCell: (params: GridRenderCellParams<BankAccountStatementRequest['type']>) => {
    return <Typography>{typesMap[params.value!]}</Typography>;
  },
};

const company: GridColDef<BankAccountStatementRequest> = {
  field: 'companyId',
  headerName: 'Company',
  width: 350,
  editable: true,
  sortable: false,
  filterable: false,
  renderCell: (params: GridRenderCellParams<BankAccountStatementRequest['companyId']>) => {
    return <CompanyCell companyId={params.value!} />;
  },
};

const comment: GridColDef<BankAccountStatementRequest> = {
  field: 'finaloopData',
  headerName: 'Comment',
  width: 350,
  editable: true,
  sortable: false,
  filterable: false,
  valueGetter: (params: GridRenderCellParams<BankAccountStatementRequest['finaloopData']>) => {
    return params.value?.comment;
  },
  valueSetter: (params: GridValueSetterParams) => {
    return { ...params.row, finaloopData: { ...params.row.finaloopData, comment: params.value } };
  },
};

const customerData: GridColDef<BankAccountStatementRequest> = {
  field: 'customerData',
  headerName: 'Customer Data',
  width: 350,
  sortable: false,
  filterable: false,
  renderCell: (params: GridRenderCellParams<BankAccountStatementRequest['customerData']>) => {
    return <CustomerDataCell customerData={params.value!} bankAccountStatementRequest={params.row} />;
  },
};

export const bankAccountStatementsDisplayColumns: GridColDef[] = [
  company,
  bankAccount,
  type,
  fromDate,
  status,
  customerData,
  comment,
];
