import { memo } from 'react';
import { Navigate, Route, Routes as DomRoutes } from 'react-router-dom';
import { BankAccountsWithStatements } from './components/BankAccounts';
import { BankAccountStatements } from './components/BankAccountStatements';

import { BankTransactions } from './components/BankTransactions';
import { LocalRules } from './components/LocalRules';
import { ProtectedRoute } from './components/ProtectedRoute';
import { UploadBankTransactions } from './components/UploadBankTransactions';

export const Routes = memo(() => (
  <DomRoutes>
    <Route path="/" element={<Navigate to="/bank-transactions" />} />
    <Route path="/paypal-bank-transactions" element={<Navigate to="/bank-transactions" />} />
    <Route path="/plaid-bank-transactions" element={<Navigate to="/bank-transactions" />} />
    <Route path="/bank-transactions" element={<ProtectedRoute component={BankTransactions} />} />
    {/*<Route path="/bank-accounts" element={<ProtectedRoute component={BankAccounts} />} />*/}
    <Route path="/bank-accounts-with-statements" element={<ProtectedRoute component={BankAccountsWithStatements} />} />
    <Route path="/bank-account-statements" element={<ProtectedRoute component={BankAccountStatements} />} />
    <Route path="/upload-bank-transactions" element={<ProtectedRoute component={UploadBankTransactions} />} />
    <Route path="/local-rules" element={<ProtectedRoute component={LocalRules} />} />
  </DomRoutes>
));
