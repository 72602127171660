import { useQuery } from '@tanstack/react-query';
import { BankAccountsService } from '../../../services/bank-accounts';
import { BankStatementsRequestProps, StatementsTypes } from '../../../services/bank-accounts/types';
import { BankAccountStatementRequest } from '../../../types/BankAccountStatementRequest';

export interface UseBankAccountsResult {
  bankAccountStatementRequests?: BankAccountStatementRequest[] | null;
  pageInfo: {
    cursor?: string;
    totalDocs: number;
  };
  isLoading: boolean;
  type?: StatementsTypes;
}

export const useBankStatements = (params: BankStatementsRequestProps): UseBankAccountsResult => {
  const { data, isLoading } = useQuery(
    ['bank-transaction-statements', params],
    () => BankAccountsService.getInstance().getBankAccountStatementRequestsPage(params),
    { enabled: true, select: (data) => data, refetchOnWindowFocus: false, refetchInterval: false },
  );

  return {
    bankAccountStatementRequests: data?.items || [],
    pageInfo: { cursor: data?.cursor || undefined, totalDocs: data?.totalDocs || 0 },
    isLoading,
  };
};
