import { Divider, Stack } from '@mui/material';
import { type ReactElement, useEffect, useState } from 'react';
import { useBankAccountsWithStatements } from '../../../shared/use-bank-accounts-with-statements';
import { ReconciliationStatus } from '../../../types';
import { BankAccountsWithStatementsTable } from './BankAccountsWithStatementsTable';
import { EnrichedBankAccount } from './types';

export function BankAccountsWithStatements(): ReactElement {
  const { bankAccounts, isLoading: isLoadingBankAccounts } = useBankAccountsWithStatements();
  const [enrichedBankAccounts, setEnrichedBankAccounts] = useState<EnrichedBankAccount[]>([]);

  useEffect(() => {
    if (!bankAccounts) {
      return;
    }

    setEnrichedBankAccounts(
      bankAccounts.map((bankAccount) => ({
        ...bankAccount,
        reconciliationStatuses2021: bankAccount.reconciliationStatuses?.['2021'] || ReconciliationStatus.NotReconciled,
        reconciliationStatuses2022: bankAccount.reconciliationStatuses?.['2022'] || ReconciliationStatus.NotReconciled,
      })),
    );
  }, [bankAccounts]);

  return (
    <Stack spacing={2}>
      <Divider sx={{ marginTop: 2 }} />
      <BankAccountsWithStatementsTable
        bankAccounts={enrichedBankAccounts || []}
        isLoading={isLoadingBankAccounts}
      />
    </Stack>
  );
}
