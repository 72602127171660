import { BankAccount } from './BankAccount';

export enum BankAccountStatementRequestStatus {
  NotRequired = 'NOT_REQUIRED', // @todo we might not need this status
  PendingUserInput = 'PENDING_USER_INPUT',
  UserCompleted = 'USER_COMPLETED',
  FinaloopApproved = 'FINALOOP_APPROVED',
  Irrelevant = 'IRRELEVANT',
  IrrelevantApproved = 'IRRELEVANT_APPROVED',
}

export enum BankAccountStatementRequestType {
  EndOfYearBalance = 'END_OF_YEAR_BALANCE',
  StartOfYearBalance = 'START_OF_YEAR_BALANCE',
  TransactionsBackfill = 'TRANSACTIONS_BACKFILL',
}

export enum IrrelevantType {
  OpenAfterSpecifiedDate = 'OPEN_AFTER_SPECIFIED_DATE',
  ClosedBeforeSpecifiedDate = 'CLOSED_BEFORE_SPECIFIED_DATE',
  Other = 'OTHER',
}

export interface BankStatementCustomerData {
  fileNames: string[];
  csvFileNames?: string[];
  noCSVFiles?: boolean;
  noPDFFiles?: boolean;
  displayedBalance?: number;
  realBalance?: number;
  cycleStart?: Date;
  cycleEnd?: Date;
  isConfirmed?: boolean;
  irrelevantReason?: string;
  irrelevantType?: IrrelevantType;
  isMachineReconciled?: boolean;
  newOwner?: 'THIRD_PARTY' | 'OWNER_OR_RELATED_BUSINESS';
  bankDetails?: {
    name: string;
    lastFourDigits?: string;
    type?: string;
    missingBank?: boolean;
    accountName: string;
  };
  transferDate?: Date;
  closedDate?: Date;
  relatedMissionId?: string;
  isIntegrationTransfers?: boolean;
  isClosedThisYear?: boolean;
}


interface BankStatementFinaloopData {
  comment?: string;
}

export interface BankAccountStatementRequest {
  id: string;
  companyId: string;
  fromDate: string;
  toDate: string;
  bankAccount: Pick<BankAccount, 'id' | 'name' | 'type' | 'subtype' | 'mask' | 'institutionName' | 'currency'> & {
    institutionId?: string;
  };
  type: BankAccountStatementRequestType;
  customerData?: BankStatementCustomerData;
  finaloopData?: BankStatementFinaloopData;
  status: BankAccountStatementRequestStatus;
  csvUploads?: { name: string; uploadId: string }[];
  balanceId?: string;
}
