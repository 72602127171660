import { HTMLAttributes, PropsWithChildren, ReactElement, SyntheticEvent, useCallback, useMemo } from 'react';
import {
  Autocomplete as MUIAutocomplete,
  AutocompleteChangeReason,
  AutocompleteRenderOptionState,
  Box,
  FormControl,
  LinearProgress,
  Stack,
  TextField,
} from '@mui/material';

import type { AutocompleteOption } from './AutocompleteOption';
import type { AutocompleteProps } from './props';

export function Autocomplete(props: PropsWithChildren<AutocompleteProps>): ReactElement {
  const { isMultiple, label, optionEqualityFn, optionKeyFn, optionLabelFn, options, value } = props;

  const getOptionKey = useCallback(
    (option: AutocompleteProps['value']) => optionKeyFn?.(option) ?? transformOptionToString(option),
    [optionKeyFn],
  );

  const getOptionLabel = useCallback(
    (option: AutocompleteProps['value']) => optionLabelFn?.(option) ?? transformOptionToString(option),
    [optionLabelFn],
  );

  const transformOptionToString = (option: AutocompleteOption['value']): string =>
    typeof option === 'string' ? option : JSON.stringify(option);

  const innerValue = useMemo(
    () => value?.map((item) => ({ key: getOptionKey(item), label: getOptionLabel(item), value: item })) || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value],
  );

  const autocompleteOptions: AutocompleteOption[] = useMemo(
    () =>
      options.map((option) => ({
        key: `${getOptionLabel(option)}_${getOptionKey(option)}`,
        label: getOptionLabel(option),
        value: option,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options],
  );

  const renderInput = useCallback((params: any) => <TextField {...params} label={label} variant="filled" />, [label]);

  const renderOption = useCallback(
    (props: HTMLAttributes<HTMLLIElement>, option: AutocompleteOption, state: AutocompleteRenderOptionState) => (
      <li {...props} key={option.key}>
        {option.label}
      </li>
    ),
    [],
  );

  const handleSelectionChange = (
    event: SyntheticEvent<Element, Event>,
    value: unknown,
    reason: AutocompleteChangeReason,
  ) => {
    props.onChange?.(
      isMultiple
        ? (value as AutocompleteOption[]).map((item) => item.value)
        : value
        ? [(value as AutocompleteOption).value]
        : [],
    );
  };

  const checkOptionEquality = useCallback(
    ({ value: value0 }: AutocompleteOption, { value: value1 }: AutocompleteOption): boolean =>
      optionEqualityFn?.(value0, value1) ?? value0 === value1,
    [optionEqualityFn],
  );

  const isLoading = !!props.isLoading;

  return (
    <Stack sx={{ width: '100%' }}>
      <FormControl fullWidth>
        <MUIAutocomplete
          clearOnBlur
          disableClearable={props.isClearable === false}
          disabled={props.isDisabled || isLoading}
          handleHomeEndKeys
          isOptionEqualToValue={checkOptionEquality}
          multiple={!!props.isMultiple}
          onChange={handleSelectionChange}
          options={autocompleteOptions}
          renderInput={renderInput}
          renderOption={renderOption}
          selectOnFocus
          value={isMultiple ? innerValue : innerValue[0] || null}
        />
      </FormControl>
      <Box sx={{ height: '5px' }}>{isLoading ? <LinearProgress variant="query" /> : undefined}</Box>
    </Stack>
  );
}
