import { createClient } from '../__generated-global__';
import { TokenService } from '../services/auth/TokenService';

export const gqlClient = createClient({
  url: process.env.REACT_APP_GRAPHQL_API_URL,
  async headers() {
    const token = await TokenService.getInstance().getToken();

    return {
      Authorization: `Bearer ${token}`,
      'x-request-initiator': 'bank-transactions-feed',
    };
  },
});
