import { ReactElement, useLayoutEffect, useMemo, useState } from 'react';
import { Dialog } from '../ui/Dialog';
import { BusinessMeaning, EnrichedBankTransaction } from '../types';
import { LeanMerchant } from './CategorizationDialog/MerchantSelection';
import { resolveMoneyDirection, useBusinessMeanings } from '../shared';
import { MerchantSelector } from './MerchantSelector';
import { Divider } from '@mui/material';
import { BusinessMeaningSelector } from './BusinessMeaningSelector';

export interface BulkCategorizationDialogProps {
  companyId: string;
  bankTransactions: EnrichedBankTransaction[];
  isOpen: boolean;
  onClose: () => void;
  onCategorizationChange: (result: BulkCategorizationResult) => void;
}

export interface BulkCategorizationResult {
  merchant: LeanMerchant | null;
  businessEvent: string | null;
}

const businessEventsBannedFromBulk = new Set([
  'ask-the-user-always',
  'internal-transfer',
  'payout-initiated',
  'topup-initiated',
  'bill-paid',
  'invoice-payment-received-in-bank',
  'due-to-from-decreased-from-bank',
  'due-to-from-decreased-from-bank-car-loan-paid',
  'due-to-from-increased-from-bank',
  'split',
]);

export const BulkCategorizationDialog = ({
  companyId,
  bankTransactions,
  isOpen,
  onCategorizationChange,
  onClose,
}: BulkCategorizationDialogProps): ReactElement => {
  const { businessMeanings } = useBusinessMeanings();
  const [bulkCategorizationResult, setBulkCategorizationResult] = useState<BulkCategorizationResult>({
    merchant: null,
    businessEvent: null,
  });

  const [currentMerchant, setCurrentMerchant] = useState<LeanMerchant | undefined>();
  const [currentBusinessMeaning, setCurrentBusinessMeaning] = useState<BusinessMeaning | undefined | null>();

  const groupDetails = useMemo(() => {
    const allHaveMerchant = bankTransactions.every((bankTransaction) => bankTransaction.merchant?.id != null);
    const hasSameMerchant = bankTransactions.every(
      (bankTransaction) => bankTransactions[0].merchant?.id === bankTransaction.merchant?.id,
    );
    const singleMerchant = hasSameMerchant ? bankTransactions[0]?.merchant : null;
    const hasSameMoneyDirection = bankTransactions.every(
      (bankTransaction) => resolveMoneyDirection(bankTransaction) === resolveMoneyDirection(bankTransactions[0]),
    );
    const singleMoneyDirection =
      hasSameMoneyDirection && bankTransactions[0] ? resolveMoneyDirection(bankTransactions[0]) : null;
    const hasSameBusinessEvent = bankTransactions.every(
      ({ businessEvent }) =>
        businessEvent?.classifications.length === 1 &&
        businessEvent?.classifications?.[0]?.businessEvent ===
          bankTransactions[0].businessEvent?.classifications?.[0]?.businessEvent,
    );
    const singleBusinessEvent = hasSameBusinessEvent
      ? bankTransactions[0]?.businessEvent?.classifications?.[0]?.businessEvent
      : null;

    return {
      allHaveMerchant,
      singleMerchant,
      singleMoneyDirection,
      singleBusinessEvent,
    };
  }, [bankTransactions]);

  const selectableBusinessMeanings = useMemo(
    () =>
      (businessMeanings?.forClassification || []).filter((businessMeaning) => {
        if (businessEventsBannedFromBulk.has(businessMeaning.id)) return false;

        if (!groupDetails.allHaveMerchant && businessMeaning.vendorRequired) return false;

        const businessMeaningMoneyDirection = businessMeaning.moneyDirection || 'ALL';
        if (
          businessMeaningMoneyDirection !== 'ALL' &&
          groupDetails.singleMoneyDirection !== businessMeaningMoneyDirection
        ) {
          return false;
        }

        return true;
      }),
    [groupDetails, businessMeanings],
  );

  const handleCategorization = () => {
    onCategorizationChange(bulkCategorizationResult);
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleMerchantChange = (merchant: LeanMerchant | undefined) => {
    setCurrentMerchant(merchant);
    setBulkCategorizationResult((current) => ({ ...current, merchant: merchant || null }));
  };

  const handleBusinessMeaningChange = ([businessMeaning]: BusinessMeaning[]) => {
    setCurrentBusinessMeaning(businessMeaning);
    setBulkCategorizationResult((current) => ({
      ...current,
      businessEvent: businessMeaning ? businessMeaning.id : null,
    }));
  };

  useLayoutEffect(() => {
    handleMerchantChange(groupDetails.singleMerchant || undefined);
  }, [isOpen, groupDetails.singleMerchant]);

  useLayoutEffect(() => {
    handleBusinessMeaningChange(
      groupDetails.singleBusinessEvent
        ? ([businessMeanings?.forClassification.find(({ id }) => id === groupDetails.singleBusinessEvent)].filter(
            Boolean,
          ) as BusinessMeaning[])
        : [],
    );
  }, [isOpen, businessMeanings, groupDetails.singleBusinessEvent]);

  return (
    <Dialog
      isOKDisabled={false}
      isOpen={isOpen}
      okText="Save"
      onOK={handleCategorization}
      onClose={handleOnClose}
      title={`Categorization of ${bankTransactions.length} transactions`}
    >
      <MerchantSelector companyId={companyId} canAdd value={currentMerchant} onChange={handleMerchantChange} />
      <Divider sx={{ my: 2 }} />
      <BusinessMeaningSelector
        businessMeanings={selectableBusinessMeanings}
        isLoading={businessMeanings == null}
        onChange={handleBusinessMeaningChange}
        value={currentBusinessMeaning ? [currentBusinessMeaning.id] : []}
      />
    </Dialog>
  );
};
