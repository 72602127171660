import React, { Fragment } from 'react';
import BugsnagClient from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import { ConfigService } from '../config';

const apiKey = ConfigService.getInstance().get('BUGSNAG_API_KEY');

if (apiKey) {
  BugsnagClient.start({
    apiKey,
    plugins: [new BugsnagPluginReact()],
  });
}

export const Bugsnag = BugsnagClient;

export const BugsnagErrorBoundary = BugsnagClient.isStarted()
  ? BugsnagClient.getPlugin('react')!.createErrorBoundary(React)
  : Fragment;
