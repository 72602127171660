import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

export const Search = ({ query, onChange }: { query: string; onChange: (value: string) => void }) => {
  const [term, setTerm] = useState(query);

  useEffect(() => {
    setTerm(query);
  }, [query]);

  const handleClear = (e: any) => {
    e.target.value = '';
    setTerm('');
    onChange('');
  };

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      onChange(term);
      e.target.blur();
    }
  };

  return (
    <div style={{ marginTop: 32, marginBottom: 8, display: 'flex', gap: 8, alignItems: 'center' }}>
      <TextField
        sx={{ width: 400 }}
        onChange={(e) => setTerm(e.target.value)}
        onKeyPress={handleKeyPress}
        onBlur={() => onChange(term)}
        value={term}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="small" />
            </InputAdornment>
          ),
          endAdornment:
            term.length > 0 ? (
              <InputAdornment position="end">
                <IconButton onClick={handleClear} onMouseDown={(e) => e.preventDefault()}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ) : undefined,
        }}
        size="small"
        placeholder="Type anything..."
      />

      <Button onClick={() => onChange(term)}>Search</Button>
    </div>
  );
};
