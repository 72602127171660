import { type ReactElement, useMemo } from 'react';
import { Divider, Stack, Typography } from '@mui/material';

import { getMonetaryColumnDescription } from '../../shared';
import type { EnrichedBankTransaction } from '../../types';

export interface BankTransactionsTableSelectionSummaryProps {
  selectedBankTransactions: EnrichedBankTransaction[];
}

export function BankTransactionsTableSelectionSummary(props: BankTransactionsTableSelectionSummaryProps): ReactElement {
  const { selectedBankTransactions } = props;

  const selectedRowCount = selectedBankTransactions.length;
  const selectedBankTransactionSummary = useMemo(
    () =>
      selectedBankTransactions.reduce(
        (acc, { amountInUsd, resolvedMoneyDirection }) => ({
          received: acc.received + (resolvedMoneyDirection! === 'IN' ? amountInUsd : 0),
          spent: acc.spent + (resolvedMoneyDirection! === 'OUT' ? amountInUsd : 0),
        }),
        { received: 0, spent: 0 },
      ),
    [selectedBankTransactions],
  );

  if (!selectedRowCount) {
    return <></>;
  }

  return (
    <Stack direction="row" flex={1} spacing={2}>
      <Typography>
        {selectedRowCount} selected bank transaction{selectedRowCount === 1 ? '' : 's'}
      </Typography>
      <Divider orientation="vertical" flexItem />
      <Typography>
        {getMonetaryColumnDescription(
          selectedBankTransactionSummary.spent,
          selectedBankTransactionSummary.spent,
          'USD',
          false,
        )}{' '}
        spent
      </Typography>
      <Divider orientation="vertical" flexItem />
      <Typography>
        {getMonetaryColumnDescription(
          selectedBankTransactionSummary.received,
          selectedBankTransactionSummary.received,
          'USD',
          false,
        )}{' '}
        received
      </Typography>
    </Stack>
  );
}
