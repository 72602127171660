import type { AxiosInstance } from 'axios';

import { addAuthorizationTokenToAxiosRequests, getAxiosInstance, handleAxiosResponses } from '../../shared';
import { Page, GlobalVendor, CompanyVendor, Vendor, VendorType } from '../../types';
import { ConfigService } from '../config';
import type {
  CompanyVendorsApiCreateCompanyVendorsRequestBody,
  CompanyVendorsApiCreateCompanyVendorsResponseBody,
  CompanyVendorsApiReadCompanyVendorsResponseBody,
} from './types';
import { gqlClient } from '../../shared/gqlClient';

export class CompanyVendorsService {
  protected static instance: CompanyVendorsService;

  protected constructor(
    protected readonly config: ConfigService,
    protected readonly axiosInstance: AxiosInstance,
  ) {}

  static getInstance(
    config: ConfigService = ConfigService.getInstance(),
    axiosInstance?: AxiosInstance,
  ): CompanyVendorsService {
    if (CompanyVendorsService.instance) {
      return CompanyVendorsService.instance;
    }

    const apiClient = axiosInstance || getAxiosInstance({ baseURL: config.getOrFail('COMPANY_VENDORS_API_URL') });
    addAuthorizationTokenToAxiosRequests(apiClient);
    handleAxiosResponses(apiClient);

    return (CompanyVendorsService.instance = new CompanyVendorsService(config, apiClient));
  }

  async createCompanyVendor(name: string, companyId: string, creatorEmail?: string) {
    const { createVendor } = await gqlClient.mutation({
      createVendor: {
        id: true,
        name: true,
        companyId: true,
        type: true,
        logoUrl: true,
        __args: {
          createVendorArgs: {
            name,
            companyId,
            source: 'FINALOOP_TRANSACTIONS',
            externalSourceId: creatorEmail,
          },
        },
      },
    });

    return createVendor;
  }

  async getCompanyVendorsPage(companyId: string, cursor?: any): Promise<Page<CompanyVendor>> {
    try {
      const response = await this.axiosInstance.get<CompanyVendorsApiReadCompanyVendorsResponseBody>(
        '/company-vendors',
        { params: { companyId } },
      );

      const { data: responseData } = response;

      return {
        items: responseData.items,
        cursor: responseData.cursor,
      };
    } catch (err) {
      return { items: [], cursor: cursor || null };
    }
  }

  async searchVendors(term: string, companyId: string): Promise<Page<GlobalVendor>> {
    throw new Error('unimplemented!');
  }
}
