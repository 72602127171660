export class TokenService {
  protected static instance: TokenService;
  protected tokenGenerator: undefined | (() => Promise<string>);

  static getInstance(): TokenService {
    return (TokenService.instance ??= new TokenService());
  }

  setTokenGenerator(tokenGenerator: (() => Promise<string>) | undefined) {
    this.tokenGenerator = tokenGenerator;
  }

  getToken(): Promise<string | undefined> {
    return this.tokenGenerator ? this.tokenGenerator() : Promise.resolve(undefined);
  }
}
