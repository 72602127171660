import { useState, useLayoutEffect } from 'react';
import { accrualFragment } from '../../services/bank-transactions/fragments';
import { gqlClient } from '../../shared/gqlClient';
import { EnrichedBankTransaction } from '../../types';

export const checkHasAccrual = async (companyId: string, transactionId: EnrichedBankTransaction['id']) => {
  const { bankTransaction } = await gqlClient.query({
    __name: 'bankTransaction',
    bankTransaction: {
      accrual: accrualFragment,
      __args: {
        getBankTransactionInput: {
          transactionId,
          companyId,
        },
      },
    },
  });
  console.log('bankTransaction', bankTransaction);
  return Boolean(bankTransaction?.accrual?.id);
};

export const useIsDisabledByAccrual = (companyId: string, transactionId?: EnrichedBankTransaction['id']) => {
  const [isDisabledByAccrual, setDisabledByAccrual] = useState(true);

  useLayoutEffect(() => {
    if (transactionId) {
      checkHasAccrual(companyId, transactionId).then(setDisabledByAccrual);
    }
    return () => {
      setDisabledByAccrual(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId]);

  return { isDisabledByAccrual, setDisabledByAccrual };
};
