import { Box, styled } from '@mui/material';

import { drawerWidth } from './index';
import { SideBarHeader } from './SideBar';

const StyledMainContent = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface MainContentProps {
  sideBarOpen: boolean;
  children: JSX.Element;
}

export const MainContent = ({ sideBarOpen, children }: MainContentProps) => (
  <StyledMainContent open={sideBarOpen}>
    <SideBarHeader />
    <Box display="flex" flexDirection="column">
      {children}
    </Box>
  </StyledMainContent>
);
