import { Box, Button, TextField } from '@mui/material';
import { ChangeEvent, useMemo, useState } from 'react';
import {
  BankTransactionBusinessEventClassification,
  BusinessMeaning,
  EnrichedBankTransaction,
} from '../../../../types';
import { BusinessMeaningSelector } from '../../../BusinessMeaningSelector';
import { LeanMerchant } from '../../MerchantSelection';
import { COMPANY_AFFILIATE_REQUIREMENTS } from '../../../../shared/classification-utils';

interface SplitTransactionClassificationProps {
  index: number;
  businessMeanings: BusinessMeaning[];
  businessMeaningsMap: Record<string, BusinessMeaning>;
  bankTransaction: EnrichedBankTransaction;
  businessEventClassification: Partial<BankTransactionBusinessEventClassification>;
  removeSplitTransactionClassification: (index: number) => void;
  updateBusinessEventClassification: (
    index: number,
    businessEventClassification: Partial<BankTransactionBusinessEventClassification>,
  ) => void;
  selectedMerchant: LeanMerchant | null;
  isDisabled?: boolean;
}

const NOT_APPLICABLE_BUSINESS_MEANINGS = new Set([
  'split',
  'internal-transfer',
  'payout-received-in-bank',
  'top-up-paid-from-bank',
  'payout-initiated',
  'topup-initiated',
  'ask-the-user',
  'ask-the-user-always',
  'bill-paid',
  'invoice-payment-received-in-bank',
]);

export const SplitTransactionClassification = (props: SplitTransactionClassificationProps) => {
  const {
    index,
    businessMeanings,
    businessEventClassification,
    removeSplitTransactionClassification,
    businessMeaningsMap,
    updateBusinessEventClassification,
    selectedMerchant,
    isDisabled = false,
  } = props;

  const applicableBusinessMeanings = useMemo(() => {
    return businessMeanings
      .filter(({ id }) => !NOT_APPLICABLE_BUSINESS_MEANINGS.has(id))
      .filter((businessMeaning) => {
        const containsCompanyAffiliateSelection = COMPANY_AFFILIATE_REQUIREMENTS[businessMeaning.id];

        if (
          containsCompanyAffiliateSelection &&
          !containsCompanyAffiliateSelection.affiliateTypes.includes(selectedMerchant?.merchantSubtype ?? '')
        ) {
          return false;
        }

        return true;
      });
  }, [businessMeanings, selectedMerchant?.id]);

  const [selectedBusinessMeaning, setSelectedBusinessMeaning] = useState<BusinessMeaning | undefined | null>(
    businessMeaningsMap[businessEventClassification?.businessEvent || ''],
  );

  const handleBusinessMeaningChange = (businessMeanings: BusinessMeaning[]) => {
    const [businessMeaning] = businessMeanings;

    if (!businessMeaning) {
      throw new Error('Business meaning is required');
    }

    setSelectedBusinessMeaning(businessMeaning);
    updateBusinessEventClassification(index, { ...businessEventClassification, businessEvent: businessMeaning.id });
  };

  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    businessEventClassification.amount = event.target.value ? parseFloat(event.target.value) : 0;
    updateBusinessEventClassification(index, {
      ...businessEventClassification,
      amount: event.target.value ? parseFloat(event.target.value) : 0,
    });
  };

  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateBusinessEventClassification(index, {
      ...businessEventClassification,
      description: event.target.value,
    });
  };

  const handleRemoveClicked = () => {
    removeSplitTransactionClassification(props.index);
  };

  return (
    <Box display="flex" alignItems="center" mt={1}>
      <BusinessMeaningSelector
        businessMeanings={applicableBusinessMeanings}
        onChange={handleBusinessMeaningChange}
        value={selectedBusinessMeaning ? [selectedBusinessMeaning.id] : []}
        isDisabled={isDisabled}
      />
      <Box alignSelf="start" mx={2}>
        <TextField
          type="number"
          onChange={handleAmountChange}
          value={Math.abs(businessEventClassification.amount || 0)}
          disabled={isDisabled}
        />
      </Box>
      <Box alignSelf="start" mx={2}>
        <TextField
          type="text"
          onChange={handleDescriptionChange}
          value={businessEventClassification.description}
          disabled={isDisabled}
        />
      </Box>
      <Button variant="contained" color="warning" onClick={handleRemoveClicked} disabled={isDisabled}>
        Remove
      </Button>
    </Box>
  );
};
