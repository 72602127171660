import { assertConfiguration, configureAbly } from '@ably-labs/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useLayoutEffect, useState } from 'react';
import { usePrevious } from 'react-use';
import { BankTransaction } from '../types';

export function useTransactionUpdatedChannel(
  companyId: string | null | undefined,
  callback: (message: {
    data: {
      topic: string;
      payload: { status: 'success' | 'failure'; bankTransaction: BankTransaction };
    };
  }) => void,
): void {
  const { user } = useAuth0();
  const [channel, setChannel] = useState();
  const prevCompanyId = usePrevious(companyId);
  const [isClientConnected, setIsClientConnected] = useState(false);

  useLayoutEffect(() => {
    if (!user) return;
    configureAbly({ key: process.env.REACT_APP_ABLY_KEY, clientId: user?.sub });
    setIsClientConnected(true);
  }, [setIsClientConnected, user]);

  useEffect(() => {
    if (!companyId || !isClientConnected) {
      return;
    }

    if (prevCompanyId === companyId) {
      return;
    }
    const ably = assertConfiguration();

    // @ts-ignore
    setChannel((currentChannel) => {
      // @ts-ignore
      if (currentChannel && currentChannel.state === 'attached') {
        // @ts-ignore
        currentChannel.unsubscribe(prevCompanyId);
        // @ts-ignore
        currentChannel.detach();

        console.log('unsubscribing from', prevCompanyId);
      }

      console.log('subscribing to', companyId);
      const newChannel = ably.channels.get(companyId);
      newChannel.unsubscribe(companyId);
      newChannel.subscribe(companyId, callback);

      return newChannel;
    });
  }, [setChannel, companyId, isClientConnected, callback, prevCompanyId]);
}
