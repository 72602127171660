import { BankAccountStatementRequest } from './BankAccountStatementRequest';

export type BankAccountSubType = 'Checking' | 'Savings' | 'CreditCard';
export interface BankAccount {
  id: string;
  companyId: string;
  source: 'PLAID' | 'QUICKBOOKS_BANKS' | 'USER' | 'FINALOOP_BANK_TRANSACTIONS_UPLOAD' | 'CompanyAffiliate';
  type: 'Depository' | 'CreditCard';
  subtype: BankAccountSubType;
  connectionStatus: 'Connected' | 'Disconnected';
  lastUpdateTimeMs: number | null;
  currency: string;
  institutionName: string | null;
  name: string;
  displayName: string;
  fullName: string;
  mask: string | null;
  isDisabled?: boolean;
  currentBalance: number;
  connectedAtMs?: number;
  connectedAtIso?: string;
  reconciliationStatuses?: Record<string, ReconciliationStatus>;
  status:
    | 'ACTIVE'
    | 'DUPLICATE'
    | 'PROCESSING'
    | 'SUSPECTED_AS_DUPLICATE'
    | 'DELETED'
    | 'PENDING'
    | 'MERGING'
    | 'MERGED'
    | 'MERGE_DESTINATION';
}

export enum ReconciliationStatus {
  Reconciled = 'Reconciled',
  NotReconciled = 'NotReconciled',
}

export interface BankAccountWithStatements extends BankAccount {
  statementRequests: BankAccountStatementRequest[];
  companyName: string;
}
