import { type ReactElement, useEffect, useState } from 'react';
import { Button, Divider, Stack } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import { AgGridReact } from 'ag-grid-react';
import type { SelectionChangedEvent } from 'ag-grid-community';
import { keyBy, omit, pick } from 'lodash';

import { LocalRulesService } from '../../services/local-rules';
import { useCompanies } from '../../shared';
import {
  type Company,
  type LocalRule,
  LocalRuleType,
  LocalVendorRule,
  LocalVendorToBusinessMeaningRule,
  MoneyDirection,
} from '../../types';
import { CompanySelector } from '../CompanySelector';
import { MerchantSelector } from '../MerchantSelector';
import { LocalRuleTypeSelector } from './LocalRuleTypeSelector';
import { MoneyDirectionSelector } from './MoneyDirectionSelector';
import { NoRulesOverlay } from './NoRulesOverlay';
import { getLocalRulesColumns } from './useLocalRulesColumns';
import { useLocalRulesData } from './useLocalRulesData';
import { gqlClient } from '../../shared/gqlClient';
import { uniq } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { LeanMerchant } from '../CategorizationDialog/MerchantSelection';

export function LocalRules(): ReactElement {
  const { companies, isLoading: isLoadingCompanies } = useCompanies();
  const [selectedCompany, setSelectedCompany] = useState<Company | null | undefined>();

  const [selectedRuleType, setSelectedRuleType] = useState<LocalRuleType | null | undefined>();

  const [selectedMerchant, setSelectedMerchant] = useState<LeanMerchant | undefined>();

  const [selectedMoneyDirection, setSelectedMoneyDirection] = useState<MoneyDirection | null | undefined>();

  const filters = {
    company: selectedCompany,
    moneyDirection: selectedMoneyDirection,
    ruleType: selectedRuleType,
    vendor: selectedMerchant,
  };

  const { fetchNextPage, hasMorePages, isLoading, localRules, setLocalRules } = useLocalRulesData(filters);

  const { data: vendors } = useQuery(
    ['vendors', selectedCompany?.id, localRules.map((x) => x.id)],
    async () => {
      const merchantIds = uniq(
        localRules
          .map(
            (r) =>
              (r as LocalVendorToBusinessMeaningRule).conditions?.find((c) => c.path === 'vendorId')?.value ||
              (r as any).conditions?.conditions.find((c: any) => c.path === 'vendorId')?.value ||
              (r as LocalVendorRule).vendorId,
          )
          .filter(Boolean) as string[],
      );

      const { vendorsByIds, institutions, companyAffiliatesByIds } = await gqlClient.query({
        __name: 'merchantByIds',
        companyAffiliatesByIds: {
          id: true,
          name: true,
          __args: {
            input: {
              ids: merchantIds,
            },
          },
        },
        institutions: {
          institutions: {
            id: true,
            name: true,
          },
          __args: {
            input: {
              ids: merchantIds,
            },
          },
        },
        vendorsByIds: {
          __args: {
            input: {
              companyId: selectedCompany!.id,
              ids: merchantIds,
            },
          },
          __scalar: true,
        },
      });

      return [...companyAffiliatesByIds, ...vendorsByIds, ...institutions.institutions].filter(Boolean);
    },
    { enabled: !!selectedCompany?.id && !!localRules.length },
  );

  const { columns } = getLocalRulesColumns(vendors || []);

  const [selectedLocalRules, setSelectedLocalRules] = useState<Record<string, LocalRule>>({});
  const [localRulesBeingDeleted, setLocalRulesBeingDeleted] = useState<Record<string, LocalRule>>({});

  const { inView, ref: pageRequestRef } = useInView({ root: null, rootMargin: '20px', threshold: 0 });
  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const handleCompanySelectorChange = (company: Company | null | undefined) => {
    setSelectedCompany(company);
  };

  const handleDeleteLocalRulesClick = async (event: React.MouseEvent): Promise<void> => {
    event.stopPropagation();
    event.preventDefault();

    if (!selectedCompany) {
      return;
    }

    const localRuleIdsToDelete = Object.keys(selectedLocalRules).filter((id) => !localRulesBeingDeleted[id]);

    setLocalRulesBeingDeleted((localRules) => ({ ...localRules, ...pick(selectedLocalRules, localRuleIdsToDelete) }));

    await LocalRulesService.getInstance().deleteLocalRules(selectedCompany.id, localRuleIdsToDelete);

    setLocalRules((localRules) => localRules.filter((localRule) => !selectedLocalRules[localRule.id]));
    setLocalRulesBeingDeleted((localRulesBeingDeleted) => omit(localRulesBeingDeleted, localRuleIdsToDelete));
  };

  const handleLocalRuleTypeSelectorChange = (localRuleType: LocalRuleType | null | undefined) => {
    setSelectedRuleType(localRuleType);
  };

  const handleMoneyDirectionSelectorChange = (moneyDirection: MoneyDirection | null | undefined) => {
    setSelectedMoneyDirection(moneyDirection);
  };

  const handleTableSelectionChange = (event: SelectionChangedEvent) => {
    setSelectedLocalRules(keyBy(event.api.getSelectedRows() as LocalRule[], 'id'));
  };

  const handleMerchantSelectorChange = (merchant: LeanMerchant | undefined) => {
    setSelectedMerchant(merchant);
  };

  const selectedLocalRuleArr = Object.keys(selectedLocalRules);

  return (
    <Stack spacing={1.5}>
      <Stack direction="row" spacing={2} flex={1}>
        <CompanySelector
          companies={companies}
          isLoading={isLoadingCompanies}
          onChange={handleCompanySelectorChange}
          required
          value={selectedCompany?.id}
        />
        <LocalRuleTypeSelector onChange={handleLocalRuleTypeSelectorChange} value={selectedRuleType} />
      </Stack>
      <Stack direction="row" spacing={2} flex={1}>
        <MerchantSelector
          isDisabled={!selectedCompany}
          onChange={handleMerchantSelectorChange}
          value={selectedMerchant}
        />
        <MoneyDirectionSelector onChange={handleMoneyDirectionSelectorChange} value={selectedMoneyDirection} />
      </Stack>
      <Divider />
      <Stack justifyContent="right" direction="row">
        <Button
          color="error"
          disabled={!selectedLocalRuleArr.length}
          onClick={handleDeleteLocalRulesClick}
          variant="outlined"
        >
          Delete Local Rules ({selectedLocalRuleArr.length})
        </Button>
      </Stack>
      <div className="ag-theme-material">
        <AgGridReact
          columnDefs={columns}
          columnHoverHighlight={false}
          defaultColDef={{ autoHeight: true, flex: 1, wrapText: true }}
          domLayout="autoHeight"
          getRowId={(row) => (row.data as LocalRule).id}
          isRowSelectable={(row) => !localRulesBeingDeleted[row.data.id]}
          noRowsOverlayComponent={NoRulesOverlay}
          noRowsOverlayComponentParams={filters}
          onSelectionChanged={handleTableSelectionChange}
          rowData={localRules}
          rowSelection="multiple"
          suppressCellFocus={true}
          suppressMovableColumns={true}
        />
      </div>
      {!isLoading && hasMorePages ? (
        <div ref={pageRequestRef} style={{ textAlign: 'center' }}>
          LOAD MORE...
        </div>
      ) : undefined}
    </Stack>
  );
}
