import { Avatar, Chip, Link, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import type { Table } from '@tanstack/react-table';
import _ from 'lodash';
import { type MouseEvent, useMemo, useState } from 'react';

import { ConfigService } from '../../services/config';
import { getMonetaryColumnDescription } from '../../shared';
import type { EnrichedBankTransaction } from '../../types';
import { BankTransactionSource, PaypalSpecificData } from '../../types';
import { IndeterminateCheckbox } from '../../ui/IndeterminateCheckbox';
import { BusinessMeaningChip } from '../BusinessMeaningChip';
import { DateExplorerLink } from '../DataExplorerLink';
import { SkeletonChip } from '../SkeletonChip';
import { UnknownChip } from '../UnknownChip';
import { MerchantChip } from '../MerchantChip';
import type { BankTransactionTableGenerics, HandleBusinessMeaningCellClick, HandleVendorCellClick } from './types';
import { UTCFormat } from '../../shared/time-utils';

export interface UseBankTransactionTableColumnsProps {
  bankTransactions?: EnrichedBankTransaction[];
  handleBusinessMeaningCellClick?: HandleBusinessMeaningCellClick;
  handleVendorCellClick?: HandleVendorCellClick;
  table: Table<BankTransactionTableGenerics>;
  companyId?: string | null;
}

export function useBankTransactionTableColumns(props: UseBankTransactionTableColumnsProps) {
  const { bankTransactions, handleBusinessMeaningCellClick, handleVendorCellClick, table } = props;

  const isBankTransactionSelectable = (bankTransaction: EnrichedBankTransaction | undefined) =>
    bankTransaction?.bankAccount.id !== 'skeleton' &&
    !bankTransaction?.isUpdating &&
    bankTransaction?.businessEvent?.classifications?.[0]?.businessEvent !== 'internal-transfer'; // TODO: will be dealt with in the future

  const selectableBankTransactions = useMemo(
    () => bankTransactions?.filter(isBankTransactionSelectable) || [],
    [bankTransactions],
  );

  const [rowSelection, setRowSelection] = useState({});

  const columns = [
    createSelectionColumn(table, selectableBankTransactions, isBankTransactionSelectable),
    createAccountNameColumn(table),
    createSourceColumn(table),
    createReceivedAtMsColumn(table),
    createOriginalVendorColumn(table),
    createOriginalDescriptionColumn(table),
    createInstitutionMemoColumn(table),
    createSpentColumn(table),
    createReceivedColumn(table),
    createVendorColumn(table, handleVendorCellClick),
    createBusinessMeaningColumn(table, handleBusinessMeaningCellClick),
    createPaypalLinkColumn(table),
    createDataExplorerLinkColumn(table, props.companyId),
  ];

  return { columns, rowSelection, setRowSelection };
}

export const TextSkeleton = () => <Skeleton variant="text"></Skeleton>;
export const Processing = () => <span style={{ opacity: '70%', fontStyle: 'italic' }}>Processing...</span>;

export const createSelectionColumn = (
  table: Table<BankTransactionTableGenerics>,
  selectableBankTransactions: EnrichedBankTransaction[],
  isBankTransactionSelectable: (bankTransaction: EnrichedBankTransaction | undefined) => boolean,
) =>
  table.createDisplayColumn({
    cell: ({ row: { getIsSelected, getToggleSelectedHandler, original } }) => (
      <IndeterminateCheckbox
        checked={getIsSelected()}
        disabled={!isBankTransactionSelectable(original)}
        onChange={getToggleSelectedHandler()}
      />
    ),
    header: ({ instance }) => (
      <IndeterminateCheckbox
        checked={instance.getIsAllRowsSelected()}
        disabled={!selectableBankTransactions.length}
        indeterminate={instance.getIsSomeRowsSelected()}
        onChange={instance.getToggleAllRowsSelectedHandler()}
      />
    ),
    id: 'select',
  });

export const createAccountNameColumn = (table: Table<BankTransactionTableGenerics>) =>
  table.createDataColumn('bankAccount', {
    cell: (props) => (props.row.original?.bankAccount.id !== 'skeleton' ? props.getValue().fullName : <TextSkeleton />),
    header: 'Bank Account',
  });

export const createSourceColumn = (table: Table<BankTransactionTableGenerics>) =>
  table.createDataColumn('source', {
    cell: (props) => (props.row.original?.bankAccount.id !== 'skeleton' ? props.getValue() : <TextSkeleton />),
    header: 'Source',
  });

export const createReceivedAtMsColumn = (table: Table<BankTransactionTableGenerics>) =>
  table.createDataColumn('postedAt', {
    cell: (props) =>
      props.row.original?.bankAccount.id !== 'skeleton' ? (
        UTCFormat(props.getValue(), ConfigService.getInstance().get('DATE_FORMAT', 'MM/DD/YYYY'))
      ) : (
        <TextSkeleton />
      ),
    header: 'Date',
  });

export const createOriginalDescriptionColumn = (table: Table<BankTransactionTableGenerics>) =>
  table.createDataColumn('description', {
    cell: (props) => (props.row.original?.bankAccount.id !== 'skeleton' ? props.getValue() : <TextSkeleton />),
    header: 'Description',
  });

export const createInstitutionMemoColumn = (table: Table<BankTransactionTableGenerics>) =>
  table.createDataColumn('institutionMemo', {
    cell: (props) =>
      props.row.original?.bankAccount.id !== 'skeleton' ? (
        <Tooltip title={props.getValue() || ''} placement="right">
          <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '10vw' }}>
            {props.getValue()}
          </div>
        </Tooltip>
      ) : (
        <TextSkeleton />
      ),
    header: 'Institution Memo',
  });

export const createSpentColumn = (table: Table<BankTransactionTableGenerics>) =>
  table.createDataColumn('amount', {
    cell: (props) => {
      const { original: bankTransaction } = props.row;

      if (!bankTransaction || bankTransaction.bankAccount.id === 'skeleton') {
        return <TextSkeleton />;
      }

      const amount = props.getValue();

      const direction = amount > 0 ? 'IN' : 'OUT';

      if (direction === 'IN') {
        return;
      }

      return (
        <div style={{ textAlign: 'right' }}>
          {getMonetaryColumnDescription(
            amount,
            bankTransaction.amountInUsd,
            bankTransaction.currency,
            bankTransaction.currency !== 'USD',
          )}
        </div>
      );
    },
    header: () => <div style={{ textAlign: 'right' }}>Spent</div>,
    id: 'spent',
  });

export const createReceivedColumn = (table: Table<BankTransactionTableGenerics>) =>
  table.createDataColumn('amount', {
    cell: (props) => {
      const { original: bankTransaction } = props.row;

      if (!bankTransaction || bankTransaction.bankAccount.id === 'skeleton') {
        return <TextSkeleton />;
      }

      const amount = props.getValue();

      const direction = amount > 0 ? 'IN' : 'OUT';

      if (direction === 'OUT') {
        return;
      }

      return (
        <div style={{ textAlign: 'right' }}>
          {getMonetaryColumnDescription(
            amount,
            bankTransaction.amountInUsd,
            bankTransaction.currency,
            bankTransaction.currency !== 'USD',
          )}
        </div>
      );
    },
    header: () => <div style={{ textAlign: 'right' }}>Received</div>,
    id: 'received',
  });

export const createOriginalVendorColumn = (table: Table<BankTransactionTableGenerics>) =>
  table.createDataColumn('sourceSpecificData', {
    cell: (props) => {
      const { original } = props.row;

      if (original?.bankAccount.id === 'skeleton') {
        return <TextSkeleton />;
      }

      if (original?.source !== BankTransactionSource.Paypal) {
        return;
      }

      const paypalData = props.row.original?.sourceSpecificData as PaypalSpecificData;
      return paypalData?.vendorName || paypalData?.payerEmail;
    },
    header: 'Original Vendor',
    id: 'originalVendor',
  });

export const createPaypalLinkColumn = (table: Table<BankTransactionTableGenerics>) =>
  table.createDataColumn('sourceSpecificData', {
    cell: (props) => {
      const { original } = props.row;

      if (original?.bankAccount.id === 'skeleton') {
        return <TextSkeleton />;
      }

      if (original?.source !== BankTransactionSource.Paypal) {
        return;
      }

      const value = (props.row.original?.sourceSpecificData as PaypalSpecificData).transactionId;

      return value ? (
        <Link href={`https://www.paypal.com/activity/payment/${value}`} target="_blank">
          Link
        </Link>
      ) : undefined;
    },
    header: 'Paypal Link',
    id: 'paypalLink',
  });

export const createVendorColumn = (
  table: Table<BankTransactionTableGenerics>,
  handleVendorCellClick?: (event: MouseEvent | undefined, bankTransaction: EnrichedBankTransaction) => void,
) =>
  table.createDataColumn('merchant', {
    cell: (props) => {
      const { original: bankTransaction } = props.row;

      if (!bankTransaction || bankTransaction.bankAccount.id === 'skeleton' || bankTransaction.isUpdating) {
        return <SkeletonChip />;
      }

      const { merchant, processedByMachineAt, businessEvent } = bankTransaction;
      const isInternalTransfer = businessEvent?.classifications.some(
        ({ businessEvent }) => businessEvent === 'internal-transfer',
      );

      if (!merchant?.id) {
        if (!processedByMachineAt) {
          return <Processing />;
        }

        return (
          <UnknownChip
            disabled={isInternalTransfer}
            onClick={(event) => handleVendorCellClick?.(event, bankTransaction)}
          />
        );
      }

      return (
        <MerchantChip
          disabled={isInternalTransfer}
          onClick={() => handleVendorCellClick?.(undefined, bankTransaction)}
          merchant={merchant}
          enrichmentRuleType={bankTransaction.classificationMetadata?.enrichmentRuleType}
          enrichmentRuleId={bankTransaction.classificationMetadata?.enrichmentRuleId}
          isAugmented={bankTransaction.classificationMetadata?.isAugmented}
        />
      );
    },
    header: 'Merchant',
  });

export const createBusinessMeaningColumn = (
  table: Table<BankTransactionTableGenerics>,
  handleBusinessMeaningCellClick?: (event: MouseEvent | undefined, bankTransaction: EnrichedBankTransaction) => void,
) =>
  table.createDataColumn('businessEvent', {
    cell: (props) => {
      const { original: bankTransaction } = props.row as { original: EnrichedBankTransaction };

      if (!bankTransaction || bankTransaction.bankAccount.id === 'skeleton' || bankTransaction.isUpdating) {
        return <SkeletonChip />;
      }

      const { processedByMachineAt, businessEvent } = bankTransaction;
      const businessEventClassifications = businessEvent?.classifications;

      const getManualTransactionsUploadFileLink = (transaction: EnrichedBankTransaction) => {
        if (!transaction.bankTransactionsUploadId) return;

        return (
          `https://streamlit.myfinaloop.com/?page=Bank+Txns+Uploaded+Files&subpage=Company+Uploaded+File&` +
          `company_id=${bankTransaction.companyId}&account_id=${transaction.bankAccount.id}&file_id=${transaction.bankTransactionsUploadId}`
        );
      };

      const getAskTheUserFileLink = (transaction: EnrichedBankTransaction) => {
        const suitableClassification = businessEventClassifications?.find(
          (c) => c.askTheUserResult?.data?.uploadTransactionsFile?.targetBankAccount?.id,
        );
        if (!suitableClassification || !transaction.uploadedFileId) return;

        return (
          `https://streamlit.myfinaloop.com/?page=Bank+Txns+Uploaded+Files&subpage=Company+Uploaded+File&` +
          `company_id=${bankTransaction.companyId}&account_id=${suitableClassification?.askTheUserResult?.data?.uploadTransactionsFile?.targetBankAccount?.id}&file_id=${transaction.uploadedFileId}`
        );
      };

      if (_.isEmpty(businessEventClassifications)) {
        if (!processedByMachineAt) {
          return <Processing />;
        }

        return <UnknownChip onClick={(event) => handleBusinessMeaningCellClick?.(event, bankTransaction)} />;
      }

      const askTheUserUploadedFileLink = getAskTheUserFileLink(bankTransaction);
      const manualTransactionsUpload = getManualTransactionsUploadFileLink(bankTransaction);

      return (
        <Stack>
          {businessEventClassifications?.map(
            ({
              businessMeaning,
              classificationText,
              classificationTagText,
              businessEvent,
              amount,
              askTheUserResult,
            }) =>
              businessMeaning ? (
                <>
                  <div>
                    <BusinessMeaningChip
                      businessEventRuleType={bankTransaction.classificationMetadata?.businessEventRuleType}
                      businessEventRuleId={bankTransaction.classificationMetadata?.businessEventRuleId}
                      description={
                        businessEventClassifications.length === 1 ? businessMeaning : `${businessMeaning} (${amount})`
                      }
                      onClick={() => handleBusinessMeaningCellClick?.(undefined, bankTransaction)}
                    />
                  </div>
                  {(classificationText || classificationTagText) && (
                    <Tooltip title={classificationText || classificationTagText || ''} placement="right">
                      <Typography noWrap maxWidth="30ch">
                        {classificationText || classificationTagText}
                      </Typography>
                    </Tooltip>
                  )}
                  {askTheUserUploadedFileLink || manualTransactionsUpload ? (
                    <Typography noWrap maxWidth="30ch">
                      {askTheUserUploadedFileLink && (
                        <Link target="_blank" href={askTheUserUploadedFileLink}>
                          Link to uploaded file
                        </Link>
                      )}
                      {manualTransactionsUpload && (
                        <Link target="_blank" href={manualTransactionsUpload}>
                          Link to transaction upload
                        </Link>
                      )}
                    </Typography>
                  ) : undefined}
                  {askTheUserResult && (
                    <Tooltip title={JSON.stringify(askTheUserResult)} placement="right">
                      <Typography noWrap maxWidth="30ch">
                        {JSON.stringify(askTheUserResult)}
                      </Typography>
                    </Tooltip>
                  )}
                </>
              ) : (
                <Chip
                  avatar={<Avatar>!</Avatar>}
                  color="error"
                  label={businessEventClassifications.length === 1 ? businessEvent : `${businessEvent} (${amount})`}
                  onClick={(event) => handleBusinessMeaningCellClick?.(event, bankTransaction)}
                  variant="outlined"
                />
              ),
          )}
        </Stack>
      );
    },
    header: 'Business Meaning',
  });

export const createDataExplorerLinkColumn = (table: Table<BankTransactionTableGenerics>, companyId?: string | null) =>
  table.createDataColumn('id', {
    cell: (props) =>
      props.row.original?.bankAccount.id !== 'skeleton' ? (
        <DateExplorerLink bankTransactionId={props.getValue()} companyId={companyId} />
      ) : (
        <TextSkeleton />
      ),
  });
