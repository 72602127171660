import axios from 'axios';

export const downloadFile = async (params: DownloadFileParams): Promise<void> => {
  const response = await axios.get(params.url, { responseType: 'blob' });

  const element = document.createElement('a');
  element.href = window.URL.createObjectURL(new Blob([response.data]));
  element.download = params.fileName;
  element.target = params.target ? params.target : '_blank';

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export interface DownloadFileParams {
  url: string;
  fileName: string;
  target?: HTMLAnchorElement['target'];
}
