import type { AxiosInstance } from 'axios';
import { SetRequired } from 'type-fest';
import { gqlClient } from '../../shared/gqlClient';

import { addAuthorizationTokenToAxiosRequests, getAxiosInstance, handleAxiosResponses } from '../../shared';
import {
  type BankTransaction,
  type BusinessMeaningDialogResult,
  ClassificationChangeValue,
  type EnrichedBankTransaction,
  type Page,
  PairedEntity,
  VendorDialogResult,
} from '../../types';

import { ConfigService } from '../config';
import type { GetBankTransactionPageParams } from './GetBankTransactionsPageParams';
import type { GetInternalTransferCandidatesParams } from './GetInternalTransferCandidatesParams';
import { bankTransactionFragment } from './fragments';
import { ArrayType } from '../../shared/types.utils';

export type InternalTransferCandidate = ArrayType<
  Awaited<ReturnType<typeof bankTransactionService.getInternalTransferCandidates>>
>;

export class BankTransactionsService {
  protected static instance: BankTransactionsService;

  protected constructor(
    protected readonly config: ConfigService,
    protected readonly axiosInstance: AxiosInstance,
  ) {}

  static getInstance(
    config: ConfigService = ConfigService.getInstance(),
    axiosInstance?: AxiosInstance,
  ): BankTransactionsService {
    if (BankTransactionsService.instance) {
      return BankTransactionsService.instance;
    }

    const apiClient = axiosInstance || getAxiosInstance({ baseURL: config.getOrFail('BANK_TRANSACTIONS_API_URL') });
    addAuthorizationTokenToAxiosRequests(apiClient);
    handleAxiosResponses(apiClient);

    return (BankTransactionsService.instance = new BankTransactionsService(config, apiClient));
  }

  async getBankTransactionWithPairedEntity({ transactionId, companyId }: { transactionId: string; companyId: string }) {
    const { bankTransaction } = await gqlClient.query({
      __name: 'bankTransaction',
      bankTransaction: {
        ...bankTransactionFragment,
        __args: {
          getBankTransactionInput: {
            transactionId,
            companyId,
          },
        },
      },
    });

    return bankTransaction;
  }

  async getBankTransactionPairedEntities(
    transactionId: string,
    companyId: string,
  ): Promise<{ pairedEntities: PairedEntity[] }> {
    try {
      const response = await this.axiosInstance.get(`/bank-transactions/${transactionId}/paired-entities`, {
        params: { companyId },
      });

      return response.data;
    } catch (err) {
      return { pairedEntities: [] };
    }
  }

  async getBankTransactionPage(params: GetBankTransactionPageParams): Promise<Page<BankTransaction, string>> {
    try {
      const { bankAccountId, businessEvent, endDate, startDate, description, ...prms } = params;

      const response = await this.axiosInstance.get('/bank-transactions', {
        params: {
          bankAccountId,
          businessEvent: businessEvent?.join(',') || undefined,
          endDate: this.normalizeDate(endDate)?.getTime(),
          startDate: this.normalizeDate(startDate)?.getTime(),
          description: description ? description : undefined,
          ...prms,
        },
      });

      return response.data;
    } catch (err) {
      return { items: [], cursor: params.cursor || null };
    }
  }

  protected normalizeDate(date: string | Date | null | undefined): Date | null {
    if (date == null) return null;

    if (typeof date === 'string') {
      return new Date(date);
    }

    return date;
  }

  async getInternalTransferCandidates(params: GetInternalTransferCandidatesParams) {
    const { bankTransaction, allowNotExactDateMatch, rangePercentageAmount, sort } = params;

    const { internalTransferCandidatesV2 } = await gqlClient.query({
      __name: 'InternalTransferCandidatesV2',
      internalTransferCandidatesV2: {
        bankTransactions: bankTransactionFragment,
        __args: {
          input: {
            bankTransactionId: bankTransaction.id,
            companyId: bankTransaction.companyId,
            allowNotExactDateMatch,
            rangePercentageAmount,
            sort,
            limit: 100,
          },
        },
      },
    });

    return internalTransferCandidatesV2.bankTransactions;
  }

  async getSimilarBankTransactionsPage(
    transactionId: string,
    companyId: string,
  ): Promise<Page<BankTransaction, string>> {
    try {
      const response = await this.axiosInstance.get(`/bank-transactions/${transactionId}/similar-bank-transactions`, {
        params: { companyId },
      });

      return response.data;
    } catch (err) {
      return { items: [], cursor: null };
    }
  }

  getBusinessMeaningChange(
    bankTransaction: EnrichedBankTransaction,
    change: BusinessMeaningDialogResult,
  ): ClassificationChangeValue | null {
    const { merchant: prevVendor } = bankTransaction;
    const { businessMeanings, pairedEntityId: newPairedEntityId, pairedEntityType: newPairedEntityType } = change;

    const newPairedEntity = newPairedEntityId != null ? { id: newPairedEntityId, type: newPairedEntityType! } : null;

    const vendor = !!prevVendor
      ? {
          id: prevVendor.id,
          name: prevVendor.name,
          source: prevVendor.vendorType,
        }
      : null;

    return businessMeanings?.length !== 0
      ? {
          businessEventClassifications: businessMeanings.map(({ businessMeaning, amount, description }) => ({
            businessEvent: businessMeaning.id,
            amount,
            description,
          })),
          pairedEntity: newPairedEntity,
          vendor,
        }
      : null;
  }

  async deleteBankTransactions({
    companyId,
    bankTransactionIds,
  }: {
    companyId: string;
    bankTransactionIds: string[];
  }): Promise<{ deletedTransactionIds: string[]; notDeletedTransactionIds: string[] }> {
    const { data } = await this.axiosInstance.delete<{ successfullyDeletedIds: string[]; failedToDeleteIds: string[] }>(
      '/bank-transactions',
      {
        data: { bankTransactionIds },
        params: { companyId },
      },
    );

    return { deletedTransactionIds: data.successfullyDeletedIds, notDeletedTransactionIds: data.failedToDeleteIds };
  }

  async excludeBankTransactions(
    bankTransactions: EnrichedBankTransaction[],
  ): Promise<{ excludedTransactions: EnrichedBankTransaction[]; notExcludedTransactions: EnrichedBankTransaction[] }> {
    const { companyId } = bankTransactions[0];
    const { data } = await this.axiosInstance.post<{ successfullyExcludedIds: string[]; failedToExcludeIds: string[] }>(
      '/excluded-bank-transactions',
      { bankTransactionIds: bankTransactions.map(({ id }) => id) },
      { params: { companyId } },
    );

    const excludedTransactions = data.successfullyExcludedIds.map((id) => bankTransactions.find((t) => t.id === id)!);
    const notExcludedTransactions = data.failedToExcludeIds.map((id) => bankTransactions.find((t) => t.id === id)!);

    return { excludedTransactions, notExcludedTransactions };
  }
}

export const bankTransactionService = BankTransactionsService.getInstance();
