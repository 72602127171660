import { BankTransactionBusinessEventClassification } from '../__generated-global__';
import { PairedEntity } from '../__generated-global__';
import { startCase } from 'lodash';
import { GeneralPairedEntity } from './BankTransactions/hooks/usePairedEntity';

export const findPairedClassification = (
  classifications?: Pick<
    BankTransactionBusinessEventClassification,
    'pairedEntityId' | 'pairedEntityType' | 'pairedEntity'
  >[],
) => {
  return classifications?.find((cl) => cl.pairedEntityId && cl.pairedEntityType);
};

export const resolveGeneralPairedEntityFields = (pairedEntity: PairedEntity): GeneralPairedEntity => {
  return {
    type: startCase(pairedEntity.__typename),
    ...resolvePropsByPairedEntityType(pairedEntity),
    ...pairedEntity,
  };
};

export const resolvePropsByPairedEntityType = (pairedEntity: PairedEntity): Partial<GeneralPairedEntity> => {
  switch (pairedEntity.__typename) {
    case 'BankTransaction':
      return { merchantName: pairedEntity.merchant?.name, date: pairedEntity.postedAt };
    case 'Bill':
      return { merchantName: pairedEntity.vendor.name, date: pairedEntity.createdDate };
    case 'Payout':
      return { merchantName: pairedEntity.integrationType, date: pairedEntity.eventTime };
    case 'Topup':
      return { merchantName: pairedEntity.integrationType, date: pairedEntity.eventTime };
  }

  return { merchantName: 'Unknown', date: 'Unknown' };
};
