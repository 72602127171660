import { useQuery } from '@tanstack/react-query';
import { BankAccountsService } from '../services/bank-accounts';
import { BankAccountWithStatements } from '../types';

export interface UseBankAccountsResult {
  bankAccounts?: BankAccountWithStatements[] | null;
  isLoading: boolean;
}

export const useBankAccountsWithStatements = (): UseBankAccountsResult => {
  const { data: bankAccounts, isLoading } = useQuery(
    ['bankAccountsWithStatements'],
    () => BankAccountsService.getInstance().getBankAccountsWithStatements(),
    { refetchOnWindowFocus: false, refetchInterval: false },
  );

  return { bankAccounts, isLoading };
};
