import { BankAccountStatementRequest } from '../../../types/BankAccountStatementRequest';

export const links = (bankAccountStatementRequest: BankAccountStatementRequest) => {
  return `https://streamlit.myfinaloop.com/?page=Bank%20Txns%20Uploaded%20Files&subpage=Company%20Uploaded%20File&company_id=${bankAccountStatementRequest.companyId}&account_id=${bankAccountStatementRequest.bankAccount.id}`;
};

export const getStreamlitValidationLink = (bankAccountStatementRequest: BankAccountStatementRequest) => {
  return `https://streamlit.myfinaloop.com/?page=Bank+account+balance+validations&subpage=Company+validations&company_id=${bankAccountStatementRequest.companyId}&account_id=${bankAccountStatementRequest.bankAccount.id}`
  };


export const getWorksheetLink = (bankAccountStatementRequest: BankAccountStatementRequest) => {
  return `https://data-explorer.finaloop.com/worksheet?companyId=%22${bankAccountStatementRequest.companyId}%22&showTestCompanies=false`
}
