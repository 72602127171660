import { Divider, Stack } from '@mui/material';
import { useEffect, useState, type ReactElement } from 'react';
import { useSearchParams } from 'react-router-dom';
import { BankAccountsService } from '../../services/bank-accounts';
import { BankAccountStatementsFilters } from './BankAccountStatementsFilters';
import { BankAccountStatementsTable } from './BankAccountStatementsTable/BankAccountStatementsTable';
import { BankAccountStatementsSelectedFilters } from './types';
import { downloadFile } from './shared/download-file';
import { StatementsTypes } from '../../services/bank-accounts/types';

async function handleStatementDownload(statementId: string, statementType: string) {
  const {items} = await BankAccountsService.getInstance().getBankAccountStatementRequestsPage({
    statementId,
    type: statementType as StatementsTypes
  });
  
  if (items.length === 1 && items[0].customerData) {
    const file = items[0].customerData.fileNames[0];
    const decodedAttachment = decodeURI(file);
    const attachmentDownloadUrl = await BankAccountsService.getInstance().getDownloadBankStatementUrl({
      fileName: file,
      statementDateMs: new Date(items[0].toDate).getTime(),
      companyId: items[0].companyId,
      bankAccountId: items[0].bankAccount.id,
    });

    await downloadFile({
      url: attachmentDownloadUrl,
      fileName: decodedAttachment,
    });
  }
}

export function BankAccountStatements(): ReactElement {
  const [searchParams] = useSearchParams();
  const [selectedFilters, setSelectedFilters] = useState<BankAccountStatementsSelectedFilters>({});

  const statementId = searchParams?.get('downloadStatementId');
  const statementType = searchParams?.get('downloadStatementType');

  useEffect(()=>{
    const downloadStatement = async () => {
      if (statementId && statementType) {
       await handleStatementDownload(statementId,statementType);
      }
    }
    downloadStatement();
    
  },[statementId,statementType]);

  return (
    <Stack spacing={2}>
      <BankAccountStatementsFilters selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
      <Divider sx={{ marginTop: 2 }} />
      <BankAccountStatementsTable selectedFilters={selectedFilters}/>
    </Stack>
  );
}
