import { memo, useMemo } from 'react';
import { keyBy } from 'lodash';

import type { Company } from '../types';
import { Autocomplete } from '../ui/Autocomplete';

export interface CompanySelectorProps {
  companies: Company[] | null | undefined;
  isLoading?: boolean | null;
  onChange?: (company: Company | undefined | null) => void;
  required?: boolean | null;
  value?: string | null;
}

export const CompanySelector = memo(function (props: CompanySelectorProps) {
  const { companies } = props;

  // TODO: add "show test companies state"..

  const companyDictionary = useMemo(
    () =>
      keyBy(
        companies?.filter(
          (company) => !company.isTestCompany && company.email !== 'archived' && company.email !== 'archive',
        ),
        'id',
      ),
    [companies],
  );

  const val = companyDictionary[props.value || ''];

  return (
    // TODO: add "show test companies checkbox"..
    <Autocomplete
      isClearable={false}
      isLoading={props.isLoading}
      label={`Company${props.required ? ' *' : ''}`}
      onChange={(value) => props.onChange?.(value[0])}
      optionEqualityFn={(company0: Company, company1: Company) => company0.id === company1.id}
      optionKeyFn={(company: Company) => company.id}
      optionLabelFn={(company: Company) => company.name!}
      options={companies || []}
      value={val ? [val] : []}
    />
  );
});
