import type { AxiosInstance } from 'axios';
import { addAuthorizationTokenToAxiosRequests, getAxiosInstance, handleAxiosResponses } from '../../shared';
import { ConfigService } from '../config';
import { BalanceTransaction, createClient, everything, GetBalanceTransactionsArgs } from '../../__genql__';
import { TokenService } from '../auth/TokenService';
import { gqlClient } from '../../shared/gqlClient';
import { merchantSelection } from '../bank-transactions/fragments';
import { IntegrationTransferType } from '../../__generated-global__';
import { ArrayType } from '../../shared/types.utils';
export * from '../../__genql__';

const config = ConfigService.getInstance();

export const balanceTransactionsClient = createClient({
  url: `${config.getOrFail('COMPANIES_API_URL')}/balance-transactions`,
  headers: async () => {
    const token = await TokenService.getInstance().getToken();
    return { Authorization: `Bearer ${token}` };
  },
});

export type IntegrationTransfer = ArrayType<
  Awaited<ReturnType<typeof bankAffectingIntegrationEventsService.getMatchableIntegrationTransfers>>
>;

const addFinaloopSubjectId = (balanceTransaction: BalanceTransaction) => {
  return {
    ...balanceTransaction,
    amountInUSD: balanceTransaction.amountInUsd,
    finaloopSubjectId: balanceTransaction.id,
  };
};

export class BankAffectingIntegrationEventsService {
  protected static instance: BankAffectingIntegrationEventsService;

  protected constructor(
    protected readonly config: ConfigService,
    protected readonly axiosInstance: AxiosInstance,
  ) {}

  static getInstance(
    config: ConfigService = ConfigService.getInstance(),
    axiosInstance?: AxiosInstance,
  ): BankAffectingIntegrationEventsService {
    if (BankAffectingIntegrationEventsService.instance) {
      return BankAffectingIntegrationEventsService.instance;
    }

    const apiClient = axiosInstance || getAxiosInstance({ baseURL: config.getOrFail('BANK_TRANSACTIONS_API_URL') });
    addAuthorizationTokenToAxiosRequests(apiClient);
    handleAxiosResponses(apiClient);

    return (BankAffectingIntegrationEventsService.instance = new BankAffectingIntegrationEventsService(
      config,
      apiClient,
    ));
  }

  getMatchablePayouts = async (args: GetBalanceTransactionsArgs) => {
    const response = await balanceTransactionsClient.query({
      getPayouts: [
        { args },
        {
          ...everything,
          pairedBankTransaction: {
            ...everything,
            merchant: {
              ...everything,
            },
          },
        },
      ],
    });

    return response.getPayouts.map(addFinaloopSubjectId);
  };

  getMatchableIntegrationTransfers = async (args: any, type: IntegrationTransferType) => {
    const { integrationTransfersBackoffice } = await gqlClient.query({
      __name: 'IntegrationTransfersBackoffice',
      integrationTransfersBackoffice: {
        __args: {
          input: { ...args, type },
        },
        __typename: true,
        id: true,
        amount: true,
        amountInUsd: true,
        integrationAccountId: true,
        integrationType: true,
        // integrationAccountName: true, // does not work
        currency: true,
        eventTime: true,
        companyId: true,
        pairedBankTransaction: {
          __scalar: true,
          __typename: true,
          merchant: merchantSelection,
        },
      },
    });

    return integrationTransfersBackoffice.map((item) => ({ ...item, finaloopSubjectId: item.id }));
  };

  getMatchableTopups = async (args: GetBalanceTransactionsArgs) => {
    const response = await balanceTransactionsClient.query({
      getTopups: [
        { args },
        {
          ...everything,
          pairedBankTransaction: {
            ...everything,
            merchant: {
              ...everything,
            },
          },
        },
      ],
    });

    return response.getTopups.map(addFinaloopSubjectId);
  };
}

export const bankAffectingIntegrationEventsService = BankAffectingIntegrationEventsService.getInstance();
