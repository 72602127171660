import { SimulatedLoanFailure, SimulatedLoanUpdate, isSimulatedLoanFailure } from '../../__generated-global__';
import { Dialog } from '../../ui/Dialog';
import { Typography } from '@mui/material';

export interface LoanApproveDialogProps {
  isOpen: boolean;
  onOK: () => void;
  onClose: () => void;
  effect: SimulatedLoanFailure | SimulatedLoanUpdate | null;
}

export const LoanApproveDialog = ({ isOpen, onClose, onOK, effect }: LoanApproveDialogProps) => {
  if (!effect) {
    return null;
  }

  if (isSimulatedLoanFailure(effect)) {
    return (
      <Dialog
        isOpen={isOpen}
        okText="OK"
        onOK={onClose}
        onClose={onClose}
        cancelHidden
        title="Error in applying loan changes"
      >
        <Typography fontSize={18}>
          Unable to categorize the transaction because of an error in applying changes to company loans. error:{' '}
          {effect.reason || 'Unknown server error'}
        </Typography>
      </Dialog>
    );
  }

  return (
    <Dialog isOpen={isOpen} okText="Approve" onOK={onOK} onClose={onClose} title="Approve Changes in Company Loans">
      <Typography variant="h6" gutterBottom>
        You are about to modify the loans list of the company. To continue with the categorization you must approve
        these changes. This is the list of changes:
      </Typography>
      <ul>
        {effect.createdLoan ? (
          <li>
            <Typography fontSize={18}>
              A new loan will be created:
              <ul>
                <li>type: {effect.createdLoan.type || 'Unknown'}</li>
                <li>short term: {effect.createdLoan.isShortTerm ? 'True' : 'False'}</li>
                <li>date: {effect.createdLoan.date || 'Unknown'}</li>
                <li>amount: {effect.createdLoan.amount || 'Unknown'}</li>
                <li>lender: {effect.createdLoan.lender?.name || 'Unknown'}</li>
              </ul>
            </Typography>
          </li>
        ) : null}
        {effect.deletedLoan ? (
          <li>
            <Typography fontSize={18}>
              An existing loan will be deleted:
              <ul>
                <li>loan id: {effect.deletedLoan.loanId}</li>
                <li>type: {effect.deletedLoan.type || 'Unknown'}</li>
                <li>short term: {effect.deletedLoan.isShortTerm ? 'True' : 'False'}</li>
                <li>date: {effect.deletedLoan.date || 'Unknown'}</li>
                <li>amount: {effect.deletedLoan.amount || 'Unknown'}</li>
                <li>lender: {effect.deletedLoan.lender?.name || 'Unknown'}</li>
              </ul>
            </Typography>
          </li>
        ) : null}
      </ul>
    </Dialog>
  );
};
