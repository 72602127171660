import { Stack } from '@mui/material';
import { endOfDay } from 'date-fns';
import { useBusinessMeanings, useCompanies } from '../../shared';
import { type BankAccount, BankTransactionSource, type BusinessMeaning, type Company, VendorType } from '../../types';
import { DateRangePicker } from '../../ui/DateRangePicker';
import { BankAccountSelector } from '../BankAccountSelector';
import { BusinessMeaningSelector } from '../BusinessMeaningSelector';
import { EnrichmentRuleTypeSelector } from '../EnrichmentRuleTypeSelector';
import { CompanySelector } from '../CompanySelector';
import { MerchantSelector } from '../MerchantSelector';
import { useBankAccounts } from '../../shared/use-bank-accounts';
import { SourceSelector } from '../SourceSelector';
import { BusinessEventRuleTypeSelector } from '../BusinessEventRuleTypeSelector';
import { LeanMerchant } from '../CategorizationDialog/MerchantSelection';

export interface BankTransactionsFiltersProps {
  onChange: (change: BankTransactionsFilterChange) => void;
  value: BankTransactionsFilterChange | null;
}

export interface BankTransactionsFilterChange {
  bankAccount?: BankAccount | null;
  businessMeaning?: BusinessMeaning[];
  company?: { id?: string } | null;
  endDate?: string | Date | null;
  startDate?: string | Date | null;
  source?: BankTransactionSource | null;
  merchant?: LeanMerchant | null;
  businessEventRuleType?: string | '{null}' | null;
  enrichmentRuleType?: string | '{null}' | null;
}

export function BankTransactionsFilters(props: BankTransactionsFiltersProps) {
  const { value } = props;
  const { company, merchant } = value || {};

  const { companies, isLoading: isLoadingCompanies } = useCompanies();
  const { businessMeanings, isLoading: isLoadingBusinessMeanings } = useBusinessMeanings();
  const { bankAccounts, isLoading: isLoadingBankAccounts } = useBankAccounts(company?.id);

  const updateFilters = (change: BankTransactionsFilterChange): void => {
    props.onChange({ ...value, ...change });
  };

  const handleBankAccountChange = (bankAccount: BankAccount | null | undefined): void => {
    updateFilters({ bankAccount });
  };

  const handleSourceChange = (source: BankTransactionSource | null | undefined): void => {
    updateFilters({ source });
  };

  const handleBusinessMeaningChange = (businessMeaning: BusinessMeaning[]): void => {
    updateFilters({ businessMeaning });
  };

  const handleBusinessEventRuleTypeChange = (businessEventRuleType: string | '{null}' | null): void => {
    updateFilters({ businessEventRuleType });
  };

  const handleCompanyChange = (company: Company | null | undefined): void => {
    const change: BankTransactionsFilterChange = { company: { id: company?.id }, bankAccount: null };

    if ((merchant as any)?.merchantData?.type === VendorType.LOCAL) {
      change.merchant = null;
    }

    updateFilters(change);
  };

  const handleDateRangeChange = (startDate: Date | null | undefined, endDate: Date | null | undefined): void => {
    updateFilters({ endDate, startDate });
  };

  const handleMerchantChange = (merchant: LeanMerchant | null | undefined): void => {
    updateFilters({ merchant: merchant || null });
  };

  const handleEnrichmentRuleTypeChange = (enrichmentRuleType: string | '{null}' | null): void => {
    updateFilters({ enrichmentRuleType });
  };

  return (
    <Stack spacing={1}>
      <h4>Filtering Options</h4>
      <Stack direction="row" justifyContent="flex-start" spacing={1}>
        <CompanySelector
          companies={companies || []}
          isLoading={isLoadingCompanies}
          onChange={handleCompanyChange}
          required
          value={company?.id}
        />
        <BankAccountSelector
          bankAccounts={bankAccounts || []}
          isDisabled={!company}
          isLoading={isLoadingBankAccounts}
          onChange={handleBankAccountChange}
          value={value?.bankAccount?.id}
        />
      </Stack>
      <Stack direction="row" justifyContent="flex-start" spacing={1}>
        <div style={{ flex: 1 }}>
          <DateRangePicker
            endDate={value?.endDate}
            label="Date"
            maxDate={endOfDay(Date.now())}
            onChange={handleDateRangeChange}
            startDate={value?.startDate}
            timezone="UTC"
            sx={{ width: '100%' }}
          />
        </div>
        <div style={{ flex: 1 }}>
          <SourceSelector isDisabled={!company} onChange={handleSourceChange} value={value?.source} />
        </div>
      </Stack>
      <h5>Classification</h5>
      <Stack direction="row" justifyContent="flex-start" spacing={1}>
        <MerchantSelector
          isDisabled={!company}
          isLoading={false}
          onChange={handleMerchantChange}
          value={merchant as any}
          companyId={company?.id}
        />
        <BusinessMeaningSelector
          businessMeanings={[
            { id: '{null}', moneyDirection: 'ALL', name: '[unknown]', vendorRequired: false },
            ...(businessMeanings?.forFiltering || []),
          ]}
          isLoading={isLoadingBusinessMeanings}
          isMultiple
          onChange={handleBusinessMeaningChange}
          value={value?.businessMeaning?.map((item) => item.id)}
        />
      </Stack>
      <Stack direction="row" justifyContent="flex-start" spacing={1}>
        <EnrichmentRuleTypeSelector
          label="Enrichment (Merchant) Rule Type"
          onChange={handleEnrichmentRuleTypeChange}
          value={value?.enrichmentRuleType}
        />
        <BusinessEventRuleTypeSelector
          label="Business Event Rule Type"
          onChange={handleBusinessEventRuleTypeChange}
          value={value?.businessEventRuleType}
        />
      </Stack>
    </Stack>
  );
}
