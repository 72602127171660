import { Box, Link, Typography } from '@mui/material';
import { format } from 'date-fns';
import { BankAccountsService } from '../../../../services/bank-accounts';
import {
  BankAccountStatementRequest,
  BankAccountStatementRequestStatus,
  BankStatementCustomerData, IrrelevantType,
} from '../../../../types/BankAccountStatementRequest';
import { downloadFile } from '../../shared/download-file';
import { links } from '../../shared/links';

export const CustomerDataCell = ({
                                   customerData,
                                   bankAccountStatementRequest,
                                 }: {
  bankAccountStatementRequest: BankAccountStatementRequest;
  customerData?: BankStatementCustomerData;
}): JSX.Element | null => {
  const onAttachmentDownload = async (attachmentKey: string): Promise<void> => {
    const decodedAttachment = decodeURI(attachmentKey);
    const attachmentDownloadUrl = await BankAccountsService.getInstance().getDownloadBankStatementUrl({
      fileName: decodedAttachment,
      statementDateMs: new Date(bankAccountStatementRequest.toDate).getTime(),
      companyId: bankAccountStatementRequest.companyId,
      bankAccountId: bankAccountStatementRequest.bankAccount.id,
    });

    await downloadFile({
      url: attachmentDownloadUrl,
      fileName: decodedAttachment,
    });
  };

  return customerData ? (
    <Box>
      {
        !customerData.bankDetails &&
        <>
          <Typography variant="body2">Displayed balance: {customerData.displayedBalance}</Typography>
          {customerData.realBalance && (
            <Typography variant="body2">Entered balance: {customerData.realBalance}</Typography>)}
          <Typography variant="body2">Balance confirmed: {customerData.isConfirmed ? 'Yes' : 'No'}</Typography>
          {bankAccountStatementRequest.bankAccount.subtype === 'CreditCard' && (
            <Typography variant="body2">
              Cycle end date: {customerData.cycleEnd && format(new Date(customerData.cycleEnd), 'yyyy-MM-dd')}
            </Typography>
          )}
          {[BankAccountStatementRequestStatus.Irrelevant, BankAccountStatementRequestStatus.IrrelevantApproved].includes(bankAccountStatementRequest.status) && (
            <>
              <Typography variant="body2">Irrelevant type: {customerData.irrelevantType}</Typography>
              {customerData.irrelevantReason && (
                <Typography variant="body2">Irrelevant reason: {customerData.irrelevantReason}</Typography>
              )}
            </>
          )}
          {customerData.newOwner && (
            <Typography variant="body2">New owner: {customerData.newOwner}</Typography>
          )}
          {customerData.transferDate && (
            <Typography variant="body2">
              Transfer date: {format(new Date(customerData.transferDate), 'yyyy-MM-dd')}
            </Typography>
          )}
          {customerData.closedDate && (
            <Typography variant="body2">
              Closed date: {format(new Date(customerData.closedDate), 'yyyy-MM-dd')}
            </Typography>
          )}
          {customerData.isClosedThisYear !== null && (
            <Typography variant="body2">Is closed this year: {customerData.isClosedThisYear ? 'Yes' : 'No'}</Typography>
          )}
        </>
      }
      {bankAccountStatementRequest.customerData?.isMachineReconciled !== undefined && (
        <Typography variant="body2">
          Is machine reconciled: {customerData.isMachineReconciled ? 'Yes' : 'No'}
        </Typography>
      )}
      {
        customerData.bankDetails?.name &&
        <Typography variant="body2">
          {customerData.bankDetails?.missingBank ? 'Missing' : ''} bank name: {customerData.bankDetails.name}
        </Typography>
      }
      {
        customerData.bankDetails?.accountName &&
        <Typography variant="body2">
          account name: {customerData.bankDetails.accountName}
        </Typography>
      }
      {
        customerData.bankDetails?.type &&
        <Typography variant="body2">
          Account type: {customerData.bankDetails.type}
        </Typography>
      }
      {
        customerData.bankDetails?.lastFourDigits &&
        <Typography variant="body2">
          Account last 4 digits: {customerData.bankDetails.lastFourDigits}
        </Typography>
      }
      {customerData.fileNames?.length > 0 && (
        <Box display="flex" gap={1}>
          <Typography variant="body2">Attachments:</Typography>
          {customerData.fileNames.map((attachment) => (
            <Link
              key={attachment}
              component="button"
              variant="body2"
              onClick={async () => {
                await onAttachmentDownload(attachment);
              }}
            >
              {decodeURI(attachment)}
            </Link>
          ))}
        </Box>
      )}
      {customerData.noPDFFiles && <Typography variant="body2">No PDF files</Typography>}
      {customerData.csvFileNames && customerData.csvFileNames?.length > 0 && (
        <Box display="flex" gap={1}>
          <Typography variant="body2">CSV Attachments:</Typography>
          {customerData.csvFileNames.map((attachment) => (
            <Link
              target="_blank"
              rel="noopener"
              key={attachment}
              component="button"
              variant="body2"
              onClick={async () => {
                const streamlitLink = links(bankAccountStatementRequest);
                window.open(streamlitLink, '_blank');
              }}
            >
              {decodeURI(attachment)}
            </Link>
          ))}
        </Box>
      )}
      {customerData.noCSVFiles && <Typography variant="body2">No CSV files</Typography>}
    </Box>
  ) : null;
};

