export enum BankTransactionClassifierType {
  RULE = 'rule',
  USER = 'user',
  LOCAL_RULE = 'localRule',
  INTEGRATION = 'integration',
  DATA_DELETER = 'data-deleter',
  FINALOOP_TEAM = 'finaloop-team',
  FINALOOP_CUSTOMER = 'finaloop-customer',
  NTROPY = 'ntropy',
}
