import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Snackbar, IconButton } from '@mui/material';
import { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GeneralPairedEntity } from '../../BankTransactions/hooks/usePairedEntity';

export const MatchedEntityDetails = ({ pairedEntity }: { pairedEntity: GeneralPairedEntity }) => {
  const [copiedTextOpen, setCopiedTextOpen] = useState(false);

  const handleCopyIdClick = () => {
    setCopiedTextOpen(true);
    navigator.clipboard.writeText(pairedEntity.id);
  };

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
        Matched {pairedEntity.type}
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <Table aria-label="simple table" sx={{ borderTop: '1px solid grey' }}>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Currency</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Amount in USD</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Merchant</TableCell>
              <TableCell>ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={pairedEntity.id}>
              <TableCell>{pairedEntity.type}</TableCell>
              <TableCell component="th" scope="row">
                {pairedEntity.description}
              </TableCell>
              <TableCell>{pairedEntity.currency}</TableCell>
              <TableCell>{pairedEntity.amount}</TableCell>
              <TableCell>{pairedEntity.amountInUsd}</TableCell>
              <TableCell>{pairedEntity.date}</TableCell>
              <TableCell>{pairedEntity.merchantName}</TableCell>
              <TableCell>
                <div style={{ textOverflow: 'ellipsis', width: '150px', overflow: 'hidden' }}>{pairedEntity.id}</div>
                <IconButton onClick={handleCopyIdClick}>
                  <ContentCopyIcon fontSize="medium" />
                </IconButton>
                <Snackbar
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  open={copiedTextOpen}
                  onClose={() => setCopiedTextOpen(false)}
                  autoHideDuration={2000}
                  message="Copied to clipboard"
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
};
