import { memo, type ReactElement, useMemo } from 'react';
import { keyBy } from 'lodash';

import { Autocomplete } from '../ui/Autocomplete';

export interface BusinessEventTypeSelectorProps {
  label?: string | null;
  onChange?: (classifierType: any) => void;
  value?: any;
}

interface Option {
  id: string;
  name: string;
}

const types: Option[] = [
  {
    id: '{null}',
    name: '[Unclassified]',
  },
  {
    id: 'finaloop-team',
    name: 'Finaloop Team',
  },
  {
    id: 'finaloop-customer',
    name: 'Finaloop Customer',
  },
  {
    id: 'bill-match-model',
    name: 'Bill match model',
  },
  {
    id: 'company-vendor-to-ask-the-user',
    name: 'Company vendor to ask the user',
  },
  {
    id: 'global-business-event-rule',
    name: 'Global business event rule',
  },
  {
    id: 'integration-match-model',
    name: 'Integration match model',
  },
  {
    id: 'internal-transfer-model',
    name: 'Internal transfer model',
  },
  {
    id: 'invoice-match-model',
    name: 'Invoice match model',
  },
  {
    id: 'local-vendor-to-business-event-rule',
    name: 'Local vendor to business event rule',
  },
  {
    id: 'paypal-refund-match-model',
    name: 'PayPal refund match model',
  },
  {
    id: 'refund-match-model',
    name: 'Refund match model',
  },
  {
    id: 'taxonomy-to-business-event',
    name: 'Taxonomy to business event',
  },
];

export const BusinessEventRuleTypeSelector = memo((props: BusinessEventTypeSelectorProps): ReactElement => {
  const { value } = props;

  const typeDictionary = useMemo(() => keyBy(types, 'id'), []);

  const innerValue = typeDictionary[value || ''];

  return (
    <Autocomplete
      label={props.label}
      onChange={(value) => props.onChange?.(value[0]?.id)}
      optionEqualityFn={(type0: Option, type1: Option): boolean => type0.id === type1.id}
      optionKeyFn={(type: Option) => type.id}
      optionLabelFn={(type: Option) => type.name.trim()}
      options={types}
      value={innerValue ? [innerValue] : []}
    />
  );
});
