import { useQuery } from '@tanstack/react-query';

import { BusinessMeaningsService } from '../services/business-meanings';

const split = [{ id: 'split', name: 'Split', vendorRequired: false, moneyDirection: 'ALL' }] as const;

export function useBusinessMeanings() {
  const { data, isLoading } = useQuery(
    ['business-meanings'],
    () => BusinessMeaningsService.getInstance().getBusinessMeaningsPage(),
    {
      select: (data) => ({
        forClassification: data.filter((b) => b.hideForManualClassification !== true).concat(split),
        forFiltering: data
          .filter((b) => b.hideForManualClassification !== true || b.forceShowInEventsListings === true)
          .concat(split),
      }),
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: false,
    },
  );

  return { businessMeanings: data, isLoading };
}
