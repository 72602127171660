import { Box } from '@mui/material';
import { DataGrid, GridRowModel } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { BankAccountsService } from '../../../services/bank-accounts';
import { BankAccountStatementRequest } from '../../../types/BankAccountStatementRequest';
import { bankAccountStatementsDisplayColumns } from '../bankAccountStatementsDisplayColumns';
import { useBankStatements } from '../hooks/useBankStatements';
import { BankAccountStatementsSelectedFilters } from '../types';

interface BankAccountStatementsTableProps {
  selectedFilters: BankAccountStatementsSelectedFilters;
}

export const BankAccountStatementsTable = ({ selectedFilters }: BankAccountStatementsTableProps) => {
  const [page, setPage] = useState(0);
  const mapPageToNextCursor = React.useRef<{ [page: number]: string }>({});
  const { pageInfo, isLoading, bankAccountStatementRequests } = useBankStatements({
    cursor: mapPageToNextCursor.current[page - 1],
    ...selectedFilters,
    type: selectedFilters.statementTypes
  });

  const handlePageChange = (newPage: number) => {
    if (newPage === 0 || mapPageToNextCursor.current[newPage - 1]) {
      setPage(newPage);
    }
  };

  useEffect(() => {
    if (!isLoading && pageInfo.cursor) {
      mapPageToNextCursor.current[page] = pageInfo.cursor;
    }
  }, [page, isLoading, pageInfo.cursor]);

  const [rowCountState, setRowCountState] = useState(pageInfo.totalDocs || 0);
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      pageInfo.totalDocs !== undefined ? pageInfo.totalDocs : prevRowCountState,
    );
  }, [pageInfo.totalDocs, setRowCountState]);

  const processRowUpdate = async (newRow: GridRowModel) => {
    const { status, id, toDate, fromDate, type, finaloopData } = newRow as BankAccountStatementRequest;
    const updatedBankAccountStatementRequest =
      await BankAccountsService.getInstance().updateBankAccountStatementRequest({
        bankAccountStatementRequest: {
          status,
          id,
          toDate,
          fromDate,
          type,
          finaloopData,
        },
      });

    console.log('updating', updatedBankAccountStatementRequest);
    return updatedBankAccountStatementRequest;
  };

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
      }}
    >
      <DataGrid
        rows={bankAccountStatementRequests || []}
        columns={bankAccountStatementsDisplayColumns}
        pagination
        pageSize={100}
        rowsPerPageOptions={[100]}
        rowCount={rowCountState}
        paginationMode="server"
        onPageChange={handlePageChange}
        page={page}
        rowHeight={200}
        loading={isLoading}
        experimentalFeatures={{ newEditingApi: true }}
        processRowUpdate={processRowUpdate}
      />
    </Box>
  );
};
