import { toast } from 'react-toastify';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { TokenService } from '../services/auth/TokenService';

export function getAxiosInstance(config?: AxiosRequestConfig<any>): AxiosInstance {
  return axios.create({
    headers: { 'Content-Type': 'application/json' },
    ...config,
  });
}

export function addAuthorizationTokenToAxiosRequests(axiosInstance: AxiosInstance): void {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await TokenService.getInstance().getToken();

      return {
        ...config,
        headers: { ...config.headers, Authorization: `Bearer ${token}` },
      };
    },
    (error) => {
      return Promise.reject(error);
    },
  );
}

export function handleAxiosResponses(axiosInstance: AxiosInstance): void {
  axiosInstance.interceptors.response.use(
    (response) => {
      console.log('response', response.data);

      return response;
    },
    (error) => {
      const { response } = error;

      if (!response) {
        return Promise.reject(error);
      }

      console.error(response);

      toast(response.data, {
        hideProgressBar: true,
        position: 'bottom-center',
        type: 'error',
      });

      return Promise.reject(error);
    },
  );
}
