import type { ChangeEvent, ReactElement } from 'react';
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

import { LocalRuleType } from '../../types';

export interface LocalRuleTypeSelectorProps {
  onChange?: (localRuleType: LocalRuleType | null | undefined) => void;
  value?: LocalRuleType | null;
}

export function LocalRuleTypeSelector(props: LocalRuleTypeSelectorProps): ReactElement {
  const labelId = 'local-rule-type-selector-label';

  const handleRadioGroupChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    props.onChange?.(value === 'ANY' ? undefined : (value as LocalRuleType));
  };

  return (
    <Box sx={{ width: '100%' }}>
      <FormControl>
        <FormLabel id={labelId}>Rule Type</FormLabel>
        <RadioGroup
          row
          aria-labelledby={labelId}
          name="local-rule-type-selector-radio-group"
          value={props.value || 'ANY'}
          onChange={handleRadioGroupChange}
        >
          <FormControlLabel control={<Radio />} label="All" value="ANY" />
          <FormControlLabel control={<Radio />} label="Transactions » Vendor" value={LocalRuleType.Vendor} />
          <FormControlLabel
            control={<Radio />}
            label="Vendor » Business Meaning"
            value={LocalRuleType.VendorToBusinessMeaning}
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
}
