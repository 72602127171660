import { SPLIT } from '../../../types/bankClassificationActions';
import { BankAffectingDisplay } from './BankAffectingDisplay';
import { DefaultDisplay } from './DefaultDisplay';
import { InternalTransferDisplay } from './InternalTransferDisplay';
import { SplitTransactionDisplay } from './SplitTransactionDisplay';
import { BankTransaction, BusinessMeaning } from '../../../types';
import { BusinessEventSelectionResult } from './BusinessEventSelection';
import { CategorizationResult } from '../index';
import { PaymentCheckDisplay } from './PaymentCheckDisplay';

export interface ContentProps {
  bankTransaction: BankTransaction;
  currentBusinessMeaning: BusinessMeaning | undefined | null;
  categorizationResult: CategorizationResult;
  onBusinessEventSelectionChange: (result: BusinessEventSelectionResult) => void;
  onShouldApplyGlobalRuleChange?: (shouldApplyGlobalRule: boolean) => void;
  isDisabled?: boolean;
}

export interface SelectionContentProps extends ContentProps {
  selectableBusinessMeanings: BusinessMeaning[];
}

export const ContentSelector = ({
  currentBusinessMeaning,
  onBusinessEventSelectionChange,
  onShouldApplyGlobalRuleChange,
  bankTransaction,
  categorizationResult,
  selectableBusinessMeanings,
  isDisabled = false,
}: SelectionContentProps) => {
  const selectedBusinessMeaningId = currentBusinessMeaning?.id;
  switch (selectedBusinessMeaningId) {
    case 'internal-transfer':
      return (
        <InternalTransferDisplay
          bankTransaction={bankTransaction}
          currentBusinessMeaning={currentBusinessMeaning}
          categorizationResult={categorizationResult}
          onBusinessEventSelectionChange={onBusinessEventSelectionChange}
        />
      );
    case 'payout-received-in-bank':
      return (
        <BankAffectingDisplay
          bankTransaction={bankTransaction}
          currentBusinessMeaning={currentBusinessMeaning}
          onBusinessEventSelectionChange={onBusinessEventSelectionChange}
          categorizationResult={categorizationResult}
          eventTitle="payout"
          type="PAYOUT"
        />
      );
    case 'top-up-paid-from-bank':
      return (
        <BankAffectingDisplay
          bankTransaction={bankTransaction}
          currentBusinessMeaning={currentBusinessMeaning}
          onBusinessEventSelectionChange={onBusinessEventSelectionChange}
          categorizationResult={categorizationResult}
          eventTitle="topup"
          type="TOPUP"
        />
      );
    case 'payroll-check-paid-from-bank':
      return (
        <PaymentCheckDisplay
          bankTransaction={bankTransaction}
          currentBusinessMeaning={currentBusinessMeaning}
          categorizationResult={categorizationResult}
          onBusinessEventSelectionChange={onBusinessEventSelectionChange}
        />
      );
    case SPLIT:
      return (
        <SplitTransactionDisplay
          bankTransaction={bankTransaction}
          currentBusinessMeaning={currentBusinessMeaning}
          selectableBusinessMeanings={selectableBusinessMeanings}
          categorizationResult={categorizationResult}
          onBusinessEventSelectionChange={onBusinessEventSelectionChange}
          isDisabled={isDisabled}
        />
      );
    default:
      return (
        <DefaultDisplay
          bankTransaction={bankTransaction}
          currentBusinessMeaning={currentBusinessMeaning}
          categorizationResult={categorizationResult}
          onShouldApplyGlobalRuleChange={onShouldApplyGlobalRuleChange}
          onBusinessEventSelectionChange={onBusinessEventSelectionChange}
          isDisabled={isDisabled}
        />
      );
  }
};
