import React, { ReactElement, useEffect, useState } from 'react';
import { useCompanies } from '../../shared';
import { useBankAccounts } from '../../shared/use-bank-accounts';
import { BankAccount, Company } from '../../types';
import { CompanySelector } from '../CompanySelector';
import { BankAccountSelector } from '../BankAccountSelector';
import { Stack } from '@mui/material';
import { LoadCsv } from './LoadCsv';

export const UploadBankTransactions = (): ReactElement => {
  const [selectedCompany, setSelectedCompany] = useState<Company | null | undefined>();
  const [selectedBankAccount, setSelectedBankAccount] = useState<BankAccount | null | undefined>();
  const { companies, isLoading: isLoadingCompanies } = useCompanies();
  const { bankAccounts, isLoading: isLoadingBankAccounts } = useBankAccounts(selectedCompany?.id);

  useEffect(() => {
    setSelectedBankAccount(null);
  }, [selectedCompany]);

  return (
    <div style={{ maxWidth: 1024 }}>
      <Stack direction="column" justifyContent="flex-start" spacing={2} maxWidth={600} mb={4}>
        <CompanySelector
          companies={companies || []}
          value={selectedCompany?.id}
          isLoading={isLoadingCompanies}
          onChange={setSelectedCompany}
        />
        <BankAccountSelector
          bankAccounts={bankAccounts || []}
          value={selectedBankAccount?.id}
          isDisabled={selectedCompany == null}
          isLoading={isLoadingBankAccounts}
          onChange={setSelectedBankAccount}
        />
      </Stack>

      <LoadCsv company={selectedCompany || null} bankAccount={selectedBankAccount || null} />
    </div>
  );
};
