import { useAuth0 } from '@auth0/auth0-react';
import { Box, CssBaseline } from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';

import { MainContent } from './components/Layout/MainContent';
import { SideBar } from './components/Layout/SideBar';
import { TopBar } from './components/Layout/TopBar';
import { Routes } from './Routes';
import { TokenService } from './services/auth/TokenService';
import { Bugsnag } from './services/bugsnag';

export default function App(): ReactElement {
  const [sideBarOpen, setSideBar] = useState(false);
  const { error, isLoading, getAccessTokenSilently, user } = useAuth0();

  const handleSideBarClick = () => {
    setSideBar(!sideBarOpen);
  };

  useEffect(() => {
    TokenService.getInstance().setTokenGenerator(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (!user) {
      return;
    }

    Bugsnag.setUser(user.sub!, user.email, user.name);
  }, [user]);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <div>Loading....</div>;
  }

  return (
    <>
      <CssBaseline />
      <Box display="flex">
        <TopBar handleSideBarClick={handleSideBarClick} sideBarOpen={sideBarOpen} />
        <SideBar handleSideBarClick={handleSideBarClick} sideBarOpen={sideBarOpen} />
        <MainContent sideBarOpen={sideBarOpen}>
          <Routes />
        </MainContent>
      </Box>
    </>
  );
}
