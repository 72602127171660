export enum PairedEntityType {
  BankAffectingIntegrationEvent = 'BANK_AFFECTING_INTEGRATION_EVENT',
  BankTransaction = 'BANK_TRANSACTION',
  Bill = 'BILL',
  CompanyAffiliateBalance = 'COMPANY_AFFILIATE_BALANCE',
  Invoice = 'INVOICE',
  Order = 'ORDER',
  PaymentCheck = 'PAYMENT_CHECK',
  Payroll = 'PAYROLL',
}

export enum PairingType {
  INTERNAL_TRANSFER = 'INTERNAL_TRANSFER',
  MATCH = 'MATCH',
  REFUND = 'REFUND',
}
