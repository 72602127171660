import { gqlClient } from '../../shared/gqlClient';

class MerchantService {
  getVendorById = async (vendorId: string, companyId: string) => {
    const { vendor } = await gqlClient.query({
      __name: 'Vendor',
      vendor: {
        __args: {
          input: {
            companyId,
            id: vendorId,
          },
        },
        on_Vendor: {
          __typename: true,
          type: true,
          companyId: true,
        },
      },
    });

    return vendor;
  };
}

export const merchantService = new MerchantService();
