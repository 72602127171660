import { AskTheUserResult } from './AskTheUserResult';
import { BankAccount } from './BankAccount';
import type { BankTransactionClassifierType } from './BankTransactionClassifierType';
import type { MoneyDirection } from './MoneyDirection';
import { PairingType } from './PairedEntityType';
import { MerchantType } from '../__genql__/merchants';
import { CompanyAffiliateType, PairedEntityType, enumCompanyAffiliateType } from '../__generated-global__';

export interface PairedEntity {
  bankTransactionId: string;
  pairedEntityId: string;
  type: PairedEntityType;
}

export interface BankTransaction {
  id: string;
  companyId: string;
  source: BankTransactionSource;
  amount: number;
  amountInUsd: number;
  currency: string;
  description: string;
  institutionMemo?: string;
  postedAt: Date;
  deletedAt?: Date;
  merchant?: BankTransactionMerchant;
  businessEvent?: BankTransactionBusinessEvent;
  classificationVersion: number;
  processedByMachineAt?: Date;
  lockedForClassificationAt?: Date;
  bankAccount: BankAccount;
  sourceSpecificData?: PaypalSpecificData;
  latestAccountingEventIds?: string[];
  applyingClassificationJobId?: string;
  bankTransactionsUploadId?: string;
  uploadedFileId?: string;
  classificationMetadata?: {
    enrichmentRuleType: string | null;
    enrichmentRuleId: string | null;
    businessEventRuleType: string | null;
    businessEventRuleId: string | null;
    isAugmented: boolean | null;
    actorType?: string;
    actorId?: string;
  };
  externalSourceId: string;
}

export interface BankTransactionBusinessEvent {
  classifiedAt: Date;
  classifierType: BankTransactionClassifierType;
  classifiedBy: string;
  classifications: BankTransactionBusinessEventClassification[];
}

export interface BankTransactionMerchant {
  id: string;
  name: string;
  type: MerchantType;
  vendorType: 'local' | 'global';
  logo?: string;
  classifierType: BankTransactionClassifierType;
  classifiedAt: Date;
  classifiedBy: string;
  merchantSubtype: keyof typeof enumCompanyAffiliateType;
}

export interface BankTransactionBusinessEventClassification {
  pairedEntityId?: string;
  pairedEntityType?: PairedEntityType;
  pairingType?: PairingType;
  businessEvent: string;
  classificationTagId?: string;
  classificationTagText?: string;
  classificationText?: string;
  description?: string;
  amount: number;
  askTheUserResult?: AskTheUserResult;
  extraData?: Record<string, any>;
}

export interface PaypalSpecificData {
  transactionId: string;
  vendorName?: string;
  payerEmail?: string;
}

export enum BankAccountType {
  Depository = 'Depository',
  CreditCard = 'CreditCard',
  Loan = 'Loan',
  Investment = 'Investment',
  Other = 'Other',
}

export enum BankAccountSubtype {
  Checking = 'Checking',
  Savings = 'Savings',
  CreditCard = 'CreditCard',
  Loan = 'Loan',
  Unknown = 'Unknown',
}

export enum BalanceAccountType {
  Checking = 'Checking',
  Savings = 'Savings',
  CreditCard = 'Credit Card',
  Trust = 'Trust',
  DueToFromOwner = 'Due to/from owner',
  ShortTermLoanTaken = 'Short term loan taken',
}

export enum BankTransactionSource {
  Paypal = 'PAYPAL',
  Quickbooks = 'QUICKBOOKS_BANKS',
  Plaid = 'PLAID',
  FinlaoopBankTransactionsUpload = 'FINALOOP_BANK_TRANSACTIONS_UPLOAD',
  FinaloopInternal = 'FINALOOP_INTERNAL',
  FinaloopTransactions = 'FINALOOP_TRANSACTIONS',
  ReportedBusinessBankTransactions = 'REPORTED_BUSINESS_BANK_TRANSACTIONS',
}

export type EnrichedBankTransactionBusinessEventClassification = BankTransactionBusinessEventClassification & {
  businessMeaning?: string;
};

export type EnrichedBankTransaction = Omit<BankTransaction, 'businessEvent'> & {
  businessEvent?: Omit<BankTransactionBusinessEvent, 'classifications'> & {
    classifications: EnrichedBankTransactionBusinessEventClassification[];
  };
  merchant?: BankTransaction['merchant'] & { merchantSubtype?: CompanyAffiliateType };
  hasInvertedBusinessMeaning?: boolean;
  resolvedMoneyDirection?: MoneyDirection;
  isUpdating?: boolean;
};
