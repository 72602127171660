import type { AxiosInstance } from 'axios';

import { addAuthorizationTokenToAxiosRequests, getAxiosInstance, handleAxiosResponses } from '../../shared';
import type { BankAccount, InfoPage, Page } from '../../types';
import { BankAccountWithStatements, ReconciliationStatus } from '../../types';
import { BankAccountStatementRequest } from '../../types/BankAccountStatementRequest';
import { ConfigService } from '../config';
import { BankStatementsRequestProps, DownloadBankStatementUrlRequest } from './types';

export class BankAccountsService {
  protected static instance: BankAccountsService;

  protected constructor(protected readonly config: ConfigService, protected readonly axiosInstance: AxiosInstance) {}

  static getInstance(
    config: ConfigService = ConfigService.getInstance(),
    axiosInstance?: AxiosInstance,
  ): BankAccountsService {
    if (BankAccountsService.instance) {
      return BankAccountsService.instance;
    }

    const apiClient = axiosInstance || getAxiosInstance({ baseURL: config.getOrFail('BANK_ACCOUNTS_API_URL') });
    addAuthorizationTokenToAxiosRequests(apiClient);
    handleAxiosResponses(apiClient);

    return (BankAccountsService.instance = new BankAccountsService(config, apiClient));
  }

  async getBankAccountsPage(companyId: string): Promise<Page<BankAccount>> {
    try {
      const response = await this.axiosInstance.get('/bank-accounts', { params: { companyId } });

      return {
        items: response.data.items.filter((ba: any) => ba.deletedByUserAt == null && !['MERGED', 'DUPLICATE'].includes(ba.status)),
        cursor: response.data.cursor,
      };
    } catch (err) {
      return { items: [], cursor: null };
    }
  }

  async getBankAccountsWithStatements(): Promise<BankAccountWithStatements[]> {
    try {
      const response = await this.axiosInstance.get('/bank-accounts-with-statements');

      return response.data.bankAccounts;
    } catch (err) {
      return [];
    }
  }

  async getBankAccountStatementRequestsPage(
    params: BankStatementsRequestProps,
  ): Promise<InfoPage<BankAccountStatementRequest>> {
    try {
      const response = await this.axiosInstance.get('/bank-account-statements-requests-finaloop', {
        params,
      });

      return response.data;
    } catch (err) {
      return { items: [], cursor: null, totalDocs: 0 };
    }
  }

  async getDownloadBankStatementUrl(request: DownloadBankStatementUrlRequest): Promise<string> {
    const { data } = await this.axiosInstance.get<{ url: string }>(`/bank-account-statement-download-url`, {
      params: request,
    });

    return data.url;
  }

  async updateBankAccountStatementRequest({
    bankAccountStatementRequest,
  }: {
    bankAccountStatementRequest: Omit<BankAccountStatementRequest, 'customerData' | 'companyId' | 'bankAccount'>;
  }): Promise<BankAccountStatementRequest> {
    const { data } = await this.axiosInstance.put<{ bankAccountStatementRequest: BankAccountStatementRequest }>(
      'bank-account-statements-requests-finaloop',
      { ...bankAccountStatementRequest },
    );

    return data.bankAccountStatementRequest;
  }

  async markBankAccountReconciliationStatus({
    id,
    status,
    year,
  }: {
    id: string;
    status: ReconciliationStatus;
    year: string;
  }): Promise<BankAccountWithStatements> {
    const { data } = await this.axiosInstance.put<{ bankAccount: BankAccountWithStatements }>(
      'bank-account-reconciled',
      {
        id,
        status,
        year,
      },
    );

    return data.bankAccount;
  }
}
