import { BankAccountStatementRequestStatus } from '../../types/BankAccountStatementRequest';

export const statusMap: Record<BankAccountStatementRequestStatus, { displayName: string; color: string }> = {
  [BankAccountStatementRequestStatus.PendingUserInput]: {
    displayName: 'ACTION REQUIRED',
    color: 'red',
  },
  [BankAccountStatementRequestStatus.UserCompleted]: {
    displayName: 'PROCESSING',
    color: 'gray',
  },
  [BankAccountStatementRequestStatus.FinaloopApproved]: {
    displayName: 'COMPLETED',
    color: 'green',
  },
  [BankAccountStatementRequestStatus.NotRequired]: {
    displayName: 'INACTIVE',
    color: 'blue',
  },
  [BankAccountStatementRequestStatus.Irrelevant]: {
    displayName: 'IRRLEVANT',
    color: 'blue',
  },
  [BankAccountStatementRequestStatus.IrrelevantApproved]: {
    displayName: 'IRRLEVANT APPROVED',
    color: 'blue',
  },
};

export const bankAccountStatementRequestStatusOptions = [
  {
    value: BankAccountStatementRequestStatus.FinaloopApproved,
    name: 'COMPLETED',
  },
  {
    value: BankAccountStatementRequestStatus.UserCompleted,
    name: 'PROCESSING',
  },
  {
    value: BankAccountStatementRequestStatus.NotRequired,
    name: 'INACTIVE',
  },
  {
    value: BankAccountStatementRequestStatus.Irrelevant,
    name: 'IRRELEVANT',
  },
  {
    value: BankAccountStatementRequestStatus.IrrelevantApproved,
    name: 'IRRELEVANT APPROVED',
  },
  {
    value: BankAccountStatementRequestStatus.PendingUserInput,
    name: 'ACTION REQUIRED',
  },
];
