import { gqlClient } from '../../shared/gqlClient';
import { GlobalRule } from './types';
import { Merchant, MoneyDirection } from '../../__generated-global__';
import { BankTransaction, BusinessMeaning } from '../../types';

class GlobalRulesService {
  buildRule = (
    merchant: Pick<Merchant, 'id'>,
    businessMeaning: BusinessMeaning,
    transaction: BankTransaction,
  ): GlobalRule => {
    return {
      vendorId: merchant.id,
      outcome: {
        business_event: {
          id: businessMeaning.id,
          name: businessMeaning.name,
        },
      },
      conditions: [
        {
          path: 'direction',
          operator: 'equal',
          value: transaction.amountInUsd < 0 ? 'money_out' : 'money_in',
        },
        {
          path: 'vendor_id',
          operator: 'equal',
          value: merchant.id,
        },
      ],
      logicalOperator: 'AND',
    };
  };

  isGlobalRuleExist = async (vendorId: string, moneyDirection: MoneyDirection): Promise<boolean> => {
    const { isGlobalRuleExistByVendorAndMoneyDirection } = await gqlClient.query({
      __name: 'isGlobalRuleExistByVendorAndMoneyDirection',
      isGlobalRuleExistByVendorAndMoneyDirection: {
        __args: {
          input: { vendorId, moneyDirection },
        },
        exists: true,
        id: true,
      },
    });

    return isGlobalRuleExistByVendorAndMoneyDirection.exists;
  };

  createGlobalRule = async (rule: GlobalRule): Promise<{ id?: string | null; success: boolean }> => {
    const { createGlobalRule: ruleResult } = await gqlClient.mutation({
      __name: 'CreateGlobalRule',
      createGlobalRule: {
        __args: {
          input: { ...rule },
        },
        success: true,
        id: true,
      },
    });

    return { id: ruleResult?.id, success: Boolean(ruleResult?.success) };
  };
}

export const globalRulesService = new GlobalRulesService();
