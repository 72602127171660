import {formatMoney, formatMoneyWithForex} from "./format-money.util";

export const getMonetaryColumnDescription = (amount: number, amountInUSD: number, currency: string, isForex: boolean) => {
    const absoluteAmount = Math.abs(amount);

    if (!isForex) {
        return formatMoney(absoluteAmount);
    }

    const absoluteAmountInUSD = Math.abs(amountInUSD);

    return formatMoneyWithForex(absoluteAmount, absoluteAmountInUSD, currency);
};
