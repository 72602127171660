export default {
    "scalars": [
        0,
        2,
        3,
        6,
        7,
        8,
        9
    ],
    "types": {
        "JSONObject": {},
        "Merchant": {
            "id": [
                2
            ],
            "type": [
                3
            ],
            "name": [
                2
            ],
            "logo": [
                2
            ],
            "merchantData": [
                0
            ],
            "__typename": [
                2
            ]
        },
        "String": {},
        "MerchantType": {},
        "Query": {
            "getMerchants": [
                1,
                {
                    "getMerchantsArgs": [
                        5,
                        "GetMerchantsArgs!"
                    ]
                }
            ],
            "__typename": [
                2
            ]
        },
        "GetMerchantsArgs": {
            "searchTerm": [
                2
            ],
            "companyId": [
                8
            ],
            "__typename": [
                2
            ]
        },
        "Date": {},
        "UserToken": {},
        "RequestedCompanyIdScalar": {},
        "Boolean": {}
    }
}