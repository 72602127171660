import { memo, type ReactElement } from 'react';
import { Autocomplete } from '../ui/Autocomplete';
import { BankTransactionSource } from '../types';

export interface SourceSelectorProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  onChange?: (source: BankTransactionSource | undefined | null) => void;
  value?: string | null;
}

export const SourceSelector = memo((props: SourceSelectorProps): ReactElement => {
  const { value } = props;
  const sources: { label: string; value: BankTransactionSource }[] = [
    { label: 'Plaid', value: BankTransactionSource.Plaid },
    { label: 'PayPal', value: BankTransactionSource.Paypal },
    { label: 'CSV Upload', value: BankTransactionSource.FinlaoopBankTransactionsUpload },
    { label: 'Reported Business Transactions', value: BankTransactionSource.ReportedBusinessBankTransactions },
    { label: 'Quickbooks', value: BankTransactionSource.Quickbooks },
  ];

  return (
    <Autocomplete
      isDisabled={props.isDisabled}
      isLoading={props.isLoading}
      label="Source"
      onChange={(option) => props.onChange?.(option[0]?.value)}
      options={sources}
      optionLabelFn={(source: any) => source.label}
      value={sources.filter((source) => value === source.value)}
    />
  );
});
