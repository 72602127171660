import { useSearchParams } from 'react-router-dom';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { BankTransactionsFilterChange } from './BankTransactionsFilters';

export const useQueryStringFilters = ({
  filters,
  setFilters,
  query,
  setQuery,
}: {
  filters: BankTransactionsFilterChange;
  setFilters: Dispatch<SetStateAction<BankTransactionsFilterChange>>;
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const filters = searchParams.has('filters')
      ? JSON.parse(decodeURIComponent(atob(searchParams.get('filters')!)))
      : {};
    setFilters(filters);
    console.log(filters);

    if (filters.query) {
      setQuery(filters.query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      const encodedFilters = btoa(encodeURIComponent(JSON.stringify({ ...filters, query: query ? query : undefined })));
      setSearchParams({ filters: encodedFilters });
    }
  }, [filters, query, setSearchParams]);
};
