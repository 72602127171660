import { Avatar, Badge, Chip, Tooltip } from '@mui/material';
import _ from 'lodash';
import { type MouseEvent, type ReactElement } from 'react';
import { BankTransactionMerchant } from '../types';

export interface MerchantChipProps {
  onClick?: () => void;
  merchant: Pick<BankTransactionMerchant, 'id' | 'name' | 'type' | 'logo'>;
  enrichmentRuleType?: string | null;
  enrichmentRuleId?: string | null;
  isAugmented?: Boolean | null;
  disabled?: boolean;
}

export const MerchantChip = (props: MerchantChipProps): ReactElement => {
  const { enrichmentRuleType, enrichmentRuleId, isAugmented, merchant, disabled } = props;

  const vendorInitials = initials(merchant.name) || 'The Nameless Vendor';

  const avatar = (
    <Avatar src={merchant.logo || ''} alt={vendorInitials || ''}>
      {vendorInitials || ''}
    </Avatar>
  );

  const handleClick = (event: MouseEvent) => {
    event.stopPropagation();

    props.onClick?.();
  };

  const badgeContent = isAugmented ? 'Augmented' : initials(enrichmentRuleType);
  const title = isAugmented
    ? 'Merchant was augmented from based on the classification'
    : `[${enrichmentRuleType}]: ${enrichmentRuleId}`;

  return (
    <Tooltip title={title}>
      <Badge badgeContent={badgeContent} color={'primary'}>
        <Chip
          disabled={disabled}
          onClick={handleClick}
          avatar={avatar}
          label={merchant.name}
          variant="outlined"
          color="default"
        />
      </Badge>
    </Tooltip>
  );
};

const initials = (str: string | null | undefined) =>
  str
    ? _.startCase(str)
        .split(' ')
        .map((word) => word[0])
        .join('')
    : '';
