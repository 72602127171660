import { Stack } from '@mui/material';
import type { ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import type { AgGridColumnGroupProps, AgGridColumnProps } from 'ag-grid-react';
import { format as formatDate } from 'date-fns';

import { ConfigService } from '../../services/config';
import { type LocalRule, LocalRuleType, LocalVendorToBusinessMeaningRule, LocalVendorRule } from '../../types';
import { DateExplorerLink } from '../DataExplorerLink';
import { Vendor } from '../../__generated-global__';
import { keyBy } from 'lodash';

export interface UseLocalRulesColumnsResult {
  columns: (AgGridColumnProps | AgGridColumnGroupProps)[];
}

export function getLocalRulesColumns(vendors: { id: string; name: string }[]): UseLocalRulesColumnsResult {
  const dateFormat = ConfigService.getInstance().getOrFail('DATE_FORMAT');

  // so stupid
  const vendorsByIds = keyBy(vendors, 'id');

  const columns = [
    {
      checkboxSelection: true,
      field: 'ruleType',
      flex: 1.7,
      headerCheckboxSelection: true,
      headerName: 'Rule',
      valueFormatter: (params: ValueFormatterParams) =>
        (params.value as LocalRule['ruleType']) === LocalRuleType.Vendor
          ? 'Transactions » Vendor'
          : 'Vendor » Business Meaning',
    },
    {
      field: 'updatedAtMS',
      flex: 1,
      headerName: 'Last Updated',
      valueFormatter: (params: ValueFormatterParams) =>
        formatDate(params.value as LocalRule['updatedAtMS'], `${dateFormat} HH:mm`),
    },
    {
      cellRenderer: (params: ValueFormatterParams) => {
        const localRule = params.data as LocalRule;

        if (localRule.ruleType !== LocalRuleType.Vendor) {
          return;
        }

        return <DateExplorerLink bankTransactionId={localRule.bankTransactionId || localRule.bankTransaction?.id} />;
      },
      flex: 0.65,
      headerName: 'Transaction',
    },
    {
      cellRenderer: (params: ValueFormatterParams) => {
        const vendor = params.value as Vendor | undefined;

        if (!vendor) {
          return <span style={{ color: 'red', fontStyle: 'italic' }}>DELETED</span>;
        }

        return (
          <Stack spacing={-3.5}>
            <span>{vendor.name}</span>
            <span style={{ fontSize: '0.6em' }}>
              {vendor.source} » {vendor.id}
            </span>
          </Stack>
        );
      },
      flex: 1.5,
      headerName: 'Vendor',
      valueGetter: (params: ValueGetterParams) => {
        const localRule = params.data as LocalRule;

        return vendorsByIds[
          (localRule as LocalVendorToBusinessMeaningRule).metadata?.merchant?.id ||
            // @ts-ignore => consistent across migration
            (localRule as LocalVendorToBusinessMeaningRule).metadata?.vendorId ||
            (localRule as LocalVendorRule).vendorId
        ];

        // return vendorDictionary[
        //   (localRule as LocalVendorRule).vendorId ||
        //     // @ts-ignore => consistent across migration
        //     (localRule as LocalVendorToBusinessMeaningRule).metadata.vendorId ||
        //     (localRule as LocalVendorToBusinessMeaningRule).metadata.merchant?.id ||
        //     ''
        // ];
      },
    },
    {
      flex: 0.5,
      headerName: 'Money',
      valueGetter: (params: ValueGetterParams) => {
        const localRule = params.data as LocalRule;

        if (localRule.ruleType !== LocalRuleType.VendorToBusinessMeaning) {
          return;
        }

        const directionCondition = localRule.conditions.find((condition) => condition.path === 'direction');

        if (!directionCondition) {
          return;
        }

        return directionCondition.value === 'money_out' ? 'OUT' : 'IN';
      },
    },
    { field: 'outcome.business_event.name', flex: 2, headerName: 'Business Meaning' },
    {
      flex: 1,
      headerName: 'Rule Creator',
      valueGetter: (params: ValueGetterParams) => {
        const localRule = params.data as LocalRule;

        return localRule.classifierType === 'finaloop-customer' ? 'Customer' : 'Finaloop team';
      },
    },
  ];

  return { columns };
}
