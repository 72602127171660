import { Typography } from '@mui/material';
import * as React from 'react';
import { memo } from 'react';
import { useCompanies } from '../../../../shared';

export const CompanyCell = memo(({ companyId }: { companyId: string }) => {
  // @todo can we get it from the cache?
  const { companies } = useCompanies();
  const company = companies?.find((company) => company.id === companyId);
  return <Typography>{company?.name}</Typography>;
});
