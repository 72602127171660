import { AppBarProps, AppBar, Toolbar, Typography, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth0 } from '@auth0/auth0-react';

import { TokenService } from '../../services/auth/TokenService';
import { drawerWidth } from './index';

interface TopBarProps {
  sideBarOpen: boolean;
  handleSideBarClick: () => void;
}

const StyledTopBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps & { open: boolean }>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const TopBar = ({ sideBarOpen, handleSideBarClick }: TopBarProps) => {
  const { logout } = useAuth0();
  const logoutAndRedirect = async () => {
    await logout();
    TokenService.getInstance().setTokenGenerator(undefined);
  };

  return (
    <StyledTopBar position="fixed" open={sideBarOpen}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleSideBarClick}
          edge="start"
          sx={{ mr: 2, ...(sideBarOpen && { display: 'none' }) }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
          Bank Transactions Feed
        </Typography>
        <Button variant="contained" color="success" onClick={logoutAndRedirect}>
          Logout
        </Button>
      </Toolbar>
    </StyledTopBar>
  );
};
