import { Avatar, Box, Link, Typography } from '@mui/material';
import { BankAccountStatementRequest } from '../../../../types/BankAccountStatementRequest';
import {
  links,
  getStreamlitValidationLink,
  getWorksheetLink,
} from '../../shared/links';

const getBankAccountLogo = (institutionId?: string): string | undefined =>
  institutionId &&
  `https://res.cloudinary.com/finaloop/image/upload/w_32,h_32,c_limit,d_bank.png/v1652617462/bank-logos/${institutionId}.png`;

export const BankAccountCell = ({ bankAccount, bankAccountStatementRequest }: { bankAccount: BankAccountStatementRequest['bankAccount'], bankAccountStatementRequest: BankAccountStatementRequest }) => {
  const logo = getBankAccountLogo(bankAccount.institutionId);
  return (
    <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
      <Avatar alt={bankAccount.name} src={logo ? logo : 'broken-link'} />
      <Box display="flex" flexDirection="column" alignItems="start" gap={0.5}>
        <Typography variant="caption" fontWeight="600" marginTop={0}>
          {bankAccount.institutionName}
        </Typography>
        <Typography variant="caption" fontWeight="400" marginTop={0}>
          {bankAccount.name}
        </Typography>
        <Typography variant="caption" fontWeight="400" marginTop={0}>
          {bankAccount.subtype}
        </Typography>
        {bankAccount.mask && (
          <Typography variant="caption" fontWeight="400" marginTop={0}>
            {`•••• ${bankAccount.mask}`}
          </Typography>
        )}
        <Link
          target="_blank"
          rel="noopener"
          component="button"
          variant="body2"
          onClick={async () => {
            const streamlitLink = getStreamlitValidationLink(bankAccountStatementRequest);
            window.open(streamlitLink, '_blank');
          }}
        >
          Bank Account Validation
        </Link>
        <Link
          target="_blank"
          rel="noopener"
          component="button"
          variant="body2"
          onClick={async () => {
            const worksheetLink = getWorksheetLink(bankAccountStatementRequest);
            window.open(worksheetLink, '_blank');
          }}
        >
          Worksheet
        </Link>
      </Box>
    </Box>
  );
};
