export default {
    "scalars": [
        1,
        5,
        6,
        8,
        11,
        15,
        20,
        22,
        33,
        34,
        41,
        75,
        76,
        79,
        82,
        96,
        98,
        99,
        108,
        111,
        114,
        116,
        117,
        118,
        119,
        121,
        123,
        127,
        130,
        136,
        137,
        138,
        140,
        141,
        142,
        143,
        144,
        146,
        155,
        157,
        174,
        175,
        176,
        188,
        193,
        205,
        232,
        235,
        236,
        238,
        250,
        264,
        271,
        279,
        280,
        286,
        287,
        288,
        295,
        340,
        348,
        349,
        361,
        403,
        410,
        418,
        420,
        423,
        425,
        443,
        445,
        460,
        461,
        463,
        476,
        491,
        568,
        569,
        577,
        591,
        592,
        629,
        633,
        646,
        652,
        653,
        660,
        669,
        677,
        683,
        684,
        694,
        696,
        700,
        702,
        706,
        707,
        709,
        711,
        712,
        716,
        721,
        730,
        736,
        741,
        763,
        771,
        772,
        789,
        791,
        793,
        800,
        803,
        804,
        807,
        808,
        809,
        810,
        811,
        812,
        813,
        814,
        818,
        820,
        821,
        823,
        824,
        827,
        830,
        832,
        833,
        837,
        838,
        839,
        840,
        841,
        845,
        847,
        851,
        867,
        879,
        888,
        890,
        891,
        894,
        896,
        904,
        905,
        906,
        910,
        920,
        922,
        924,
        926,
        927,
        930,
        931,
        937,
        945,
        946,
        955,
        957,
        967,
        969,
        980,
        986,
        994,
        995,
        1000,
        1002,
        1008,
        1024,
        1025,
        1030,
        1033,
        1037,
        1041,
        1044,
        1054,
        1056,
        1074,
        1078,
        1090,
        1094,
        1107,
        1108,
        1119,
        1142,
        1143,
        1145,
        1146,
        1148,
        1155,
        1173,
        1174,
        1177,
        1205,
        1218,
        1251,
        1255,
        1266,
        1267,
        1275,
        1281,
        1282,
        1294,
        1302,
        1307,
        1318,
        1319,
        1321,
        1330,
        1331,
        1444,
        1455,
        1457,
        1458,
        1467,
        1470,
        1479,
        1484,
        1491,
        1492,
        1493,
        1509,
        1520,
        1521,
        1523
    ],
    "types": {
        "AcceptedInviteCompanyInfo": {
            "id": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "String": {},
        "AcceptInvitationArgs": {
            "invitationCode": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AcceptInvitationResponse": {
            "userInvitation": [
                1430
            ],
            "__typename": [
                1
            ]
        },
        "AccountingInfo": {
            "isSalesAffecting": [
                5
            ],
            "isCogsAffecting": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "Boolean": {},
        "AccountingMethod": {},
        "Accrual": {
            "id": [
                8
            ],
            "accrualsData": [
                12
            ],
            "bankTransactionId": [
                8
            ],
            "companyId": [
                8
            ],
            "createdAt": [
                361
            ],
            "updatedAt": [
                361
            ],
            "archivedAt": [
                361
            ],
            "createdBy": [
                22
            ],
            "createdById": [
                1
            ],
            "latestActorType": [
                22
            ],
            "latestActorId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ID": {},
        "AccrualCategorizationInput": {
            "bankTransactionId": [
                8
            ],
            "merchant": [
                873
            ],
            "classifications": [
                10
            ],
            "__typename": [
                1
            ]
        },
        "AccrualClassificationInput": {
            "amount": [
                11
            ],
            "classificationTagId": [
                8
            ],
            "classificationText": [
                8
            ],
            "businessEvent": [
                1
            ],
            "pairedEntityId": [
                1
            ],
            "pairedEntityType": [
                994
            ],
            "pairingType": [
                995
            ],
            "askTheUserResult": [
                772
            ],
            "extraData": [
                772
            ],
            "description": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Float": {},
        "AccrualData": {
            "classificationTagId": [
                8
            ],
            "businessEventType": [
                1
            ],
            "spreadType": [
                1275
            ],
            "specificDate": [
                1268
            ],
            "spreadOverTime": [
                1273
            ],
            "customSpread": [
                351
            ],
            "defaultFixedAssetsData": [
                469
            ],
            "customFixedAssetsData": [
                469
            ],
            "canceledAt": [
                361
            ],
            "canceledBy": [
                1
            ],
            "canceledAccrualsData": [
                211
            ],
            "__typename": [
                1
            ]
        },
        "AccrualDataInput": {
            "classificationTagId": [
                8
            ],
            "businessEventType": [
                1
            ],
            "spreadType": [
                1275
            ],
            "specificDate": [
                1269
            ],
            "spreadOverTime": [
                1274
            ],
            "customSpread": [
                352
            ],
            "defaultFixedAssetsData": [
                470
            ],
            "customFixedAssetsData": [
                470
            ],
            "__typename": [
                1
            ]
        },
        "AccrualsSpreadResponse": {
            "amount": [
                11
            ],
            "postedAt": [
                361
            ],
            "classificationTag": [
                225
            ],
            "businessEventType": [
                1
            ],
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "accrualId": [
                1
            ],
            "bankTransactionId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "AccrualsStatus": {},
        "AcknowledgeLoansChanges": {
            "loans": [
                787
            ],
            "__typename": [
                1
            ]
        },
        "AcknowledgeLoansChangesInput": {
            "loans": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "ActionChange": {
            "accountRole": [
                1
            ],
            "moneyDirection": [
                20
            ],
            "__typename": [
                1
            ]
        },
        "ActionChangeInput": {
            "accountRole": [
                1
            ],
            "moneyDirection": [
                20
            ],
            "__typename": [
                1
            ]
        },
        "ActionChangeMoneyDirection": {},
        "ActorContextInput": {
            "actorType": [
                22
            ],
            "actorId": [
                1
            ],
            "classifiedAt": [
                361
            ],
            "metadata": [
                772
            ],
            "__typename": [
                1
            ]
        },
        "ActorType": {},
        "AddBankTransactionAttachmentInput": {
            "companyId": [
                8
            ],
            "bankTransactionId": [
                8
            ],
            "attachmentLink": [
                1
            ],
            "attachmentName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AddDepreciationBonus": {
            "date": [
                361
            ],
            "percent": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "AddDepreciationBonusInput": {
            "date": [
                1
            ],
            "percent": [
                11
            ],
            "amount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "AddPriorYearAdjustmentInput": {
            "amount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "Address": {
            "country": [
                1
            ],
            "state": [
                1
            ],
            "city": [
                1
            ],
            "postalCode": [
                1
            ],
            "line1": [
                1
            ],
            "line2": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AddressArgs": {
            "country": [
                1
            ],
            "state": [
                1
            ],
            "city": [
                1
            ],
            "postalCode": [
                1
            ],
            "line1": [
                1
            ],
            "line2": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AddRoleToAffiliatedEntityInput": {
            "companyId": [
                8
            ],
            "sourceEntityOneOf": [
                40
            ],
            "role": [
                250
            ],
            "metadata": [
                30
            ],
            "__typename": [
                1
            ]
        },
        "AddRoleToAffiliatedEntityMetadataInput": {
            "creatorEmail": [
                1
            ],
            "source": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AddRoleToAffiliatedEntityResult": {
            "createdEntityId": [
                8
            ],
            "affiliatedEntity": [
                36
            ],
            "__typename": [
                1
            ]
        },
        "AdjustmentRestockingAction": {
            "quantity": [
                33
            ],
            "id": [
                1
            ],
            "date": [
                361
            ],
            "type": [
                1
            ],
            "salesChannel": [
                1465
            ],
            "sku": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Int": {},
        "AdjustmentType": {},
        "AffectedTransactionsIds": {
            "transactionsIds": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "AffiliatedEntity": {
            "id": [
                8
            ],
            "institutions": [
                648
            ],
            "company": [
                241
            ],
            "name": [
                1
            ],
            "companyAffiliates": [
                245
            ],
            "holders": [
                622
            ],
            "relatedParties": [
                1134
            ],
            "lenders": [
                898,
                {
                    "filter": [
                        38
                    ]
                }
            ],
            "employees": [
                422
            ],
            "contractors": [
                293
            ],
            "vendors": [
                1445,
                {
                    "filter": [
                        39
                    ]
                }
            ],
            "__typename": [
                1
            ]
        },
        "AffiliatedEntityByIdInput": {
            "id": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "AffiliatedEntityLenderInput": {
            "companyId": [
                8
            ],
            "onlyGlobal": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "AffiliatedEntityVendorInput": {
            "companyId": [
                8
            ],
            "onlyGlobal": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "AffiliateSourceInput": {
            "affiliatedEntityId": [
                8
            ],
            "companyAffiliateId": [
                8
            ],
            "vendorId": [
                8
            ],
            "institutionId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "AllowClassificationForVersion": {},
        "AlterBankTransactionsLifecycleStateInput": {
            "companyId": [
                8
            ],
            "bankTransactionIds": [
                8
            ],
            "actorType": [
                22
            ],
            "actorId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AlterBankTransactionsLifecycleStateResult": {
            "processedIds": [
                8
            ],
            "unprocessed": [
                44
            ],
            "__typename": [
                1
            ]
        },
        "AlterBankTransactionsLifecycleStateUnprocessedResult": {
            "id": [
                8
            ],
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AmazonAvailableMarketplace": {
            "displayName": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "marketplaceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AmazonAvailableValidationDataInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "AmazonAvailableValidationDataResponse": {
            "integrationAccountIdsData": [
                48
            ],
            "__typename": [
                1
            ]
        },
        "AmazonIntegrationAccountIdAvailableData": {
            "availableMarketplaces": [
                45
            ],
            "shipmentsReportEndTime": [
                361
            ],
            "returnsReportEndTime": [
                361
            ],
            "integrationAccountId": [
                1
            ],
            "impactOnBooksSince": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "AmazonOrdersDiffResponse": {
            "data": [
                771
            ],
            "__typename": [
                1
            ]
        },
        "AmazonProduct": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "finaloopId": [
                1
            ],
            "lineOfBusinessId": [
                1
            ],
            "sku": [
                1
            ],
            "price": [
                11
            ],
            "displayName": [
                1
            ],
            "imageUrl": [
                1
            ],
            "listedChannels": [
                1158
            ],
            "appliedCosts": [
                1073
            ],
            "dailySalesData": [
                1159,
                {
                    "timeRange": [
                        1079,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "totalSold": [
                33
            ],
            "avgCostPerUnit": [
                11
            ],
            "asin": [
                1
            ],
            "canEditLatestAppliedCost": [
                5
            ],
            "isFractional": [
                5
            ],
            "isHidden": [
                5
            ],
            "salesData": [
                1171,
                {
                    "timeRange": [
                        1079,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "salesData2": [
                1171,
                {
                    "timeRange": [
                        1079,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "rawRecipes": [
                1113,
                {
                    "timeRange": [
                        1079,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "componentOfProducts": [
                1071
            ],
            "isAmbiguousSku": [
                5
            ],
            "analyticsOverview": [
                1072,
                {
                    "input": [
                        69
                    ]
                }
            ],
            "warehousesAnalyticsOverview": [
                1098,
                {
                    "input": [
                        69
                    ]
                }
            ],
            "tags": [
                1
            ],
            "isCreatedManually": [
                5
            ],
            "isDataSyncing": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "AmazonProductsReconciliationResponse": {
            "on_AmazonProductsValidationReadyResponse": [
                54
            ],
            "on_AmazonProductsValidationNotReadyResponse": [
                53
            ],
            "on_AmazonProductsValidationDataToDownloadResponse": [
                52
            ],
            "__typename": [
                1
            ]
        },
        "AmazonProductsValidationDataToDownloadResponse": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AmazonProductsValidationNotReadyResponse": {
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AmazonProductsValidationReadyResponse": {
            "checkStartTime": [
                361
            ],
            "checkEndTime": [
                361
            ],
            "salesReports": [
                1140
            ],
            "restocksReports": [
                1140
            ],
            "data": [
                63
            ],
            "summary": [
                62
            ],
            "__typename": [
                1
            ]
        },
        "AmazonReconciliationEntityData": {
            "restocked": [
                33
            ],
            "mfn": [
                33
            ],
            "afn": [
                33
            ],
            "totalSold": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "AmazonReconciliationEntityEntityValidationStatus": {
            "restockedDiff": [
                33
            ],
            "afnDiff": [
                33
            ],
            "mfnDiff": [
                33
            ],
            "mcfDiff": [
                33
            ],
            "reportDiff": [
                33
            ],
            "totalDiff": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "AmazonReconciliationProductData": {
            "unitsSold": [
                33
            ],
            "unitsRestocked": [
                33
            ],
            "unitsGiveaway": [
                33
            ],
            "ambiguousSkuQuantity": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "AmazonReconciliationProductValidationStatus": {
            "soldDiff": [
                33
            ],
            "restockedDiff": [
                33
            ],
            "totalDiff": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "AmazonReconciliationRestockedReportData": {
            "mcf": [
                33
            ],
            "afn": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "AmazonReconciliationSalesOrderIds": {
            "recentInvoices": [
                1
            ],
            "oldInvoices": [
                1
            ],
            "notInTimeRange": [
                1
            ],
            "mfn": [
                1
            ],
            "afn": [
                1
            ],
            "all": [
                1
            ],
            "restocked": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AmazonReconciliationSalesReportData": {
            "recentInvoices": [
                33
            ],
            "oldInvoices": [
                33
            ],
            "notInTimeRange": [
                33
            ],
            "mcf": [
                33
            ],
            "afn": [
                33
            ],
            "total": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "AmazonReconciliationSummary": {
            "entityData": [
                55
            ],
            "entityValidationStatus": [
                56
            ],
            "salesReportData": [
                61
            ],
            "restocksReportData": [
                59
            ],
            "productData": [
                57
            ],
            "productValidationStatus": [
                58
            ],
            "__typename": [
                1
            ]
        },
        "AmazonSkuReconciliationData": {
            "comments": [
                1
            ],
            "sku": [
                1
            ],
            "orderIds": [
                60
            ],
            "summary": [
                62
            ],
            "__typename": [
                1
            ]
        },
        "AmazonValidationAdjustSkuQuantitiesInput": {
            "companyId": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "marketplaceId": [
                1
            ],
            "sku": [
                1
            ],
            "reportQuantity": [
                33
            ],
            "finaloopQuantity": [
                33
            ],
            "orderId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AmazonValidationInput": {
            "startTime": [
                361
            ],
            "endTime": [
                361
            ],
            "integrationAccountId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "companyId": [
                8
            ],
            "marketplaceId": [
                1
            ],
            "ignoreCached": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "AmountRange": {
            "from": [
                11
            ],
            "to": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "AmountRangeInput": {
            "from": [
                11
            ],
            "to": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "Amounts": {
            "amount": [
                11
            ],
            "amountInHomeCurrency": [
                11
            ],
            "currency": [
                340
            ],
            "__typename": [
                1
            ]
        },
        "AnalyticsOverviewInput": {
            "asOfDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "AppendProcessingLogToBankTransactionsUploadInput": {
            "companyId": [
                8
            ],
            "bankTransactionsUploadId": [
                1
            ],
            "processingLog": [
                173
            ],
            "status": [
                176
            ],
            "__typename": [
                1
            ]
        },
        "AppliedCostItem": {
            "finaloopProductId": [
                1
            ],
            "cost": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "ApplyPendingWarehouseOpeningBalanceInput": {
            "companyId": [
                8
            ],
            "date": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "ArtificialTransactionsByMonth": {
            "type": [
                75
            ],
            "integrationType": [
                1
            ],
            "count": [
                33
            ],
            "sum": [
                11
            ],
            "month": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "ArtificialTransactionsByMonthResponse": {
            "payments": [
                73
            ],
            "refunds": [
                73
            ],
            "__typename": [
                1
            ]
        },
        "ArtificialTransactionsType": {},
        "AskTheUserFlowType": {},
        "AskTheUserNotificationInput": {
            "companyId": [
                8
            ],
            "notificationType": [
                79
            ],
            "notificationData": [
                772
            ],
            "__typename": [
                1
            ]
        },
        "AskTheUserNotificationServiceResult": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "AskTheUserNotificationType": {},
        "AskTheUserResult": {
            "type": [
                1
            ],
            "data": [
                772
            ],
            "__typename": [
                1
            ]
        },
        "AssembledByMap": {
            "id": [
                1
            ],
            "assembled": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "AssemblyDeletedReason": {},
        "AssignCompanyToUser": {
            "email": [
                1
            ],
            "companyId": [
                1
            ],
            "roles": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AssignCompanyToUserResponse": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "AttachmentTargetURL": {
            "fileName": [
                1
            ],
            "url": [
                1
            ],
            "signedUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "AttachmentTargetURLS": {
            "signedUrls": [
                85
            ],
            "__typename": [
                1
            ]
        },
        "AttachRelationsToLoanInput": {
            "ids": [
                8
            ],
            "loanId": [
                8
            ],
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "AttachTransactionToCompanyLoanTransactionInput": {
            "transactionId": [
                1
            ],
            "classifications": [
                303
            ],
            "__typename": [
                1
            ]
        },
        "AttemptToClassifyTransactionsInput": {
            "classificationRequests": [
                223
            ],
            "actorContext": [
                21
            ],
            "__typename": [
                1
            ]
        },
        "Auth0UserLookupInput": {
            "email": [
                1
            ],
            "accessKey": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Auth0UserLookupResponse": {
            "exists": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "AwaitedExportResponse": {
            "requestId": [
                1
            ],
            "status": [
                443
            ],
            "signedUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BalanceAdjustment": {
            "value": [
                11
            ],
            "forDate": [
                1
            ],
            "actorType": [
                96
            ],
            "actorId": [
                1
            ],
            "createdAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "BalanceLocation": {
            "balance": [
                11
            ],
            "balanceAtIso": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankAccount": {
            "id": [
                8
            ],
            "companyId": [
                1
            ],
            "integrationAccountId": [
                8
            ],
            "source": [
                684
            ],
            "externalSourceId": [
                1
            ],
            "externalInstitutionId": [
                1
            ],
            "type": [
                123
            ],
            "connectionStatus": [
                99
            ],
            "subtype": [
                121
            ],
            "currency": [
                1
            ],
            "currentBalance": [
                11
            ],
            "institution": [
                648
            ],
            "institutionId": [
                1
            ],
            "institutionName": [
                1
            ],
            "name": [
                1
            ],
            "fullName": [
                1
            ],
            "displayName": [
                1
            ],
            "mask": [
                1
            ],
            "connectedAt": [
                361
            ],
            "isHistoricalUpdateCompleted": [
                5
            ],
            "locks": [
                103
            ],
            "rawData": [
                772
            ],
            "closingBalanceLogs": [
                230
            ],
            "balanceAdjustments": [
                93
            ],
            "latestAccountingEventIds": [
                1
            ],
            "transactionsImportStatus": [
                122
            ],
            "deletedByUserAt": [
                361
            ],
            "deletedByUserId": [
                1
            ],
            "createdAt": [
                361
            ],
            "createdBy": [
                1
            ],
            "shouldUpdateAccountDetailsOnNextUpdate": [
                5
            ],
            "vendor": [
                1445
            ],
            "duplicateOfBankAccountId": [
                1
            ],
            "hasRecurringMission": [
                5
            ],
            "lockStatus": [
                106,
                {
                    "year": [
                        33
                    ]
                }
            ],
            "machineValidationData": [
                850
            ],
            "markedAsClosedByCustomerAt": [
                361
            ],
            "status": [
                119
            ],
            "forwardTransactionsToBankAccountId": [
                1
            ],
            "isManagedByFinaloop": [
                5
            ],
            "hasNotifiedUponNoHistoricalUpdate": [
                5
            ],
            "transactionsCount": [
                33,
                {
                    "fromDate": [
                        361
                    ],
                    "untilDate": [
                        361
                    ]
                }
            ],
            "minimumTransactionDate": [
                361,
                {
                    "fromBooksStartTime": [
                        5
                    ]
                }
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountBalanceAdjustmentActorType": {},
        "BankAccountBalanceInput": {
            "companyId": [
                8
            ],
            "bankAccountId": [
                8
            ],
            "balanceDateInISO": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountClosingBalanceActorType": {},
        "BankAccountConnectionStatus": {},
        "BankAccountEventIdsInput": {
            "bankAccountId": [
                8
            ],
            "eventIds": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountInput": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountInternalInput": {
            "id": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountLock": {
            "type": [
                1
            ],
            "startTime": [
                361
            ],
            "endTime": [
                361
            ],
            "lockedAt": [
                361
            ],
            "actorType": [
                22
            ],
            "actorId": [
                1
            ],
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountLockByTypeInput": {
            "startDate": [
                361
            ],
            "endDate": [
                361
            ],
            "lockedAt": [
                361
            ],
            "actorType": [
                22
            ],
            "actorId": [
                1
            ],
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountLockInput": {
            "startDate": [
                361
            ],
            "endDate": [
                361
            ],
            "lockedAt": [
                361
            ],
            "actorType": [
                22
            ],
            "actorId": [
                1
            ],
            "type": [
                1
            ],
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountLockStatus": {
            "lockedAt": [
                361
            ],
            "minimumAllowedEventTime": [
                361
            ],
            "year": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountNewOwner": {
            "type": [
                108
            ],
            "id": [
                8
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountNewOwnerType": {},
        "BankAccountReconciliation": {
            "id": [
                8
            ],
            "bankAccount": [
                95
            ],
            "companyId": [
                1
            ],
            "status": [
                111
            ],
            "transactionsFileLocations": [
                459
            ],
            "statementFileLocations": [
                459
            ],
            "balanceLocation": [
                94
            ],
            "reconciliationDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountReconciliationsInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountReconciliationStatus": {},
        "BankAccountsByIdsInput": {
            "companyId": [
                8
            ],
            "bankAccountIds": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountsInput": {
            "companyId": [
                8
            ],
            "sources": [
                684
            ],
            "statuses": [
                119
            ],
            "types": [
                123
            ],
            "subtypes": [
                121
            ],
            "withDisabled": [
                5
            ],
            "withDeleted": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountStatementFileType": {},
        "BankAccountStatementRequest": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "fromDate": [
                361
            ],
            "toDate": [
                361
            ],
            "bankAccount": [
                134
            ],
            "type": [
                118
            ],
            "subType": [
                117
            ],
            "customerData": [
                135
            ],
            "finaloopData": [
                139
            ],
            "status": [
                116
            ],
            "fileNames": [
                1
            ],
            "displayedBalance": [
                11
            ],
            "csvUploads": [
                339
            ],
            "balanceId": [
                1
            ],
            "markedAsClosedByCustomerAt": [
                361
            ],
            "lockedStatus": [
                846
            ],
            "originalBankAccountId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountStatementRequestStatus": {},
        "BankAccountStatementRequestSubType": {},
        "BankAccountStatementRequestType": {},
        "BankAccountStatus": {},
        "BankAccountStopImportNewOwner": {
            "id": [
                1
            ],
            "type": [
                108
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountSubtype": {},
        "BankAccountTransactionsImportStatus": {
            "changedBy": [
                1
            ],
            "changedAt": [
                361
            ],
            "stopImportFromDate": [
                361
            ],
            "balanceAtStopImportFromDate": [
                11
            ],
            "newOwner": [
                120
            ],
            "__typename": [
                1
            ]
        },
        "BankAccountType": {},
        "BankConnection": {
            "companyId": [
                8
            ],
            "integrationType": [
                684
            ],
            "institution": [
                648
            ],
            "integrationAccountId": [
                8
            ],
            "institutionName": [
                1
            ],
            "institutionLogo": [
                1
            ],
            "isTest": [
                5
            ],
            "accounts": [
                125
            ],
            "isRefreshRequired": [
                5
            ],
            "isDisconnected": [
                5
            ],
            "syncStatus": [
                131
            ],
            "impactOnBooksSince": [
                361
            ],
            "deletedAt": [
                361
            ],
            "deletedBy": [
                1
            ],
            "status": [
                130
            ],
            "__typename": [
                1
            ]
        },
        "BankConnectionAccount": {
            "id": [
                8
            ],
            "minimumTransactionDate": [
                361,
                {
                    "fromBooksStartTime": [
                        5
                    ]
                }
            ],
            "institutionName": [
                1
            ],
            "institutionLogo": [
                1
            ],
            "institution": [
                648
            ],
            "integrationType": [
                684
            ],
            "integrationAccountId": [
                8
            ],
            "name": [
                1
            ],
            "fullName": [
                1
            ],
            "mask": [
                1
            ],
            "type": [
                123
            ],
            "subtype": [
                121
            ],
            "currentBalance": [
                11
            ],
            "status": [
                119
            ],
            "connectionStatus": [
                127
            ],
            "transactionsImportStatus": [
                128
            ],
            "isManual": [
                5
            ],
            "isRefreshRequired": [
                5
            ],
            "isDisconnected": [
                5
            ],
            "deletedAt": [
                361
            ],
            "deletedBy": [
                1
            ],
            "createdAt": [
                361
            ],
            "createdBy": [
                1
            ],
            "connectedAt": [
                361
            ],
            "duplicateOfBankAccountId": [
                1
            ],
            "hasRecurringMission": [
                5
            ],
            "isHistoricalUpdateCompleted": [
                5
            ],
            "currency": [
                1
            ],
            "markedAsClosedByCustomerAt": [
                361
            ],
            "locks": [
                126
            ],
            "isManagedByFinaloop": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "BankConnectionAccountLock": {
            "startTime": [
                361
            ],
            "endTime": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "BankConnectionAccountStatus": {},
        "BankConnectionAccountTransactionsImportStatus": {
            "changedBy": [
                1
            ],
            "changedAt": [
                361
            ],
            "stopImportFromDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "BankConnectionsInput": {
            "companyId": [
                8
            ],
            "withDeleted": [
                5
            ],
            "sources": [
                684
            ],
            "statuses": [
                119
            ],
            "__typename": [
                1
            ]
        },
        "BankConnectionStatus": {},
        "BankConnectionSyncStatus": {
            "lastSuccessfulTransactionsUpdate": [
                361
            ],
            "lastFailedTransactionsUpdate": [
                361
            ],
            "updatedAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "BankDetails": {
            "name": [
                1
            ],
            "lastFourDigits": [
                1
            ],
            "type": [
                1
            ],
            "missingBank": [
                5
            ],
            "accountName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankReconciliationRequest": {
            "id": [
                8
            ],
            "bankAccount": [
                95
            ],
            "companyId": [
                1
            ],
            "requestType": [
                144
            ],
            "fiscalYear": [
                33
            ],
            "finaloopMessage": [
                1
            ],
            "finaloopMessageSender": [
                1
            ],
            "expectedEndDate": [
                361
            ],
            "booksBalanceAtDate": [
                11
            ],
            "customerBalanceByStatement": [
                11
            ],
            "customerConfirmedBooksBalanceAtDate": [
                5
            ],
            "irrelevantReason": [
                140
            ],
            "transferredEntity": [
                142
            ],
            "defaultEndDate": [
                361
            ],
            "resolutionType": [
                141
            ],
            "status": [
                143
            ],
            "latestActorId": [
                1
            ],
            "latestActorType": [
                22
            ],
            "deletedAt": [
                361
            ],
            "createdAt": [
                361
            ],
            "updatedAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementBankAccount": {
            "id": [
                8
            ],
            "integrationAccountId": [
                8
            ],
            "name": [
                1
            ],
            "type": [
                123
            ],
            "subtype": [
                121
            ],
            "institutionName": [
                1
            ],
            "institutionId": [
                1
            ],
            "currency": [
                1
            ],
            "mask": [
                1
            ],
            "source": [
                684
            ],
            "transactionsImportStatus": [
                1320
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementCustomerData": {
            "fileNames": [
                1
            ],
            "csvFileNames": [
                1
            ],
            "noCSVFiles": [
                5
            ],
            "noPDFFiles": [
                5
            ],
            "displayedBalance": [
                11
            ],
            "realBalance": [
                11
            ],
            "cycleStart": [
                361
            ],
            "cycleEnd": [
                361
            ],
            "isConfirmed": [
                5
            ],
            "irrelevantReason": [
                1
            ],
            "irrelevantType": [
                763
            ],
            "isMachineReconciled": [
                5
            ],
            "newOwner": [
                1
            ],
            "newOwnerType": [
                108
            ],
            "newOwnerId": [
                1
            ],
            "newOwnerName": [
                1
            ],
            "bankDetails": [
                132
            ],
            "transferDate": [
                361
            ],
            "closedDate": [
                361
            ],
            "relatedMissionId": [
                1
            ],
            "isIntegrationTransfers": [
                5
            ],
            "isClosedThisYear": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementFileSubSystem": {},
        "BankStatementFilesValidationProvider": {},
        "BankStatementFileSystem": {},
        "BankStatementFinaloopData": {
            "comment": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementReconciliationIrrelevantReason": {},
        "BankStatementReconciliationResolutionType": {},
        "BankStatementReconciliationTransferredEntity": {},
        "BankStatementRequestStatus": {},
        "BankStatementRequestType": {},
        "BankStatementValidationResults": {
            "fileId": [
                8
            ],
            "runStatus": [
                146
            ],
            "failureReason": [
                1
            ],
            "matchConfidence": [
                11
            ],
            "institution": [
                1
            ],
            "startDate": [
                361
            ],
            "endDate": [
                361
            ],
            "openingBalance": [
                11
            ],
            "closingBalance": [
                11
            ],
            "accountNumber": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankStatementValidationRunStatus": {},
        "BankTransaction": {
            "id": [
                8
            ],
            "accrual": [
                7
            ],
            "companyId": [
                8
            ],
            "bankAccount": [
                95
            ],
            "postedAt": [
                361
            ],
            "amount": [
                11
            ],
            "currency": [
                1
            ],
            "amountInUsd": [
                11
            ],
            "merchant": [
                870
            ],
            "businessEvent": [
                149
            ],
            "classificationMetadata": [
                221
            ],
            "description": [
                1
            ],
            "processedByMachineAt": [
                361
            ],
            "lastProcessedByMachineAt": [
                361
            ],
            "applyingClassificationJobId": [
                1
            ],
            "source": [
                1
            ],
            "sourceSpecificData": [
                772
            ],
            "userMemo": [
                160
            ],
            "attachments": [
                148
            ],
            "classificationVersion": [
                33
            ],
            "deletedAt": [
                361
            ],
            "loan": [
                270
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionAttachment": {
            "signedUrl": [
                1
            ],
            "url": [
                1
            ],
            "name": [
                1
            ],
            "addedBy": [
                1
            ],
            "createdAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionBusinessEvent": {
            "classifiedAt": [
                361
            ],
            "classifierType": [
                1
            ],
            "classifiedBy": [
                1
            ],
            "classifications": [
                150
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionBusinessEventClassification": {
            "businessEvent": [
                1
            ],
            "amount": [
                11
            ],
            "pairedEntityId": [
                1
            ],
            "pairedEntityType": [
                994
            ],
            "pairingType": [
                995
            ],
            "classificationTagId": [
                1
            ],
            "classificationTagText": [
                1
            ],
            "classificationText": [
                1
            ],
            "description": [
                1
            ],
            "extraData": [
                772
            ],
            "askTheUserResult": [
                80
            ],
            "pairedEntity": [
                993
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionByMerchant": {
            "groupId": [
                8
            ],
            "merchant": [
                870
            ],
            "transactions": [
                147
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionByMerchantResult": {
            "groups": [
                151
            ],
            "total": [
                33
            ],
            "nextToken": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionCategorizationInput": {
            "bankTransactionId": [
                8
            ],
            "merchant": [
                873
            ],
            "classifications": [
                156
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionCategorizationResult": {
            "bankTransaction": [
                147
            ],
            "status": [
                155
            ],
            "error": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionCategorizationResultStatus": {},
        "BankTransactionClassificationInput": {
            "amount": [
                11
            ],
            "classificationTagId": [
                8
            ],
            "classificationText": [
                8
            ],
            "businessEvent": [
                1
            ],
            "pairedEntityId": [
                1
            ],
            "pairedEntityType": [
                994
            ],
            "pairingType": [
                995
            ],
            "askTheUserResult": [
                772
            ],
            "extraData": [
                772
            ],
            "description": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionClassifierType": {},
        "BankTransactionCursorInput": {
            "offset": [
                33
            ],
            "limit": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionInput": {
            "companyId": [
                8
            ],
            "transactionId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionMemo": {
            "content": [
                1
            ],
            "updatedAt": [
                361
            ],
            "updatedBy": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionPairingInput": {
            "pairedEntityType": [
                994
            ],
            "pairedEntityId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionPairingResult": {
            "pairedEntityType": [
                994
            ],
            "pairedEntityId": [
                1
            ],
            "bankTransactions": [
                147
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionResponse": {
            "bankTransactions": [
                147
            ],
            "nextOffset": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsByMerchantInput": {
            "companyId": [
                8
            ],
            "bankAccountId": [
                8
            ],
            "dateRange": [
                363
            ],
            "merchantIds": [
                8
            ],
            "merchantIdsOperator": [
                463
            ],
            "nextToken": [
                8
            ],
            "limit": [
                33
            ],
            "searchTerm": [
                1
            ],
            "amountRange": [
                67
            ],
            "absoluteAmounts": [
                5
            ],
            "moneyDirection": [
                891
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsByPairingsInput": {
            "pairings": [
                161
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsInput": {
            "companyId": [
                8
            ],
            "bankTransactionIds": [
                8
            ],
            "bankAccountIds": [
                8
            ],
            "bankAccountIdsOperator": [
                463
            ],
            "businessEvents": [
                1
            ],
            "businessEventsOperator": [
                463
            ],
            "searchTerm": [
                1
            ],
            "endDate": [
                361
            ],
            "startDate": [
                361
            ],
            "amountLte": [
                11
            ],
            "amountGte": [
                11
            ],
            "checkAgainstOriginalAmount": [
                5
            ],
            "absoluteAmounts": [
                5
            ],
            "source": [
                684
            ],
            "merchantIds": [
                8
            ],
            "searchMerchantsByName": [
                5
            ],
            "merchantIdsOperator": [
                463
            ],
            "onlyPendingUserInput": [
                5
            ],
            "moneyDirection": [
                891
            ],
            "cursor": [
                158
            ],
            "excludePairedEntityTypes": [
                994
            ],
            "showOnlySpreadTransactions": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsResponse": {
            "bankTransactions": [
                147
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsSort": {
            "closeToAmount": [
                11
            ],
            "closeToDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsSummary": {
            "amountInUsd": [
                11
            ],
            "count": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsUpload": {
            "id": [
                8
            ],
            "companyId": [
                1
            ],
            "bankAccount": [
                95
            ],
            "fileLocation": [
                171
            ],
            "uploadOriginator": [
                1
            ],
            "uploadedBy": [
                22
            ],
            "uploadedById": [
                1
            ],
            "uploadedAt": [
                361
            ],
            "processingLogs": [
                172
            ],
            "status": [
                176
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsUploadFileLocation": {
            "bucket": [
                1
            ],
            "key": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsUploadProcessingLog": {
            "processorType": [
                175
            ],
            "processorId": [
                1
            ],
            "createdAt": [
                361
            ],
            "state": [
                174
            ],
            "metadata": [
                772
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsUploadProcessingLogInput": {
            "processorType": [
                175
            ],
            "processorId": [
                1
            ],
            "state": [
                174
            ],
            "message": [
                1
            ],
            "metadata": [
                772
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionsUploadProcessingLogState": {},
        "BankTransactionsUploadProcessorType": {},
        "BankTransactionsUploadStatus": {},
        "BankTransactionToLinkInput": {
            "transactionId": [
                8
            ],
            "amount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "BankTransactionUploadsInput": {
            "companyId": [
                8
            ],
            "bankAccountIds": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "BankTxSaleTransaction": {
            "id": [
                8
            ],
            "integrationInfo": [
                666
            ],
            "amount": [
                11
            ],
            "postedAt": [
                361
            ],
            "currency": [
                340
            ],
            "amountInHomeCurrency": [
                11
            ],
            "type": [
                1321
            ],
            "finaloopPaymentGateway": [
                1
            ],
            "originalPaymentGateway": [
                1
            ],
            "paymentGatewayVariant": [
                1
            ],
            "isIndication": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "BigValidationAccountRoleAmount": {
            "amount": [
                11
            ],
            "accountRole": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BigValidationData": {
            "method": [
                851
            ],
            "startDate": [
                361
            ],
            "endDate": [
                361
            ],
            "entityData": [
                183
            ],
            "booksData": [
                183
            ],
            "status": [
                185
            ],
            "isGreen": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "BigValidationInventoryBalanceData": {
            "inventory": [
                11
            ],
            "inventoryInProcess": [
                11
            ],
            "inventoryInTransit": [
                11
            ],
            "inventoryInTransitInternal": [
                11
            ],
            "legacyInventory": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "BigValidationPeriodData": {
            "cogs": [
                180
            ],
            "purchases": [
                180
            ],
            "endOfYearAdjustments": [
                11
            ],
            "inventoryEndingBalance": [
                182
            ],
            "inventoryOpeningBalance": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "BigValidationResponse": {
            "data": [
                181
            ],
            "__typename": [
                1
            ]
        },
        "BigValidationStatus": {
            "openingBalance": [
                5
            ],
            "cogs": [
                5
            ],
            "methodChangeAdjustments": [
                5
            ],
            "endOfYearAdjustments": [
                5
            ],
            "endingBalance": [
                5
            ],
            "purchases": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "Bill": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "billNumber": [
                1
            ],
            "currency": [
                1
            ],
            "amount": [
                11
            ],
            "createdDate": [
                361
            ],
            "deletedAt": [
                361
            ],
            "vendor": [
                1445
            ],
            "categories": [
                214
            ],
            "balance": [
                11
            ],
            "payments": [
                192
            ],
            "credits": [
                189
            ],
            "adjustments": [
                187
            ],
            "__typename": [
                1
            ]
        },
        "BillAdjustment": {
            "id": [
                8
            ],
            "type": [
                188
            ],
            "amount": [
                11
            ],
            "currency": [
                340
            ],
            "actionTime": [
                361
            ],
            "eventTime": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "BillAdjustmentType": {},
        "BillCredit": {
            "creditDate": [
                361
            ],
            "amount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "Billing": {
            "id": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "BillItemToReconcile": {
            "companyId": [
                8
            ],
            "billId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "BillPayment": {
            "id": [
                8
            ],
            "paymentDate": [
                361
            ],
            "amount": [
                11
            ],
            "feeAmount": [
                11
            ],
            "amountInUSD": [
                11
            ],
            "currency": [
                340
            ],
            "vendor": [
                1445
            ],
            "settlementType": [
                193
            ],
            "__typename": [
                1
            ]
        },
        "BillPaymentSettlementType": {},
        "BillsDueDateSummary": {
            "total": [
                417
            ],
            "totalBalanceDue": [
                417
            ],
            "pastDue": [
                417
            ],
            "dueNext7Days": [
                417
            ],
            "dueAfterNext7Days": [
                417
            ],
            "__typename": [
                1
            ]
        },
        "BillsDueDateSummaryInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "BusinessEventActionsConfiguration": {
            "id": [
                8
            ],
            "type": [
                1
            ],
            "variant": [
                1
            ],
            "accrualConfig": [
                769
            ],
            "cashConfig": [
                769
            ],
            "__typename": [
                1
            ]
        },
        "BusinessEventActionsConfigurationByType": {
            "type": [
                1
            ],
            "variant": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BusinessEventCategorizationInput": {
            "classifications": [
                220
            ],
            "shouldApplyAlways": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "BusinessEventClassificationInput": {
            "businessEvent": [
                1
            ],
            "amount": [
                11
            ],
            "pairedEntityId": [
                1
            ],
            "pairedEntityType": [
                994
            ],
            "pairingType": [
                995
            ],
            "classificationTagId": [
                1
            ],
            "classificationTagText": [
                1
            ],
            "classificationText": [
                1
            ],
            "description": [
                1
            ],
            "extraData": [
                772
            ],
            "askTheUserResult": [
                772
            ],
            "__typename": [
                1
            ]
        },
        "BusinessEventDefinition": {
            "id": [
                8
            ],
            "name": [
                1
            ],
            "customerDisplayName": [
                1
            ],
            "groups": [
                1
            ],
            "vendorRequired": [
                5
            ],
            "irrelevantForVendor": [
                5
            ],
            "hideForManualClassification": [
                5
            ],
            "forceShowInEventsListings": [
                5
            ],
            "moneyDirection": [
                891
            ],
            "customerDescriptionTemplate": [
                1
            ],
            "isRelevantToAccruals": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "BusinessEventDefinitionsByGroups": {
            "groups": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BusinessEventDefinitionsByIds": {
            "ids": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "BusinessEventInput": {
            "classifications": [
                199
            ],
            "__typename": [
                1
            ]
        },
        "BusinessEventItemToReconcile": {
            "companyId": [
                8
            ],
            "businessEventId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "BusinessEventRuleType": {},
        "BusinessEventsAccrualsConfig": {
            "id": [
                1
            ],
            "isRelevantToAccruals": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "CalculateInvoiceInput": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "companyAddressId": [
                8
            ],
            "customerId": [
                8
            ],
            "shippingAddressId": [
                8
            ],
            "billingAddressId": [
                8
            ],
            "displayName": [
                1
            ],
            "postedAt": [
                361
            ],
            "dueDate": [
                361
            ],
            "taxFixedAmount": [
                11
            ],
            "lineItems": [
                1153
            ],
            "discounts": [
                409
            ],
            "paymentMethodIds": [
                8
            ],
            "customerNote": [
                1
            ],
            "issueInvoice": [
                5
            ],
            "currency": [
                340
            ],
            "__typename": [
                1
            ]
        },
        "CalendlyEventInput": {
            "eventId": [
                1
            ],
            "inviteeId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CalendlyEventResponse": {
            "email": [
                1
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CancelAccrualInput": {
            "companyId": [
                8
            ],
            "bankTransactionId": [
                8
            ],
            "canceledAtData": [
                212
            ],
            "__typename": [
                1
            ]
        },
        "CanceledAccrualData": {
            "classificationTagId": [
                8
            ],
            "businessEventType": [
                1
            ],
            "spreadType": [
                1275
            ],
            "specificDate": [
                1268
            ],
            "spreadOverTime": [
                1273
            ],
            "customSpread": [
                351
            ],
            "defaultFixedAssetsData": [
                469
            ],
            "customFixedAssetsData": [
                469
            ],
            "__typename": [
                1
            ]
        },
        "CanceledAtDataItem": {
            "canceledAt": [
                1
            ],
            "classificationTagId": [
                1
            ],
            "businessEventType": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CategorizeBankTransactionsInput": {
            "companyId": [
                8
            ],
            "categorizations": [
                153
            ],
            "__typename": [
                1
            ]
        },
        "Category": {
            "description": [
                1
            ],
            "amount": [
                11
            ],
            "classificationTag": [
                225
            ],
            "__typename": [
                1
            ]
        },
        "CategoryBreakdown": {
            "category": [
                232
            ],
            "amount": [
                11
            ],
            "costType": [
                295
            ],
            "__typename": [
                1
            ]
        },
        "ChangeProductsHiddenStatusInput": {
            "companyId": [
                8
            ],
            "productStatusChange": [
                1095
            ],
            "__typename": [
                1
            ]
        },
        "ChartDataInput": {
            "companyId": [
                8
            ],
            "chartName": [
                1
            ],
            "fromDate": [
                361
            ],
            "toDate": [
                361
            ],
            "breakdown": [
                1
            ],
            "timezone": [
                1
            ],
            "comparisonPeriod": [
                1
            ],
            "view": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ChartDataResponse": {
            "title": [
                1
            ],
            "updatedAt": [
                361
            ],
            "category": [
                1
            ],
            "data": [
                360
            ],
            "labels": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CheckAwaitedExportStatusInput": {
            "companyId": [
                8
            ],
            "requestId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationInput": {
            "businessEvent": [
                1
            ],
            "amount": [
                11
            ],
            "description": [
                1
            ],
            "pairedEntityId": [
                1
            ],
            "pairedEntityType": [
                994
            ],
            "pairingType": [
                995
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationMetadata": {
            "enrichmentRuleType": [
                423
            ],
            "enrichmentRuleId": [
                1
            ],
            "businessEventRuleType": [
                205
            ],
            "businessEventRuleId": [
                1
            ],
            "isAugmented": [
                5
            ],
            "actorType": [
                22
            ],
            "actorId": [
                1
            ],
            "classifiedAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationMetadataInput": {
            "enrichmentRuleType": [
                423
            ],
            "enrichmentRuleId": [
                1
            ],
            "businessEventRuleType": [
                205
            ],
            "businessEventRuleId": [
                1
            ],
            "isAugmented": [
                5
            ],
            "metadata": [
                772
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationRequestInput": {
            "id": [
                8
            ],
            "merchant": [
                873
            ],
            "businessEvent": [
                203
            ],
            "allowClassificationForVersion": [
                41
            ],
            "classificationMetadata": [
                222
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationResult": {
            "current": [
                147
            ],
            "previous": [
                147
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationTag": {
            "id": [
                8
            ],
            "name": [
                1
            ],
            "moneyDirection": [
                891
            ],
            "isHidden": [
                5
            ],
            "isRelevantToBills": [
                5
            ],
            "isRelevantToApplyAlways": [
                5
            ],
            "displayNameForReports": [
                1
            ],
            "keywords": [
                1
            ],
            "classification": [
                226
            ],
            "isApplicableForRefund": [
                5
            ],
            "weight": [
                33
            ],
            "reportsDisplayNameForTagSelection": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationTagClassification": {
            "in": [
                1
            ],
            "out": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationTagsByIdsInput": {
            "ids": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "ClassificationTagsInput": {
            "forBillsOnly": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ClassifyIntegrationTransferInput": {
            "companyId": [
                8
            ],
            "classifications": [
                673
            ],
            "__typename": [
                1
            ]
        },
        "ClosingBalance": {
            "value": [
                11
            ],
            "forDate": [
                1
            ],
            "actorType": [
                98
            ],
            "actorId": [
                1
            ],
            "createdAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "COGSBreakdownItem": {
            "product": [
                1071
            ],
            "quantity": [
                33
            ],
            "avgCostPerUnit": [
                11
            ],
            "totalCogs": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "CogsCategory": {},
        "COGSEvent": {
            "id": [
                1
            ],
            "groupName": [
                1
            ],
            "period": [
                234
            ],
            "amount": [
                11
            ],
            "salesChannel": [
                1158
            ],
            "lineOfBusinessId": [
                1
            ],
            "memo": [
                1
            ],
            "type": [
                238
            ],
            "source": [
                235
            ],
            "isFullyProcessed": [
                5
            ],
            "costType": [
                295
            ],
            "categoryBreakdown": [
                215
            ],
            "__typename": [
                1
            ]
        },
        "COGSEventPeriod": {
            "fromDate": [
                361
            ],
            "toDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "COGSEventSource": {},
        "CogsSource": {},
        "COGSSummaryV2": {
            "groupName": [
                1
            ],
            "type": [
                238
            ],
            "sum": [
                11
            ],
            "salesChannel": [
                1158
            ],
            "realtimeSum": [
                11
            ],
            "manualSum": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "CogsType": {},
        "CommonAction": {
            "quantity": [
                33
            ],
            "id": [
                1
            ],
            "date": [
                361
            ],
            "type": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CommonWarehouseTransfersFilterInput": {
            "companyId": [
                1
            ],
            "productIds": [
                1
            ],
            "shippingDate": [
                1495
            ],
            "arrivalDate": [
                1495
            ],
            "warehouse": [
                1469
            ],
            "textSearch": [
                1
            ],
            "withDeleted": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "Company": {
            "id": [
                8
            ],
            "billing": [
                258
            ],
            "name": [
                1
            ],
            "userName": [
                1
            ],
            "refferalCode": [
                1
            ],
            "source": [
                772
            ],
            "email": [
                1
            ],
            "isManufacturer": [
                5
            ],
            "phoneNumber": [
                1
            ],
            "address": [
                27
            ],
            "accountingMethod": [
                6
            ],
            "booksStartTime": [
                772
            ],
            "dataRestrictionTime": [
                361
            ],
            "customerSelectedAccountingMethod": [
                348
            ],
            "inventorySettings": [
                715
            ],
            "ordersSettings": [
                979
            ],
            "generalDetails": [
                772
            ],
            "configuration": [
                260
            ],
            "isTestCompany": [
                5
            ],
            "isArchived": [
                5
            ],
            "createdAtMs": [
                11
            ],
            "createdAtIso": [
                1
            ],
            "canceledAtIso": [
                1
            ],
            "preloadEmail": [
                1
            ],
            "eventsLock": [
                772
            ],
            "eventsLock2": [
                426
            ],
            "legalEntityType": [
                1
            ],
            "taxEntityType": [
                1
            ],
            "trialStartTimeIso": [
                1
            ],
            "trialStartTimeMs": [
                11
            ],
            "trialDurationInDays": [
                33
            ],
            "isPaying": [
                5
            ],
            "fullUiAccess": [
                5
            ],
            "paymentStatus": [
                1
            ],
            "tier": [
                1
            ],
            "tierAtConversion": [
                1
            ],
            "taxPlan": [
                1
            ],
            "stripeCustomerId": [
                8
            ],
            "isOnboardingCompleted": [
                5
            ],
            "onboardingState": [
                907
            ],
            "isParkerReferral": [
                5
            ],
            "estimatedProjectedRevenue": [
                425
            ],
            "calculatedTier": [
                279
            ],
            "hasActiveTrial": [
                5
            ],
            "lockStatus": [
                276
            ],
            "isLiveTestCompany": [
                5
            ],
            "gtmSource": [
                613
            ],
            "fiscalYear": [
                262
            ],
            "lockHistory": [
                275
            ],
            "__typename": [
                1
            ]
        },
        "CompanyAccountValidation": {
            "account": [
                1
            ],
            "status": [
                287
            ],
            "value": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "CompanyAddress": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "source": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "isDefault": [
                5
            ],
            "name": [
                1
            ],
            "email": [
                1
            ],
            "phone": [
                1
            ],
            "line1": [
                1
            ],
            "line2": [
                1
            ],
            "city": [
                1
            ],
            "country": [
                1
            ],
            "province": [
                1
            ],
            "zip": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyAddressInput": {
            "companyId": [
                8
            ],
            "id": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "CompanyAffiliate": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "type": [
                250
            ],
            "name": [
                1
            ],
            "createdAt": [
                361
            ],
            "updatedAt": [
                361
            ],
            "logoUrl": [
                1
            ],
            "holdingsInPercentage": [
                11
            ],
            "isForeignResident": [
                5
            ],
            "balance": [
                11
            ],
            "transactions": [
                249
            ],
            "status": [
                629
            ],
            "affiliatedEntityId": [
                8
            ],
            "latestAccountingEventIds": [
                1
            ],
            "deletedAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "CompanyAffiliateInput": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "CompanyAffiliatesByIdsInput": {
            "ids": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "CompanyAffiliatesInput": {
            "companyId": [
                8
            ],
            "type": [
                250
            ],
            "searchTerm": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyAffiliateTransaction": {
            "entityId": [
                8
            ],
            "entityType": [
                1
            ],
            "bankAccountId": [
                1
            ],
            "postedAt": [
                361
            ],
            "amount": [
                11
            ],
            "currency": [
                1
            ],
            "amountInUsd": [
                11
            ],
            "type": [
                1
            ],
            "source": [
                684
            ],
            "__typename": [
                1
            ]
        },
        "CompanyAffiliateType": {},
        "CompanyArCashDiffsActionsResponse": {
            "accountRole": [
                1
            ],
            "amount": [
                11
            ],
            "isAccrual": [
                5
            ],
            "isCash": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "CompanyArCashDiffsFixbooksResponse": {
            "eventTime": [
                361
            ],
            "createdAt": [
                361
            ],
            "creatorEmail": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "memo": [
                1
            ],
            "actions": [
                251
            ],
            "__typename": [
                1
            ]
        },
        "CompanyArCashDiffsInput": {
            "companyId": [
                8
            ],
            "unpaidBalanceAsOf": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "CompanyArCashDiffsOrdersResponse": {
            "orderId": [
                8
            ],
            "integrationAccountId": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "displayName": [
                1
            ],
            "ordersVsBooks": [
                11
            ],
            "ordersBalance": [
                11
            ],
            "booksBalance": [
                11
            ],
            "placedAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "CompanyArCashDiffsResponse": {
            "companyId": [
                8
            ],
            "reportDate": [
                361
            ],
            "orders": [
                254
            ],
            "fixbooks": [
                252
            ],
            "totals": [
                256
            ],
            "lockTime": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "CompanyArCashTotalsResponse": {
            "arCashInBooks": [
                11
            ],
            "unpaidOrders": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "CompanyArtificialFulfillmentSettingsInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "CompanyBilling": {
            "features": [
                445
            ],
            "purchasedOffers": [
                1099
            ],
            "portalUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyByStripeCustomerIdInput": {
            "stripeCustomerId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyConfiguration": {
            "isPnlReportReady": [
                5
            ],
            "isBalanceSheetReportFinal": [
                5
            ],
            "includeCCAccountsInCashFlowBeforePayment": [
                5
            ],
            "includeUDFAccountsInCashFlowBeforeDeposit": [
                5
            ],
            "cashFlowStartTimeISO": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyEventsLockHistory": {
            "year": [
                33
            ],
            "createdAt": [
                361
            ],
            "userEmail": [
                1
            ],
            "type": [
                847
            ],
            "__typename": [
                1
            ]
        },
        "CompanyFiscalYear": {
            "day": [
                33
            ],
            "month": [
                33
            ],
            "userEmail": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyHealthStatus": {
            "company": [
                241
            ],
            "domains": [
                285
            ],
            "status": [
                287
            ],
            "__typename": [
                1
            ]
        },
        "CompanyHealthStatusSortDirection": {},
        "CompanyIdAndStripeCustomerId": {
            "companyId": [
                1
            ],
            "stripeCustomerId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyIdInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "CompanyIntegration": {
            "companyId": [
                8
            ],
            "integrationType": [
                684
            ],
            "integrationAccountId": [
                8
            ],
            "integrationTypeAndIntegrationAccountId": [
                1
            ],
            "data": [
                771
            ],
            "enabled": [
                5
            ],
            "impactOnBooksSince": [
                1
            ],
            "createdAtMs": [
                11
            ],
            "deletedByUserAt": [
                1
            ],
            "deletedByUserId": [
                1
            ],
            "disconnectedAt": [
                1
            ],
            "disconnectedBy": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyIntegrationInput": {
            "companyId": [
                8
            ],
            "integrationType": [
                684
            ],
            "integrationAccountId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyIntegrationsInput": {
            "companyId": [
                8
            ],
            "integrationType": [
                684
            ],
            "__typename": [
                1
            ]
        },
        "CompanyLoan": {
            "id": [
                8
            ],
            "company": [
                241
            ],
            "source": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "type": [
                833
            ],
            "lender": [
                898
            ],
            "currency": [
                340
            ],
            "postedAt": [
                361
            ],
            "direction": [
                804
            ],
            "isShortTerm": [
                5
            ],
            "amount": [
                11
            ],
            "totalAmount": [
                11
            ],
            "outstandingAmount": [
                11
            ],
            "accruedInterest": [
                11
            ],
            "unrecognizedFees": [
                11
            ],
            "prepaymentLiabilityAmount": [
                11
            ],
            "status": [
                830
            ],
            "userActionStatus": [
                837
            ],
            "acknowledgedInternallyAt": [
                361
            ],
            "acknowledgedInternallyBy": [
                1
            ],
            "paidOffAt": [
                361
            ],
            "attachments": [
                792
            ],
            "workingPaperLink": [
                1
            ],
            "updatedBy": [
                1
            ],
            "lastSeenByClientAt": [
                361
            ],
            "deletedAt": [
                361
            ],
            "createdAt": [
                361
            ],
            "updatedAt": [
                361
            ],
            "interestDays": [
                811
            ],
            "repaymentType": [
                824
            ],
            "repaymentFrequency": [
                823
            ],
            "repaymentRate": [
                11
            ],
            "interestRateType": [
                812
            ],
            "interestRate": [
                11
            ],
            "gracePeriod": [
                33
            ],
            "catchUpPeriod": [
                33
            ],
            "catchUpPercent": [
                11
            ],
            "originationFees": [
                11
            ],
            "prepaymentFees": [
                11
            ],
            "recurringDataFrequency": [
                821
            ],
            "instantRepaymentMethod": [
                810
            ],
            "isIndexLinked": [
                5
            ],
            "interestType": [
                813
            ],
            "variableInterestUpdatePeriod": [
                840
            ],
            "variableInterestSpread": [
                11
            ],
            "variableInterestBase": [
                839
            ],
            "compoundFrequency": [
                800
            ],
            "indexType": [
                809
            ],
            "isEarlyRepaymentDiscount": [
                5
            ],
            "earlyRepaymentDiscounts": [
                419
            ],
            "transactions": [
                147
            ],
            "lastTransaction": [
                147
            ],
            "userFollowUp2": [
                1428
            ],
            "askIfRepaid": [
                5
            ],
            "hideOutdatedWarning": [
                5
            ],
            "deleteOptionMode": [
                271
            ],
            "pendingDelete": [
                5
            ],
            "pendingRestoreAcknowledgement": [
                5
            ],
            "lastUserMoveToProcessingAt": [
                361
            ],
            "dataRequestType": [
                803
            ],
            "isHidden": [
                5
            ],
            "lastEventDate": [
                361
            ],
            "isBillPaymentEventAttached": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "CompanyLoanDeleteOptionMode": {},
        "CompanyLoanResponse": {
            "loan": [
                270
            ],
            "__typename": [
                1
            ]
        },
        "CompanyLoansResponse": {
            "loans": [
                270
            ],
            "__typename": [
                1
            ]
        },
        "CompanyLockConditions": {
            "accountingMethod": [
                1
            ],
            "moneyIn": [
                845
            ],
            "moneyOut": [
                845
            ],
            "reclassificationMoneyIn": [
                845
            ],
            "reclassificationMoneyOut": [
                845
            ],
            "assetsEqualToLiabilitiesAndEquities": [
                845
            ],
            "isPayrollOk": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "CompanyLockHistory": {
            "type": [
                841
            ],
            "userEmail": [
                1
            ],
            "startDate": [
                361
            ],
            "endDate": [
                361
            ],
            "createdAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "CompanyLockStatus": {
            "minimumAllowedEventTime": [
                361
            ],
            "lockedAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "CompanyMainHolderInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "CompanySyncBodyInput": {
            "id": [
                8
            ],
            "booksStartTime": [
                361
            ],
            "deletedAt": [
                361
            ],
            "name": [
                1
            ],
            "accountingMethod": [
                1
            ],
            "isTestCompany": [
                5
            ],
            "booksStatus": [
                771
            ],
            "isArchived": [
                5
            ],
            "archivedAtIso": [
                361
            ],
            "createdAt": [
                361
            ],
            "tier": [
                280
            ],
            "trialStartTime": [
                361
            ],
            "trialDurationInDays": [
                33
            ],
            "isPaying": [
                5
            ],
            "eventsLock": [
                771
            ],
            "__typename": [
                1
            ]
        },
        "CompanyTier": {},
        "CompanyTierType": {},
        "CompanyUser": {
            "email": [
                1
            ],
            "roles": [
                1437
            ],
            "name": [
                1
            ],
            "invitationStatus": [
                741
            ],
            "__typename": [
                1
            ]
        },
        "CompanyUsersArgs": {
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CompanyUsersResponse": {
            "users": [
                281
            ],
            "__typename": [
                1
            ]
        },
        "CompanyValidation": {
            "type": [
                288
            ],
            "name": [
                1
            ],
            "status": [
                287
            ],
            "accounts": [
                242
            ],
            "__typename": [
                1
            ]
        },
        "CompanyValidationDomain": {
            "type": [
                286
            ],
            "name": [
                1
            ],
            "status": [
                287
            ],
            "validations": [
                284
            ],
            "__typename": [
                1
            ]
        },
        "CompanyValidationDomainType": {},
        "CompanyValidationStatus": {},
        "CompanyValidationType": {},
        "CompanyWorksheet": {
            "worksheetRows": [
                290
            ],
            "company": [
                241
            ],
            "__typename": [
                1
            ]
        },
        "CompanyWorksheetRow": {
            "companyId": [
                1
            ],
            "dateForLocking": [
                361
            ],
            "name": [
                1
            ],
            "amount": [
                11
            ],
            "comment": [
                1
            ],
            "signedAt": [
                361
            ],
            "year": [
                33
            ],
            "attachmentUrl": [
                1
            ],
            "lockableEntity": [
                291
            ],
            "periodStart": [
                361
            ],
            "periodEnd": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "CompanyWorksheetRowLockableEntity": {
            "on_BankAccount": [
                95
            ],
            "__typename": [
                1
            ]
        },
        "ConfirmRoleForPaymentGatewayInput": {
            "companyId": [
                8
            ],
            "paymentGatewayType": [
                1002
            ],
            "__typename": [
                1
            ]
        },
        "Contractor": {
            "id": [
                8
            ],
            "company": [
                241
            ],
            "source": [
                1
            ],
            "department": [
                402
            ],
            "firstName": [
                1
            ],
            "lastName": [
                1
            ],
            "middleName": [
                1
            ],
            "name": [
                1
            ],
            "businessName": [
                1
            ],
            "isActive": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "CopyBankAccountInput": {
            "sourceBankAccountId": [
                1
            ],
            "newBankAccountId": [
                1
            ],
            "companyId": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "status": [
                119
            ],
            "__typename": [
                1
            ]
        },
        "CostType": {},
        "CreateBankAccountInput": {
            "companyId": [
                8
            ],
            "id": [
                8
            ],
            "integrationAccountId": [
                1
            ],
            "source": [
                684
            ],
            "externalSourceId": [
                1
            ],
            "type": [
                123
            ],
            "subtype": [
                121
            ],
            "connectionStatus": [
                99
            ],
            "lastUpdateTime": [
                361
            ],
            "isHistoricalUpdateCompleted": [
                5
            ],
            "currency": [
                1
            ],
            "externalInstitutionId": [
                1
            ],
            "institutionId": [
                1
            ],
            "institutionName": [
                1
            ],
            "name": [
                1
            ],
            "displayName": [
                1
            ],
            "overrideFullName": [
                1
            ],
            "mask": [
                1
            ],
            "currentBalance": [
                11
            ],
            "connectedAt": [
                361
            ],
            "rawData": [
                772
            ],
            "shouldUpdateAccountDetailsOnNextUpdate": [
                5
            ],
            "status": [
                119
            ],
            "createdByActor": [
                22
            ],
            "createdBy": [
                1
            ],
            "createdFromSource": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateBankAccountStatementForStopImportInput": {
            "bankAccountId": [
                8
            ],
            "companyId": [
                8
            ],
            "stopImportFromDate": [
                1
            ],
            "displayedBalance": [
                11
            ],
            "closedDate": [
                1
            ],
            "isConfirmed": [
                5
            ],
            "fileNames": [
                1
            ],
            "csvFileNames": [
                1
            ],
            "realBalance": [
                11
            ],
            "newOwner": [
                107
            ],
            "__typename": [
                1
            ]
        },
        "CreateBankTransactionsUploadInput": {
            "companyId": [
                8
            ],
            "bankAccountId": [
                1
            ],
            "bucket": [
                1
            ],
            "key": [
                1
            ],
            "uploadOriginator": [
                1
            ],
            "uploadedBy": [
                22
            ],
            "uploadedById": [
                1
            ],
            "uploadedAt": [
                361
            ],
            "skipMachineTrigger": [
                5
            ],
            "originFileId": [
                8
            ],
            "fileStatus": [
                176
            ],
            "uploadedOnBankTransactionId": [
                8
            ],
            "fileUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateCheckoutSessionInput": {
            "companyId": [
                8
            ],
            "paymentOptionIds": [
                8
            ],
            "successUrl": [
                1
            ],
            "cancelUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateCheckoutSessionResponse": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateCompanyAffiliateInput": {
            "companyId": [
                8
            ],
            "name": [
                1
            ],
            "type": [
                250
            ],
            "isForeignResident": [
                5
            ],
            "holdingsInPercentage": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "CreateCompanyInput": {
            "email": [
                1
            ],
            "stripeCustomerId": [
                1
            ],
            "source": [
                771
            ],
            "referralCode": [
                1
            ],
            "isParkerReferral": [
                5
            ],
            "withoutNotification": [
                5
            ],
            "gtmSource": [
                614
            ],
            "__typename": [
                1
            ]
        },
        "CreateCompanyLoanFromTransactionClassificationInput": {
            "amount": [
                11
            ],
            "classificationTagId": [
                8
            ],
            "classificationText": [
                8
            ],
            "businessEvent": [
                1
            ],
            "pairedEntityId": [
                1
            ],
            "pairedEntityType": [
                994
            ],
            "pairingType": [
                995
            ],
            "askTheUserResult": [
                772
            ],
            "extraData": [
                772
            ],
            "description": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateCompanyLoanFromTransactionMerchantInput": {
            "id": [
                1
            ],
            "type": [
                879
            ],
            "__typename": [
                1
            ]
        },
        "CreateCompanyLoanInput": {
            "companyId": [
                8
            ],
            "data": [
                834
            ],
            "__typename": [
                1
            ]
        },
        "CreateCustomerArgs": {
            "name": [
                1
            ],
            "companyName": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "source": [
                349
            ],
            "email": [
                1
            ],
            "phoneNumber": [
                1
            ],
            "billingAddress": [
                28
            ],
            "shippingAddress": [
                28
            ],
            "__typename": [
                1
            ]
        },
        "CreateCustomerCategorizationRuleInput": {
            "companyId": [
                8
            ],
            "applyForAlreadyCategorizedTransactions": [
                5
            ],
            "merchant": [
                873
            ],
            "moneyDirection": [
                1255
            ],
            "classification": [
                346
            ],
            "__typename": [
                1
            ]
        },
        "CreateCustomerCategorizationRuleResult": {
            "realTimeApplierJobId": [
                1
            ],
            "bankTransactionIdsAffectedByJobId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "CreateFileUploadUrlInput": {
            "companyId": [
                1
            ],
            "source": [
                461
            ],
            "fileName": [
                1
            ],
            "featureContext": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateGlobalRuleResult": {
            "id": [
                8
            ],
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "CreateGlobalVendorInput": {
            "id": [
                1
            ],
            "name": [
                1
            ],
            "notificationInfo": [
                1454
            ],
            "subTypes": [
                1457
            ],
            "is1099Contractor": [
                5
            ],
            "websiteUrl": [
                1
            ],
            "source": [
                1455
            ],
            "labels": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateGlobalVendorRuleInput": {
            "vendorIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateImageUploadUrlInput": {
            "companyId": [
                1
            ],
            "fileName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateInstitutionInput": {
            "name": [
                1
            ],
            "preferredProvider": [
                653
            ],
            "isMajor": [
                1
            ],
            "logoUrl": [
                1
            ],
            "websiteUrl": [
                1
            ],
            "csvInstructionsLink": [
                1
            ],
            "pdfInstructionsLink": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateInstitutionMappingsInput": {
            "source": [
                652
            ],
            "externalSourceId": [
                1
            ],
            "externalSourceName": [
                1
            ],
            "institutionId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateIntegrationTransferRuleInput": {
            "companyId": [
                8
            ],
            "integrationType": [
                684
            ],
            "companyAffiliateId": [
                8
            ],
            "integrationAccountId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "CreateInventoryVendorValidationInput": {
            "companyId": [
                8
            ],
            "fromDate": [
                361
            ],
            "toDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "CreateInventoryVendorValidationResponse": {
            "on_InventoryVendorValidationResult": [
                738
            ],
            "on_InventoryVendorValidationError": [
                737
            ],
            "__typename": [
                1
            ]
        },
        "CreateLoanEventDefinitionInput": {
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateLoanFromBalanceTransactionInput": {
            "companyId": [
                8
            ],
            "balanceTransactionId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "CreateLoanFromBillFinancedInput": {
            "companyId": [
                8
            ],
            "billId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "CreateLoanInput": {
            "companyId": [
                8
            ],
            "data": [
                835
            ],
            "__typename": [
                1
            ]
        },
        "CreateLoansEventsEffectConfigurationInput": {
            "eventDefinitionId": [
                8
            ],
            "loanType": [
                833
            ],
            "isShortTerm": [
                5
            ],
            "direction": [
                804
            ],
            "minGracePeriod": [
                33
            ],
            "repaymentType": [
                824
            ],
            "lenderId": [
                8
            ],
            "memoTemplate": [
                1
            ],
            "dateTemplate": [
                1
            ],
            "accrualConfig": [
                770
            ],
            "cashConfig": [
                770
            ],
            "loanOutstandingAmountAffect": [
                818
            ],
            "loanAmountAffect": [
                791
            ],
            "__typename": [
                1
            ]
        },
        "CreateManualLoanEventInput": {
            "companyId": [
                8
            ],
            "loanId": [
                8
            ],
            "events": [
                326
            ],
            "__typename": [
                1
            ]
        },
        "CreateManualLoanEventInput2": {
            "companyId": [
                8
            ],
            "loanId": [
                8
            ],
            "eventDefinitionId": [
                8
            ],
            "amount": [
                11
            ],
            "memo": [
                1
            ],
            "postedAt": [
                361
            ],
            "spreadInput": [
                327
            ],
            "recurrenceSettings": [
                1131
            ],
            "__typename": [
                1
            ]
        },
        "CreateManualLoanEventInputEvents": {
            "eventDefinitionId": [
                8
            ],
            "amount": [
                11
            ],
            "memo": [
                1
            ],
            "postedAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "CreateManualLoanEventSpreadInput": {
            "startDate": [
                361
            ],
            "endDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "CreateOrGetBusinessEventDefinitionInput": {
            "type": [
                1
            ],
            "variant": [
                1
            ],
            "accrualConfig": [
                770
            ],
            "cashConfig": [
                770
            ],
            "originalActorId": [
                1
            ],
            "originalActorType": [
                22
            ],
            "integrationType": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateOrUpdateAccrualInput": {
            "companyId": [
                8
            ],
            "bankTransactionId": [
                8
            ],
            "accrualsData": [
                13
            ],
            "categorizations": [
                9
            ],
            "__typename": [
                1
            ]
        },
        "CreateOrUpdateAccrualResult": {
            "bankTransaction": [
                147
            ],
            "status": [
                15
            ],
            "error": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateProductManuallyError": {
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateProductManuallyInput": {
            "source": [
                1090
            ],
            "companyId": [
                1
            ],
            "displayName": [
                1
            ],
            "sku": [
                1
            ],
            "imageUrl": [
                1
            ],
            "isFractional": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "CreateProductManuallyResponse": {
            "on_CreateProductManuallyError": [
                331
            ],
            "on_CreateProductManuallySuccess": [
                334
            ],
            "__typename": [
                1
            ]
        },
        "CreateProductManuallySuccess": {
            "product": [
                1071
            ],
            "__typename": [
                1
            ]
        },
        "CreateTeamCategorizationRuleResult": {
            "realTimeApplierJobId": [
                1
            ],
            "bankTransactionIdsAffectedByJobId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "CreateUserResponse": {
            "user": [
                1421
            ],
            "__typename": [
                1
            ]
        },
        "CreateVendorArgs": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "name": [
                1
            ],
            "notificationInfo": [
                1454
            ],
            "subTypes": [
                1457
            ],
            "is1099Contractor": [
                5
            ],
            "contactEmail": [
                1
            ],
            "source": [
                1455
            ],
            "externalSourceId": [
                1
            ],
            "isPaymentVendor": [
                5
            ],
            "labels": [
                1
            ],
            "logoUrl": [
                1
            ],
            "websiteUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CreateWarehouseInput": {
            "companyId": [
                1
            ],
            "source": [
                1491
            ],
            "name": [
                1
            ],
            "phone": [
                1
            ],
            "city": [
                1
            ],
            "province": [
                1
            ],
            "country": [
                1
            ],
            "zip": [
                1
            ],
            "address": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CSVUpload": {
            "name": [
                1
            ],
            "uploadId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "CurrencyCode": {},
        "CurrentlyRunningInventoryDataSyncInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "CurrentlyRunningInventoryDataSyncResponse": {
            "totalItems": [
                33
            ],
            "completedItems": [
                33
            ],
            "updatingProducts": [
                1071
            ],
            "__typename": [
                1
            ]
        },
        "Customer": {
            "id": [
                8
            ],
            "name": [
                1
            ],
            "companyId": [
                8
            ],
            "companyName": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "source": [
                349
            ],
            "email": [
                1
            ],
            "phoneNumber": [
                1
            ],
            "billingAddress": [
                344
            ],
            "shippingAddress": [
                344
            ],
            "__typename": [
                1
            ]
        },
        "CustomerAddress": {
            "id": [
                8
            ],
            "country": [
                1
            ],
            "state": [
                1
            ],
            "city": [
                1
            ],
            "postalCode": [
                1
            ],
            "line1": [
                1
            ],
            "line2": [
                1
            ],
            "isShipping": [
                5
            ],
            "isBilling": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "CustomerAddressArgs": {
            "line1": [
                1
            ],
            "line2": [
                1
            ],
            "city": [
                1
            ],
            "country": [
                1
            ],
            "countryCode": [
                1
            ],
            "province": [
                1
            ],
            "provinceCode": [
                1
            ],
            "zip": [
                1
            ],
            "source": [
                349
            ],
            "__typename": [
                1
            ]
        },
        "CustomerCategorizationRuleClassificationInput": {
            "classificationTagId": [
                8
            ],
            "businessEvent": [
                1
            ],
            "pairedEntityType": [
                994
            ],
            "pairedEntityId": [
                1
            ],
            "pairingType": [
                995
            ],
            "__typename": [
                1
            ]
        },
        "CustomerNotExistError": {
            "message": [
                1
            ],
            "id": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "CustomerSelectedAccountingMethod": {},
        "CustomerSource": {},
        "CustomerV2": {
            "id": [
                8
            ],
            "companyId": [
                1
            ],
            "source": [
                1
            ],
            "name": [
                1
            ],
            "email": [
                1
            ],
            "billingAddress": [
                344
            ],
            "shippingAddress": [
                344
            ],
            "addresses": [
                344
            ],
            "__typename": [
                1
            ]
        },
        "CustomSpread": {
            "specificDate": [
                1268
            ],
            "spreadOverTime": [
                1273
            ],
            "__typename": [
                1
            ]
        },
        "CustomSpreadInput": {
            "specificDate": [
                1269
            ],
            "spreadOverTime": [
                1274
            ],
            "__typename": [
                1
            ]
        },
        "DailyAggregatedOwnersDraw": {
            "employeeId": [
                8
            ],
            "employeeName": [
                1
            ],
            "amount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "DailyAggregatedPaymentCheck": {
            "id": [
                8
            ],
            "amount": [
                11
            ],
            "postedAt": [
                361
            ],
            "employeeId": [
                8
            ],
            "employmentType": [
                1054
            ],
            "categoryGroup": [
                1041
            ],
            "isImmaterial": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "DailyAggregatedPayrollData": {
            "date": [
                361
            ],
            "salariesAndWages": [
                11
            ],
            "bonus": [
                11
            ],
            "commission": [
                11
            ],
            "correctionPayment": [
                11
            ],
            "tips": [
                11
            ],
            "holidayPay": [
                11
            ],
            "sickLeave": [
                11
            ],
            "severance": [
                11
            ],
            "ownersDraw": [
                11
            ],
            "vacationPay": [
                11
            ],
            "ficaEmployee": [
                11
            ],
            "ficaEmployer": [
                11
            ],
            "federalEmployee": [
                11
            ],
            "federalEmployer": [
                11
            ],
            "stateAndLocalEmployee": [
                11
            ],
            "stateAndLocalEmployer": [
                11
            ],
            "savingsAndRetirement401kEmployee": [
                11
            ],
            "savingsAndRetirement401kEmployer": [
                11
            ],
            "savingsAndRetirementOtherEmployee": [
                11
            ],
            "savingsAndRetirementOtherEmployer": [
                11
            ],
            "healthEmployee": [
                11
            ],
            "healthEmployer": [
                11
            ],
            "transportationEmployee": [
                11
            ],
            "transportationEmployer": [
                11
            ],
            "otherBenefitsEmployee": [
                11
            ],
            "otherBenefitsEmployer": [
                11
            ],
            "childSupport": [
                11
            ],
            "otherGarnishments": [
                11
            ],
            "otherDeductions": [
                11
            ],
            "grossPay": [
                11
            ],
            "netPay": [
                11
            ],
            "employerCost": [
                11
            ],
            "employmentType": [
                1054
            ],
            "categoryGroup": [
                1041
            ],
            "__typename": [
                1
            ]
        },
        "DailyAggregatedReimbursement": {
            "id": [
                8
            ],
            "amount": [
                11
            ],
            "postedAt": [
                361
            ],
            "employeeId": [
                8
            ],
            "isClearedByNetPayPayment": [
                5
            ],
            "paymentMethod": [
                1
            ],
            "employmentType": [
                1054
            ],
            "categoryGroup": [
                1041
            ],
            "__typename": [
                1
            ]
        },
        "DailyPaymentsByContractor": {
            "contractor": [
                293
            ],
            "payments": [
                11
            ],
            "reimbursements": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "DailyPaymentsByEmployee": {
            "employee": [
                422
            ],
            "netPay": [
                11
            ],
            "grossPay": [
                11
            ],
            "employeeTaxes": [
                11
            ],
            "deductionsAndBenefits": [
                11
            ],
            "reimbursements": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "DangerousTouchLoanInput": {
            "loanId": [
                8
            ],
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "DataSerie": {
            "label": [
                1
            ],
            "values": [
                11
            ],
            "unitGroup": [
                1
            ],
            "valueType": [
                1444
            ],
            "previous": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "Date": {},
        "DateRange": {
            "startTime": [
                361
            ],
            "endTime": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "DateRangeInput": {
            "from": [
                361
            ],
            "to": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "DeadInventoryCogs": {
            "amount": [
                11
            ],
            "quantity": [
                11
            ],
            "monthlyBreakdown": [
                365
            ],
            "__typename": [
                1
            ]
        },
        "DeadInventoryMonthlyBreakdown": {
            "date": [
                361
            ],
            "quantity": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "DeleteAccrualInput": {
            "bankTransactionId": [
                8
            ],
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "DeleteBankAccountsInput": {
            "companyId": [
                1
            ],
            "bankAccountIds": [
                8
            ],
            "userEmail": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteBankConnectionInput": {
            "companyId": [
                8
            ],
            "integrationType": [
                684
            ],
            "integrationAccountId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteBankTransactionAttachmentInput": {
            "companyId": [
                8
            ],
            "bankTransactionId": [
                8
            ],
            "attachmentUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteBankTransactionsByBankAccountIdsInput": {
            "companyId": [
                8
            ],
            "bankAccountIds": [
                8
            ],
            "userEmail": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteCompanyAffiliateInput": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "DeleteHolderCapitalInvestmentInput": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "DeleteHolderShareInput": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "DeleteInventoryPurchaseInput": {
            "companyId": [
                8
            ],
            "id": [
                8
            ],
            "lastUpdatedAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "DeleteInvoicesResponse": {
            "deleted": [
                8
            ],
            "errors": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteLoanAttachmentInput": {
            "companyId": [
                8
            ],
            "loanId": [
                8
            ],
            "attachmentId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "DeleteLoanInput": {
            "loanId": [
                8
            ],
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "DeleteLoanManualAccountingEventsInput": {
            "companyId": [
                8
            ],
            "loanId": [
                8
            ],
            "accountingEventIds": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "DeleteLoanRelationshipsInput": {
            "relationshipIds": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "DeleteLoansEventsEffectConfigurationInput": {
            "id": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "DeleteLoansEventsEffectConfigurationResult": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "DeleteLoansInput": {
            "loans": [
                377
            ],
            "__typename": [
                1
            ]
        },
        "DeleteLoansResults": {
            "loans": [
                787
            ],
            "__typename": [
                1
            ]
        },
        "DeleteManualAssemblyInput": {
            "companyId": [
                8
            ],
            "id": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteManualLoanEventInput": {
            "companyId": [
                8
            ],
            "loanId": [
                8
            ],
            "eventId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "DeleteManualLoanEventsInput": {
            "companyId": [
                8
            ],
            "loanId": [
                8
            ],
            "eventIds": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "DeleteOrderGroupInput": {
            "companyId": [
                8
            ],
            "orderGroupId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "DeleteOrderGroupResponse": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "DeleteOrderGroupUponTransactionChangeInput": {
            "companyId": [
                1
            ],
            "orderGroupId": [
                1
            ],
            "changedBankTransactionId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteOrderGroupUponTransactionChangeResponse": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "DeletePaymentGroupInput": {
            "companyId": [
                8
            ],
            "paymentGroupId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "DeletePaymentGroupResponse": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "DeletePaymentGroupUponTransactionChangeInput": {
            "companyId": [
                1
            ],
            "paymentGroupId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeletePaymentGroupUponTransactionChangeResponse": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "DeletePendingWarehouseOpeningAttachmentInput": {
            "companyId": [
                8
            ],
            "attachmentId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "DeleteProductsInput": {
            "companyId": [
                8
            ],
            "productIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeletePurchaseOrderInput": {
            "companyId": [
                1
            ],
            "id": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteRecipeInput": {
            "companyId": [
                8
            ],
            "recipeId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteWarehouseInput": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteWarehouseOpeningBalancesInput": {
            "companyId": [
                8
            ],
            "date": [
                361
            ],
            "productId": [
                1
            ],
            "warehouseId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DeleteWarehouseTransferInput": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Department": {
            "id": [
                8
            ],
            "company": [
                241
            ],
            "source": [
                1
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DepreciationMethod": {},
        "DirectLineItem": {
            "id": [
                8
            ],
            "product": [
                1071
            ],
            "quantity": [
                33
            ],
            "vendor": [
                1445
            ],
            "indirectCostsAllocation": [
                642
            ],
            "directCostPerUnit": [
                11
            ],
            "indirectCostPerUnit": [
                11
            ],
            "remainderIndirectCostPerUnit": [
                11
            ],
            "discountCostPerUnit": [
                11
            ],
            "remainderDiscountCostPerUnit": [
                11
            ],
            "landedCostPerUnit": [
                11
            ],
            "remainderLandedCostPerUnit": [
                11
            ],
            "directCostTotal": [
                11
            ],
            "indirectCostTotal": [
                11
            ],
            "landedCostTotal": [
                11
            ],
            "discountCostTotal": [
                11
            ],
            "note": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DirectLineItemInput": {
            "id": [
                8
            ],
            "productId": [
                1
            ],
            "quantity": [
                33
            ],
            "vendorId": [
                8
            ],
            "directCostPerUnit": [
                11
            ],
            "indirectCostsAllocation": [
                643
            ],
            "indirectCostPerUnit": [
                11
            ],
            "remainderIndirectCostPerUnit": [
                11
            ],
            "discountCostPerUnit": [
                11
            ],
            "remainderDiscountCostPerUnit": [
                11
            ],
            "landedCostPerUnit": [
                11
            ],
            "remainderLandedCostPerUnit": [
                11
            ],
            "directCostTotal": [
                11
            ],
            "indirectCostTotal": [
                11
            ],
            "landedCostTotal": [
                11
            ],
            "discountCostTotal": [
                11
            ],
            "note": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DiscardPendingWarehouseOpeningBalancesInput": {
            "companyId": [
                8
            ],
            "date": [
                361
            ],
            "warehouseId": [
                1
            ],
            "productId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DisconnectConnectionInput": {
            "companyId": [
                8
            ],
            "integrationType": [
                684
            ],
            "integrationAccountId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Discount": {
            "id": [
                8
            ],
            "type": [
                410
            ],
            "amount": [
                11
            ],
            "description": [
                1
            ],
            "totalAmount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "DiscountInput": {
            "id": [
                8
            ],
            "amount": [
                11
            ],
            "type": [
                410
            ],
            "description": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DiscountType": {},
        "DonationCogs": {
            "amount": [
                11
            ],
            "quantity": [
                33
            ],
            "monthlyBreakdown": [
                412
            ],
            "__typename": [
                1
            ]
        },
        "DonationMonthlyBreakdown": {
            "date": [
                361
            ],
            "quantity": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "DownloadAttachmentInput": {
            "companyId": [
                8
            ],
            "loanId": [
                8
            ],
            "attachmentId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "DownloadAttachmentResult": {
            "fileName": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DownloadExplainFifoInput": {
            "companyId": [
                8
            ],
            "fromDate": [
                361
            ],
            "toDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "DownloadExplainFifoResult": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "DueDateSummary": {
            "count": [
                33
            ],
            "sum": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "DuplicateCheckResult": {},
        "EarlyRepaymentDiscount": {
            "period": [
                33
            ],
            "fee": [
                11
            ],
            "periodType": [
                420
            ],
            "__typename": [
                1
            ]
        },
        "EarlyRepaymentDiscountPeriodType": {},
        "EarlyRepaymentDiscountUpdate": {
            "period": [
                33
            ],
            "fee": [
                11
            ],
            "periodType": [
                420
            ],
            "__typename": [
                1
            ]
        },
        "Employee": {
            "id": [
                8
            ],
            "company": [
                241
            ],
            "source": [
                1
            ],
            "department": [
                402
            ],
            "firstName": [
                1
            ],
            "lastName": [
                1
            ],
            "middleName": [
                1
            ],
            "name": [
                1
            ],
            "isOfficer": [
                5
            ],
            "isTerminated": [
                5
            ],
            "jobs": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "EnrichmentRuleType": {},
        "Error": {
            "message": [
                1
            ],
            "on_CustomerNotExistError": [
                347
            ],
            "on_VendorNotExistError": [
                1451
            ],
            "__typename": [
                1
            ]
        },
        "EstimatedProjectedRevenue": {},
        "EventsLock": {
            "minimumAllowedEventTime": [
                361
            ],
            "lockHistory": [
                261
            ],
            "__typename": [
                1
            ]
        },
        "ExcludedSalesChannelItemReconciliation": {
            "sku": [
                1
            ],
            "isBlacklisted": [
                5
            ],
            "shopifySalesChannel": [
                1
            ],
            "summary": [
                1127
            ],
            "__typename": [
                1
            ]
        },
        "ExplainFifoInput": {
            "companyId": [
                8
            ],
            "productId": [
                1
            ],
            "fromDate": [
                361
            ],
            "toDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "ExplainFifoResult": {
            "warehouseMovements": [
                1476
            ],
            "__typename": [
                1
            ]
        },
        "ExportBalanceSheetApiArgs": {
            "companyId": [
                8
            ],
            "isGoogleExport": [
                5
            ],
            "fromTimeIso": [
                361
            ],
            "toTimeIso": [
                361
            ],
            "noDecimals": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ExportCashFlowApiArgs": {
            "companyId": [
                8
            ],
            "exportArgs": [
                432
            ],
            "__typename": [
                1
            ]
        },
        "ExportCashFlowArgs": {
            "timeRange": [
                1311
            ],
            "fileType": [
                460
            ],
            "groupByTimeWindow": [
                890
            ],
            "includeUDFAccounts": [
                5
            ],
            "includeCCAccounts": [
                5
            ],
            "breakdowns": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ExportCOGSBreakdownRequest": {
            "companyId": [
                8
            ],
            "from": [
                361
            ],
            "to": [
                361
            ],
            "type": [
                238
            ],
            "salesChannel": [
                1161
            ],
            "category": [
                232
            ],
            "sort": [
                490
            ],
            "searchText": [
                1
            ],
            "productIds": [
                1
            ],
            "warehouseIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ExportCOGSBreakdownResponse": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ExportCOGSOverviewRequest": {
            "companyId": [
                8
            ],
            "from": [
                361
            ],
            "to": [
                361
            ],
            "filters": [
                492
            ],
            "__typename": [
                1
            ]
        },
        "ExportCOGSOverviewResponse": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ExportInvoicesResponse": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ExportLedgerAccountTransactionsInput": {
            "companyId": [
                8
            ],
            "exportArgs": [
                446
            ],
            "withBalance": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ExportLoansCsvInput": {
            "companyIds": [
                1
            ],
            "lenderIds": [
                1
            ],
            "vendorIds": [
                1
            ],
            "affiliatedEntityIds": [
                1
            ],
            "updateBy": [
                1
            ],
            "statuses": [
                832
            ],
            "companyTiers": [
                1
            ],
            "owners": [
                1
            ],
            "showOnlyModified": [
                5
            ],
            "userActionStatuses": [
                837
            ],
            "includeDeleted": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ExportLoansResult": {
            "url": [
                1
            ],
            "fileName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ExportProductsResponse": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ExportResponse": {
            "signedUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ExportStatus": {},
        "ExternalBalanceInput": {
            "integrationAccountId": [
                8
            ],
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "Feature": {},
        "FetchLedgerAccountTransactionsInput": {
            "vendorId": [
                1
            ],
            "categoryId": [
                1
            ],
            "classificationTagId": [
                1
            ],
            "categoryName": [
                1
            ],
            "accountName": [
                1
            ],
            "filterByVendor": [
                5
            ],
            "filterByCategory": [
                5
            ],
            "timeRange": [
                915
            ],
            "accountingMethod": [
                1
            ],
            "isCashFlow": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "FifoCogsBusinessEventsMetrics": {
            "type": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "integrationType": [
                1
            ],
            "metrics": [
                448
            ],
            "__typename": [
                1
            ]
        },
        "FifoCogsBusinessEventsSingleMetric": {
            "name": [
                1
            ],
            "fifo": [
                11
            ],
            "isValid": [
                5
            ],
            "be": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "FifoValidationCogsSummary": {
            "name": [
                1
            ],
            "fifoCogs": [
                11
            ],
            "isValidCost": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "FifoValidationInput": {
            "date": [
                361
            ],
            "companyId": [
                8
            ],
            "id": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "FifoValidationProductData": {
            "product": [
                1071
            ],
            "warehouse": [
                1463
            ],
            "baseMetrics": [
                457
            ],
            "cogsMetrics": [
                449
            ],
            "__typename": [
                1
            ]
        },
        "FifoValidationProductTotals": {
            "baseMetrics": [
                457
            ],
            "cogsMetrics": [
                449
            ],
            "__typename": [
                1
            ]
        },
        "FifoValidationProductValidationData": {
            "data": [
                451
            ],
            "totals": [
                452
            ],
            "totalBalance": [
                458
            ],
            "fifoCogsBusinessEventsMetrics": [
                447
            ],
            "fifoCogsBusinessEventsMetricsTotal": [
                448
            ],
            "isUnitsValid": [
                5
            ],
            "isCogsValid": [
                5
            ],
            "isBusinessEventsValid": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "FifoValidationResponse": {
            "id": [
                1
            ],
            "validationData": [
                453
            ],
            "reportLatestDate": [
                361
            ],
            "reportPath": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "FifoValidationsListInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "FifoValidationsListResponse": {
            "id": [
                1
            ],
            "date": [
                361
            ],
            "generatedBy": [
                1
            ],
            "startTime": [
                361
            ],
            "endTime": [
                361
            ],
            "error": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "FifoValidationSummary": {
            "name": [
                1
            ],
            "source": [
                11
            ],
            "sourceCost": [
                11
            ],
            "fifo": [
                11
            ],
            "fifoCost": [
                11
            ],
            "fifoCogs": [
                11
            ],
            "isValidQuantity": [
                5
            ],
            "isValidCost": [
                5
            ],
            "isValidCogs": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "FifoValidationTotalsBalance": {
            "totalIncomingFromSource": [
                11
            ],
            "totalOnHandFromFifo": [
                11
            ],
            "totalCogs": [
                11
            ],
            "totalWaste": [
                11
            ],
            "isValid": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "FileLocation": {
            "name": [
                1
            ],
            "key": [
                1
            ],
            "bankTransactionsUploadId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "FileType": {},
        "FileUploadSource": {},
        "FileUploadUrlResult": {
            "uploadUrl": [
                1
            ],
            "url": [
                1
            ],
            "fileName": [
                1
            ],
            "key": [
                1
            ],
            "bucket": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "FilterOperator": {},
        "FinalizeAskTheUserFlowInput": {
            "companyId": [
                8
            ],
            "bankTransactionId": [
                8
            ],
            "type": [
                76
            ],
            "snapshot": [
                772
            ],
            "__typename": [
                1
            ]
        },
        "FinalizeAskTheUserFlowResult": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "FinaloopProduct": {
            "id": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "FinaloopProductInternalInput": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "FinaloopTagsInput": {
            "id": [
                1
            ],
            "name": [
                1
            ],
            "isNew": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "FixedAssetsData": {
            "usefulLifeStartDate": [
                361
            ],
            "depreciationMethod": [
                403
            ],
            "residualValue": [
                11
            ],
            "section179Deduction": [
                1189
            ],
            "addDepreciationBonus": [
                24
            ],
            "startDate": [
                361
            ],
            "endDate": [
                361
            ],
            "amount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "FixedAssetsDataInput": {
            "usefulLifeStartDate": [
                1
            ],
            "depreciationMethod": [
                403
            ],
            "residualValue": [
                11
            ],
            "section179Deduction": [
                1190
            ],
            "addDepreciationBonus": [
                25
            ],
            "startDate": [
                1
            ],
            "endDate": [
                1
            ],
            "amount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "FloatRange": {
            "min": [
                11
            ],
            "max": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "FulfillLineItemInput": {
            "lineItemId": [
                8
            ],
            "fulfillmentDate": [
                361
            ],
            "quantity": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "FulfillmentAction": {
            "quantity": [
                33
            ],
            "id": [
                1
            ],
            "date": [
                361
            ],
            "type": [
                1
            ],
            "salesChannel": [
                1465
            ],
            "sku": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "FulfillmentMethod": {
            "startDate": [
                361
            ],
            "endDate": [
                361
            ],
            "type": [
                476
            ],
            "__typename": [
                1
            ]
        },
        "FulfillmentMethodInput": {
            "startDate": [
                361
            ],
            "endDate": [
                361
            ],
            "type": [
                476
            ],
            "__typename": [
                1
            ]
        },
        "FulfillmentMethodType": {},
        "FulfillmentSetting": {
            "companyId": [
                8
            ],
            "integrationAccountId": [
                8
            ],
            "methods": [
                474
            ],
            "__typename": [
                1
            ]
        },
        "FulfillmentSettingInput": {
            "companyId": [
                8
            ],
            "integrationAccountIds": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "GenerateBigValidationInput": {
            "companyId": [
                8
            ],
            "to": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "GeneratePdfError": {
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GeneratePdfInput": {
            "companyId": [
                1
            ],
            "html": [
                1
            ],
            "id": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GeneratePdfResponse": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GeneratePdfResponseV2": {
            "on_GeneratePdfResponse": [
                482
            ],
            "on_GeneratePdfError": [
                480
            ],
            "__typename": [
                1
            ]
        },
        "GetAccrualsByTransactionIDsInput": {
            "companyId": [
                8
            ],
            "bankTransactionIds": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "GetAccrualsSpreadInput": {
            "companyId": [
                8
            ],
            "bankTransactionIds": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "GetArtificialTransactionsInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "GetAttachmentTargetURLSInput": {
            "companyId": [
                8
            ],
            "loanId": [
                8
            ],
            "fileNames": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetCOGSBreakdownRequest": {
            "companyId": [
                8
            ],
            "from": [
                361
            ],
            "to": [
                361
            ],
            "type": [
                238
            ],
            "salesChannel": [
                1161
            ],
            "category": [
                232
            ],
            "limit": [
                33
            ],
            "offset": [
                33
            ],
            "sort": [
                490
            ],
            "searchText": [
                1
            ],
            "productIds": [
                1
            ],
            "warehouseIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetCOGSBreakdownResponse": {
            "from": [
                361
            ],
            "to": [
                361
            ],
            "type": [
                238
            ],
            "items": [
                231
            ],
            "totalCount": [
                33
            ],
            "hasNext": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "GetCogsBreakdownSort": {
            "field": [
                491
            ],
            "direction": [
                1094
            ],
            "__typename": [
                1
            ]
        },
        "GetCogsBreakdownSortField": {},
        "GetCOGSOverviewFilters": {
            "source": [
                236
            ],
            "type": [
                238
            ],
            "costType": [
                295
            ],
            "category": [
                232
            ],
            "productIds": [
                1
            ],
            "warehouseIds": [
                1
            ],
            "salesChannel": [
                1161
            ],
            "groupNames": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetCOGSOverviewRequest": {
            "companyId": [
                8
            ],
            "from": [
                361
            ],
            "to": [
                361
            ],
            "filters": [
                492
            ],
            "__typename": [
                1
            ]
        },
        "GetCOGSOverviewResponse": {
            "from": [
                361
            ],
            "to": [
                361
            ],
            "summary": [
                237
            ],
            "summaryEvents": [
                233
            ],
            "__typename": [
                1
            ]
        },
        "GetCompaniesByIdsInput": {
            "companyIds": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "GetCompaniesInput": {
            "payingCompaniesOnly": [
                5
            ],
            "tiers": [
                279
            ],
            "__typename": [
                1
            ]
        },
        "GetCompanyInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "GetCompanyLoanInput": {
            "companyId": [
                8
            ],
            "loanId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "GetCompanyLoansInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "GetCompanyLockConditionsArgs": {
            "companyId": [
                1
            ],
            "year": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "GetCompanyLockConditionsArgsV2": {
            "companyId": [
                1
            ],
            "periodDates": [
                1064
            ],
            "__typename": [
                1
            ]
        },
        "GetCompanyWorksheetsArgs": {
            "companyId": [
                1
            ],
            "year": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "GetCompanyWorksheetsArgsV2": {
            "companyId": [
                1
            ],
            "periodDates": [
                1064
            ],
            "__typename": [
                1
            ]
        },
        "GetCustomerArgs": {
            "searchTerm": [
                1
            ],
            "limit": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "GetCustomerByIdInput": {
            "companyId": [
                8
            ],
            "id": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "GetCustomerInternal": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "GetCustomerResponse": {
            "on_Customer": [
                343
            ],
            "on_CustomerNotExistError": [
                347
            ],
            "on_Error": [
                424
            ],
            "__typename": [
                1
            ]
        },
        "GetFiscalYerDataInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "GetFromEmailAddressError": {
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetFromEmailAddressInput": {
            "companyId": [
                1
            ],
            "entityType": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetFromEmailAddressResponse": {
            "fromAddress": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetFromEmailAddressResult": {
            "on_GetFromEmailAddressResponse": [
                511
            ],
            "on_GetFromEmailAddressError": [
                509
            ],
            "__typename": [
                1
            ]
        },
        "GetFulfillmentSettingInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "GetFulfillmentSettingsResponse": {
            "settings": [
                664
            ],
            "__typename": [
                1
            ]
        },
        "GetGlobalVendorsInput": {
            "ids": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "GetHealthStatusInput": {
            "asOfDate": [
                361
            ],
            "createdAt": [
                361
            ],
            "sort": [
                621
            ],
            "limit": [
                33
            ],
            "offset": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "GetIntegrationTagsInput": {
            "companyId": [
                8
            ],
            "searchText": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetIntegrationTransferInput": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "GetIntegrationTransfersBackofficeInput": {
            "companyId": [
                8
            ],
            "ids": [
                8
            ],
            "timeRange": [
                682
            ],
            "sort": [
                681
            ],
            "type": [
                683
            ],
            "limit": [
                33
            ],
            "integrationType": [
                684
            ],
            "amountRange": [
                66
            ],
            "__typename": [
                1
            ]
        },
        "GetIntegrationTransfersInput": {
            "companyId": [
                8
            ],
            "ids": [
                8
            ],
            "timeRange": [
                682
            ],
            "sort": [
                681
            ],
            "type": [
                683
            ],
            "limit": [
                33
            ],
            "offset": [
                33
            ],
            "integrationType": [
                684
            ],
            "checkAgainstOriginalAmount": [
                5
            ],
            "onlyUnidentified": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryAccountBalanceInput": {
            "companyId": [
                8
            ],
            "pointInTime": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryAccountBalanceResponse": {
            "inventoryAccountBalance": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryPurchasesByIdsInput": {
            "companyId": [
                8
            ],
            "ids": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryPurchasesExportInput": {
            "filters": [
                525
            ],
            "sort": [
                705
            ],
            "managementMethod": [
                851
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryPurchasesFilters": {
            "companyId": [
                8
            ],
            "startDate": [
                361
            ],
            "endDate": [
                361
            ],
            "text": [
                1
            ],
            "status": [
                709
            ],
            "categoryName": [
                694
            ],
            "purchaseType": [
                712
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryPurchasesForCompaniesInput": {
            "companyIds": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryPurchasesForCompanyInput": {
            "companyId": [
                8
            ],
            "historicalPurchasesOnly": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryPurchasesInput": {
            "filters": [
                525
            ],
            "offset": [
                33
            ],
            "limit": [
                33
            ],
            "sort": [
                705
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryPurchaseSummaryInput": {
            "companyId": [
                8
            ],
            "startDate": [
                361
            ],
            "endDate": [
                361
            ],
            "text": [
                1
            ],
            "status": [
                709
            ],
            "purchaseType": [
                712
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryPurchasesValidationResult": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryVendorBillsInput": {
            "companyId": [
                8
            ],
            "affiliatedEntityId": [
                8
            ],
            "fromDate": [
                361
            ],
            "toDate": [
                361
            ],
            "text": [
                1
            ],
            "fromAmount": [
                11
            ],
            "toAmount": [
                11
            ],
            "itemTypes": [
                721
            ],
            "limit": [
                33
            ],
            "offset": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryVendorBillsOverviewInput": {
            "companyId": [
                8
            ],
            "affiliatedEntityId": [
                8
            ],
            "fromDate": [
                361
            ],
            "toDate": [
                361
            ],
            "text": [
                1
            ],
            "fromAmount": [
                11
            ],
            "toAmount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryVendorFilterValuesInput": {
            "companyId": [
                8
            ],
            "untilDate": [
                361
            ],
            "text": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryVendorParamsInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryVendorRollingBalanceInput": {
            "companyId": [
                8
            ],
            "affiliatedEntityId": [
                8
            ],
            "fromDate": [
                361
            ],
            "toDate": [
                361
            ],
            "text": [
                1
            ],
            "fromAmount": [
                11
            ],
            "toAmount": [
                11
            ],
            "skipZeroAmount": [
                5
            ],
            "itemTypes": [
                730
            ],
            "limit": [
                33
            ],
            "offset": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryVendorRollingBalanceOverviewInput": {
            "companyId": [
                8
            ],
            "affiliatedEntityId": [
                8
            ],
            "fromDate": [
                361
            ],
            "toDate": [
                361
            ],
            "text": [
                1
            ],
            "fromAmount": [
                11
            ],
            "toAmount": [
                11
            ],
            "skipZeroAmount": [
                5
            ],
            "itemTypes": [
                730
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryVendorsInput": {
            "companyId": [
                8
            ],
            "text": [
                1
            ],
            "untilDate": [
                361
            ],
            "skipZeroRollingBalance": [
                5
            ],
            "negativeRollingBalance": [
                5
            ],
            "fromRollingBalance": [
                11
            ],
            "toRollingBalance": [
                11
            ],
            "sort": [
                735
            ],
            "offset": [
                33
            ],
            "limit": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "GetInventoryVendorsOverviewInput": {
            "companyId": [
                8
            ],
            "text": [
                1
            ],
            "untilDate": [
                361
            ],
            "skipZeroRollingBalance": [
                5
            ],
            "negativeRollingBalance": [
                5
            ],
            "fromRollingBalance": [
                11
            ],
            "toRollingBalance": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "GetInvoiceAffectingPaymentGroupsInput": {
            "companyId": [
                1
            ],
            "invoiceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetInvoiceAffectingPaymentGroupsResponse": {
            "paymentGroups": [
                747
            ],
            "__typename": [
                1
            ]
        },
        "GetInvoiceSettingsInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "GetInvoicesResult": {
            "invoices": [
                744
            ],
            "__typename": [
                1
            ]
        },
        "GetLoanEventsInput": {
            "companyId": [
                8
            ],
            "loanId": [
                8
            ],
            "filters": [
                807
            ],
            "page": [
                33
            ],
            "size": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "GetLoanEventsLengthInput": {
            "companyId": [
                8
            ],
            "loanId": [
                8
            ],
            "filters": [
                807
            ],
            "__typename": [
                1
            ]
        },
        "GetLoanEventsLengthResult": {
            "length": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "GetLoanEventsResult": {
            "events": [
                805
            ],
            "page": [
                33
            ],
            "size": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "GetLoanRelationshipsResult": {
            "relationships": [
                822
            ],
            "page": [
                33
            ],
            "size": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "GetLoansByIdInput": {
            "loanIds": [
                8
            ],
            "includeDeleted": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "GetLoansForAllocateInput": {
            "companyId": [
                8
            ],
            "lenderId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "GetLoansInput": {
            "page": [
                33
            ],
            "companyIds": [
                1
            ],
            "lenderIds": [
                1
            ],
            "vendorIds": [
                1
            ],
            "affiliatedEntityIds": [
                1
            ],
            "updateBy": [
                1
            ],
            "statuses": [
                832
            ],
            "companyTiers": [
                1
            ],
            "owners": [
                1
            ],
            "showOnlyModified": [
                5
            ],
            "includeDeleted": [
                5
            ],
            "userActionStatuses": [
                837
            ],
            "sort": [
                829
            ],
            "__typename": [
                1
            ]
        },
        "GetLoansOutstandingAmountOnDateInput": {
            "companyId": [
                8
            ],
            "loansIds": [
                8
            ],
            "date": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "GetLoansOutstandingAmountOnDateResult": {
            "loans": [
                819
            ],
            "__typename": [
                1
            ]
        },
        "GetMatchingEffectRuleInput": {
            "companyId": [
                8
            ],
            "loanId": [
                8
            ],
            "eventDefinitionId": [
                8
            ],
            "loanInput": [
                554
            ],
            "__typename": [
                1
            ]
        },
        "GetMatchingEffectRuleLoanInput": {
            "amount": [
                11
            ],
            "postedAt": [
                361
            ],
            "spreadInput": [
                327
            ],
            "memo": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetMatchingEffectRuleResult": {
            "effect": [
                826
            ],
            "memo": [
                1
            ],
            "dailyAccrual": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "GetNonMatchingProductsInput": {
            "companyId": [
                8
            ],
            "fromDate": [
                361
            ],
            "toDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "GetOffersInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "GetOffersResponse": {
            "status": [
                904
            ],
            "offers": [
                903
            ],
            "__typename": [
                1
            ]
        },
        "GetOnboardingStatusesInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "GetOnboardingStatusInput": {
            "companyId": [
                8
            ],
            "scope": [
                906
            ],
            "step": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetOrderFilteringBoundsResult": {
            "finaloopPaymentGateways": [
                1
            ],
            "salesChannels": [
                1
            ],
            "orderTotalP999": [
                11
            ],
            "maxItemsCount": [
                33
            ],
            "hasVoidedOrders": [
                5
            ],
            "hasNotSalesAndCogsOrders": [
                5
            ],
            "hasUncollectibleOrders": [
                5
            ],
            "hasCogsOnlyOrders": [
                5
            ],
            "hasGiveawayOrders": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "GetOrdersAggregationsInput": {
            "filter": [
                564
            ],
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "GetOrdersDateRangeInput": {
            "min": [
                361
            ],
            "max": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "GetOrdersFilter": {
            "productIds": [
                8
            ],
            "tagIds": [
                8
            ],
            "customerIds": [
                8
            ],
            "paymentStatuses": [
                967
            ],
            "finaloopStatus": [
                927
            ],
            "finaloopPaymentGateways": [
                1
            ],
            "salesChannels": [
                1
            ],
            "integrationAccountIds": [
                1
            ],
            "itemsCount": [
                471
            ],
            "totalAmountInHomeCurrency": [
                471
            ],
            "placedDate": [
                563
            ],
            "firstPaymentDate": [
                563
            ],
            "firstRefundDate": [
                563
            ],
            "firstFulfillmentDate": [
                563
            ],
            "unpaidAsOfDate": [
                361
            ],
            "hasRestockedItem": [
                5
            ],
            "allTransactionsMatched": [
                5
            ],
            "fulfillmentStatuses": [
                931
            ],
            "searchText": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetOrdersInput": {
            "companyId": [
                8
            ],
            "filter": [
                564
            ],
            "sort": [
                567
            ],
            "limit": [
                33
            ],
            "offset": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "GetOrdersResultV2": {
            "orders": [
                988
            ],
            "__typename": [
                1
            ]
        },
        "GetOrdersSort": {
            "type": [
                569
            ],
            "direction": [
                568
            ],
            "__typename": [
                1
            ]
        },
        "GetOrdersSortDirection": {},
        "GetOrdersSortType": {},
        "GetPendingWarehouseOpeningBalancesAttachmentsInput": {
            "companyId": [
                8
            ],
            "date": [
                361
            ],
            "displayDeleted": [
                5
            ],
            "limit": [
                33
            ],
            "offset": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "GetPresignedUrlInput": {
            "companyId": [
                1
            ],
            "name": [
                1
            ],
            "year": [
                33
            ],
            "mimeType": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetPresignedUrlInputV2": {
            "companyId": [
                1
            ],
            "name": [
                1
            ],
            "mimeType": [
                1
            ],
            "periodDates": [
                1064
            ],
            "__typename": [
                1
            ]
        },
        "GetProductInput": {
            "companyId": [
                8
            ],
            "productId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetProductsFilter": {
            "searchText": [
                1
            ],
            "sku": [
                1
            ],
            "ids": [
                1
            ],
            "source": [
                1
            ],
            "withoutCostOnly": [
                5
            ],
            "withNegativeOnHandOnly": [
                5
            ],
            "attributes": [
                1078
            ],
            "canEditCurrentAppliedCost": [
                5
            ],
            "onlyPrimaryProducts": [
                5
            ],
            "warehouseId": [
                1
            ],
            "asOfDate": [
                361
            ],
            "startDate": [
                361
            ],
            "notMatchingProducts": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "GetProductsInput": {
            "companyId": [
                8
            ],
            "limit": [
                33
            ],
            "offset": [
                33
            ],
            "sort": [
                576
            ],
            "filter": [
                574
            ],
            "extendedList": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "GetProductsSort": {
            "field": [
                577
            ],
            "direction": [
                1094
            ],
            "__typename": [
                1
            ]
        },
        "GetProductsSortField": {},
        "GetPurchaseOrderInput": {
            "companyId": [
                8
            ],
            "purchaseOrderId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetPurchaseOrdersInput": {
            "companyId": [
                8
            ],
            "purchaseOrderIds": [
                1
            ],
            "returnDeleted": [
                5
            ],
            "searchField": [
                1
            ],
            "productIds": [
                1
            ],
            "status": [
                1108
            ],
            "fromInProgressDate": [
                361
            ],
            "toInProgressDate": [
                361
            ],
            "fromLandedCostTotal": [
                11
            ],
            "toLandedCostTotal": [
                11
            ],
            "sortField": [
                1107
            ],
            "sortDirection": [
                1267
            ],
            "showHistorical": [
                5
            ],
            "limit": [
                33
            ],
            "offset": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "GetPurchaseOrdersOverviewInput": {
            "companyId": [
                8
            ],
            "purchaseOrderIds": [
                1
            ],
            "returnDeleted": [
                5
            ],
            "searchField": [
                1
            ],
            "productIds": [
                1
            ],
            "fromInProgressDate": [
                361
            ],
            "toInProgressDate": [
                361
            ],
            "fromLandedCostTotal": [
                11
            ],
            "toLandedCostTotal": [
                11
            ],
            "showHistorical": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "GetSaleAggregationsInput": {
            "companyId": [
                8
            ],
            "filter": [
                587
            ],
            "__typename": [
                1
            ]
        },
        "GetSaleDateRangeInput": {
            "min": [
                361
            ],
            "max": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "GetSaleIntRangeInput": {
            "min": [
                33
            ],
            "max": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "GetSalesChannelsFilter": {
            "ids": [
                8
            ],
            "source": [
                1
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetSalesChannelsInput": {
            "companyId": [
                8
            ],
            "filter": [
                584
            ],
            "sort": [
                590
            ],
            "limit": [
                33
            ],
            "offset": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "GetSalesChannelTemplatesInput": {
            "filter": [
                584
            ],
            "sort": [
                590
            ],
            "limit": [
                33
            ],
            "offset": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "GetSalesFilter": {
            "ids": [
                8
            ],
            "customerIds": [
                8
            ],
            "excludeCustomerIds": [
                8
            ],
            "withDeleted": [
                5
            ],
            "withoutVoided": [
                5
            ],
            "withoutDrafts": [
                5
            ],
            "salesChannelName": [
                1
            ],
            "search": [
                1
            ],
            "sources": [
                1173
            ],
            "dueInDays": [
                583
            ],
            "total": [
                583
            ],
            "balance": [
                583
            ],
            "netSales": [
                583
            ],
            "postedAt": [
                582
            ],
            "currencies": [
                340
            ],
            "excludeCurrencies": [
                340
            ],
            "paymentMethodIds": [
                8
            ],
            "productIds": [
                8
            ],
            "unpaidAsOfDate": [
                361
            ],
            "statuses": [
                1174
            ],
            "__typename": [
                1
            ]
        },
        "GetSalesInput": {
            "companyId": [
                8
            ],
            "filter": [
                587
            ],
            "sort": [
                590
            ],
            "limit": [
                33
            ],
            "offset": [
                33
            ],
            "balanceAsOfDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "GetSalesResult": {
            "sales": [
                1147
            ],
            "__typename": [
                1
            ]
        },
        "GetSalesSort": {
            "type": [
                592
            ],
            "direction": [
                591
            ],
            "__typename": [
                1
            ]
        },
        "GetSalesSortDirection": {},
        "GetSalesSortType": {},
        "GetTax1099VendorGroupsInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "GetVendorInput": {
            "companyId": [
                8
            ],
            "id": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "GetVendorResponse": {
            "on_Vendor": [
                1445
            ],
            "on_VendorNotExistError": [
                1451
            ],
            "on_Error": [
                424
            ],
            "__typename": [
                1
            ]
        },
        "GetVendorsInput": {
            "companyId": [
                8
            ],
            "ids": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "GetWarehouseOpeningBalanceOverviewInput": {
            "companyId": [
                8
            ],
            "date": [
                361
            ],
            "text": [
                1
            ],
            "warehouseIds": [
                8
            ],
            "missingData": [
                5
            ],
            "pendingChanges": [
                5
            ],
            "attachmentIds": [
                8
            ],
            "productSource": [
                1090
            ],
            "productAttributes": [
                1078
            ],
            "__typename": [
                1
            ]
        },
        "GetWarehouseOpeningBalancesInput": {
            "companyId": [
                8
            ],
            "date": [
                361
            ],
            "productSource": [
                1090
            ],
            "productAttributes": [
                1078
            ],
            "text": [
                1
            ],
            "warehouseIds": [
                8
            ],
            "missingData": [
                5
            ],
            "pendingChanges": [
                5
            ],
            "attachmentIds": [
                8
            ],
            "sort": [
                1483
            ],
            "limit": [
                33
            ],
            "offset": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "GetWarehousesDefaultsInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "GetWarehousesInput": {
            "companyId": [
                8
            ],
            "limit": [
                33
            ],
            "offset": [
                33
            ],
            "searchText": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetWarehouseTransferInput": {
            "companyId": [
                1
            ],
            "id": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GetWarehouseTransfersInput": {
            "filters": [
                240
            ],
            "status": [
                1523
            ],
            "sort": [
                1522
            ],
            "offset": [
                33
            ],
            "limit": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "GiveawayCogs": {
            "amount": [
                11
            ],
            "quantity": [
                33
            ],
            "monthlyBreakdown": [
                604
            ],
            "__typename": [
                1
            ]
        },
        "GiveawayMonthlyBreakdown": {
            "date": [
                361
            ],
            "quantity": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "GlobalRuleExistByVendorAndMoneyDirectionInput": {
            "vendorId": [
                1
            ],
            "moneyDirection": [
                891
            ],
            "__typename": [
                1
            ]
        },
        "GlobalRuleInput": {
            "vendorId": [
                1
            ],
            "metadata": [
                772
            ],
            "outcome": [
                772
            ],
            "conditions": [
                772
            ],
            "logicalOperator": [
                1146
            ],
            "updatedAtMS": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "GlobalVendorMappingRule": {
            "id": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "GoogleConnectionLinkInput": {
            "state": [
                772
            ],
            "__typename": [
                1
            ]
        },
        "GoogleConnectLinkResponse": {
            "link": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GoogleConnectStatusResponse": {
            "isConnected": [
                5
            ],
            "email": [
                1
            ],
            "sharedFolderUrl": [
                1
            ],
            "sharedFolderName": [
                1
            ],
            "accessToken": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GoogleTokenCodeArgs": {
            "companyId": [
                8
            ],
            "code": [
                1
            ],
            "scope": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GoogleTokenCodeResponse": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "GTMSource": {
            "utms": [
                615
            ],
            "identifiers": [
                771
            ],
            "__typename": [
                1
            ]
        },
        "GTMSourceInput": {
            "utms": [
                616
            ],
            "identifiers": [
                771
            ],
            "__typename": [
                1
            ]
        },
        "GTMSourceUtms": {
            "firstUtms": [
                617
            ],
            "lastUtms": [
                617
            ],
            "__typename": [
                1
            ]
        },
        "GTMSourceUtmsInput": {
            "firstUtms": [
                618
            ],
            "lastUtms": [
                618
            ],
            "__typename": [
                1
            ]
        },
        "GTMUtms": {
            "utmTimestamp": [
                1
            ],
            "uuid": [
                1
            ],
            "utmSource": [
                1
            ],
            "utmMedium": [
                1
            ],
            "utmCampaign": [
                1
            ],
            "firstPageSeen": [
                1
            ],
            "utmTerm": [
                1
            ],
            "utmContent": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "GTMUtmsInput": {
            "utmTimestamp": [
                1
            ],
            "uuid": [
                1
            ],
            "utmSource": [
                1
            ],
            "utmMedium": [
                1
            ],
            "utmCampaign": [
                1
            ],
            "firstPageSeen": [
                1
            ],
            "utmTerm": [
                1
            ],
            "utmContent": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "HandleUserLoginArgs": {
            "source": [
                771
            ],
            "invitationCode": [
                1
            ],
            "gtmSource": [
                614
            ],
            "__typename": [
                1
            ]
        },
        "HandleUserLoginResponse": {
            "acceptedInviteCompanyInfo": [
                0
            ],
            "wasCompanyCreated": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "HealthStatusSortType": {
            "validationType": [
                288
            ],
            "direction": [
                264
            ],
            "__typename": [
                1
            ]
        },
        "Holder": {
            "id": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "HolderCapitalInvestment": {
            "id": [
                8
            ],
            "company": [
                241
            ],
            "holder": [
                245
            ],
            "amount": [
                11
            ],
            "createdAt": [
                361
            ],
            "updatedAt": [
                361
            ],
            "deletedAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "HolderCapitalInvestmentsByIdsInput": {
            "ids": [
                8
            ],
            "includeDeleted": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "HolderCapitalInvestmentsInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "HolderShare": {
            "id": [
                8
            ],
            "company": [
                241
            ],
            "holder": [
                245
            ],
            "type": [
                1218
            ],
            "series": [
                1
            ],
            "amount": [
                11
            ],
            "parValue": [
                11
            ],
            "quantity": [
                33
            ],
            "createdAt": [
                361
            ],
            "updatedAt": [
                361
            ],
            "deletedAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "HolderSharesByIdsInput": {
            "ids": [
                8
            ],
            "includeDeleted": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "HolderSharesInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "HolderStatus": {},
        "HolderTransaction": {
            "id": [
                8
            ],
            "company": [
                241
            ],
            "holder": [
                245
            ],
            "bankTransaction": [
                147
            ],
            "type": [
                633
            ],
            "amount": [
                11
            ],
            "postedAt": [
                361
            ],
            "createdAt": [
                361
            ],
            "updatedAt": [
                361
            ],
            "deletedAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "HolderTransactionsByIdsInput": {
            "holderIds": [
                8
            ],
            "includeDeleted": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "HolderTransactionsInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "HolderTransactionType": {},
        "ImageUploadUrlResult": {
            "uploadUrl": [
                1
            ],
            "url": [
                1
            ],
            "iconUrl": [
                1
            ],
            "fileName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ImpersonateArgs": {
            "companyIdToSwitchTo": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ImpersonateResponse": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "IncludedFinaloopSalesChannelItemReconciliation": {
            "salesChannel": [
                768
            ],
            "data": [
                638
            ],
            "summary": [
                1127
            ],
            "productItemSoldData": [
                1249
            ],
            "productStatus": [
                1245
            ],
            "unknownSkuQuantity": [
                33
            ],
            "unknownSkuZeroPriceQuantity": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "IncludedShopifySalesChannelItemReconciliation": {
            "sku": [
                1
            ],
            "isBlacklisted": [
                5
            ],
            "salesChannel": [
                768
            ],
            "shopifySalesChannel": [
                1
            ],
            "summary": [
                1127
            ],
            "__typename": [
                1
            ]
        },
        "IncomingBatchAnalytics": {
            "origin": [
                640
            ],
            "analytics": [
                641
            ],
            "__typename": [
                1
            ]
        },
        "IncomingBatchOrigin": {
            "on_Shipment": [
                1219
            ],
            "on_WarehouseOpeningBalance": [
                1477
            ],
            "on_RecipeWithBreakdown": [
                1121
            ],
            "on_ManualAssemblyWithBreakdown": [
                856
            ],
            "on_WarehouseTransferWithBreakdown": [
                1526
            ],
            "__typename": [
                1
            ]
        },
        "IncomingInventoryAnalytics": {
            "assembled": [
                11
            ],
            "incoming": [
                11
            ],
            "fulfilled": [
                11
            ],
            "warehouseTransfers": [
                11
            ],
            "adjustments": [
                11
            ],
            "restocked": [
                11
            ],
            "onHand": [
                11
            ],
            "landedCostPerUnit": [
                11
            ],
            "onHandCost": [
                11
            ],
            "unavailable": [
                11
            ],
            "unavailableByAssemblies": [
                11
            ],
            "unavailableByWarehouseTransfersOut": [
                11
            ],
            "netDepleted": [
                11
            ],
            "openingBalanceQuantity": [
                11
            ],
            "openingBalanceCost": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "IndirectCostAllocation": {
            "id": [
                8
            ],
            "indirectLineItem": [
                644
            ],
            "allocatedCostPerUnit": [
                11
            ],
            "remainderAllocationCostPerUnit": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "IndirectCostAllocationInput": {
            "indirectLineItemId": [
                8
            ],
            "allocatedCostPerUnit": [
                11
            ],
            "remainderAllocationCostPerUnit": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "IndirectLineItem": {
            "id": [
                8
            ],
            "type": [
                646
            ],
            "cost": [
                11
            ],
            "vendor": [
                1445
            ],
            "isDefaultVendor": [
                5
            ],
            "hasAutomaticAllocation": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "IndirectLineItemInput": {
            "id": [
                8
            ],
            "type": [
                646
            ],
            "cost": [
                11
            ],
            "vendorId": [
                8
            ],
            "isDefaultVendor": [
                5
            ],
            "hasAutomaticAllocation": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "IndirectLineItemType": {},
        "InitiateTotalArCashDiffsReportInput": {
            "unpaidBalanceAsOf": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "Institution": {
            "id": [
                8
            ],
            "name": [
                1
            ],
            "preferredProvider": [
                653
            ],
            "logoUrl": [
                1
            ],
            "websiteUrl": [
                1
            ],
            "affiliatedEntityId": [
                8
            ],
            "csvInstructionsLink": [
                1
            ],
            "pdfInstructionsLink": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InstitutionMapping": {
            "id": [
                8
            ],
            "source": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "externalSourceName": [
                1
            ],
            "institutionId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InstitutionMappingResponse": {
            "mappings": [
                649
            ],
            "__typename": [
                1
            ]
        },
        "InstitutionMappingsInput": {
            "source": [
                652
            ],
            "externalSourceId": [
                1
            ],
            "externalSourceName": [
                1
            ],
            "institutionId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InstitutionMappingSourceType": {},
        "InstitutionProviders": {},
        "InstitutionsInput": {
            "name": [
                1
            ],
            "searchTerm": [
                1
            ],
            "ids": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InstitutionsResponse": {
            "institutions": [
                648
            ],
            "__typename": [
                1
            ]
        },
        "InstitutionVendorID": {
            "vendorId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationCompanyAddressInput": {
            "name": [
                1
            ],
            "email": [
                1
            ],
            "phone": [
                1
            ],
            "line1": [
                1
            ],
            "line2": [
                1
            ],
            "city": [
                1
            ],
            "country": [
                1
            ],
            "province": [
                1
            ],
            "provinceCode": [
                1
            ],
            "zip": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationConnectionAccount": {
            "integrationType": [
                684
            ],
            "connectedAt": [
                361
            ],
            "integrationAccountId": [
                8
            ],
            "integrationAccountName": [
                1
            ],
            "impactOnBooksSince": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationConnectionsFilter": {
            "companyId": [
                8
            ],
            "connectionTypes": [
                660
            ],
            "integrationTypes": [
                684
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationConnectionType": {},
        "IntegrationCustomerAddressInput": {
            "line1": [
                1
            ],
            "line2": [
                1
            ],
            "city": [
                1
            ],
            "country": [
                1
            ],
            "countryCode": [
                1
            ],
            "province": [
                1
            ],
            "provinceCode": [
                1
            ],
            "zip": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationCustomerInput": {
            "name": [
                1
            ],
            "email": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationDiscountInput": {
            "id": [
                8
            ],
            "amount": [
                11
            ],
            "type": [
                410
            ],
            "description": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationFulfillmentSetting": {
            "companyId": [
                8
            ],
            "integrationAccountId": [
                8
            ],
            "source": [
                684
            ],
            "methods": [
                474
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationFulfillmentSettingsInput": {
            "integrationAccountId": [
                1
            ],
            "methods": [
                475
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationInfo": {
            "accountId": [
                8
            ],
            "accountName": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationSaleLineItemInput": {
            "id": [
                8
            ],
            "quantity": [
                11
            ],
            "unitPrice": [
                11
            ],
            "type": [
                1155
            ],
            "currency": [
                340
            ],
            "salesChannelName": [
                1
            ],
            "productId": [
                8
            ],
            "sku": [
                1
            ],
            "description": [
                1
            ],
            "discounts": [
                409
            ],
            "taxRate": [
                11
            ],
            "externalSourceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationSaleTransaction": {
            "id": [
                8
            ],
            "integrationInfo": [
                666
            ],
            "amount": [
                11
            ],
            "postedAt": [
                361
            ],
            "currency": [
                340
            ],
            "amountInHomeCurrency": [
                11
            ],
            "type": [
                1321
            ],
            "finaloopPaymentGateway": [
                1
            ],
            "originalPaymentGateway": [
                1
            ],
            "paymentGatewayVariant": [
                1
            ],
            "isIndication": [
                5
            ],
            "source": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationSource": {},
        "IntegrationTransactionInput": {
            "postedAt": [
                361
            ],
            "amount": [
                11
            ],
            "currency": [
                340
            ],
            "amountInHomeCurrency": [
                11
            ],
            "externalSourceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransfer": {
            "id": [
                8
            ],
            "companyId": [
                1
            ],
            "amount": [
                11
            ],
            "amountInUsd": [
                11
            ],
            "currency": [
                1
            ],
            "eventTime": [
                361
            ],
            "deletedAt": [
                361
            ],
            "integrationType": [
                684
            ],
            "pairedBankTransaction": [
                147
            ],
            "pairedCompanyAffiliate": [
                245
            ],
            "type": [
                683
            ],
            "classification": [
                672
            ],
            "integrationAccountId": [
                8
            ],
            "integrationAccountName": [
                1
            ],
            "link": [
                1
            ],
            "uploadedFileId": [
                1
            ],
            "on_Payout": [
                1039
            ],
            "on_Topup": [
                1315
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransferClassification": {
            "businessEvent": [
                1
            ],
            "askTheUserResult": [
                772
            ],
            "description": [
                1
            ],
            "pairedEntity": [
                675
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransferClassificationInput": {
            "integrationTransferId": [
                8
            ],
            "businessEvent": [
                1
            ],
            "askTheUserResult": [
                772
            ],
            "description": [
                1
            ],
            "pairedEntity": [
                676
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransferGroup": {
            "groupId": [
                8
            ],
            "integrationTransfers": [
                671
            ],
            "payer": [
                678
            ],
            "payee": [
                678
            ],
            "isProcessing": [
                5
            ],
            "action": [
                1331
            ],
            "type": [
                683
            ],
            "tooltipText": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransferPairedEntity": {
            "id": [
                8
            ],
            "type": [
                677
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransferPairedEntityInput": {
            "id": [
                8
            ],
            "type": [
                677
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransferPairedEntityType": {},
        "IntegrationTransferParty": {
            "on_UnknownIntegrationTransferBank": [
                1333
            ],
            "on_UnknownIntegrationTransferAccount": [
                1332
            ],
            "on_Vendor": [
                1445
            ],
            "on_BankAccount": [
                95
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransferRule": {
            "id": [
                8
            ],
            "integrationType": [
                684
            ],
            "companyAffiliate": [
                245
            ],
            "integrationAccountId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransfersByCompanyAffiliateInput": {
            "companyId": [
                8
            ],
            "companyAffiliateId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransfersSort": {
            "closeToAmount": [
                11
            ],
            "closeToDate": [
                361
            ],
            "byDate": [
                1267
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransferTimeRangeInput": {
            "from": [
                361
            ],
            "to": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "IntegrationTransferType": {},
        "IntegrationType": {},
        "InternalTransferCandidatesInput": {
            "companyId": [
                8
            ],
            "bankTransactionId": [
                8
            ],
            "allowNotExactMatch": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "internalTransferCandidatesV2Input": {
            "companyId": [
                8
            ],
            "bankTransactionId": [
                8
            ],
            "rangePercentageAmount": [
                11
            ],
            "allowNotExactDateMatch": [
                5
            ],
            "sort": [
                168
            ],
            "limit": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "InventoryBatch": {
            "action": [
                1464
            ],
            "quantity": [
                33
            ],
            "directCost": [
                11
            ],
            "indirectCosts": [
                895
            ],
            "__typename": [
                1
            ]
        },
        "InventoryInitStatus": {
            "areProductsLoaded": [
                5
            ],
            "isSalesDataLoaded": [
                5
            ],
            "isDataReconciled": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchase": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "source": [
                702
            ],
            "sourceEntity": [
                703
            ],
            "bankTransactionId": [
                8
            ],
            "billId": [
                8
            ],
            "amazonShippingInBusinessEventId": [
                8
            ],
            "payrollId": [
                8
            ],
            "sourceDescription": [
                1
            ],
            "direction": [
                696
            ],
            "dates": [
                362
            ],
            "affiliatedEntity": [
                36
            ],
            "currency": [
                1
            ],
            "amount": [
                11
            ],
            "amountInHomeCurrency": [
                11
            ],
            "historicalPurchaseAffectingMethodId": [
                8
            ],
            "categories": [
                692
            ],
            "items": [
                698
            ],
            "paymentStatus": [
                700
            ],
            "canceledAt": [
                361
            ],
            "lastUpdatedAt": [
                361
            ],
            "attachments": [
                690
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseAttachment": {
            "filename": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseAttachmentInput": {
            "filename": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseCategory": {
            "serialNumber": [
                33
            ],
            "categoryName": [
                694
            ],
            "purchaseType": [
                712
            ],
            "amount": [
                11
            ],
            "amountInHomeCurrency": [
                11
            ],
            "classificationTag": [
                225
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseCategoryInput": {
            "serialNumber": [
                33
            ],
            "categoryName": [
                694
            ],
            "purchaseType": [
                712
            ],
            "amount": [
                11
            ],
            "classificationTagId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseCategoryName": {},
        "InventoryPurchaseCategorySummary": {
            "categoryName": [
                694
            ],
            "purchasedAmountInHomeCurrency": [
                11
            ],
            "incidentalsAmountInHomeCurrency": [
                11
            ],
            "receivedAmountInHomeCurrency": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseDirection": {},
        "InventoryPurchaseInput": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "sourceDescription": [
                1
            ],
            "direction": [
                696
            ],
            "dates": [
                363
            ],
            "vendorId": [
                8
            ],
            "affiliatedEntityId": [
                8
            ],
            "currency": [
                1
            ],
            "amount": [
                11
            ],
            "categories": [
                693
            ],
            "items": [
                699
            ],
            "paymentStatus": [
                700
            ],
            "lastUpdatedAt": [
                361
            ],
            "attachments": [
                691
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseItem": {
            "amount": [
                11
            ],
            "shipmentDate": [
                361
            ],
            "receivedDate": [
                361
            ],
            "purchaseDate": [
                361
            ],
            "note": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseItemInput": {
            "amount": [
                11
            ],
            "shipmentDate": [
                361
            ],
            "receivedDate": [
                361
            ],
            "purchaseDate": [
                361
            ],
            "note": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchasePaymentStatus": {},
        "InventoryPurchasesExportResponse": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseSource": {},
        "InventoryPurchaseSourceEntity": {
            "on_BankTransaction": [
                147
            ],
            "on_Bill": [
                186
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchasesResponse": {
            "purchases": [
                689
            ],
            "hasNext": [
                5
            ],
            "totalCount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchasesSort": {
            "field": [
                706
            ],
            "direction": [
                707
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchasesSortByField": {},
        "InventoryPurchasesSortDirection": {},
        "InventoryPurchasesSummaryResponse": {
            "summary": [
                695
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseStatus": {},
        "InventoryPurchasesValidationResult": {
            "checkTime": [
                361
            ],
            "expectedInProcessBalance": [
                11
            ],
            "expectedInTransitBalance": [
                11
            ],
            "actualInProcessBalance": [
                11
            ],
            "actualInTransitBalance": [
                11
            ],
            "clearingAccountBalance": [
                11
            ],
            "missingExpectedPurchaseIds": [
                8
            ],
            "unexpectedPurchaseIds": [
                8
            ],
            "missingExpectedBusinessEventsForPurchaseIds": [
                8
            ],
            "unexpectedBusinessEventIds": [
                8
            ],
            "status": [
                711
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchasesValidationStatus": {},
        "InventoryPurchaseType": {},
        "InventoryPurchaseUpdateOutOfDateError": {
            "inventoryPurchase": [
                689
            ],
            "__typename": [
                1
            ]
        },
        "InventoryPurchaseValidationResultResponse": {
            "lastValidationResultCreatedAt": [
                361
            ],
            "result": [
                710
            ],
            "__typename": [
                1
            ]
        },
        "InventorySettings": {
            "companyId": [
                8
            ],
            "managementMethod": [
                1
            ],
            "rangedManagementMethod": [
                1110
            ],
            "isEligibleForNoClosingBalancePurchaseBased": [
                5
            ],
            "openingBalance": [
                11
            ],
            "latestOpeningBalanceBusinessEventId": [
                1
            ],
            "closingBalances": [
                772
            ],
            "latestClosingBalanceBusinessEventIds": [
                1
            ],
            "realtimeCogsStatus": [
                1112
            ],
            "hasUserReviewedProducts": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "InventoryUsageType": {},
        "InventoryVendor": {
            "affiliatedEntity": [
                36
            ],
            "rollingBalance": [
                11
            ],
            "unpaidBills": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorAdjustment": {
            "date": [
                361
            ],
            "amount": [
                11
            ],
            "bill": [
                186
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorBillItem": {
            "amount": [
                11
            ],
            "balance": [
                11
            ],
            "paidAmount": [
                11
            ],
            "source": [
                720
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorBillItemSource": {
            "on_InventoryPurchase": [
                689
            ],
            "on_InventoryVendorPayment": [
                727
            ],
            "on_InventoryVendorCredit": [
                724
            ],
            "on_InventoryVendorAdjustment": [
                718
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorBillItemType": {},
        "InventoryVendorBillsOverviewResponse": {
            "billsAmount": [
                11
            ],
            "billPayments": [
                11
            ],
            "billCredits": [
                11
            ],
            "billAdjustments": [
                11
            ],
            "billsPurchasesAmount": [
                11
            ],
            "billsIncidentalPurchasesAmount": [
                11
            ],
            "count": [
                33
            ],
            "minAmount": [
                11
            ],
            "maxAmount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorBillsResponse": {
            "hasNext": [
                5
            ],
            "count": [
                33
            ],
            "items": [
                719
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorCredit": {
            "date": [
                361
            ],
            "amount": [
                11
            ],
            "bill": [
                186
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorFilterValuesResponse": {
            "maxRollingBalance": [
                11
            ],
            "minRollingBalance": [
                11
            ],
            "maxUnpaidBills": [
                11
            ],
            "minUnpaidBills": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorParamsResponse": {
            "inventoryVendorBillsCount": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorPayment": {
            "date": [
                361
            ],
            "amount": [
                11
            ],
            "bill": [
                186
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorRollingBalanceItem": {
            "amount": [
                11
            ],
            "rollingBalance": [
                11
            ],
            "source": [
                729
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorRollingBalanceItemSource": {
            "on_PurchaseOrder": [
                1100
            ],
            "on_InventoryPurchase": [
                689
            ],
            "on_WarehouseTransfer": [
                1496
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorRollingBalanceItemTypes": {},
        "InventoryVendorRollingBalanceOverviewResponse": {
            "affiliatedEntity": [
                36
            ],
            "purchaseOrdersBalance": [
                11
            ],
            "warehouseTransfersBalance": [
                11
            ],
            "paymentsBalance": [
                11
            ],
            "billsBalance": [
                11
            ],
            "count": [
                33
            ],
            "minAmount": [
                11
            ],
            "maxAmount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorRollingBalanceResponse": {
            "hasNext": [
                5
            ],
            "items": [
                728
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorsOverviewResponse": {
            "count": [
                33
            ],
            "negativeRollingBalanceCount": [
                33
            ],
            "rollingBalance": [
                11
            ],
            "unpaidBills": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorsResponse": {
            "vendors": [
                717
            ],
            "hasNext": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorsSort": {
            "field": [
                736
            ],
            "direction": [
                1267
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorsSortField": {},
        "InventoryVendorValidationError": {
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorValidationResult": {
            "id": [
                8
            ],
            "totalsResult": [
                740
            ],
            "vendorsResults": [
                739
            ],
            "nonMatchingUnpaidBills": [
                901
            ],
            "emptyVendorInventoryPurchases": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorValidationResultItem": {
            "affiliatedEntity": [
                36
            ],
            "rollingBalanceTotalAmount": [
                11
            ],
            "rollingBalanceListAmount": [
                11
            ],
            "rollingBalanceOverviewAmount": [
                11
            ],
            "unpaidBillsTotalAmount": [
                11
            ],
            "unpaidBillsListAmount": [
                11
            ],
            "unpaidBillsOverviewAmount": [
                11
            ],
            "rollingBalancePassed": [
                5
            ],
            "unpaidBillsPassed": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "InventoryVendorValidationTotalsResult": {
            "rollingBalanceOverviewAmount": [
                11
            ],
            "rollingBalanceListAmount": [
                11
            ],
            "rollingBalanceBooksAmount": [
                11
            ],
            "rollingBalanceDbAmount": [
                11
            ],
            "unpaidBillsOverviewAmount": [
                11
            ],
            "unpaidBillsIncidentalsAmount": [
                11
            ],
            "unpaidBillsBooksAmount": [
                11
            ],
            "unpaidBillsListAmount": [
                11
            ],
            "unpaidBillsDbAmount": [
                11
            ],
            "rollingBalancePassed": [
                5
            ],
            "unpaidBillsPassed": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "InvitationStatus": {},
        "InviteUserArgs": {
            "email": [
                1
            ],
            "roles": [
                1
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InviteUserResponse": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "Invoice": {
            "id": [
                8
            ],
            "dueDate": [
                361
            ],
            "dueInDays": [
                33
            ],
            "companyAddress": [
                243
            ],
            "currentPdf": [
                753
            ],
            "pdfs": [
                753
            ],
            "paymentMethod": [
                1031
            ],
            "paymentMethods": [
                1031
            ],
            "saleType": [
                1
            ],
            "companyId": [
                8
            ],
            "source": [
                1173
            ],
            "externalSourceUrl": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "integrationAccountId": [
                8
            ],
            "integrationAccountName": [
                1
            ],
            "currency": [
                340
            ],
            "displayName": [
                1
            ],
            "statuses": [
                1174
            ],
            "postedAt": [
                361
            ],
            "createdAt": [
                361
            ],
            "updatedAt": [
                361
            ],
            "deletedAt": [
                361
            ],
            "publishedAt": [
                361
            ],
            "lineItems": [
                1152
            ],
            "shipping": [
                1228
            ],
            "discounts": [
                408
            ],
            "taxes": [
                1308
            ],
            "taxFixedAmount": [
                11
            ],
            "adjustments": [
                1149
            ],
            "payments": [
                1176
            ],
            "credits": [
                1176
            ],
            "refunds": [
                1176
            ],
            "paymentIndications": [
                1176
            ],
            "refundIndications": [
                1176
            ],
            "customer": [
                350
            ],
            "customerId": [
                8
            ],
            "shippingAddress": [
                344
            ],
            "billingAddress": [
                344
            ],
            "integrationTags": [
                1
            ],
            "integrationNote": [
                1
            ],
            "finaloopNote": [
                1
            ],
            "customerNote": [
                1
            ],
            "accounting": [
                4
            ],
            "voidedAt": [
                361
            ],
            "voidedBy": [
                1148
            ],
            "summary": [
                1175
            ],
            "__typename": [
                1
            ]
        },
        "InvoiceAdjustmentInput": {
            "invoiceId": [
                8
            ],
            "companyId": [
                8
            ],
            "adjustmentId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "InvoiceInput": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "balanceAsOfDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "InvoicePaymentGroup": {
            "id": [
                8
            ],
            "companyId": [
                1
            ],
            "postedAt": [
                361
            ],
            "createdAt": [
                361
            ],
            "updatedAt": [
                361
            ],
            "deletedAt": [
                361
            ],
            "invoices": [
                1012
            ],
            "bankTransactions": [
                1011
            ],
            "expenses": [
                1006
            ],
            "__typename": [
                1
            ]
        },
        "InvoicePaymentGroupByIdResponse": {
            "paymentGroup": [
                747
            ],
            "__typename": [
                1
            ]
        },
        "InvoicePaymentGroupsByIdsResponse": {
            "paymentGroups": [
                747
            ],
            "__typename": [
                1
            ]
        },
        "InvoicePaymentGroupsResponse": {
            "paymentGroups": [
                747
            ],
            "__typename": [
                1
            ]
        },
        "InvoicePaymentGroupsUnclearedBalanceResponse": {
            "asOfDate": [
                361
            ],
            "unclearedBalances": [
                752
            ],
            "__typename": [
                1
            ]
        },
        "InvoicePaymentGroupUnclearedBalance": {
            "paymentGroup": [
                747
            ],
            "unclearedBalance": [
                68
            ],
            "__typename": [
                1
            ]
        },
        "InvoicePdf": {
            "id": [
                8
            ],
            "location": [
                1
            ],
            "createdAt": [
                361
            ],
            "sentAt": [
                361
            ],
            "issuedAt": [
                361
            ],
            "isDraft": [
                5
            ],
            "isVoided": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "InvoicePdfInput": {
            "companyId": [
                8
            ],
            "invoiceId": [
                8
            ],
            "invoicePdfId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "InvoicePdfSentInput": {
            "companyId": [
                8
            ],
            "invoicePdfId": [
                8
            ],
            "invoiceId": [
                8
            ],
            "sentAtStr": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "InvoiceRefundInput": {
            "invoiceId": [
                8
            ],
            "companyId": [
                8
            ],
            "refundId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "InvoiceRefundOrAdjustmentInput": {
            "invoiceId": [
                8
            ],
            "companyId": [
                8
            ],
            "refundId": [
                8
            ],
            "adjustmentId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "InvoicesAggregations": {
            "invoicesCount": [
                33
            ],
            "invoicesAverageValue": [
                11
            ],
            "invoicesNetSales": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "InvoicesBalance": {
            "balance": [
                11
            ],
            "count": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "InvoiceSettings": {
            "logoImageLocation": [
                1
            ],
            "currentAutoInvoiceNumber": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "InvoicesInput": {
            "companyId": [
                8
            ],
            "balanceAsOfDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "IPaymentGroup": {
            "id": [
                8
            ],
            "companyId": [
                1
            ],
            "postedAt": [
                361
            ],
            "createdAt": [
                361
            ],
            "updatedAt": [
                361
            ],
            "deletedAt": [
                361
            ],
            "on_InvoicePaymentGroup": [
                747
            ],
            "on_OrderPaymentGroup": [
                961
            ],
            "__typename": [
                1
            ]
        },
        "IrrelevantType": {},
        "IsCompanyFulfillmentSettingsAppliedInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "IsGlobalRuleExistResult": {
            "exists": [
                5
            ],
            "id": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "IsInvitationCodeForRegisteredUserArgs": {
            "invitationCode": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "IsInvitationCodeForRegisteredUserResponse": {
            "isRegisteredUser": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ItemReconciliationFinaloopSalesChannel": {
            "displayName": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "JournalEntryActions": {
            "firstAccount": [
                18
            ],
            "secondAccount": [
                18
            ],
            "__typename": [
                1
            ]
        },
        "JournalEntryActionsInput": {
            "firstAccount": [
                19
            ],
            "secondAccount": [
                19
            ],
            "__typename": [
                1
            ]
        },
        "JSON": {},
        "JSONObject": {},
        "LastAccountingReportSyncInput": {
            "companyId": [
                8
            ],
            "reportType": [
                1143
            ],
            "__typename": [
                1
            ]
        },
        "LastAccountingReportSyncResponse": {
            "status": [
                1142
            ],
            "exportedAt": [
                361
            ],
            "fileUrl": [
                1
            ],
            "fileName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "LastBigValidationInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "LenderFromCompanyAffiliateInput": {
            "companyId": [
                8
            ],
            "affiliateId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "LenderFromVendorInput": {
            "companyId": [
                8
            ],
            "vendorId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "LinkedBankTransaction": {
            "bankTransaction": [
                147
            ],
            "amount": [
                68
            ],
            "__typename": [
                1
            ]
        },
        "LinkedOrder": {
            "order": [
                988
            ],
            "amountInHomeCurrency": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "LinkInvoicePaymentGroupInput": {
            "companyId": [
                8
            ],
            "postedAt": [
                361
            ],
            "invoices": [
                1009
            ],
            "bankTransactions": [
                1004
            ],
            "expenses": [
                1007
            ],
            "__typename": [
                1
            ]
        },
        "LinkInvoicePaymentGroupResponse": {
            "paymentGroup": [
                747
            ],
            "__typename": [
                1
            ]
        },
        "LinkOrderGroupInput": {
            "companyId": [
                8
            ],
            "postedAt": [
                361
            ],
            "orders": [
                983
            ],
            "bankTransactions": [
                177
            ],
            "expenses": [
                936
            ],
            "__typename": [
                1
            ]
        },
        "LinkOrderGroupResponse": {
            "orderGroup": [
                932
            ],
            "__typename": [
                1
            ]
        },
        "LinkOrderPaymentGroupInput": {
            "companyId": [
                8
            ],
            "postedAt": [
                361
            ],
            "orders": [
                1018
            ],
            "bankTransactions": [
                1004
            ],
            "expenses": [
                1007
            ],
            "__typename": [
                1
            ]
        },
        "LinkOrderPaymentGroupResponse": {
            "paymentGroup": [
                961
            ],
            "__typename": [
                1
            ]
        },
        "ListCompaniesInput": {
            "withArchivedCompanies": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "Loan": {
            "id": [
                8
            ],
            "company": [
                241
            ],
            "source": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "latestActorType": [
                22
            ],
            "latestActorId": [
                1
            ],
            "type": [
                833
            ],
            "lender": [
                898
            ],
            "currency": [
                340
            ],
            "postedAt": [
                361
            ],
            "direction": [
                804
            ],
            "internalNote": [
                1
            ],
            "isShortTerm": [
                5
            ],
            "amount": [
                11
            ],
            "totalAmount": [
                11
            ],
            "amountInHomeCurrency": [
                11
            ],
            "outstandingAmount": [
                11
            ],
            "accruedInterest": [
                11
            ],
            "unrecognizedFees": [
                11
            ],
            "prepaymentLiabilityAmount": [
                11
            ],
            "status": [
                830
            ],
            "userActionStatus": [
                837
            ],
            "treatmentStatus": [
                832
            ],
            "acknowledgedInternallyAt": [
                361
            ],
            "acknowledgedInternallyBy": [
                1
            ],
            "paidOffAt": [
                361
            ],
            "lastManuallyHandledAt": [
                361
            ],
            "lastManuallyHandledBy": [
                1
            ],
            "attachments": [
                792
            ],
            "createdBy": [
                1
            ],
            "createdById": [
                1
            ],
            "workingPaperLink": [
                1
            ],
            "internalOwner": [
                1
            ],
            "updatedBy": [
                1
            ],
            "lastSeenByClientAt": [
                361
            ],
            "deletedAt": [
                361
            ],
            "createdAt": [
                361
            ],
            "updatedAt": [
                361
            ],
            "restoredAt": [
                361
            ],
            "directionSource": [
                820
            ],
            "merchantSource": [
                820
            ],
            "postedAtSource": [
                820
            ],
            "amountSource": [
                820
            ],
            "interestDays": [
                811
            ],
            "repaymentType": [
                824
            ],
            "repaymentFrequency": [
                823
            ],
            "repaymentRate": [
                11
            ],
            "interestRateType": [
                812
            ],
            "interestRate": [
                11
            ],
            "gracePeriod": [
                33
            ],
            "catchUpPeriod": [
                33
            ],
            "catchUpPercent": [
                11
            ],
            "originationFees": [
                11
            ],
            "prepaymentFees": [
                11
            ],
            "dataRequestType": [
                803
            ],
            "recurringDataFrequency": [
                821
            ],
            "instantRepaymentMethod": [
                810
            ],
            "isIndexLinked": [
                5
            ],
            "interestType": [
                813
            ],
            "variableInterestUpdatePeriod": [
                840
            ],
            "variableInterestSpread": [
                11
            ],
            "variableInterestBase": [
                839
            ],
            "compoundFrequency": [
                800
            ],
            "indexType": [
                809
            ],
            "modifiedFields": [
                1
            ],
            "manualEventsGroup": [
                815
            ],
            "billPaymentEvents": [
                795
            ],
            "isEarlyRepaymentDiscount": [
                5
            ],
            "earlyRepaymentDiscounts": [
                419
            ],
            "userFollowUp2": [
                1428
            ],
            "pendingDelete": [
                5
            ],
            "pendingRestoreAcknowledgement": [
                5
            ],
            "lastUserMoveToProcessingAt": [
                361
            ],
            "isHidden": [
                5
            ],
            "firstEventDate": [
                361
            ],
            "isBillPaymentEventAttached": [
                5
            ],
            "accountingEvents": [
                788
            ],
            "__typename": [
                1
            ]
        },
        "LoanAccountingEvent": {
            "id": [
                8
            ],
            "amount": [
                11
            ],
            "postedAt": [
                361
            ],
            "memo": [
                1
            ],
            "businessEventActionsConfiguration": [
                196
            ],
            "customTags": [
                772
            ],
            "latestActorType": [
                22
            ],
            "latestActorId": [
                1
            ],
            "updatedAt": [
                361
            ],
            "createdAt": [
                361
            ],
            "businessEventData": [
                797
            ],
            "__typename": [
                1
            ]
        },
        "LoanAccountRoleEffectDirection": {},
        "LoanAlreadyExistsResult": {
            "loan": [
                787
            ],
            "__typename": [
                1
            ]
        },
        "LoanAmountEffect": {},
        "LoanAttachment": {
            "id": [
                8
            ],
            "name": [
                1
            ],
            "createdAt": [
                361
            ],
            "type": [
                793
            ],
            "__typename": [
                1
            ]
        },
        "LoanAttachmentType": {},
        "LoanBillEvent": {
            "id": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "LoanBillPaymentEvent": {
            "id": [
                8
            ],
            "bill": [
                186
            ],
            "paymentId": [
                8
            ],
            "postedAt": [
                361
            ],
            "amount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "LoanBusinessEvent": {
            "id": [
                8
            ],
            "postedAt": [
                361
            ],
            "eventType": [
                1
            ],
            "memo": [
                1
            ],
            "actions": [
                772
            ],
            "integrationAccountId": [
                1
            ],
            "amount": [
                11
            ],
            "isMigrationAdjustment": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "LoanBusinessEventData": {
            "id": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "LoanComment": {
            "id": [
                8
            ],
            "text": [
                1
            ],
            "createdAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "LoanCommentInput": {
            "id": [
                8
            ],
            "text": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "LoanCompoundFrequency": {},
        "LoanCreationFailureResult": {
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "LoanCreationResult": {
            "on_LoanUpdatedResult": [
                836
            ],
            "on_LoanCreationFailureResult": [
                801
            ],
            "on_LoanAlreadyExistsResult": [
                790
            ],
            "on_LoanTransactionProcessedOnlineResult": [
                831
            ],
            "__typename": [
                1
            ]
        },
        "LoanDataRequestType": {},
        "LoanDirection": {},
        "LoanEvent": {
            "on_LoanManualEvent": [
                815
            ],
            "on_BankTransaction": [
                147
            ],
            "on_LoanBillEvent": [
                794
            ],
            "on_LoanBusinessEvent": [
                796
            ],
            "on_LoanBillPaymentEvent": [
                795
            ],
            "on_Payout": [
                1039
            ],
            "on_Topup": [
                1315
            ],
            "on_IntegrationTransfer": [
                671
            ],
            "__typename": [
                1
            ]
        },
        "LoanEventDefinition": {
            "id": [
                8
            ],
            "name": [
                1
            ],
            "isSpreadable": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "LoanEventFilter": {},
        "LoanEventRecurrence": {},
        "LoanIndexType": {},
        "LoanInstantRepaymentMethod": {},
        "LoanInterestDays": {},
        "LoanInterestRateType": {},
        "LoanInterestType": {},
        "LoanLenderType": {},
        "LoanManualEvent": {
            "id": [
                8
            ],
            "eventDefinition": [
                806
            ],
            "amount": [
                11
            ],
            "postedAt": [
                361
            ],
            "memo": [
                1
            ],
            "recurrenceSettings": [
                1130
            ],
            "spreadData": [
                816
            ],
            "latestActorType": [
                22
            ],
            "latestActorId": [
                1
            ],
            "updatedAt": [
                361
            ],
            "createdAt": [
                361
            ],
            "effect": [
                826
            ],
            "accountingEvents": [
                788
            ],
            "__typename": [
                1
            ]
        },
        "LoanManualEventSpreadData": {
            "startDate": [
                361
            ],
            "endDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "LoanMerchantInput": {
            "id": [
                8
            ],
            "type": [
                879
            ],
            "__typename": [
                1
            ]
        },
        "LoanOutstandingAmountAffect": {},
        "LoanOutstandingAmountOnDate": {
            "loanId": [
                8
            ],
            "outstandingAmount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "LoanPropertySource": {},
        "LoanRecurringDataFrequency": {},
        "LoanRelationship": {
            "id": [
                8
            ],
            "isManuallyAttached": [
                5
            ],
            "event": [
                805
            ],
            "__typename": [
                1
            ]
        },
        "LoanRepaymentFrequency": {},
        "LoanRepaymentType": {},
        "LoansByIdsResponse": {
            "loans": [
                787
            ],
            "__typename": [
                1
            ]
        },
        "LoansEventsEffectConfiguration": {
            "id": [
                8
            ],
            "event": [
                806
            ],
            "loanType": [
                833
            ],
            "isShortTerm": [
                5
            ],
            "direction": [
                804
            ],
            "minGracePeriod": [
                33
            ],
            "repaymentType": [
                824
            ],
            "lender": [
                898
            ],
            "memoTemplate": [
                1
            ],
            "dateTemplate": [
                1
            ],
            "businessEventActionsConfiguration": [
                196
            ],
            "loanOutstandingAmountAffect": [
                818
            ],
            "loanAmountAffect": [
                791
            ],
            "latestActorType": [
                22
            ],
            "latestActorId": [
                1
            ],
            "createdAt": [
                361
            ],
            "updatedAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "LoanSortDirection": {},
        "LoansResponse": {
            "loans": [
                787
            ],
            "page": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "LoansSort": {
            "field": [
                1
            ],
            "direction": [
                827
            ],
            "__typename": [
                1
            ]
        },
        "LoanStatus": {},
        "LoanTransactionProcessedOnlineResult": {
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "LoanTreatmentStatus": {},
        "LoanType": {},
        "LoanUpdateBasicData": {
            "lenderId": [
                1
            ],
            "userActionStatus": [
                837
            ],
            "postedAt": [
                361
            ],
            "amount": [
                11
            ],
            "status": [
                830
            ],
            "hideOutdatedWarning": [
                5
            ],
            "type": [
                833
            ],
            "pendingDelete": [
                5
            ],
            "userFollowUp2": [
                1429
            ],
            "direction": [
                804
            ],
            "dataRequestType": [
                803
            ],
            "recurringDataFrequency": [
                821
            ],
            "isShortTerm": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "LoanUpdateData": {
            "status": [
                830
            ],
            "userActionStatus": [
                837
            ],
            "lastManuallyHandledAt": [
                361
            ],
            "postedAt": [
                361
            ],
            "amount": [
                11
            ],
            "internalOwner": [
                1
            ],
            "postedAtSource": [
                820
            ],
            "amountSource": [
                820
            ],
            "isShortTerm": [
                5
            ],
            "type": [
                833
            ],
            "direction": [
                804
            ],
            "directionSource": [
                820
            ],
            "interestRateType": [
                812
            ],
            "interestRate": [
                11
            ],
            "dataRequestType": [
                803
            ],
            "interestType": [
                813
            ],
            "isIndexLinked": [
                5
            ],
            "gracePeriod": [
                33
            ],
            "interestDays": [
                811
            ],
            "originationFees": [
                11
            ],
            "prepaymentFees": [
                11
            ],
            "repaymentFrequency": [
                823
            ],
            "repaymentType": [
                824
            ],
            "lenderId": [
                1
            ],
            "lenderType": [
                814
            ],
            "merchantSource": [
                820
            ],
            "variableInterestUpdatePeriod": [
                840
            ],
            "variableInterestSpread": [
                11
            ],
            "variableInterestBase": [
                839
            ],
            "compoundFrequency": [
                800
            ],
            "recurringDataFrequency": [
                821
            ],
            "comment": [
                799
            ],
            "unrecognizedFees": [
                11
            ],
            "repaymentRate": [
                11
            ],
            "catchUpPeriod": [
                33
            ],
            "catchUpPercent": [
                11
            ],
            "instantRepaymentMethod": [
                810
            ],
            "indexType": [
                809
            ],
            "currency": [
                340
            ],
            "isEarlyRepaymentDiscount": [
                5
            ],
            "earlyRepaymentDiscounts": [
                421
            ],
            "userFollowUp2": [
                1429
            ],
            "internalNote": [
                1
            ],
            "lastUserMoveToProcessingAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "LoanUpdatedResult": {
            "loans": [
                787
            ],
            "__typename": [
                1
            ]
        },
        "LoanUserActionStatus": {},
        "LoanUserFollowUpType": {},
        "LoanVariableInterestBase": {},
        "LoanVariableInterestUpdatePeriod": {},
        "LockAction": {},
        "LockBankAccountInput": {
            "companyId": [
                1
            ],
            "name": [
                1
            ],
            "year": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "LockCompanyBankInput": {
            "companyId": [
                1
            ],
            "bankName": [
                1
            ],
            "year": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "LockCompanyInput": {
            "companyId": [
                1
            ],
            "year": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "LockConditionStatus": {},
        "LockedStatus": {
            "year": [
                33
            ],
            "lockedAt": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "LockType": {},
        "MachineClassificationInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "MachineClassificationJob": {
            "finishedAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "MachineValidationData": {
            "madePrivateAt": [
                361
            ],
            "lastValidAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "ManagementMethod": {},
        "ManualAssembly": {
            "id": [
                1
            ],
            "product": [
                1071
            ],
            "date": [
                361
            ],
            "warehouse": [
                1463
            ],
            "recipe": [
                1113
            ],
            "unitsToAssemble": [
                33
            ],
            "displayName": [
                1
            ],
            "serialNumber": [
                33
            ],
            "deletedReason": [
                82
            ],
            "__typename": [
                1
            ]
        },
        "ManualAssemblyAction": {
            "quantity": [
                33
            ],
            "id": [
                1
            ],
            "date": [
                361
            ],
            "type": [
                1
            ],
            "manualAssembly": [
                852
            ],
            "__typename": [
                1
            ]
        },
        "ManualAssemblyBreakdown": {
            "origin": [
                856
            ],
            "analytics": [
                641
            ],
            "__typename": [
                1
            ]
        },
        "ManualAssemblyDuplicateError": {
            "date": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "ManualAssemblyWithBreakdown": {
            "assembly": [
                852
            ],
            "warehouse": [
                1463
            ],
            "date": [
                361
            ],
            "breakdown": [
                1114
            ],
            "__typename": [
                1
            ]
        },
        "ManualCompanyLoanCreationResult": {
            "loan": [
                270
            ],
            "__typename": [
                1
            ]
        },
        "ManualLoanCreationResult": {
            "loan": [
                787
            ],
            "__typename": [
                1
            ]
        },
        "ManualTransactionsAttachToLoanResult": {
            "loan": [
                270
            ],
            "transactions": [
                147
            ],
            "__typename": [
                1
            ]
        },
        "ManualTxInput": {
            "companyId": [
                8
            ],
            "id": [
                8
            ],
            "amount": [
                11
            ],
            "currency": [
                340
            ],
            "amountInHomeCurrency": [
                11
            ],
            "postedAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "MarkIntegrationTransfersAsDiscrepancyInput": {
            "ids": [
                8
            ],
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "MarkOrdersAsOvercollectedInput": {
            "companyId": [
                8
            ],
            "orderIds": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "MarkOrdersAsOvercollectedResponse": {
            "orders": [
                988
            ],
            "__typename": [
                1
            ]
        },
        "MarkOrdersAsUncollectibleInput": {
            "companyId": [
                8
            ],
            "orderIds": [
                8
            ],
            "postedAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "MarkOrdersAsUncollectibleResponse": {
            "orders": [
                988
            ],
            "__typename": [
                1
            ]
        },
        "MarkOrdersInput": {
            "companyId": [
                8
            ],
            "orderMarks": [
                869
            ],
            "__typename": [
                1
            ]
        },
        "MarkOrderType": {},
        "MarkOrderWithoutFinancialEventsInput": {
            "companyId": [
                8
            ],
            "orderId": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "MarkSingleOrderInput": {
            "orderId": [
                8
            ],
            "markType": [
                867
            ],
            "__typename": [
                1
            ]
        },
        "Merchant": {
            "on_Vendor": [
                1445
            ],
            "on_Customer": [
                343
            ],
            "on_CompanyAffiliate": [
                245
            ],
            "on_Institution": [
                648
            ],
            "__typename": [
                1
            ]
        },
        "MerchantBankTransactionStatsMerchantKeyInput": {
            "id": [
                8
            ],
            "type": [
                879
            ],
            "__typename": [
                1
            ]
        },
        "MerchantCategorizationInput": {
            "merchant": [
                873
            ],
            "shouldApplyAlways": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "MerchantInput": {
            "id": [
                8
            ],
            "type": [
                879
            ],
            "__typename": [
                1
            ]
        },
        "MerchantKey": {
            "id": [
                8
            ],
            "type": [
                879
            ],
            "__typename": [
                1
            ]
        },
        "MerchantsBankTransactionStats": {
            "merchantsCount": [
                33
            ],
            "transactionsCount": [
                33
            ],
            "transactionsSum": [
                11
            ],
            "uncategorizedTransactionsCount": [
                33
            ],
            "uncategorizedTransactionsSum": [
                11
            ],
            "transactionBusinessEvents": [
                1
            ],
            "uncategorizedTransactionBusinessEvents": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "MerchantsBankTransactionStatsInput": {
            "companyId": [
                8
            ],
            "merchants": [
                871
            ],
            "moneyDirection": [
                1255
            ],
            "__typename": [
                1
            ]
        },
        "MerchantsByIdsInput": {
            "companyId": [
                8
            ],
            "merchantIds": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "MerchantsByKeysInput": {
            "keys": [
                874
            ],
            "__typename": [
                1
            ]
        },
        "MerchantType": {},
        "MeResponse": {
            "user": [
                1421
            ],
            "__typename": [
                1
            ]
        },
        "MergedProduct": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "finaloopId": [
                1
            ],
            "displayName": [
                1
            ],
            "imageUrl": [
                1
            ],
            "sku": [
                1
            ],
            "price": [
                11
            ],
            "lineOfBusinessId": [
                1
            ],
            "string": [
                1
            ],
            "listedChannels": [
                1158
            ],
            "appliedCosts": [
                1073
            ],
            "dailySalesData": [
                1159,
                {
                    "timeRange": [
                        1079,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "totalSold": [
                33
            ],
            "avgCostPerUnit": [
                11
            ],
            "representativeFinaloopProductId": [
                1
            ],
            "mergedWithProducts": [
                1071
            ],
            "canEditLatestAppliedCost": [
                5
            ],
            "isFractional": [
                5
            ],
            "isHidden": [
                5
            ],
            "salesData": [
                1171,
                {
                    "timeRange": [
                        1079,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "salesData2": [
                1171,
                {
                    "timeRange": [
                        1079,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "rawRecipes": [
                1113,
                {
                    "timeRange": [
                        1079,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "componentOfProducts": [
                1071
            ],
            "analyticsOverview": [
                1072,
                {
                    "input": [
                        69
                    ]
                }
            ],
            "warehousesAnalyticsOverview": [
                1098,
                {
                    "input": [
                        69
                    ]
                }
            ],
            "tags": [
                1
            ],
            "isDataSyncing": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "MergeProductsInput": {
            "companyId": [
                1
            ],
            "representativeFinaloopProductId": [
                1
            ],
            "mergedFinaloopProductIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "MergeProductsV2": {
            "representativeId": [
                1
            ],
            "productIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "MergeProductsV2Input": {
            "companyId": [
                1
            ],
            "mergeInput": [
                883
            ],
            "splitInput": [
                1271
            ],
            "__typename": [
                1
            ]
        },
        "MergeProductsV2Response": {
            "on_MergeProductsV2Result": [
                886
            ],
            "on_MergeProductsValidationError": [
                887
            ],
            "__typename": [
                1
            ]
        },
        "MergeProductsV2Result": {
            "splitResult": [
                1071
            ],
            "mergeResult": [
                1071
            ],
            "__typename": [
                1
            ]
        },
        "MergeProductsValidationError": {
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "MergeResult": {},
        "MergeTax1099VendorsInput": {
            "companyId": [
                8
            ],
            "vendorIds": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "MetricRangeGroupBy": {},
        "MoneyDirection": {},
        "MoveBankAccountUploadsToAnotherBankAccountInput": {
            "companyId": [
                8
            ],
            "sourceBankAccountId": [
                8
            ],
            "targetBankAccountId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "Movement": {
            "stockSituation": [
                687
            ],
            "direction": [
                894
            ],
            "batches": [
                687
            ],
            "unavailableQuantity": [
                33
            ],
            "action": [
                1464
            ],
            "__typename": [
                1
            ]
        },
        "MovementDirection": {},
        "MovementIndirectCost": {
            "type": [
                896
            ],
            "amount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "MovementIndirectCostType": {},
        "Mutation": {
            "cancelAccrual": [
                7,
                {
                    "input": [
                        210,
                        "CancelAccrualInput!"
                    ]
                }
            ],
            "createOrUpdateAccrual": [
                330,
                {
                    "input": [
                        329,
                        "CreateOrUpdateAccrualInput!"
                    ]
                }
            ],
            "deleteAccrual": [
                7,
                {
                    "input": [
                        366,
                        "DeleteAccrualInput!"
                    ]
                }
            ],
            "updateBankAccountClosingBalance": [
                95,
                {
                    "input": [
                        1346,
                        "UpdateBankAccountClosingBalanceInput!"
                    ]
                }
            ],
            "createBankAccountBalanceAdjustment": [
                95,
                {
                    "input": [
                        1345,
                        "UpdateBankAccountBalanceAdjustmentInput!"
                    ]
                }
            ],
            "deleteBankAccountBalanceAdjustment": [
                95,
                {
                    "input": [
                        1345,
                        "UpdateBankAccountBalanceAdjustmentInput!"
                    ]
                }
            ],
            "setBankAccountsLatestEventIds": [
                95,
                {
                    "input": [
                        1195,
                        "SetBankAccountsLatestEventIdsInput!"
                    ]
                }
            ],
            "createBankAccount": [
                95,
                {
                    "input": [
                        296,
                        "CreateBankAccountInput!"
                    ]
                }
            ],
            "copyBankAccount": [
                95,
                {
                    "input": [
                        294,
                        "CopyBankAccountInput!"
                    ]
                }
            ],
            "updateBankAccount": [
                95,
                {
                    "input": [
                        1347,
                        "UpdateBankAccountInput!"
                    ]
                }
            ],
            "replaceBankAccountLocks": [
                95,
                {
                    "input": [
                        1139,
                        "ReplaceBankAccountLocksInput!"
                    ]
                }
            ],
            "replaceBankAccountLocksByType": [
                95,
                {
                    "input": [
                        1138,
                        "ReplaceBankAccountLocksByTypeInput!"
                    ]
                }
            ],
            "updateBankAccountsMergeStatus": [
                1291,
                {
                    "input": [
                        1348,
                        "UpdateBankAccountsMergeStatusInput!"
                    ]
                }
            ],
            "lockBankAccount": [
                95,
                {
                    "input": [
                        842,
                        "LockBankAccountInput!"
                    ]
                }
            ],
            "createInstitution": [
                648,
                {
                    "input": [
                        314,
                        "CreateInstitutionInput!"
                    ]
                }
            ],
            "updateInstitution": [
                648,
                {
                    "input": [
                        1367,
                        "UpdateInstitutionInput!"
                    ]
                }
            ],
            "createInstitutionMapping": [
                649,
                {
                    "input": [
                        315,
                        "CreateInstitutionMappingsInput!"
                    ]
                }
            ],
            "updateInstitutionMapping": [
                649,
                {
                    "input": [
                        1368,
                        "UpdateInstitutionMappingInput!"
                    ]
                }
            ],
            "uploadBankStatement": [
                1407,
                {
                    "input": [
                        1406,
                        "UploadBankStatementInput!"
                    ]
                }
            ],
            "updateBankReconciliationRequestMessage": [
                133,
                {
                    "input": [
                        1349,
                        "UpdateBankReconciliationRequestMessageInput!"
                    ]
                }
            ],
            "stopImportingDataForBankAccount": [
                95,
                {
                    "input": [
                        1276,
                        "StopImportingDataForBankAccountInput!"
                    ]
                }
            ],
            "stopImportingDataForBankAccountNew": [
                95,
                {
                    "input": [
                        1277,
                        "StopImportingDataForBankAccountNewInput!"
                    ]
                }
            ],
            "createBankAccountStatementForStopImport": [
                115,
                {
                    "input": [
                        297,
                        "CreateBankAccountStatementForStopImportInput!"
                    ]
                }
            ],
            "deleteBankAccounts": [
                5,
                {
                    "input": [
                        367,
                        "DeleteBankAccountsInput!"
                    ]
                }
            ],
            "updateBankAccountReconciliation": [
                109,
                {
                    "input": [
                        1360,
                        "UpdatedBankAccountReconciliation!"
                    ]
                }
            ],
            "categorizeBankTransactions": [
                154,
                {
                    "input": [
                        213,
                        "CategorizeBankTransactionsInput!"
                    ]
                }
            ],
            "createCustomerCategorizationRule": [
                308,
                {
                    "input": [
                        307,
                        "CreateCustomerCategorizationRuleInput!"
                    ]
                }
            ],
            "updateBankTransactionMemo": [
                147,
                {
                    "input": [
                        1350,
                        "UpdateBankTransactionMemoInput!"
                    ]
                }
            ],
            "addBankTransactionAttachment": [
                147,
                {
                    "input": [
                        23,
                        "AddBankTransactionAttachmentInput!"
                    ]
                }
            ],
            "deleteBankTransactionAttachment": [
                147,
                {
                    "input": [
                        369,
                        "DeleteBankTransactionAttachmentInput!"
                    ]
                }
            ],
            "finalizeAskTheUserFlow": [
                465,
                {
                    "input": [
                        464,
                        "FinalizeAskTheUserFlowInput!"
                    ]
                }
            ],
            "askTheUserNotificationService": [
                78,
                {
                    "input": [
                        77,
                        "AskTheUserNotificationInput!"
                    ]
                }
            ],
            "scheduleAtuTransactionsResultProcessing": [
                1183,
                {
                    "input": [
                        1182,
                        "ScheduleAtuTransactionsResultProcessingInput!"
                    ]
                }
            ],
            "createGlobalVendorMappingRule": [
                607,
                {
                    "input": [
                        312,
                        "CreateGlobalVendorRuleInput!"
                    ]
                }
            ],
            "createBankTransactionsUpload": [
                170,
                {
                    "input": [
                        298,
                        "CreateBankTransactionsUploadInput!"
                    ]
                }
            ],
            "upsertBankTransactionLocalRule": [
                335,
                {
                    "input": [
                        1408,
                        "UpsertBankTransactionLocalRuleInput!"
                    ]
                }
            ],
            "createGlobalRule": [
                310,
                {
                    "input": [
                        606,
                        "GlobalRuleInput!"
                    ]
                }
            ],
            "attemptToClassifyTransactions": [
                224,
                {
                    "input": [
                        89,
                        "AttemptToClassifyTransactionsInput!"
                    ]
                }
            ],
            "attemptToClassifyTransactionsWithPairedEntities": [
                224,
                {
                    "input": [
                        89,
                        "AttemptToClassifyTransactionsInput!"
                    ]
                }
            ],
            "deleteBankTransactionsByBankAccountIds": [
                5,
                {
                    "input": [
                        370,
                        "DeleteBankTransactionsByBankAccountIdsInput!"
                    ]
                }
            ],
            "deleteBankTransactionsInternal": [
                43,
                {
                    "input": [
                        42,
                        "AlterBankTransactionsLifecycleStateInput!"
                    ]
                }
            ],
            "restoreBankTransactionsInternal": [
                43,
                {
                    "input": [
                        42,
                        "AlterBankTransactionsLifecycleStateInput!"
                    ]
                }
            ],
            "moveBankAccountUploadsToAnotherBankAccount": [
                170,
                {
                    "input": [
                        892,
                        "MoveBankAccountUploadsToAnotherBankAccountInput!"
                    ]
                }
            ],
            "appendProcessingLogToBankTransactionsUpload": [
                170,
                {
                    "input": [
                        70,
                        "AppendProcessingLogToBankTransactionsUploadInput!"
                    ]
                }
            ],
            "submitTransactionsFileToBankTransactionsUpload": [
                170,
                {
                    "input": [
                        1286,
                        "SubmitTransactionsFileToBankTransactionsUploadInput!"
                    ]
                }
            ],
            "clearExpiredDoItLaterResults": [
                35
            ],
            "processCheckoutWebhookEvents": [
                5,
                {
                    "args": [
                        1070,
                        "ProcessCheckoutWebhookEventsInput!"
                    ]
                }
            ],
            "syncStripeOffers": [
                5,
                {
                    "args": [
                        1299,
                        "SyncStripeOffersInput!"
                    ]
                }
            ],
            "syncStripeTier1Offer": [
                5,
                {
                    "args": [
                        1300,
                        "SyncStripeTier1Offer!"
                    ]
                }
            ],
            "createCheckoutSession": [
                300,
                {
                    "args": [
                        299,
                        "CreateCheckoutSessionInput!"
                    ]
                }
            ],
            "pollCheckoutSessionStatus": [
                241,
                {
                    "args": [
                        1066,
                        "PollCheckoutSessionStatusInput!"
                    ]
                }
            ],
            "createOrGetBusinessEventDefinition": [
                196,
                {
                    "input": [
                        328,
                        "CreateOrGetBusinessEventDefinitionInput!"
                    ]
                }
            ],
            "updateBooksStartTime": [
                1288,
                {
                    "args": [
                        1351,
                        "UpdateBooksStartTimeArgs!"
                    ]
                }
            ],
            "createFileUploadUrl": [
                462,
                {
                    "input": [
                        309,
                        "CreateFileUploadUrlInput!"
                    ]
                }
            ],
            "updateCompanyAccountingMethod": [
                241,
                {
                    "args": [
                        1354,
                        "UpdateCompanyAccountingMethodArgs!"
                    ]
                }
            ],
            "updateEstimatedProjectedRevenue": [
                241,
                {
                    "args": [
                        1364,
                        "UpdateEstimatedProjectedRevenueInput!"
                    ]
                }
            ],
            "toggleOrdersZeroNetBalance": [
                1288,
                {
                    "args": [
                        1312,
                        "ToggleOrdersZeroNetBalanceInput!"
                    ]
                }
            ],
            "toggleRecognizeZeroNetOrdersAsGiveaways": [
                5,
                {
                    "args": [
                        1313,
                        "ToggleRecognizeZeroNetOrdersAsGiveawaysInput!"
                    ]
                }
            ],
            "createImageUploadUrl": [
                634,
                {
                    "input": [
                        313,
                        "CreateImageUploadUrlInput!"
                    ]
                }
            ],
            "setSettings": [
                1288,
                {
                    "args": [
                        1214,
                        "SetSettingsArgs!"
                    ]
                }
            ],
            "upsertCompanyAddress": [
                243,
                {
                    "input": [
                        1409
                    ]
                }
            ],
            "deleteCompanyAddress": [
                5,
                {
                    "input": [
                        244,
                        "CompanyAddressInput!"
                    ]
                }
            ],
            "updateOnboardingStatus": [
                908,
                {
                    "input": [
                        1385,
                        "UpdateOnboardingStatusInput!"
                    ]
                }
            ],
            "setFiscalYearData": [
                5,
                {
                    "input": [
                        1196,
                        "SetFiscalYearDataInput!"
                    ]
                }
            ],
            "createCompany": [
                241,
                {
                    "args": [
                        302,
                        "CreateCompanyInput!"
                    ]
                }
            ],
            "updateCompanyPayingStatus": [
                241,
                {
                    "args": [
                        1356,
                        "UpdateCompanyPayingStatusInput!"
                    ]
                }
            ],
            "syncCompany": [
                5,
                {
                    "args": [
                        1295,
                        "SyncCompanyInput!"
                    ]
                }
            ],
            "lockCompany": [
                276,
                {
                    "args": [
                        844,
                        "LockCompanyInput!"
                    ]
                }
            ],
            "unlockCompany": [
                276,
                {
                    "args": [
                        844,
                        "LockCompanyInput!"
                    ]
                }
            ],
            "removeDataRestriction": [
                241,
                {
                    "args": [
                        1135,
                        "RemoveDataRestrictionInput!"
                    ]
                }
            ],
            "createCompanyAffiliate": [
                245,
                {
                    "input": [
                        301,
                        "CreateCompanyAffiliateInput!"
                    ]
                }
            ],
            "updateCompanyAffiliate": [
                245,
                {
                    "input": [
                        1355,
                        "UpdateCompanyAffiliateInput!"
                    ]
                }
            ],
            "deleteCompanyAffiliate": [
                245,
                {
                    "input": [
                        371,
                        "DeleteCompanyAffiliateInput!"
                    ]
                }
            ],
            "upsertHolderShare": [
                626,
                {
                    "input": [
                        1414,
                        "UpsertHolderShareInput!"
                    ]
                }
            ],
            "deleteHolderShare": [
                626,
                {
                    "input": [
                        373,
                        "DeleteHolderShareInput!"
                    ]
                }
            ],
            "upsertHolderCapitalInvestment": [
                623,
                {
                    "input": [
                        1413,
                        "UpsertHolderCapitalInvestmentInput!"
                    ]
                }
            ],
            "deleteHolderCapitalInvestment": [
                623,
                {
                    "input": [
                        372,
                        "DeleteHolderCapitalInvestmentInput!"
                    ]
                }
            ],
            "addRoleToAffiliatedEntity": [
                31,
                {
                    "input": [
                        29,
                        "AddRoleToAffiliatedEntityInput!"
                    ]
                }
            ],
            "deleteBankConnection": [
                5,
                {
                    "input": [
                        368,
                        "DeleteBankConnectionInput!"
                    ]
                }
            ],
            "disconnectPayrollConnection": [
                5,
                {
                    "input": [
                        407,
                        "DisconnectConnectionInput!"
                    ]
                }
            ],
            "disconnectConnection": [
                5,
                {
                    "input": [
                        407,
                        "DisconnectConnectionInput!"
                    ]
                }
            ],
            "confirmRoleForPaymentGateway": [
                5,
                {
                    "input": [
                        292,
                        "ConfirmRoleForPaymentGatewayInput!"
                    ]
                }
            ],
            "unclassifyIntegrationTransfers": [
                671,
                {
                    "input": [
                        1325,
                        "UnclassifyIntegrationTransferInput!"
                    ]
                }
            ],
            "markIntegrationTransfersAsDiscrepancy": [
                671,
                {
                    "input": [
                        861,
                        "MarkIntegrationTransfersAsDiscrepancyInput!"
                    ]
                }
            ],
            "classifyIntegrationTransfer": [
                671,
                {
                    "input": [
                        229,
                        "ClassifyIntegrationTransferInput!"
                    ]
                }
            ],
            "createIntegrationTransferRule": [
                679,
                {
                    "input": [
                        316,
                        "CreateIntegrationTransferRuleInput!"
                    ]
                }
            ],
            "scheduleAtuIntegrationTransfersResultProcessing": [
                1181,
                {
                    "input": [
                        1180,
                        "ScheduleAtuIntegrationTransfersResultProcessingInput!"
                    ]
                }
            ],
            "refreshSkuAmbiguity": [
                5,
                {
                    "input": [
                        1133,
                        "RefreshSkuAmbiguityInput!"
                    ]
                }
            ],
            "updateAppliedCostFromIntegration": [
                5,
                {
                    "input": [
                        1344,
                        "UpdateAppliedCostFromIntegrationInput!"
                    ]
                }
            ],
            "triggerCOGSFromScratch": [
                5,
                {
                    "input": [
                        1324,
                        "TriggerCOGSFromScratchInput!"
                    ]
                }
            ],
            "syncInventoryIntegrationFulfillmentSetting": [
                5,
                {
                    "input": [
                        1297,
                        "SyncInventoryIntegrationFulfillmentSettingInput!"
                    ]
                }
            ],
            "reconcileInventoryPurchasesFromTransactions": [
                5,
                {
                    "input": [
                        1126,
                        "ReconcileInventoryPurchasesFromTransactionsInput!"
                    ]
                }
            ],
            "reconcileInventoryPurchasesFromBills": [
                5,
                {
                    "input": [
                        1123,
                        "ReconcileInventoryPurchasesFromBillsInput!"
                    ]
                }
            ],
            "reconcileInventoryPurchasesFromBusinessEvents": [
                5,
                {
                    "input": [
                        1124,
                        "ReconcileInventoryPurchasesFromBusinessEventsInput!"
                    ]
                }
            ],
            "reconcileAllPayrollInventoryPurchases": [
                5,
                {
                    "input": [
                        1122,
                        "ReconcileAllPayrollInventoryPurchasesInput!"
                    ]
                }
            ],
            "shopifyItemsValidationBlacklistSku": [
                5,
                {
                    "input": [
                        1239,
                        "ShopifyItemsValidationBlacklistSkuInput!"
                    ]
                }
            ],
            "shopifyItemsValidationBlacklistSalesChannel": [
                5,
                {
                    "input": [
                        1238,
                        "ShopifyItemsValidationBlacklistSalesChannelInput!"
                    ]
                }
            ],
            "markOrderWithoutFinancialEvents": [
                5,
                {
                    "input": [
                        868,
                        "MarkOrderWithoutFinancialEventsInput!"
                    ]
                }
            ],
            "markOrdersWithoutFinancialEvents": [
                33,
                {
                    "input": [
                        65,
                        "AmazonValidationInput!"
                    ]
                }
            ],
            "generateFifoValidation": [
                5,
                {
                    "input": [
                        450,
                        "FifoValidationInput!"
                    ]
                }
            ],
            "generateBigValidation": [
                184,
                {
                    "input": [
                        479,
                        "GenerateBigValidationInput!"
                    ]
                }
            ],
            "amazonValidationAdjustSkuQuantities": [
                5,
                {
                    "input": [
                        64,
                        "AmazonValidationAdjustSkuQuantitiesInput!"
                    ]
                }
            ],
            "changeProductsHiddenStatus": [
                5,
                {
                    "input": [
                        216,
                        "ChangeProductsHiddenStatusInput!"
                    ]
                }
            ],
            "createProductManually": [
                333,
                {
                    "input": [
                        332,
                        "CreateProductManuallyInput!"
                    ]
                }
            ],
            "createWarehouse": [
                1468,
                {
                    "input": [
                        338,
                        "CreateWarehouseInput!"
                    ]
                }
            ],
            "deleteManualAssembly": [
                5,
                {
                    "input": [
                        384,
                        "DeleteManualAssemblyInput!"
                    ]
                }
            ],
            "deletePurchaseOrder": [
                5,
                {
                    "input": [
                        397,
                        "DeletePurchaseOrderInput!"
                    ]
                }
            ],
            "deleteRecipe": [
                5,
                {
                    "input": [
                        398,
                        "DeleteRecipeInput!"
                    ]
                }
            ],
            "deleteWarehouse": [
                5,
                {
                    "input": [
                        399,
                        "DeleteWarehouseInput!"
                    ]
                }
            ],
            "mergeProductsV2": [
                885,
                {
                    "input": [
                        884,
                        "MergeProductsV2Input!"
                    ]
                }
            ],
            "setProductAdjustments": [
                1071,
                {
                    "input": [
                        1206,
                        "SetProductAdjustmentsInput!"
                    ]
                }
            ],
            "setWarehouseOpeningBalance": [
                1477,
                {
                    "input": [
                        1217,
                        "SetWarehouseOpeningBalanceInput!"
                    ]
                }
            ],
            "updateManualAssembly": [
                1380,
                {
                    "input": [
                        1379,
                        "UpdateManualAssemblyInput!"
                    ]
                }
            ],
            "updateProduct": [
                1071,
                {
                    "input": [
                        1393,
                        "UpdateProductInput!"
                    ]
                }
            ],
            "updateProductFractional": [
                1071,
                {
                    "input": [
                        1392,
                        "UpdateProductFractionalInput!"
                    ]
                }
            ],
            "updatePurchaseOrder": [
                1100,
                {
                    "input": [
                        1103,
                        "PurchaseOrderInput!"
                    ]
                }
            ],
            "updateRecipe": [
                1395,
                {
                    "input": [
                        1394,
                        "UpdateRecipeInput!"
                    ]
                }
            ],
            "updateWarehouse": [
                1463,
                {
                    "input": [
                        1403,
                        "UpdateWarehouseInput!"
                    ]
                }
            ],
            "updateWarehousesDefaults": [
                1490,
                {
                    "input": [
                        1404,
                        "UpdateWarehousesDefaultsInput!"
                    ]
                }
            ],
            "generatePdf": [
                482,
                {
                    "input": [
                        481,
                        "GeneratePdfInput!"
                    ]
                }
            ],
            "generatePdfV2": [
                483,
                {
                    "input": [
                        481,
                        "GeneratePdfInput!"
                    ]
                }
            ],
            "sendPoEmail": [
                1194,
                {
                    "input": [
                        1192,
                        "SendPoEmailInput!"
                    ]
                }
            ],
            "setInventoryUsages": [
                5,
                {
                    "input": [
                        1199,
                        "SetInventoryUsagesInput!"
                    ]
                }
            ],
            "updateInventoryPurchase": [
                1370,
                {
                    "input": [
                        697,
                        "InventoryPurchaseInput!"
                    ]
                }
            ],
            "deleteInventoryPurchase": [
                713,
                {
                    "input": [
                        374,
                        "DeleteInventoryPurchaseInput!"
                    ]
                }
            ],
            "schedulePurchasesValidation": [
                5,
                {
                    "input": [
                        1184,
                        "SchedulePurchasesValidationInput!"
                    ]
                }
            ],
            "createInventoryVendorValidation": [
                318,
                {
                    "input": [
                        317,
                        "CreateInventoryVendorValidationInput!"
                    ]
                }
            ],
            "setPendingWarehouseOpeningBalance": [
                1062,
                {
                    "input": [
                        1202,
                        "SetPendingWarehouseOpeningBalancesInput!"
                    ]
                }
            ],
            "deleteWarehouseOpeningBalance": [
                5,
                {
                    "input": [
                        400,
                        "DeleteWarehouseOpeningBalancesInput!"
                    ]
                }
            ],
            "discardPendingWarehouseOpeningBalance": [
                5,
                {
                    "input": [
                        406,
                        "DiscardPendingWarehouseOpeningBalancesInput!"
                    ]
                }
            ],
            "applyPendingWarehouseOpeningBalance": [
                5,
                {
                    "input": [
                        72,
                        "ApplyPendingWarehouseOpeningBalanceInput!"
                    ]
                }
            ],
            "setPendingWarehouseOpeningBalanceWithCsv": [
                1063,
                {
                    "input": [
                        1204,
                        "SetPendingWarehouseOpeningBalancesWithCsvInput!"
                    ]
                }
            ],
            "deletePendingWarehouseOpeningBalanceAttachment": [
                5,
                {
                    "input": [
                        395,
                        "DeletePendingWarehouseOpeningAttachmentInput!"
                    ]
                }
            ],
            "updateWarehouseTransfer": [
                1496,
                {
                    "input": [
                        1405,
                        "UpdateWarehouseTransferInput!"
                    ]
                }
            ],
            "deleteWarehouseTransfer": [
                5,
                {
                    "input": [
                        401,
                        "DeleteWarehouseTransferInput!"
                    ]
                }
            ],
            "updateLoan": [
                858,
                {
                    "input": [
                        1369,
                        "UpdateInternalLoanInput!"
                    ]
                }
            ],
            "updateLoanAttachments": [
                858,
                {
                    "input": [
                        1374,
                        "UpdateLoanAttachmentsInput!"
                    ]
                }
            ],
            "createLoan": [
                858,
                {
                    "input": [
                        322,
                        "CreateLoanInput!"
                    ]
                }
            ],
            "createLoanFromTransaction": [
                802,
                {
                    "input": [
                        1322,
                        "TransactionUpdatedInput!"
                    ]
                }
            ],
            "createLoanFromBalanceTransaction": [
                802,
                {
                    "input": [
                        320,
                        "CreateLoanFromBalanceTransactionInput!"
                    ]
                }
            ],
            "createLoanFromBillFinanced": [
                5,
                {
                    "input": [
                        321,
                        "CreateLoanFromBillFinancedInput!"
                    ]
                }
            ],
            "acknowledgeLoansChanges": [
                16,
                {
                    "input": [
                        17,
                        "AcknowledgeLoansChangesInput!"
                    ]
                }
            ],
            "simulateLoanFromTransaction2": [
                1258,
                {
                    "input": [
                        1264,
                        "SimulateTransactionUpdatedInput2!"
                    ]
                }
            ],
            "deleteLoans": [
                383,
                {
                    "input": [
                        382,
                        "DeleteLoansInput!"
                    ]
                }
            ],
            "dangerousForceDeleteLoans": [
                383,
                {
                    "input": [
                        382,
                        "DeleteLoansInput!"
                    ]
                }
            ],
            "dangerousTouchLoan": [
                5,
                {
                    "input": [
                        359,
                        "DangerousTouchLoanInput!"
                    ]
                }
            ],
            "deleteLoanRelationships": [
                5,
                {
                    "input": [
                        379,
                        "DeleteLoanRelationshipsInput!"
                    ]
                }
            ],
            "deleteManualLoanEvent": [
                1383,
                {
                    "input": [
                        385,
                        "DeleteManualLoanEventInput!"
                    ]
                }
            ],
            "deleteManualLoanEvents": [
                1384,
                {
                    "input": [
                        386,
                        "DeleteManualLoanEventsInput!"
                    ]
                }
            ],
            "createManualLoanEvent2": [
                1383,
                {
                    "input": [
                        325,
                        "CreateManualLoanEventInput2!"
                    ]
                }
            ],
            "updateManualLoanEvent2": [
                1383,
                {
                    "input": [
                        1382,
                        "UpdateManualLoanEventInput2!"
                    ]
                }
            ],
            "deleteLoanManualAccountingEvents": [
                1384,
                {
                    "input": [
                        378,
                        "DeleteLoanManualAccountingEventsInput!"
                    ]
                }
            ],
            "updateLoanManualAccountingEvent": [
                1383,
                {
                    "input": [
                        1376,
                        "UpdateLoanManualAccountingEventInput!"
                    ]
                }
            ],
            "deleteLoansEventsEffectConfiguration": [
                381,
                {
                    "input": [
                        380,
                        "DeleteLoansEventsEffectConfigurationInput!"
                    ]
                }
            ],
            "createLoansEventsEffectConfiguration": [
                826,
                {
                    "input": [
                        323,
                        "CreateLoansEventsEffectConfigurationInput!"
                    ]
                }
            ],
            "createLoanEventDefinition": [
                806,
                {
                    "input": [
                        319,
                        "CreateLoanEventDefinitionInput!"
                    ]
                }
            ],
            "attachRelationsToLoan": [
                5,
                {
                    "input": [
                        87,
                        "AttachRelationsToLoanInput!"
                    ]
                }
            ],
            "deleteCompanyLoanAttachment": [
                857,
                {
                    "input": [
                        376,
                        "DeleteLoanAttachmentInput!"
                    ]
                }
            ],
            "updateCompanyLoanAttachments": [
                857,
                {
                    "input": [
                        1374,
                        "UpdateLoanAttachmentsInput!"
                    ]
                }
            ],
            "createCompanyLoan": [
                857,
                {
                    "input": [
                        305,
                        "CreateCompanyLoanInput!"
                    ]
                }
            ],
            "updateCompanyLoan": [
                857,
                {
                    "input": [
                        1375,
                        "UpdateLoanInput!"
                    ]
                }
            ],
            "deleteLoan": [
                383,
                {
                    "input": [
                        377,
                        "DeleteLoanInput!"
                    ]
                }
            ],
            "userAttachTransactionsToExistingLoan": [
                859,
                {
                    "input": [
                        1422,
                        "UserAttachTransactionsToExistingLoanInput!"
                    ]
                }
            ],
            "userAttachTransactionsToNewLoan": [
                859,
                {
                    "input": [
                        1424,
                        "UserAttachTransactionsToNewLoanInput!"
                    ]
                }
            ],
            "lenderFromVendor": [
                898,
                {
                    "input": [
                        777,
                        "LenderFromVendorInput!"
                    ]
                }
            ],
            "lenderFromCompanyAffiliate": [
                898,
                {
                    "input": [
                        776,
                        "LenderFromCompanyAffiliateInput!"
                    ]
                }
            ],
            "updateOrderEntities": [
                1287,
                {
                    "input": [
                        1386,
                        "UpdateOrderEntitiesInput!"
                    ]
                }
            ],
            "deleteOrderGroupUponTransactionChange": [
                390,
                {
                    "input": [
                        389,
                        "DeleteOrderGroupUponTransactionChangeInput!"
                    ]
                }
            ],
            "initiateTotalArCashDiffsReport": [
                1287,
                {
                    "input": [
                        647,
                        "InitiateTotalArCashDiffsReportInput!"
                    ]
                }
            ],
            "updateCompanyArCashDiffsInBucket": [
                1287,
                {
                    "input": [
                        253,
                        "CompanyArCashDiffsInput!"
                    ]
                }
            ],
            "toggleZeroNetBalance": [
                1287,
                {
                    "input": [
                        1314,
                        "ToggleZeroNetBalanceInput!"
                    ]
                }
            ],
            "setOrderFinaloopNote": [
                990,
                {
                    "input": [
                        1200,
                        "SetOrderFinaloopNoteInput!"
                    ]
                }
            ],
            "setOrderFinaloopTags": [
                990,
                {
                    "input": [
                        1201,
                        "SetOrderFinaloopTagsInput!"
                    ]
                }
            ],
            "setFulfillmentSettings": [
                1198,
                {
                    "input": [
                        1197,
                        "SetFulfillmentSettingsInput!"
                    ]
                }
            ],
            "markOrders": [
                5,
                {
                    "input": [
                        866
                    ]
                }
            ],
            "linkOrderGroup": [
                783,
                {
                    "input": [
                        782,
                        "LinkOrderGroupInput!"
                    ]
                }
            ],
            "updateOrderGroup": [
                1388,
                {
                    "input": [
                        1387,
                        "UpdateOrderGroupInput!"
                    ]
                }
            ],
            "deleteOrderGroup": [
                388,
                {
                    "input": [
                        387,
                        "DeleteOrderGroupInput!"
                    ]
                }
            ],
            "voidOrders": [
                1461,
                {
                    "input": [
                        1460,
                        "VoidOrdersInput!"
                    ]
                }
            ],
            "unvoidOrders": [
                1343,
                {
                    "input": [
                        1342,
                        "UnvoidOrdersInput!"
                    ]
                }
            ],
            "markOrdersAsUncollectible": [
                865,
                {
                    "input": [
                        864,
                        "MarkOrdersAsUncollectibleInput!"
                    ]
                }
            ],
            "markOrdersAsOvercollected": [
                863,
                {
                    "input": [
                        862,
                        "MarkOrdersAsOvercollectedInput!"
                    ]
                }
            ],
            "unMarkOrdersAsUncollectible": [
                1339,
                {
                    "input": [
                        1338,
                        "UnMarkOrdersAsUncollectibleInput!"
                    ]
                }
            ],
            "unMarkOrdersAsOvercollected": [
                1337,
                {
                    "input": [
                        1336,
                        "UnMarkOrdersAsOvercollectedInput!"
                    ]
                }
            ],
            "deletePaymentGroupUponTransactionChange": [
                394,
                {
                    "input": [
                        393,
                        "DeletePaymentGroupUponTransactionChangeInput!"
                    ]
                }
            ],
            "linkOrderPaymentGroup": [
                785,
                {
                    "input": [
                        784,
                        "LinkOrderPaymentGroupInput!"
                    ]
                }
            ],
            "updateOrderPaymentGroup": [
                1390,
                {
                    "input": [
                        1389,
                        "UpdateOrderPaymentGroupInput!"
                    ]
                }
            ],
            "deleteOrderPaymentGroup": [
                392,
                {
                    "input": [
                        391,
                        "DeletePaymentGroupInput!"
                    ]
                }
            ],
            "linkInvoicePaymentGroup": [
                781,
                {
                    "input": [
                        780,
                        "LinkInvoicePaymentGroupInput!"
                    ]
                }
            ],
            "updateInvoicePaymentGroup": [
                1372,
                {
                    "input": [
                        1371,
                        "UpdateInvoicePaymentGroupInput!"
                    ]
                }
            ],
            "deleteInvoicePaymentGroup": [
                392,
                {
                    "input": [
                        391,
                        "DeletePaymentGroupInput!"
                    ]
                }
            ],
            "updateEmployeesOfficerStatus": [
                5,
                {
                    "input": [
                        1363,
                        "UpdateEmployeesOfficerStatusInput!"
                    ]
                }
            ],
            "updatePayrollSettings": [
                5,
                {
                    "input": [
                        1391,
                        "UpdatePayrollSettingsInput!"
                    ]
                }
            ],
            "scheduleAccountingReportExport": [
                1179,
                {
                    "args": [
                        1178,
                        "ScheduleAccountingReportExportInput!"
                    ]
                }
            ],
            "upsertIntegrationCustomer": [
                350,
                {
                    "input": [
                        1415,
                        "UpsertIntegrationCustomerArgs!"
                    ]
                }
            ],
            "upsertIntegrationInvoice": [
                744,
                {
                    "input": [
                        1416,
                        "UpsertIntegrationInvoiceInput!"
                    ]
                }
            ],
            "sendSalesToReconcilerByTypeAndDate": [
                5,
                {
                    "input": [
                        1157,
                        "SalesByTypeAndDateInput!"
                    ]
                }
            ],
            "forceDeleteInvoices": [
                375,
                {
                    "input": [
                        1172,
                        "SalesInternalInput!"
                    ]
                }
            ],
            "refreshInvoiceCalculatedFields": [
                744,
                {
                    "input": [
                        1151,
                        "SaleInternalInput!"
                    ]
                }
            ],
            "refreshManyInvoicesCalculatedFields": [
                744,
                {
                    "input": [
                        1172,
                        "SalesInternalInput!"
                    ]
                }
            ],
            "createCustomer": [
                343,
                {
                    "createCustomerArgs": [
                        306,
                        "CreateCustomerArgs!"
                    ]
                }
            ],
            "updateCustomer": [
                343,
                {
                    "updateCustomerArgs": [
                        1359,
                        "UpdateCustomerArgs!"
                    ]
                }
            ],
            "upsertCustomerAddress": [
                344,
                {
                    "input": [
                        1410,
                        "UpsertCustomerAddressArgs!"
                    ]
                }
            ],
            "upsertCustomerV2": [
                350,
                {
                    "input": [
                        1411,
                        "UpsertCustomerV2Args!"
                    ]
                }
            ],
            "upsertInvoice": [
                744,
                {
                    "input": [
                        1417,
                        "UpsertInvoiceInput!"
                    ]
                }
            ],
            "voidInvoice": [
                744,
                {
                    "input": [
                        746,
                        "InvoiceInput!"
                    ]
                }
            ],
            "unvoidInvoice": [
                744,
                {
                    "input": [
                        746,
                        "InvoiceInput!"
                    ]
                }
            ],
            "deleteInvoiceAdjustment": [
                744,
                {
                    "input": [
                        745,
                        "InvoiceAdjustmentInput!"
                    ]
                }
            ],
            "markAsUncollectible": [
                744,
                {
                    "input": [
                        860,
                        "ManualTxInput!"
                    ]
                }
            ],
            "addRefundToInvoice": [
                744,
                {
                    "input": [
                        860,
                        "ManualTxInput!"
                    ]
                }
            ],
            "deleteInvoiceRefund": [
                744,
                {
                    "input": [
                        756,
                        "InvoiceRefundInput!"
                    ]
                }
            ],
            "upsertPaymentMethod": [
                1031,
                {
                    "input": [
                        1419
                    ]
                }
            ],
            "deletePaymentMethod": [
                5,
                {
                    "input": [
                        1032,
                        "PaymentMethodInput!"
                    ]
                }
            ],
            "generateInvoicePDF": [
                8,
                {
                    "input": [
                        746,
                        "InvoiceInput!"
                    ]
                }
            ],
            "markInvoicePdfAsSent": [
                1,
                {
                    "input": [
                        755,
                        "InvoicePdfSentInput!"
                    ]
                }
            ],
            "sendInvoiceEmail": [
                744,
                {
                    "input": [
                        746,
                        "InvoiceInput!"
                    ]
                }
            ],
            "issueInvoice": [
                744,
                {
                    "input": [
                        746,
                        "InvoiceInput!"
                    ]
                }
            ],
            "deleteInvoice": [
                5,
                {
                    "input": [
                        746,
                        "InvoiceInput!"
                    ]
                }
            ],
            "upsertSalesChannel": [
                1160,
                {
                    "input": [
                        1420,
                        "UpsertSalesChannelInput!"
                    ]
                }
            ],
            "upsertInvoiceSettings": [
                760,
                {
                    "input": [
                        1418,
                        "UpsertInvoiceSettingsInput!"
                    ]
                }
            ],
            "updateUserRolesForCompany": [
                1400,
                {
                    "args": [
                        1399,
                        "UpdateUserRolesForCompanyArgs!"
                    ]
                }
            ],
            "inviteUser": [
                743,
                {
                    "args": [
                        742,
                        "InviteUserArgs!"
                    ]
                }
            ],
            "removeUser": [
                1137,
                {
                    "args": [
                        1136,
                        "RemoveUserArgs!"
                    ]
                }
            ],
            "acceptInvitation": [
                3,
                {
                    "args": [
                        2,
                        "AcceptInvitationArgs!"
                    ]
                }
            ],
            "handleUserLogin": [
                620,
                {
                    "args": [
                        619,
                        "HandleUserLoginArgs!"
                    ]
                }
            ],
            "updateUserDetails": [
                1398,
                {
                    "args": [
                        1397,
                        "UpdateUserDetailsArgs!"
                    ]
                }
            ],
            "setGoogleToken": [
                612,
                {
                    "args": [
                        611,
                        "GoogleTokenCodeArgs!"
                    ]
                }
            ],
            "disconnectGoogleAccount": [
                612
            ],
            "switchCompany": [
                1293,
                {
                    "args": [
                        1292,
                        "SwitchCompanyArgs!"
                    ]
                }
            ],
            "updateSharedGoogleDriveFolder": [
                1288,
                {
                    "args": [
                        1396,
                        "UpdateSharedGoogleDriveFolder!"
                    ]
                }
            ],
            "assignCompanyToUser": [
                84,
                {
                    "args": [
                        83,
                        "AssignCompanyToUser!"
                    ]
                }
            ],
            "impersonate": [
                636,
                {
                    "args": [
                        635,
                        "ImpersonateArgs!"
                    ]
                }
            ],
            "setUserName": [
                1216,
                {
                    "args": [
                        1215,
                        "SetUserName!"
                    ]
                }
            ],
            "createVendor": [
                1445,
                {
                    "createVendorArgs": [
                        337,
                        "CreateVendorArgs!"
                    ]
                }
            ],
            "recordTax1099VendorGroupInput": [
                5,
                {
                    "input": [
                        1129,
                        "RecordTax1099VendorGroupInput!"
                    ]
                }
            ],
            "mergeTax1099Vendors": [
                5,
                {
                    "input": [
                        889,
                        "MergeTax1099VendorsInput!"
                    ]
                }
            ],
            "splitTax1099VendorGroups": [
                5,
                {
                    "input": [
                        1272,
                        "SplitTax1099VendorGroupsInput!"
                    ]
                }
            ],
            "createGlobalVendor": [
                1445,
                {
                    "input": [
                        311,
                        "CreateGlobalVendorInput!"
                    ]
                }
            ],
            "updateCompanyVendorExternalSourceId": [
                1445,
                {
                    "args": [
                        1358,
                        "UpdateCompanyVendorExternalSourceIdVendorInput!"
                    ]
                }
            ],
            "upsertCompanyVendor": [
                1445,
                {
                    "args": [
                        1401,
                        "UpdateVendorInput!"
                    ]
                }
            ],
            "updateGlobalVendorLogo": [
                1445,
                {
                    "input": [
                        1366,
                        "UpdateGlobalVendorLogoInput!"
                    ]
                }
            ],
            "upsertGlobalVendor": [
                1445,
                {
                    "args": [
                        1412,
                        "UpsertGlobalVendorInput!"
                    ]
                }
            ],
            "unsignWorksheetRow": [
                290,
                {
                    "args": [
                        1340,
                        "UnsignWorksheetRowInput!"
                    ]
                }
            ],
            "signWorksheetRow": [
                290,
                {
                    "args": [
                        1253,
                        "SignWorksheetRowInput!"
                    ]
                }
            ],
            "storeDateForLocking": [
                290,
                {
                    "args": [
                        1279,
                        "StoreDateForLockingInput!"
                    ]
                }
            ],
            "getPresignedUploadUrl": [
                1067,
                {
                    "args": [
                        571,
                        "GetPresignedUrlInput!"
                    ]
                }
            ],
            "updateComments": [
                290,
                {
                    "args": [
                        1352,
                        "UpdateCommentsInput!"
                    ]
                }
            ],
            "unsignWorksheetRowV2": [
                290,
                {
                    "args": [
                        1341,
                        "UnsignWorksheetRowInputV2!"
                    ]
                }
            ],
            "signWorksheetRowV2": [
                290,
                {
                    "args": [
                        1254,
                        "SignWorksheetRowInputV2!"
                    ]
                }
            ],
            "storeDateForLockingV2": [
                290,
                {
                    "args": [
                        1280,
                        "StoreDateForLockingInputV2!"
                    ]
                }
            ],
            "getPresignedUploadUrlV2": [
                1067,
                {
                    "args": [
                        572,
                        "GetPresignedUrlInputV2!"
                    ]
                }
            ],
            "updateCommentsV2": [
                290,
                {
                    "args": [
                        1353,
                        "UpdateCommentsInputV2!"
                    ]
                }
            ],
            "__typename": [
                1
            ]
        },
        "NewLender": {
            "id": [
                8
            ],
            "company": [
                241
            ],
            "affiliatedEntity": [
                36
            ],
            "name": [
                1
            ],
            "supportedLoanTypes": [
                833
            ],
            "defaultLoanType": [
                833
            ],
            "isShortTerm": [
                5
            ],
            "supportedFollowUpTypes": [
                838
            ],
            "logoUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "NonMatchedSku": {
            "sku": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "quantity": [
                33
            ],
            "zeroPriceQuantity": [
                33
            ],
            "restocks": [
                33
            ],
            "giveaway": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "NonMatchingProductsResponse": {
            "ambiguousSkuCount": [
                33
            ],
            "notFoundProductSkuCount": [
                33
            ],
            "notFoundProductSkus": [
                902
            ],
            "__typename": [
                1
            ]
        },
        "NonMatchingUnpaidBill": {
            "billId": [
                1
            ],
            "booksAmount": [
                11
            ],
            "inventoryPurchaseAmount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "NotFoundProductSkus": {
            "shopName": [
                1
            ],
            "source": [
                1
            ],
            "skus": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Offer": {
            "tier": [
                905
            ],
            "payment": [
                996
            ],
            "features": [
                445
            ],
            "title": [
                1
            ],
            "description": [
                1
            ],
            "couponDiscount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "OffersStatus": {},
        "OfferTier": {},
        "OnboardingScope": {},
        "OnBoardingState": {
            "signupCompleted": [
                5
            ],
            "currentPage": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "OnboardingStatus": {
            "companyId": [
                8
            ],
            "scope": [
                906
            ],
            "step": [
                1
            ],
            "status": [
                910
            ],
            "__typename": [
                1
            ]
        },
        "OnboardingStatuses": {
            "hasReportedCOGSEvents": [
                5
            ],
            "hasClassifiedPurchases": [
                5
            ],
            "hasConfirmedOpeningBalance": [
                5
            ],
            "inventoryInitStatus": [
                688
            ],
            "__typename": [
                1
            ]
        },
        "OnboardingStepStatus": {},
        "OnDemandAssemblyAction": {
            "quantity": [
                33
            ],
            "id": [
                1
            ],
            "date": [
                361
            ],
            "type": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "OnDemandAssemblyBreakdown": {
            "origin": [
                1121
            ],
            "analytics": [
                641
            ],
            "__typename": [
                1
            ]
        },
        "OneTimePayment": {
            "id": [
                8
            ],
            "price": [
                11
            ],
            "quantity": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "OpeningBalanceAction": {
            "quantity": [
                33
            ],
            "id": [
                1
            ],
            "date": [
                361
            ],
            "type": [
                1
            ],
            "averageCost": [
                11
            ],
            "isFromUserInput": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "OptionalTimeRangeInput": {
            "fromUTC": [
                361
            ],
            "toUTC": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "OrderAccountingReconciliationAccount": {
            "entity": [
                917
            ],
            "account": [
                917
            ],
            "__typename": [
                1
            ]
        },
        "OrderAccountingReconciliationAmount": {
            "amount": [
                11
            ],
            "accountRole": [
                1
            ],
            "accountName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "OrderAccountingReconciliationRow": {
            "accountName": [
                1
            ],
            "accounts": [
                916
            ],
            "__typename": [
                1
            ]
        },
        "OrderAccountingReconciliationsResponse": {
            "rows": [
                918
            ],
            "otherBalances": [
                960
            ],
            "__typename": [
                1
            ]
        },
        "OrderActionType": {},
        "OrderAdjustment": {
            "id": [
                8
            ],
            "amountInHomeCurrency": [
                11
            ],
            "type": [
                922
            ],
            "postedAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "OrderAdjustmentType": {},
        "OrderAggregationEntry": {
            "type": [
                924
            ],
            "value": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "OrderAggregationType": {},
        "OrderEntity": {
            "id": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "OrderEntityType": {},
        "OrderFinaloopStatus": {},
        "OrderFulfillment": {
            "id": [
                8
            ],
            "fulfilledAt": [
                361
            ],
            "fulfillmentLineItems": [
                929
            ],
            "warehouse": [
                1463
            ],
            "__typename": [
                1
            ]
        },
        "OrderFulfillmentLineItem": {
            "id": [
                8
            ],
            "lineItemId": [
                8
            ],
            "quantity": [
                33
            ],
            "sku": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "OrderFulfillmentSource": {},
        "OrderFulfillmentStatus": {},
        "OrderGroup": {
            "id": [
                8
            ],
            "companyId": [
                1
            ],
            "orders": [
                779
            ],
            "bankTransactions": [
                778
            ],
            "expenses": [
                935
            ],
            "postedAt": [
                361
            ],
            "createdAt": [
                361
            ],
            "updatedAt": [
                361
            ],
            "deletedAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupByIdInput": {
            "orderGroupId": [
                8
            ],
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupByIdResponse": {
            "orderGroup": [
                932
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupExpense": {
            "id": [
                8
            ],
            "expenseType": [
                937
            ],
            "amount": [
                68
            ],
            "vendor": [
                1445
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupExpenseInput": {
            "expenseType": [
                937
            ],
            "amount": [
                11
            ],
            "vendorId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupExpenseType": {},
        "OrderGroupLink": {
            "orderGroupId": [
                8
            ],
            "amountInHomeCurrency": [
                11
            ],
            "postedAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupsByIdsInput": {
            "companyId": [
                8
            ],
            "orderGroupIds": [
                8
            ],
            "withDeleted": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupsByIdsResponse": {
            "orderGroups": [
                932
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupsFilter": {
            "postedAt": [
                363
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupsInput": {
            "companyId": [
                1
            ],
            "filter": [
                941
            ],
            "sort": [
                944
            ],
            "limit": [
                33
            ],
            "offset": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupsResponse": {
            "orderGroups": [
                932
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupsSort": {
            "type": [
                946
            ],
            "direction": [
                945
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupsSortDirection": {},
        "OrderGroupsSortType": {},
        "OrderGroupsTotalUnclearedBalanceInput": {
            "companyId": [
                8
            ],
            "asOfDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupsTotalUnclearedBalanceResponse": {
            "asOfDate": [
                361
            ],
            "totalUnclearedBalance": [
                68
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupsUnclearedBalanceInput": {
            "companyId": [
                8
            ],
            "orderGroupIds": [
                8
            ],
            "asOfDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupsUnclearedBalanceResponse": {
            "asOfDate": [
                361
            ],
            "unclearedBalances": [
                951
            ],
            "__typename": [
                1
            ]
        },
        "OrderGroupUnclearedBalance": {
            "orderGroup": [
                932
            ],
            "unclearedBalance": [
                68
            ],
            "__typename": [
                1
            ]
        },
        "OrderIdInput": {
            "companyId": [
                8
            ],
            "orderId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "OrderInternalInput": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "OrderItemsFulfillmentV2": {
            "id": [
                8
            ],
            "trackingInfoRecords": [
                771
            ],
            "externalWarehouseId": [
                1
            ],
            "voidedAt": [
                361
            ],
            "fulfilledAt": [
                361
            ],
            "fulfilledQuantity": [
                33
            ],
            "voidedBy": [
                22
            ],
            "source": [
                930
            ],
            "createdAt": [
                361
            ],
            "updatedAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "OrderItemStatus": {},
        "OrderItemStatusWithOverride": {
            "status": [
                955
            ],
            "isOverridden": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "OrderItemType": {},
        "OrderItemV2": {
            "id": [
                8
            ],
            "companyId": [
                1
            ],
            "actionType": [
                920
            ],
            "type": [
                957
            ],
            "amount": [
                68
            ],
            "taxAmount": [
                11
            ],
            "taxAmountInHomeCurrency": [
                11
            ],
            "discountAmount": [
                11
            ],
            "discountAmountInHomeCurrency": [
                11
            ],
            "exchangeRateOverride": [
                11
            ],
            "taxExchangeRateOverride": [
                11
            ],
            "discountExchangeRateOverride": [
                11
            ],
            "taxes": [
                771
            ],
            "externalSourceId": [
                1
            ],
            "postedAt": [
                361
            ],
            "externalProductId": [
                1
            ],
            "warehouse": [
                1463
            ],
            "product": [
                1071
            ],
            "sku": [
                1
            ],
            "quantity": [
                33
            ],
            "unitPrice": [
                11
            ],
            "isRestocked": [
                5
            ],
            "fulfillments": [
                954
            ],
            "originalFulfillmentId": [
                1
            ],
            "voided": [
                771
            ],
            "isFulfillmentOverridden": [
                5
            ],
            "createdAt": [
                361
            ],
            "updatedAt": [
                361
            ],
            "status": [
                956
            ],
            "lineItemId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "OrderMatchedBankTransactionV2": {
            "id": [
                8
            ],
            "amount": [
                68
            ],
            "createdAt": [
                361
            ],
            "updatedAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "OrderOtherAccountingReconciliationRow": {
            "accountName": [
                1
            ],
            "accounts": [
                917
            ],
            "__typename": [
                1
            ]
        },
        "OrderPaymentGroup": {
            "id": [
                8
            ],
            "companyId": [
                1
            ],
            "postedAt": [
                361
            ],
            "createdAt": [
                361
            ],
            "updatedAt": [
                361
            ],
            "deletedAt": [
                361
            ],
            "orders": [
                1013
            ],
            "bankTransactions": [
                1011
            ],
            "expenses": [
                1006
            ],
            "__typename": [
                1
            ]
        },
        "OrderPaymentGroupByIdResponse": {
            "paymentGroup": [
                961
            ],
            "__typename": [
                1
            ]
        },
        "OrderPaymentGroupsByIdsResponse": {
            "paymentGroups": [
                961
            ],
            "__typename": [
                1
            ]
        },
        "OrderPaymentGroupsResponse": {
            "paymentGroups": [
                961
            ],
            "__typename": [
                1
            ]
        },
        "OrderPaymentGroupsUnclearedBalanceResponse": {
            "asOfDate": [
                361
            ],
            "unclearedBalances": [
                966
            ],
            "__typename": [
                1
            ]
        },
        "OrderPaymentGroupUnclearedBalance": {
            "paymentGroup": [
                961
            ],
            "unclearedBalance": [
                68
            ],
            "__typename": [
                1
            ]
        },
        "OrderPaymentStatus": {},
        "OrderReconciliationRow": {
            "title": [
                1
            ],
            "saleAmount": [
                11
            ],
            "taxAmount": [
                11
            ],
            "discountAmount": [
                11
            ],
            "shippingAmount": [
                11
            ],
            "refundAmount": [
                11
            ],
            "additionalTaxesAmount": [
                11
            ],
            "totalAmount": [
                11
            ],
            "netAmount": [
                11
            ],
            "subRows": [
                968
            ],
            "__typename": [
                1
            ]
        },
        "OrderReconciliationRowType": {},
        "OrderReconciliationsInput": {
            "startTime": [
                361
            ],
            "endTime": [
                361
            ],
            "integrationAccountId": [
                8
            ],
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "OrderReconciliationsResponse": {
            "booksRows": [
                968
            ],
            "excludedRows": [
                968
            ],
            "adjustedRows": [
                968
            ],
            "shopifyRow": [
                968
            ],
            "dateRange": [
                362
            ],
            "currency": [
                1
            ],
            "failureReason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "OrderRestock": {
            "id": [
                8
            ],
            "postedAt": [
                361
            ],
            "restockLineItems": [
                973
            ],
            "__typename": [
                1
            ]
        },
        "OrderRestockLineItem": {
            "id": [
                8
            ],
            "quantity": [
                33
            ],
            "lineItemId": [
                8
            ],
            "sku": [
                1
            ],
            "warehouse": [
                1463
            ],
            "__typename": [
                1
            ]
        },
        "OrdersByIdsInput": {
            "companyId": [
                8
            ],
            "orderIds": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "OrdersByIdsResponse": {
            "orders": [
                988
            ],
            "__typename": [
                1
            ]
        },
        "OrderSettings": {
            "void": [
                1459
            ],
            "__typename": [
                1
            ]
        },
        "OrderSettingsInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "OrderSettingsResponse": {
            "settings": [
                976
            ],
            "__typename": [
                1
            ]
        },
        "OrdersSettings": {
            "isZeroNetOrdersSupported": [
                5
            ],
            "shouldRecognizeZeroNetCogsAsGiveaways": [
                5
            ],
            "shopifyStoreV2StartDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "OrderStatus": {},
        "OrderStatusWithOverride": {
            "status": [
                980
            ],
            "isOverridden": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "OrderTag": {
            "id": [
                8
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "OrderToLinkInput": {
            "orderId": [
                8
            ],
            "amountInHomeCurrency": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "OrderTotalSummary": {
            "subtotal": [
                68
            ],
            "shippingIncome": [
                68
            ],
            "discounts": [
                68
            ],
            "netSale": [
                68
            ],
            "taxes": [
                68
            ],
            "additionalTaxes": [
                68
            ],
            "refunds": [
                68
            ],
            "fxAdjustment": [
                68
            ],
            "netOrder": [
                68
            ],
            "paid": [
                11
            ],
            "refunded": [
                11
            ],
            "netPaid": [
                11
            ],
            "uncollectible": [
                11
            ],
            "overCollected": [
                11
            ],
            "orderBalance": [
                11
            ],
            "balanceAsOf": [
                361
            ],
            "balanceAsOfAmount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "OrderTransactionMatchV2": {
            "id": [
                8
            ],
            "amount": [
                68
            ],
            "payment": [
                1283
            ],
            "__typename": [
                1
            ]
        },
        "OrderTransactionType": {},
        "OrderTransactionV2": {
            "id": [
                8
            ],
            "amount": [
                68
            ],
            "exchangeRateOverride": [
                11
            ],
            "type": [
                986
            ],
            "postedAt": [
                361
            ],
            "matches": [
                985
            ],
            "finaloopPaymentGateway": [
                1
            ],
            "originalPaymentGateway": [
                1
            ],
            "paymentGatewayVariant": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "createdAt": [
                361
            ],
            "updatedAt": [
                361
            ],
            "isManual": [
                5
            ],
            "voided": [
                771
            ],
            "authorizationCode": [
                1
            ],
            "isArtificiallyMatched": [
                5
            ],
            "matchedAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "OrderV2": {
            "id": [
                8
            ],
            "companyId": [
                1
            ],
            "matchedBankTransactions": [
                959
            ],
            "items": [
                958
            ],
            "transactions": [
                987
            ],
            "orderGroupLinks": [
                938
            ],
            "orderAdjustments": [
                921
            ],
            "totalSummary": [
                984
            ],
            "displayName": [
                1
            ],
            "currency": [
                1
            ],
            "source": [
                1
            ],
            "placedAt": [
                361
            ],
            "firstPaymentDate": [
                361
            ],
            "fullyPaidDate": [
                361
            ],
            "firstRefundDate": [
                361
            ],
            "firstFulfillmentDate": [
                361
            ],
            "latestFinancialDate": [
                361
            ],
            "salesChannel": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "customer": [
                350
            ],
            "isZeroNetOrder": [
                5
            ],
            "integrationTags": [
                1
            ],
            "finaloopTags": [
                982
            ],
            "finaloopNote": [
                1
            ],
            "integrationNote": [
                1
            ],
            "isChannelProductSalesAffecting": [
                5
            ],
            "isChannelAccountsAffecting": [
                5
            ],
            "isProductSalesAffecting": [
                5
            ],
            "isAccountsAffecting": [
                5
            ],
            "paymentStatus": [
                967
            ],
            "fulfillmentStatus": [
                931
            ],
            "balanceInHomeCurrency": [
                11
            ],
            "voidedAt": [
                361
            ],
            "uncollectibleAt": [
                361
            ],
            "shippingAddress": [
                344
            ],
            "billingAddress": [
                344
            ],
            "store": [
                1278
            ],
            "status": [
                981
            ],
            "integrationExternalUrl": [
                1
            ],
            "fulfillments": [
                928
            ],
            "restocks": [
                972
            ],
            "paymentGroupLinks": [
                1010
            ],
            "__typename": [
                1
            ]
        },
        "OrderV2NotFound": {
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "OrderV2Result": {
            "on_OrderV2": [
                988
            ],
            "on_OrderV2NotFound": [
                989
            ],
            "__typename": [
                1
            ]
        },
        "OverviewCycle": {
            "fromDate": [
                361
            ],
            "toDate": [
                361
            ],
            "openingBalance": [
                11
            ],
            "purchasesSum": [
                11
            ],
            "cogsSum": [
                11
            ],
            "cogsAdjustmentsSum": [
                11
            ],
            "closingBalance": [
                11
            ],
            "warehouseTransfersSum": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "OverviewCyclesInput": {
            "companyId": [
                8
            ],
            "fromDate": [
                361
            ],
            "toDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "PairedEntity": {
            "on_Bill": [
                186
            ],
            "on_Payout": [
                1039
            ],
            "on_Topup": [
                1315
            ],
            "on_BankTransaction": [
                147
            ],
            "on_CompanyAffiliate": [
                245
            ],
            "on_IntegrationTransfer": [
                671
            ],
            "__typename": [
                1
            ]
        },
        "PairedEntityType": {},
        "PairingType": {},
        "Payment": {
            "on_RecurringPayment": [
                1132
            ],
            "on_OneTimePayment": [
                913
            ],
            "__typename": [
                1
            ]
        },
        "PaymentCheck": {
            "id": [
                8
            ],
            "company": [
                241
            ],
            "source": [
                1
            ],
            "postedAt": [
                361
            ],
            "amount": [
                11
            ],
            "currency": [
                1
            ],
            "amountInHomeCurrency": [
                11
            ],
            "contractor": [
                293
            ],
            "employee": [
                422
            ],
            "matchedBankTransaction": [
                147
            ],
            "__typename": [
                1
            ]
        },
        "PaymentChecksInput": {
            "companyId": [
                8
            ],
            "amount": [
                11
            ],
            "currency": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PaymentDetails": {
            "id": [
                8
            ],
            "price": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGateway": {},
        "PaymentGatewaysByCompanyIntegrationAndSalesChannelInput": {
            "companyId": [
                8
            ],
            "integrationAccountId": [
                8
            ],
            "salesChannel": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGatewayType": {},
        "PaymentGroup": {
            "id": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupBankTransactionToLinkInput": {
            "transactionId": [
                8
            ],
            "amount": [
                11
            ],
            "amountInHomeCurrency": [
                11
            ],
            "currency": [
                340
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupByIdInput": {
            "paymentGroupId": [
                8
            ],
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupExpense": {
            "id": [
                8
            ],
            "expenseType": [
                1008
            ],
            "amount": [
                68
            ],
            "vendor": [
                1445
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupExpenseInput": {
            "expenseType": [
                1008
            ],
            "amount": [
                11
            ],
            "vendorId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupExpenseType": {},
        "PaymentGroupInvoiceToLinkInput": {
            "invoiceId": [
                8
            ],
            "amount": [
                11
            ],
            "amountInHomeCurrency": [
                11
            ],
            "currency": [
                340
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupLink": {
            "paymentGroupId": [
                8
            ],
            "amount": [
                11
            ],
            "amountInHomeCurrency": [
                11
            ],
            "currency": [
                340
            ],
            "postedAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupLinkedBankTransaction": {
            "bankTransaction": [
                147
            ],
            "bankTransactionPostedAt": [
                361
            ],
            "amount": [
                68
            ],
            "id": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupLinkedInvoice": {
            "invoice": [
                744
            ],
            "amountInHomeCurrency": [
                11
            ],
            "currency": [
                340
            ],
            "amount": [
                11
            ],
            "id": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupLinkedOrder": {
            "order": [
                988
            ],
            "amountInHomeCurrency": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupLinksForInvoiceInput": {
            "companyId": [
                1
            ],
            "invoiceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupLinksForInvoiceResponse": {
            "paymentGroupLinks": [
                1010
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupLinksInput": {
            "companyId": [
                1
            ],
            "saleId": [
                1
            ],
            "paymentGroupType": [
                1030
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupLinksResponse": {
            "paymentGroupLinks": [
                1010
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupOrderToLinkInput": {
            "orderId": [
                8
            ],
            "amountInHomeCurrency": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupsByIdsInput": {
            "companyId": [
                8
            ],
            "paymentGroupIds": [
                8
            ],
            "withDeleted": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupsByIdsResponse": {
            "paymentGroups": [
                762
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupsFilter": {
            "postedAt": [
                363
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupsInput": {
            "companyId": [
                1
            ],
            "filter": [
                1021
            ],
            "sort": [
                1023
            ],
            "limit": [
                33
            ],
            "offset": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupsSort": {
            "type": [
                1025
            ],
            "direction": [
                1024
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupsSortDirection": {},
        "PaymentGroupsSortType": {},
        "PaymentGroupsTotalUnclearedBalanceInput": {
            "companyId": [
                8
            ],
            "asOfDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupsTotalUnclearedBalanceResponse": {
            "asOfDate": [
                361
            ],
            "totalUnclearedBalance": [
                68
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupsUnclearedBalanceInput": {
            "companyId": [
                8
            ],
            "paymentGroupIds": [
                8
            ],
            "asOfDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupTransaction": {
            "id": [
                8
            ],
            "integrationInfo": [
                666
            ],
            "amount": [
                11
            ],
            "postedAt": [
                361
            ],
            "currency": [
                340
            ],
            "amountInHomeCurrency": [
                11
            ],
            "type": [
                1321
            ],
            "finaloopPaymentGateway": [
                1
            ],
            "originalPaymentGateway": [
                1
            ],
            "paymentGatewayVariant": [
                1
            ],
            "isIndication": [
                5
            ],
            "paymentGroup": [
                1003
            ],
            "__typename": [
                1
            ]
        },
        "PaymentGroupType": {},
        "PaymentMethod": {
            "id": [
                8
            ],
            "type": [
                1033
            ],
            "isDefault": [
                5
            ],
            "instructions": [
                1
            ],
            "bankName": [
                1
            ],
            "accountNumber": [
                1
            ],
            "routingNumber": [
                1
            ],
            "swift": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PaymentMethodInput": {
            "companyId": [
                8
            ],
            "id": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "PaymentMethodType": {},
        "PaymentOptionInput": {
            "stripeId": [
                1
            ],
            "price": [
                11
            ],
            "recurrence": [
                1037
            ],
            "__typename": [
                1
            ]
        },
        "PaymentProcessorByCompanyAndVendorInput": {
            "companyId": [
                8
            ],
            "vendorId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "PaymentProcessorByCompanyAndVendorResponse": {
            "processor": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PaymentRecurrence": {},
        "PaymentsByEntityInput": {
            "companyId": [
                8
            ],
            "startDate": [
                361
            ],
            "endDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "Payout": {
            "id": [
                8
            ],
            "companyId": [
                1
            ],
            "amount": [
                11
            ],
            "amountInUsd": [
                11
            ],
            "currency": [
                1
            ],
            "eventTime": [
                361
            ],
            "deletedAt": [
                361
            ],
            "integrationType": [
                684
            ],
            "pairedBankTransaction": [
                147
            ],
            "type": [
                683
            ],
            "classification": [
                672
            ],
            "integrationAccountId": [
                8
            ],
            "integrationAccountName": [
                1
            ],
            "pairedCompanyAffiliate": [
                245
            ],
            "link": [
                1
            ],
            "uploadedFileId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Payroll": {
            "id": [
                8
            ],
            "company": [
                241
            ],
            "source": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "latestActorType": [
                22
            ],
            "latestActorId": [
                1
            ],
            "currency": [
                1
            ],
            "postedAt": [
                361
            ],
            "deletedAt": [
                361
            ],
            "createdAt": [
                361
            ],
            "updatedAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "PayrollCategory": {},
        "PayrollCategoryMapping": {
            "on_PayrollDepartmentCategoryMapping": [
                1052
            ],
            "on_PayrollEmployeeCategoryMapping": [
                1053
            ],
            "on_PayrollContractorCategoryMapping": [
                1049
            ],
            "__typename": [
                1
            ]
        },
        "PayrollCategoryMappingInput": {
            "id": [
                8
            ],
            "type": [
                1056
            ],
            "category": [
                1041
            ],
            "__typename": [
                1
            ]
        },
        "PayrollCategoryMappingMode": {},
        "PayrollConnection": {
            "integrationAccountId": [
                8
            ],
            "integrationType": [
                684
            ],
            "integrationAccountName": [
                1
            ],
            "importStatus": [
                1046
            ],
            "disconnectedAt": [
                361
            ],
            "disconnectedBy": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PayrollConnectionImportStatus": {
            "contractorPayments": [
                1047
            ],
            "contractors": [
                1047
            ],
            "departments": [
                1047
            ],
            "employees": [
                1047
            ],
            "payrolls": [
                1047
            ],
            "__typename": [
                1
            ]
        },
        "PayrollConnectionImportStatusData": {
            "lastUpdateTime": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "PayrollConnectionsInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "PayrollContractorCategoryMapping": {
            "contractor": [
                293
            ],
            "category": [
                1041
            ],
            "__typename": [
                1
            ]
        },
        "PayrollDailyAggregate": {
            "id": [
                8
            ],
            "currency": [
                1
            ],
            "postedAt": [
                361
            ],
            "source": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "startDate": [
                361
            ],
            "endDate": [
                361
            ],
            "unallocatedHealthBenefitsContribution": [
                11
            ],
            "unallocatedCompanyTaxes": [
                11
            ],
            "unallocatedDonationDeductions": [
                11
            ],
            "pension401k": [
                11
            ],
            "deletedAt": [
                361
            ],
            "paymentChecks": [
                354
            ],
            "reimbursements": [
                356
            ],
            "ownersDraw": [
                353
            ],
            "taxDebitAmount": [
                11
            ],
            "netPayDebitAmount": [
                11
            ],
            "dailyAggregates": [
                355
            ],
            "__typename": [
                1
            ]
        },
        "PayrollDailyDataInput": {
            "companyId": [
                8
            ],
            "payrollIds": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "PayrollDepartmentCategoryMapping": {
            "department": [
                402
            ],
            "category": [
                1041
            ],
            "__typename": [
                1
            ]
        },
        "PayrollEmployeeCategoryMapping": {
            "employee": [
                422
            ],
            "category": [
                1041
            ],
            "__typename": [
                1
            ]
        },
        "PayrollEmploymentType": {},
        "PayrollItemToReconcile": {
            "companyId": [
                8
            ],
            "payrollId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "PayrollMappableType": {},
        "PayrollSettings": {
            "withCategoryMapping": [
                5
            ],
            "categoryMappingMode": [
                1044
            ],
            "categoryMappings": [
                1042
            ],
            "__typename": [
                1
            ]
        },
        "PayrollSettingsInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "PendingWarehouseOpeningBalance": {
            "id": [
                8
            ],
            "warehouse": [
                1463
            ],
            "quantity": [
                33
            ],
            "costPerUnit": [
                11
            ],
            "date": [
                361
            ],
            "attachmentId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PendingWarehouseOpeningBalanceAttachment": {
            "id": [
                8
            ],
            "companyId": [
                1
            ],
            "date": [
                361
            ],
            "filename": [
                1
            ],
            "url": [
                1
            ],
            "createdAt": [
                361
            ],
            "deletedAt": [
                361
            ],
            "pendingChangesCount": [
                33
            ],
            "summaryData": [
                1061
            ],
            "__typename": [
                1
            ]
        },
        "PendingWarehouseOpeningBalancesAttachmentSummary": {
            "totalCount": [
                33
            ],
            "validCount": [
                33
            ],
            "invalidCount": [
                33
            ],
            "errorCount": [
                33
            ],
            "errorItems": [
                1478
            ],
            "__typename": [
                1
            ]
        },
        "PendingWarehouseOpeningBalancesResponse": {
            "items": [
                1480
            ],
            "__typename": [
                1
            ]
        },
        "PendingWarehouseOpeningBalancesWithCsvResponse": {
            "attachmentId": [
                8
            ],
            "summaryData": [
                1061
            ],
            "__typename": [
                1
            ]
        },
        "PeriodDates": {
            "periodStart": [
                361
            ],
            "periodEnd": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "Permission": {
            "id": [
                8
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PollCheckoutSessionStatusInput": {
            "checkoutSessionId": [
                1
            ],
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "PresignedUrl": {
            "url": [
                1
            ],
            "presignedUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ProcessBillContentInput": {
            "companyId": [
                8
            ],
            "filePathInBucket": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ProcessBillContentResult": {
            "isSuccessful": [
                5
            ],
            "id": [
                8
            ],
            "vendor": [
                1445
            ],
            "categories": [
                214
            ],
            "date": [
                361
            ],
            "dueDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "ProcessCheckoutWebhookEventsInput": {
            "webhookEvent": [
                771
            ],
            "__typename": [
                1
            ]
        },
        "Product": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "finaloopId": [
                1
            ],
            "displayName": [
                1
            ],
            "imageUrl": [
                1
            ],
            "sku": [
                1
            ],
            "lineOfBusinessId": [
                1
            ],
            "listedChannels": [
                1158
            ],
            "appliedCosts": [
                1073
            ],
            "dailySalesData": [
                1159,
                {
                    "timeRange": [
                        1079,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "totalSold": [
                33
            ],
            "avgCostPerUnit": [
                11
            ],
            "price": [
                11
            ],
            "canEditLatestAppliedCost": [
                5
            ],
            "isFractional": [
                5
            ],
            "isHidden": [
                5
            ],
            "salesData": [
                1171,
                {
                    "timeRange": [
                        1079,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "salesData2": [
                1171,
                {
                    "timeRange": [
                        1079,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "rawRecipes": [
                1113,
                {
                    "timeRange": [
                        1079,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "componentOfProducts": [
                1071
            ],
            "analyticsOverview": [
                1072,
                {
                    "input": [
                        69
                    ]
                }
            ],
            "warehousesAnalyticsOverview": [
                1098,
                {
                    "input": [
                        69
                    ]
                }
            ],
            "tags": [
                1
            ],
            "isDataSyncing": [
                5
            ],
            "on_AmazonProduct": [
                50
            ],
            "on_MergedProduct": [
                881
            ],
            "on_ShopifyProduct": [
                1244
            ],
            "on_TikTokProduct": [
                1309
            ],
            "on_UserManualProduct": [
                1436
            ],
            "on_WalmartProduct": [
                1462
            ],
            "__typename": [
                1
            ]
        },
        "ProductAnalyticsOverview": {
            "inTransit": [
                11
            ],
            "incoming": [
                11
            ],
            "netDepleted": [
                11
            ],
            "onHand": [
                11
            ],
            "costPerUnit": [
                11
            ],
            "onHandCost": [
                11
            ],
            "unavailable": [
                11
            ],
            "openingBalanceCost": [
                11
            ],
            "openingBalanceQuantity": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "ProductAppliedCost": {
            "asOfDate": [
                361
            ],
            "appliedCost": [
                11
            ],
            "source": [
                1074
            ],
            "isLocked": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ProductAppliedCostSource": {},
        "ProductAppliedCostWithDepletedCount": {
            "asOfDate": [
                361
            ],
            "appliedCost": [
                11
            ],
            "source": [
                1074
            ],
            "depletedCount": [
                33
            ],
            "isLocked": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ProductAssembliesBreakdownInput": {
            "companyId": [
                8
            ],
            "productId": [
                1
            ],
            "date": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "ProductAssembliesBreakdownResult": {
            "manualAssemblies": [
                854
            ],
            "onDemandAssemblies": [
                912
            ],
            "__typename": [
                1
            ]
        },
        "ProductAttributesTypes": {},
        "ProductDataTimeRangeInput": {
            "fromDate": [
                361
            ],
            "toDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "ProductDeadInventoryAdjustment": {
            "id": [
                1
            ],
            "finaloopProductId": [
                1
            ],
            "date": [
                361
            ],
            "unitsDeadInventory": [
                11
            ],
            "warehouse": [
                1463
            ],
            "__typename": [
                1
            ]
        },
        "ProductDeadInventoryAdjustments": {
            "adjustments": [
                1080
            ],
            "__typename": [
                1
            ]
        },
        "ProductDonationAdjustment": {
            "finaloopProductId": [
                1
            ],
            "date": [
                361
            ],
            "unitsDonated": [
                33
            ],
            "warehouse": [
                1463
            ],
            "__typename": [
                1
            ]
        },
        "ProductDonationAdjustments": {
            "adjustments": [
                1082
            ],
            "__typename": [
                1
            ]
        },
        "ProductGiveawayAdjustment": {
            "finaloopProductId": [
                1
            ],
            "date": [
                361
            ],
            "unitsGiveaway": [
                33
            ],
            "warehouse": [
                1463
            ],
            "__typename": [
                1
            ]
        },
        "ProductGiveawayAdjustments": {
            "adjustments": [
                1084
            ],
            "__typename": [
                1
            ]
        },
        "ProductPurchases": {
            "purchaseOrder": [
                1100
            ],
            "__typename": [
                1
            ]
        },
        "Products2Result": {
            "products": [
                1071
            ],
            "hasNext": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ProductSalesDataAdjustment": {
            "salesChannel": [
                1158
            ],
            "date": [
                361
            ],
            "unitsSold": [
                33
            ],
            "unitsRestocked": [
                33
            ],
            "warehouse": [
                1463
            ],
            "__typename": [
                1
            ]
        },
        "ProductSalesDataAdjustments": {
            "adjustments": [
                1088
            ],
            "__typename": [
                1
            ]
        },
        "ProductSource": {},
        "ProductsOverviewInput": {
            "companyId": [
                8
            ],
            "filter": [
                574
            ],
            "__typename": [
                1
            ]
        },
        "ProductsOverviewResponse": {
            "totalOnHandInventory": [
                11
            ],
            "totalNegativeOnHand": [
                11
            ],
            "productsWithoutCostCount": [
                33
            ],
            "totalProducts": [
                33
            ],
            "totalHidden": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "ProductsResult": {
            "totalProducts": [
                33
            ],
            "productsWithoutCostCount": [
                33
            ],
            "totalOnHandInventory": [
                11
            ],
            "totalNegativeOnHand": [
                33
            ],
            "totalHidden": [
                33
            ],
            "products": [
                1071
            ],
            "hasNext": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ProductsSortDirection": {},
        "ProductStatusChangeInput": {
            "productId": [
                1
            ],
            "isHidden": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ProductsValidationData": {
            "skuData": [
                1265
            ],
            "ambiguousSkus": [
                899
            ],
            "unknownSkus": [
                899
            ],
            "__typename": [
                1
            ]
        },
        "ProductsValidationDataInput": {
            "companyId": [
                8
            ],
            "integrationType": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "isUIOperation": [
                5
            ],
            "timeRange": [
                1079
            ],
            "__typename": [
                1
            ]
        },
        "ProductWarehousesAnalyticsOverview": {
            "warehouse": [
                1463
            ],
            "analytics": [
                1072
            ],
            "__typename": [
                1
            ]
        },
        "PurchasedOffer": {
            "purchasedAt": [
                361
            ],
            "tier": [
                905
            ],
            "features": [
                445
            ],
            "price": [
                11
            ],
            "title": [
                1
            ],
            "description": [
                1
            ],
            "recurrence": [
                1037
            ],
            "__typename": [
                1
            ]
        },
        "PurchaseOrder": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "displayName": [
                1
            ],
            "placementDate": [
                361
            ],
            "inProgressDate": [
                361
            ],
            "vendor": [
                1445
            ],
            "directLineItems": [
                404
            ],
            "indirectLineItems": [
                644
            ],
            "shipments": [
                1219
            ],
            "directCostTotal": [
                11
            ],
            "indirectCostTotal": [
                11
            ],
            "landedCostTotal": [
                11
            ],
            "landedCostBreakdown": [
                1104
            ],
            "discountCostTotal": [
                11
            ],
            "status": [
                1108
            ],
            "publishedEventIds": [
                1
            ],
            "deletedAt": [
                361
            ],
            "isDeleted": [
                5
            ],
            "serialNumber": [
                33
            ],
            "notes": [
                1
            ],
            "attachments": [
                1101
            ],
            "__typename": [
                1
            ]
        },
        "PurchaseOrderAttachment": {
            "id": [
                8
            ],
            "filename": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PurchaseOrderAttachmentInput": {
            "id": [
                1
            ],
            "filename": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "PurchaseOrderInput": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "displayName": [
                1
            ],
            "placementDate": [
                361
            ],
            "vendorId": [
                8
            ],
            "directLineItems": [
                405
            ],
            "indirectLineItems": [
                645
            ],
            "shipments": [
                1227
            ],
            "directCostTotal": [
                11
            ],
            "indirectCostTotal": [
                11
            ],
            "landedCostTotal": [
                11
            ],
            "discountCostTotal": [
                11
            ],
            "inProgressDate": [
                361
            ],
            "notes": [
                1
            ],
            "attachments": [
                1102
            ],
            "__typename": [
                1
            ]
        },
        "PurchaseOrderLandedCostBreakdown": {
            "inTransit": [
                11
            ],
            "inProcess": [
                11
            ],
            "received": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "PurchaseOrderOverview": {
            "status": [
                1108
            ],
            "landedCostTotal": [
                11
            ],
            "totalPurchaseOrders": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "PurchaseOrderOverviewV2": {
            "maxLandedCostTotal": [
                11
            ],
            "purchaseOrderOverview": [
                1105
            ],
            "__typename": [
                1
            ]
        },
        "PurchaseOrdersSortField": {},
        "PurchaseOrderStatus": {},
        "Query": {
            "accrualsSpreadByTransactionIDs": [
                14,
                {
                    "input": [
                        485,
                        "GetAccrualsSpreadInput!"
                    ]
                }
            ],
            "getAccrualsByTransactionIDs": [
                7,
                {
                    "input": [
                        484,
                        "GetAccrualsByTransactionIDsInput!"
                    ]
                }
            ],
            "reportedBusinessBankTransactionsAccount": [
                95,
                {
                    "input": [
                        1141,
                        "ReportedBusinessBankTransactionsAccountInput!"
                    ]
                }
            ],
            "bankAccountInternal": [
                95,
                {
                    "input": [
                        102,
                        "BankAccountInternalInput!"
                    ]
                }
            ],
            "institutionVendorsIds": [
                656,
                {
                    "vendorId": [
                        8
                    ]
                }
            ],
            "institutionMappings": [
                650,
                {
                    "input": [
                        651
                    ]
                }
            ],
            "bankAccount": [
                95,
                {
                    "input": [
                        101,
                        "BankAccountInput!"
                    ]
                }
            ],
            "bankAccounts": [
                95,
                {
                    "input": [
                        113,
                        "BankAccountsInput!"
                    ]
                }
            ],
            "bankAccountsByIds": [
                95,
                {
                    "input": [
                        112,
                        "BankAccountsByIdsInput!"
                    ]
                }
            ],
            "bankAccountReconciliations": [
                109,
                {
                    "input": [
                        110,
                        "BankAccountReconciliationsInput!"
                    ]
                }
            ],
            "bankAccountBalance": [
                11,
                {
                    "input": [
                        97,
                        "BankAccountBalanceInput!"
                    ]
                }
            ],
            "institutions": [
                655,
                {
                    "input": [
                        654
                    ]
                }
            ],
            "bankTransaction": [
                147,
                {
                    "getBankTransactionInput": [
                        159,
                        "BankTransactionInput!"
                    ]
                }
            ],
            "bankTransactions": [
                163,
                {
                    "input": [
                        166,
                        "BankTransactionsInput!"
                    ]
                }
            ],
            "bankTransactionsByMerchant": [
                152,
                {
                    "bankTransactionsByMerchantInput": [
                        164,
                        "BankTransactionsByMerchantInput!"
                    ]
                }
            ],
            "searchMerchants": [
                870,
                {
                    "input": [
                        1186,
                        "SearchMerchantsInput!"
                    ]
                }
            ],
            "searchVendorsTransactionsPriority": [
                1188,
                {
                    "input": [
                        1187,
                        "SearchVendorsTransactionPriorityInput!"
                    ]
                }
            ],
            "classificationTags": [
                225,
                {
                    "input": [
                        228
                    ]
                }
            ],
            "classificationTagsByIds": [
                225,
                {
                    "input": [
                        227,
                        "ClassificationTagsByIdsInput!"
                    ]
                }
            ],
            "internalTransferCandidates": [
                163,
                {
                    "input": [
                        685,
                        "InternalTransferCandidatesInput!"
                    ]
                }
            ],
            "internalTransferCandidatesV2": [
                167,
                {
                    "input": [
                        686,
                        "internalTransferCandidatesV2Input!"
                    ]
                }
            ],
            "unidentifiedBankTransactionsGroups": [
                1328,
                {
                    "input": [
                        1329,
                        "UnidentifiedBankTransactionsGroupsInput!"
                    ]
                }
            ],
            "merchantsBankTransactionStats": [
                875,
                {
                    "input": [
                        876,
                        "MerchantsBankTransactionStatsInput!"
                    ]
                }
            ],
            "bankTransactionsSummary": [
                169,
                {
                    "input": [
                        166,
                        "BankTransactionsInput!"
                    ]
                }
            ],
            "merchantsByIds": [
                870,
                {
                    "input": [
                        877,
                        "MerchantsByIdsInput!"
                    ]
                }
            ],
            "suggestBankTransactionClassification": [
                1290,
                {
                    "input": [
                        1289,
                        "SuggestBankTransactionClassificationInput!"
                    ]
                }
            ],
            "lastMachineClassificationJob": [
                849,
                {
                    "input": [
                        848,
                        "MachineClassificationInput!"
                    ]
                }
            ],
            "merchantsByKeys": [
                870,
                {
                    "input": [
                        878,
                        "MerchantsByKeysInput!"
                    ]
                }
            ],
            "bankTransactionUploads": [
                170,
                {
                    "input": [
                        178,
                        "BankTransactionUploadsInput!"
                    ]
                }
            ],
            "bankTransactionsByPairings": [
                162,
                {
                    "input": [
                        165,
                        "BankTransactionsByPairingsInput!"
                    ]
                }
            ],
            "isGlobalRuleExistByVendorAndMoneyDirection": [
                765,
                {
                    "input": [
                        605,
                        "GlobalRuleExistByVendorAndMoneyDirectionInput!"
                    ]
                }
            ],
            "companyByStripeCustomerId": [
                241,
                {
                    "args": [
                        259,
                        "CompanyByStripeCustomerIdInput!"
                    ]
                }
            ],
            "stripeCustomerIdsByCompanyIds": [
                1285,
                {
                    "args": [
                        1284,
                        "StripeCustomerIdsByCompanyIdsInput!"
                    ]
                }
            ],
            "offers": [
                558,
                {
                    "args": [
                        557,
                        "GetOffersInput!"
                    ]
                }
            ],
            "billsByIds": [
                186,
                {
                    "input": [
                        8,
                        "[ID!]!"
                    ]
                }
            ],
            "billsDueDateSummary": [
                194,
                {
                    "input": [
                        195,
                        "BillsDueDateSummaryInput!"
                    ]
                }
            ],
            "processBillContent": [
                1069,
                {
                    "input": [
                        1068,
                        "ProcessBillContentInput!"
                    ]
                }
            ],
            "businessEventDefinitions": [
                200
            ],
            "businessEventDefinitionsByIds": [
                200,
                {
                    "input": [
                        202,
                        "BusinessEventDefinitionsByIds!"
                    ]
                }
            ],
            "businessEventDefinitionsByGroups": [
                200,
                {
                    "input": [
                        201,
                        "BusinessEventDefinitionsByGroups!"
                    ]
                }
            ],
            "businessEventActionsConfigurations": [
                196
            ],
            "businessEventActionsConfigurationByType": [
                196,
                {
                    "input": [
                        197,
                        "BusinessEventActionsConfigurationByType!"
                    ]
                }
            ],
            "classificationBusinessEventDefinitions": [
                200
            ],
            "businessEventsAccrualsConfig": [
                206
            ],
            "company": [
                241,
                {
                    "args": [
                        497,
                        "GetCompanyInput!"
                    ]
                }
            ],
            "getCompanyAddresses": [
                243,
                {
                    "input": [
                        266,
                        "CompanyIdInput!"
                    ]
                }
            ],
            "getCompanyDefaultAddress": [
                243,
                {
                    "input": [
                        266,
                        "CompanyIdInput!"
                    ]
                }
            ],
            "signedDownloadUrl": [
                1,
                {
                    "args": [
                        1252,
                        "SignedDownloadUrlInput!"
                    ]
                }
            ],
            "onboardingStatus": [
                908,
                {
                    "input": [
                        560,
                        "GetOnboardingStatusInput!"
                    ]
                }
            ],
            "listCompanies": [
                241,
                {
                    "args": [
                        786
                    ]
                }
            ],
            "getCompanies": [
                241,
                {
                    "args": [
                        496
                    ]
                }
            ],
            "getCompaniesByIds": [
                241,
                {
                    "args": [
                        495,
                        "GetCompaniesByIdsInput!"
                    ]
                }
            ],
            "healthStatus": [
                263,
                {
                    "input": [
                        516,
                        "GetHealthStatusInput!"
                    ]
                }
            ],
            "companyMainHolder": [
                245,
                {
                    "input": [
                        277,
                        "CompanyMainHolderInput!"
                    ]
                }
            ],
            "companyAffiliatesByIds": [
                245,
                {
                    "input": [
                        247,
                        "CompanyAffiliatesByIdsInput!"
                    ]
                }
            ],
            "affiliatedEntityById": [
                36,
                {
                    "input": [
                        37,
                        "AffiliatedEntityByIdInput!"
                    ]
                }
            ],
            "holderTransactionsByIds": [
                630,
                {
                    "input": [
                        631,
                        "HolderTransactionsByIdsInput!"
                    ]
                }
            ],
            "holderSharesByIds": [
                626,
                {
                    "input": [
                        627,
                        "HolderSharesByIdsInput!"
                    ]
                }
            ],
            "holderCapitalInvestmentsByIds": [
                623,
                {
                    "input": [
                        624,
                        "HolderCapitalInvestmentsByIdsInput!"
                    ]
                }
            ],
            "companyAffiliates": [
                245,
                {
                    "input": [
                        248,
                        "CompanyAffiliatesInput!"
                    ]
                }
            ],
            "companyAffiliate": [
                245,
                {
                    "input": [
                        246,
                        "CompanyAffiliateInput!"
                    ]
                }
            ],
            "holderShares": [
                626,
                {
                    "input": [
                        628,
                        "HolderSharesInput!"
                    ]
                }
            ],
            "holderCapitalInvestments": [
                623,
                {
                    "input": [
                        625,
                        "HolderCapitalInvestmentsInput!"
                    ]
                }
            ],
            "holderTransactions": [
                630,
                {
                    "input": [
                        632,
                        "HolderTransactionsInput!"
                    ]
                }
            ],
            "companyIntegration": [
                267,
                {
                    "input": [
                        268,
                        "CompanyIntegrationInput!"
                    ]
                }
            ],
            "companyIntegrations": [
                267,
                {
                    "input": [
                        269,
                        "CompanyIntegrationsInput!"
                    ]
                }
            ],
            "bankConnections": [
                124,
                {
                    "input": [
                        129,
                        "BankConnectionsInput!"
                    ]
                }
            ],
            "payrollConnections": [
                1045,
                {
                    "input": [
                        1048,
                        "PayrollConnectionsInput!"
                    ]
                }
            ],
            "integrationConnectionAccounts": [
                658,
                {
                    "input": [
                        659,
                        "IntegrationConnectionsFilter!"
                    ]
                }
            ],
            "integrationTransfersByCompanyAffiliate": [
                671,
                {
                    "input": [
                        680,
                        "IntegrationTransfersByCompanyAffiliateInput!"
                    ]
                }
            ],
            "integrationTransfersBackoffice": [
                671,
                {
                    "input": [
                        519,
                        "GetIntegrationTransfersBackofficeInput!"
                    ]
                }
            ],
            "integrationTransfer": [
                671,
                {
                    "input": [
                        518,
                        "GetIntegrationTransferInput!"
                    ]
                }
            ],
            "integrationTransfers": [
                671,
                {
                    "input": [
                        520,
                        "GetIntegrationTransfersInput!"
                    ]
                }
            ],
            "groupedIntegrationTransfers": [
                674,
                {
                    "input": [
                        520,
                        "GetIntegrationTransfersInput!"
                    ]
                }
            ],
            "finaloopProductInternal": [
                466,
                {
                    "input": [
                        467,
                        "FinaloopProductInternalInput!"
                    ]
                }
            ],
            "inventoryPurchasesForCompany": [
                689,
                {
                    "input": [
                        527,
                        "GetInventoryPurchasesForCompanyInput!"
                    ]
                }
            ],
            "inventoryPurchasesForCompanies": [
                689,
                {
                    "input": [
                        526,
                        "GetInventoryPurchasesForCompaniesInput!"
                    ]
                }
            ],
            "inventoryPurchasesByIds": [
                689,
                {
                    "input": [
                        523,
                        "GetInventoryPurchasesByIdsInput!"
                    ]
                }
            ],
            "downloadExplainFifo": [
                416,
                {
                    "input": [
                        415,
                        "DownloadExplainFifoInput!"
                    ]
                }
            ],
            "explainFifo": [
                429,
                {
                    "input": [
                        428,
                        "ExplainFifoInput!"
                    ]
                }
            ],
            "getCOGSBreakdown2": [
                489,
                {
                    "input": [
                        488,
                        "GetCOGSBreakdownRequest!"
                    ]
                }
            ],
            "exportCOGSBreakdown": [
                434,
                {
                    "input": [
                        433,
                        "ExportCOGSBreakdownRequest!"
                    ]
                }
            ],
            "getCOGSOverview2": [
                494,
                {
                    "input": [
                        493,
                        "GetCOGSOverviewRequest!"
                    ]
                }
            ],
            "exportCOGSOverview": [
                436,
                {
                    "input": [
                        435,
                        "ExportCOGSOverviewRequest!"
                    ]
                }
            ],
            "product": [
                1071,
                {
                    "input": [
                        573,
                        "GetProductInput!"
                    ]
                }
            ],
            "productAssembliesInventoryBreakdown": [
                1077,
                {
                    "input": [
                        1076,
                        "ProductAssembliesBreakdownInput!"
                    ]
                }
            ],
            "productsCsv": [
                441,
                {
                    "input": [
                        575,
                        "GetProductsInput!"
                    ]
                }
            ],
            "products2": [
                1087,
                {
                    "input": [
                        575,
                        "GetProductsInput!"
                    ]
                }
            ],
            "productsOverview": [
                1092,
                {
                    "input": [
                        1091,
                        "ProductsOverviewInput!"
                    ]
                }
            ],
            "purchaseOrder": [
                1100,
                {
                    "input": [
                        578,
                        "GetPurchaseOrderInput!"
                    ]
                }
            ],
            "purchaseOrders": [
                1100,
                {
                    "input": [
                        579,
                        "GetPurchaseOrdersInput!"
                    ]
                }
            ],
            "purchaseOrdersV2": [
                1100,
                {
                    "input": [
                        579,
                        "GetPurchaseOrdersInput!"
                    ]
                }
            ],
            "purchaseOrdersV2Total": [
                33,
                {
                    "input": [
                        579,
                        "GetPurchaseOrdersInput!"
                    ]
                }
            ],
            "purchaseOrdersOverview": [
                1105,
                {
                    "input": [
                        580,
                        "GetPurchaseOrdersOverviewInput!"
                    ]
                }
            ],
            "purchaseOrdersOverviewV2": [
                1106,
                {
                    "input": [
                        580,
                        "GetPurchaseOrdersOverviewInput!"
                    ]
                }
            ],
            "warehouses": [
                1486,
                {
                    "input": [
                        600,
                        "GetWarehousesInput!"
                    ]
                }
            ],
            "warehouseIncomingInventoryOverview": [
                1475,
                {
                    "input": [
                        1474,
                        "WarehouseIncomingInventoryOverviewInput!"
                    ]
                }
            ],
            "warehouseIncomingInventoryBreakdown": [
                1473,
                {
                    "input": [
                        1472,
                        "WarehouseIncomingInventoryBreakdownInput!"
                    ]
                }
            ],
            "productsValidationData2": [
                1096,
                {
                    "input": [
                        1097,
                        "ProductsValidationDataInput!"
                    ]
                }
            ],
            "warehouseDefaults": [
                1490,
                {
                    "input": [
                        599,
                        "GetWarehousesDefaultsInput!"
                    ]
                }
            ],
            "salesChannels": [
                1158,
                {
                    "input": [
                        1167,
                        "SalesChannelsInput!"
                    ]
                }
            ],
            "currentlyRunningInventoryDataSync": [
                342,
                {
                    "input": [
                        341,
                        "CurrentlyRunningInventoryDataSyncInput!"
                    ]
                }
            ],
            "inventoryAccountBalance": [
                522,
                {
                    "input": [
                        521,
                        "GetInventoryAccountBalanceInput!"
                    ]
                }
            ],
            "getOnboardingStatuses": [
                909,
                {
                    "input": [
                        559,
                        "GetOnboardingStatusesInput!"
                    ]
                }
            ],
            "nonMatchedProducts": [
                900,
                {
                    "input": [
                        556,
                        "GetNonMatchingProductsInput!"
                    ]
                }
            ],
            "getFromEmailAddress": [
                512,
                {
                    "input": [
                        510,
                        "GetFromEmailAddressInput!"
                    ]
                }
            ],
            "inventoryPurchases": [
                704,
                {
                    "input": [
                        528,
                        "GetInventoryPurchasesInput!"
                    ]
                }
            ],
            "inventoryPurchasesExport": [
                701,
                {
                    "input": [
                        524,
                        "GetInventoryPurchasesExportInput!"
                    ]
                }
            ],
            "inventoryPurchasesSummary": [
                708,
                {
                    "input": [
                        529,
                        "GetInventoryPurchaseSummaryInput!"
                    ]
                }
            ],
            "inventoryPurchasesValidationResult": [
                714,
                {
                    "input": [
                        530,
                        "GetInventoryPurchasesValidationResult!"
                    ]
                }
            ],
            "inventoryOverviewCycles": [
                991,
                {
                    "input": [
                        992,
                        "OverviewCyclesInput!"
                    ]
                }
            ],
            "shopifyItemsReconciliation": [
                1237,
                {
                    "input": [
                        1230,
                        "ShopifyItemsReconciliationInput!"
                    ]
                }
            ],
            "shopifyItemsReconciliationOrders": [
                1236,
                {
                    "input": [
                        1235,
                        "ShopifyItemsReconciliationOrdersInput!"
                    ]
                }
            ],
            "shopifyItemsReconciliationOrdersDiff": [
                1233,
                {
                    "input": [
                        1232,
                        "ShopifyItemsReconciliationOrdersDiffInput!"
                    ]
                }
            ],
            "amazonProductsReconciliation": [
                51,
                {
                    "input": [
                        65,
                        "AmazonValidationInput!"
                    ]
                }
            ],
            "amazonAvailableValidationData": [
                47,
                {
                    "input": [
                        46,
                        "AmazonAvailableValidationDataInput!"
                    ]
                }
            ],
            "amazonOrdersDiff": [
                49,
                {
                    "input": [
                        65,
                        "AmazonValidationInput!"
                    ]
                }
            ],
            "fifoValidation": [
                454,
                {
                    "input": [
                        450,
                        "FifoValidationInput!"
                    ]
                }
            ],
            "fifoValidationsList": [
                456,
                {
                    "input": [
                        455,
                        "FifoValidationsListInput!"
                    ]
                }
            ],
            "lastBigValidation": [
                184,
                {
                    "input": [
                        775,
                        "LastBigValidationInput!"
                    ]
                }
            ],
            "inventoryVendorsOverview": [
                733,
                {
                    "input": [
                        538,
                        "GetInventoryVendorsOverviewInput!"
                    ]
                }
            ],
            "inventoryVendors": [
                734,
                {
                    "input": [
                        537,
                        "GetInventoryVendorsInput!"
                    ]
                }
            ],
            "inventoryVendorRollingBalance": [
                732,
                {
                    "input": [
                        535,
                        "GetInventoryVendorRollingBalanceInput!"
                    ]
                }
            ],
            "inventoryVendorRollingBalanceOverview": [
                731,
                {
                    "input": [
                        536,
                        "GetInventoryVendorRollingBalanceOverviewInput!"
                    ]
                }
            ],
            "inventoryVendorBills": [
                723,
                {
                    "input": [
                        531,
                        "GetInventoryVendorBillsInput!"
                    ]
                }
            ],
            "inventoryVendorBillsOverview": [
                722,
                {
                    "input": [
                        532,
                        "GetInventoryVendorBillsOverviewInput!"
                    ]
                }
            ],
            "inventoryVendorFilterValues": [
                725,
                {
                    "input": [
                        533,
                        "GetInventoryVendorFilterValuesInput!"
                    ]
                }
            ],
            "inventoryVendorParams": [
                726,
                {
                    "input": [
                        534,
                        "GetInventoryVendorParamsInput!"
                    ]
                }
            ],
            "warehouseOpeningBalanceOverview": [
                1481,
                {
                    "input": [
                        597,
                        "GetWarehouseOpeningBalanceOverviewInput!"
                    ]
                }
            ],
            "warehouseOpeningBalances": [
                1485,
                {
                    "input": [
                        598,
                        "GetWarehouseOpeningBalancesInput!"
                    ]
                }
            ],
            "pendingWarehouseOpeningBalancesAttachments": [
                1060,
                {
                    "input": [
                        570,
                        "GetPendingWarehouseOpeningBalancesAttachmentsInput!"
                    ]
                }
            ],
            "getWarehouseOpeningBalancesCsvTemplate": [
                1482,
                {
                    "input": [
                        597,
                        "GetWarehouseOpeningBalanceOverviewInput!"
                    ]
                }
            ],
            "warehouseTransfersOverview": [
                1518,
                {
                    "input": [
                        240,
                        "CommonWarehouseTransfersFilterInput!"
                    ]
                }
            ],
            "warehouseTransfers": [
                1519,
                {
                    "input": [
                        602,
                        "GetWarehouseTransfersInput!"
                    ]
                }
            ],
            "warehouseTransfer": [
                1496,
                {
                    "input": [
                        601,
                        "GetWarehouseTransferInput!"
                    ]
                }
            ],
            "loans": [
                828,
                {
                    "input": [
                        550,
                        "GetLoansInput!"
                    ]
                }
            ],
            "getMatchingEffectRule": [
                555,
                {
                    "input": [
                        553,
                        "GetMatchingEffectRuleInput!"
                    ]
                }
            ],
            "loansEventsEffectConfiguration": [
                826
            ],
            "getLoansById": [
                825,
                {
                    "input": [
                        548,
                        "GetLoansByIdInput!"
                    ]
                }
            ],
            "exportLoans": [
                440,
                {
                    "input": [
                        439,
                        "ExportLoansCsvInput!"
                    ]
                }
            ],
            "getLoanEvents": [
                546,
                {
                    "input": [
                        543,
                        "GetLoanEventsInput!"
                    ]
                }
            ],
            "getLoanRelationships": [
                547,
                {
                    "input": [
                        543,
                        "GetLoanEventsInput!"
                    ]
                }
            ],
            "getLoanEventsLength": [
                545,
                {
                    "input": [
                        544,
                        "GetLoanEventsLengthInput!"
                    ]
                }
            ],
            "accountRoles": [
                1
            ],
            "loanEventDefinitions": [
                806
            ],
            "getLoansUpdatedBy": [
                1
            ],
            "knownLenders": [
                898
            ],
            "companyLoans": [
                273,
                {
                    "input": [
                        499,
                        "GetCompanyLoansInput!"
                    ]
                }
            ],
            "companyLoan": [
                272,
                {
                    "input": [
                        498,
                        "GetCompanyLoanInput!"
                    ]
                }
            ],
            "getAttachmentTargetURLS": [
                86,
                {
                    "input": [
                        487,
                        "GetAttachmentTargetURLSInput!"
                    ]
                }
            ],
            "downloadAttachment": [
                414,
                {
                    "input": [
                        413,
                        "DownloadAttachmentInput!"
                    ]
                }
            ],
            "getLoansOutstandingAmountOnDate": [
                552,
                {
                    "input": [
                        551,
                        "GetLoansOutstandingAmountOnDateInput!"
                    ]
                }
            ],
            "companyArCashDiffs": [
                255,
                {
                    "input": [
                        253,
                        "CompanyArCashDiffsInput!"
                    ]
                }
            ],
            "orderReconciliations": [
                971,
                {
                    "input": [
                        970,
                        "OrderReconciliationsInput!"
                    ]
                }
            ],
            "orderAccountingReconciliations": [
                919,
                {
                    "input": [
                        970,
                        "OrderReconciliationsInput!"
                    ]
                }
            ],
            "shopifyReportDateLimits": [
                1247,
                {
                    "input": [
                        1246,
                        "ShopifyReportDateLimitsInput!"
                    ]
                }
            ],
            "shopifyReconciliationData": [
                971,
                {
                    "input": [
                        970,
                        "OrderReconciliationsInput!"
                    ]
                }
            ],
            "paymentProcessorByCompanyAndVendor": [
                1036,
                {
                    "input": [
                        1035,
                        "PaymentProcessorByCompanyAndVendorInput!"
                    ]
                }
            ],
            "vendorByPaymentGateway": [
                1450,
                {
                    "input": [
                        1449,
                        "VendorByPaymentGatewayInput!"
                    ]
                }
            ],
            "salesChannelsByCompanyAndIntegration": [
                1166,
                {
                    "input": [
                        1165,
                        "SalesChannelsByCompanyAndIntegrationInput!"
                    ]
                }
            ],
            "paymentGatewaysByCompanyIntegrationAndSalesChannel": [
                1,
                {
                    "input": [
                        1001,
                        "PaymentGatewaysByCompanyIntegrationAndSalesChannelInput!"
                    ]
                }
            ],
            "getOrdersV2": [
                566,
                {
                    "input": [
                        565,
                        "GetOrdersInput!"
                    ]
                }
            ],
            "getOrdersAggregations": [
                923,
                {
                    "input": [
                        562,
                        "GetOrdersAggregationsInput!"
                    ]
                }
            ],
            "getOrderById": [
                990,
                {
                    "input": [
                        952,
                        "OrderIdInput!"
                    ]
                }
            ],
            "getFinaloopTags": [
                982,
                {
                    "input": [
                        266,
                        "CompanyIdInput!"
                    ]
                }
            ],
            "getIntegrationTags": [
                982,
                {
                    "input": [
                        517,
                        "GetIntegrationTagsInput!"
                    ]
                }
            ],
            "getFinaloopPaymentGateways": [
                1,
                {
                    "input": [
                        266,
                        "CompanyIdInput!"
                    ]
                }
            ],
            "getOrderSalesChannels": [
                1,
                {
                    "input": [
                        266,
                        "CompanyIdInput!"
                    ]
                }
            ],
            "getOrderFilteringBounds": [
                561,
                {
                    "input": [
                        266,
                        "CompanyIdInput!"
                    ]
                }
            ],
            "getFulfillmentSettings": [
                514,
                {
                    "input": [
                        513,
                        "GetFulfillmentSettingInput!"
                    ]
                }
            ],
            "isCompanyFulfillmentSettingsApplied": [
                5,
                {
                    "input": [
                        764,
                        "IsCompanyFulfillmentSettingsAppliedInput!"
                    ]
                }
            ],
            "ordersByIds": [
                975,
                {
                    "input": [
                        974,
                        "OrdersByIdsInput!"
                    ]
                }
            ],
            "orderGroupById": [
                934,
                {
                    "input": [
                        933,
                        "OrderGroupByIdInput!"
                    ]
                }
            ],
            "orderGroupsByIds": [
                940,
                {
                    "input": [
                        939,
                        "OrderGroupsByIdsInput!"
                    ]
                }
            ],
            "orderGroups": [
                943,
                {
                    "input": [
                        942,
                        "OrderGroupsInput!"
                    ]
                }
            ],
            "orderGroupsUnclearedBalance": [
                950,
                {
                    "input": [
                        949,
                        "OrderGroupsUnclearedBalanceInput!"
                    ]
                }
            ],
            "orderGroupsTotalUnclearedBalance": [
                948,
                {
                    "input": [
                        947,
                        "OrderGroupsTotalUnclearedBalanceInput!"
                    ]
                }
            ],
            "orderSettings": [
                978,
                {
                    "input": [
                        977,
                        "OrderSettingsInput!"
                    ]
                }
            ],
            "paymentGroupLinks": [
                1017,
                {
                    "input": [
                        1016,
                        "PaymentGroupLinksInput!"
                    ]
                }
            ],
            "getInvoiceAffectingPaymentGroups": [
                540,
                {
                    "input": [
                        539,
                        "GetInvoiceAffectingPaymentGroupsInput!"
                    ]
                }
            ],
            "getPaymentGroupsByIds": [
                1020,
                {
                    "input": [
                        1019,
                        "PaymentGroupsByIdsInput!"
                    ]
                }
            ],
            "orderPaymentGroupById": [
                962,
                {
                    "input": [
                        1005,
                        "PaymentGroupByIdInput!"
                    ]
                }
            ],
            "orderPaymentGroupsByIds": [
                963,
                {
                    "input": [
                        1019,
                        "PaymentGroupsByIdsInput!"
                    ]
                }
            ],
            "orderPaymentGroups": [
                964,
                {
                    "input": [
                        1022,
                        "PaymentGroupsInput!"
                    ]
                }
            ],
            "orderPaymentGroupsUnclearedBalance": [
                965,
                {
                    "input": [
                        1028,
                        "PaymentGroupsUnclearedBalanceInput!"
                    ]
                }
            ],
            "orderPaymentGroupsTotalUnclearedBalance": [
                1027,
                {
                    "input": [
                        1026,
                        "PaymentGroupsTotalUnclearedBalanceInput!"
                    ]
                }
            ],
            "invoicePaymentGroupById": [
                748,
                {
                    "input": [
                        1005,
                        "PaymentGroupByIdInput!"
                    ]
                }
            ],
            "invoicePaymentGroupsByIds": [
                749,
                {
                    "input": [
                        1019,
                        "PaymentGroupsByIdsInput!"
                    ]
                }
            ],
            "invoicePaymentGroups": [
                750,
                {
                    "input": [
                        1022,
                        "PaymentGroupsInput!"
                    ]
                }
            ],
            "invoicePaymentGroupsUnclearedBalance": [
                751,
                {
                    "input": [
                        1028,
                        "PaymentGroupsUnclearedBalanceInput!"
                    ]
                }
            ],
            "invoicePaymentGroupsTotalUnclearedBalance": [
                1027,
                {
                    "input": [
                        1026,
                        "PaymentGroupsTotalUnclearedBalanceInput!"
                    ]
                }
            ],
            "artificialTransactionsByMonth": [
                74,
                {
                    "input": [
                        486,
                        "GetArtificialTransactionsInput!"
                    ]
                }
            ],
            "paymentChecks": [
                997,
                {
                    "input": [
                        998,
                        "PaymentChecksInput!"
                    ]
                }
            ],
            "payrollDailyAggregatesByEmploymentTypeAndCategory": [
                1050,
                {
                    "input": [
                        1051,
                        "PayrollDailyDataInput!"
                    ]
                }
            ],
            "paymentsByEmployee": [
                358,
                {
                    "input": [
                        1038,
                        "PaymentsByEntityInput!"
                    ]
                }
            ],
            "paymentsByContractor": [
                357,
                {
                    "input": [
                        1038,
                        "PaymentsByEntityInput!"
                    ]
                }
            ],
            "payrollSettings": [
                1057,
                {
                    "input": [
                        1058,
                        "PayrollSettingsInput!"
                    ]
                }
            ],
            "validations": [
                1441,
                {
                    "args": [
                        1443,
                        "ValidationsArgs!"
                    ]
                }
            ],
            "exportCashFlow": [
                442,
                {
                    "args": [
                        431,
                        "ExportCashFlowApiArgs!"
                    ]
                }
            ],
            "exportBalanceSheet": [
                92,
                {
                    "args": [
                        430,
                        "ExportBalanceSheetApiArgs!"
                    ]
                }
            ],
            "ledgerAccountTransactionsCSV": [
                92,
                {
                    "args": [
                        438,
                        "ExportLedgerAccountTransactionsInput!"
                    ]
                }
            ],
            "checkExportStatus": [
                92,
                {
                    "args": [
                        219,
                        "CheckAwaitedExportStatusInput!"
                    ]
                }
            ],
            "syncReportDefinition": [
                1179,
                {
                    "args": [
                        1298,
                        "SyncReportInput!"
                    ]
                }
            ],
            "lastAccountingReportSync": [
                774,
                {
                    "args": [
                        773,
                        "LastAccountingReportSyncInput!"
                    ]
                }
            ],
            "chartData": [
                218,
                {
                    "args": [
                        217,
                        "ChartDataInput!"
                    ]
                }
            ],
            "customerInternal": [
                343,
                {
                    "args": [
                        506,
                        "GetCustomerInternal!"
                    ]
                }
            ],
            "getCustomers": [
                343,
                {
                    "getCustomerArgs": [
                        504,
                        "GetCustomerArgs!"
                    ]
                }
            ],
            "getCustomerById": [
                507,
                {
                    "id": [
                        8,
                        "ID!"
                    ]
                }
            ],
            "getCustomerByIds": [
                343,
                {
                    "ids": [
                        8,
                        "[ID!]!"
                    ]
                }
            ],
            "searchCustomers": [
                350,
                {
                    "input": [
                        1185,
                        "SearchCustomersInput!"
                    ]
                }
            ],
            "getCustomerV2ById": [
                350,
                {
                    "input": [
                        505,
                        "GetCustomerByIdInput!"
                    ]
                }
            ],
            "sale": [
                1147,
                {
                    "input": [
                        1150,
                        "SaleInput!"
                    ]
                }
            ],
            "getSales": [
                589,
                {
                    "input": [
                        588,
                        "GetSalesInput!"
                    ]
                }
            ],
            "invoice": [
                744,
                {
                    "input": [
                        746,
                        "InvoiceInput!"
                    ]
                }
            ],
            "getInvoices": [
                542,
                {
                    "input": [
                        588,
                        "GetSalesInput!"
                    ]
                }
            ],
            "getInvoicePdfUrl": [
                1,
                {
                    "input": [
                        754,
                        "InvoicePdfInput!"
                    ]
                }
            ],
            "getPaymentMethods": [
                1031,
                {
                    "input": [
                        266,
                        "CompanyIdInput!"
                    ]
                }
            ],
            "calculateInvoice": [
                744,
                {
                    "input": [
                        207,
                        "CalculateInvoiceInput!"
                    ]
                }
            ],
            "getInvoicesCsv": [
                437,
                {
                    "input": [
                        588,
                        "GetSalesInput!"
                    ]
                }
            ],
            "getInvoiceAggregations": [
                758,
                {
                    "input": [
                        581,
                        "GetSaleAggregationsInput!"
                    ]
                }
            ],
            "getInvoicesBalance": [
                759,
                {
                    "input": [
                        581,
                        "GetSaleAggregationsInput!"
                    ]
                }
            ],
            "invoicesAgingReportCSV": [
                92,
                {
                    "args": [
                        588,
                        "GetSalesInput!"
                    ]
                }
            ],
            "salesChannel": [
                1160,
                {
                    "input": [
                        1150,
                        "SaleInput!"
                    ]
                }
            ],
            "getSalesChannels": [
                1160,
                {
                    "input": [
                        585,
                        "GetSalesChannelsInput!"
                    ]
                }
            ],
            "getSalesChannelTemplates": [
                1170,
                {
                    "input": [
                        586,
                        "GetSalesChannelTemplatesInput!"
                    ]
                }
            ],
            "invoiceSettings": [
                760,
                {
                    "input": [
                        541,
                        "GetInvoiceSettingsInput!"
                    ]
                }
            ],
            "getInvoiceExamplePdfUrl": [
                1,
                {
                    "input": [
                        761,
                        "InvoicesInput!"
                    ]
                }
            ],
            "me": [
                880
            ],
            "isInvitationCodeForRegisteredUser": [
                767,
                {
                    "args": [
                        766,
                        "IsInvitationCodeForRegisteredUserArgs!"
                    ]
                }
            ],
            "companyUsers": [
                283,
                {
                    "args": [
                        282
                    ]
                }
            ],
            "userInvitationByCode": [
                1432,
                {
                    "args": [
                        1431,
                        "UserInvitationArgs!"
                    ]
                }
            ],
            "userInvitationsByEmail": [
                1434,
                {
                    "args": [
                        1433,
                        "UserInvitationsByEmailArgs!"
                    ]
                }
            ],
            "userRoles": [
                1438
            ],
            "auth0UserLookup": [
                91,
                {
                    "args": [
                        90,
                        "Auth0UserLookupInput!"
                    ]
                }
            ],
            "googleConnectStatus": [
                610
            ],
            "googleConnectionLink": [
                609,
                {
                    "input": [
                        608
                    ]
                }
            ],
            "calendlyEventDetails": [
                209,
                {
                    "args": [
                        208,
                        "CalendlyEventInput!"
                    ]
                }
            ],
            "userByEmail": [
                1426,
                {
                    "args": [
                        1425,
                        "UserByEmailArgs!"
                    ]
                }
            ],
            "usersByEmails": [
                1440,
                {
                    "args": [
                        1439,
                        "UsersByEmailsArgs!"
                    ]
                }
            ],
            "vendors": [
                1445,
                {
                    "input": [
                        1453,
                        "VendorsInput!"
                    ]
                }
            ],
            "vendor": [
                595,
                {
                    "input": [
                        594,
                        "GetVendorInput!"
                    ]
                }
            ],
            "vendorsByIds": [
                1445,
                {
                    "input": [
                        596,
                        "GetVendorsInput!"
                    ]
                }
            ],
            "vendorsBySubtype": [
                1445,
                {
                    "companyId": [
                        8,
                        "ID!"
                    ],
                    "subTypes": [
                        1457,
                        "[VendorSubType!]!"
                    ]
                }
            ],
            "tax1099VendorGroups": [
                1306,
                {
                    "input": [
                        593,
                        "GetTax1099VendorGroupsInput!"
                    ]
                }
            ],
            "institutionVendors": [
                1445
            ],
            "vendorById": [
                1445,
                {
                    "input": [
                        1447,
                        "VendorByIdInput!"
                    ]
                }
            ],
            "vendorByAffiliatedEntityId": [
                1445,
                {
                    "input": [
                        1446,
                        "VendorByAffiliatedEntityIdInput!"
                    ]
                }
            ],
            "vendorByName": [
                1445,
                {
                    "input": [
                        1448,
                        "VendorByNameInput!"
                    ]
                }
            ],
            "globalVendorsByIds": [
                1445,
                {
                    "input": [
                        515,
                        "GetGlobalVendorsInput!"
                    ]
                }
            ],
            "vendorsByIntegrationType": [
                1445,
                {
                    "input": [
                        1452,
                        "VendorsByIntegrationTypeInput!"
                    ]
                }
            ],
            "getCompanyWorksheets": [
                289,
                {
                    "args": [
                        502,
                        "GetCompanyWorksheetsArgs!"
                    ]
                }
            ],
            "getCompanyLockConditions": [
                274,
                {
                    "args": [
                        500,
                        "GetCompanyLockConditionsArgs!"
                    ]
                }
            ],
            "getCompanyWorksheetsV2": [
                289,
                {
                    "args": [
                        503,
                        "GetCompanyWorksheetsArgsV2!"
                    ]
                }
            ],
            "getCompanyLockConditionsV2": [
                274,
                {
                    "args": [
                        501,
                        "GetCompanyLockConditionsArgsV2!"
                    ]
                }
            ],
            "__typename": [
                1
            ]
        },
        "RangedManagementMethod": {
            "managementMethod": [
                851
            ],
            "startDate": [
                361
            ],
            "endDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "RangedManagementMethodInput": {
            "managementMethod": [
                851
            ],
            "startDate": [
                361
            ],
            "endDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "RealtimeCogsStatus": {
            "isAmazonEnabled": [
                5
            ],
            "isShopifyEnabled": [
                5
            ],
            "isWalmartEnabled": [
                5
            ],
            "isTikTokEnabled": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "Recipe": {
            "id": [
                1
            ],
            "from": [
                361
            ],
            "to": [
                361
            ],
            "isAssembledOnDemand": [
                5
            ],
            "product": [
                1071
            ],
            "warehouse": [
                1463
            ],
            "assemblyCost": [
                11
            ],
            "components": [
                1117
            ],
            "managementMethod": [
                1119
            ],
            "displayName": [
                1
            ],
            "deletedReason": [
                82
            ],
            "manualAssemblyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "RecipeBreakdown": {
            "quantity": [
                11
            ],
            "wasteQuantity": [
                11
            ],
            "costPerUnit": [
                11
            ],
            "product": [
                1071
            ],
            "warehouse": [
                1463
            ],
            "__typename": [
                1
            ]
        },
        "RecipeCirclesFoundError": {
            "data": [
                1116
            ],
            "__typename": [
                1
            ]
        },
        "RecipeCirclesFoundErrorData": {
            "recipe": [
                1113
            ],
            "product": [
                1071
            ],
            "__typename": [
                1
            ]
        },
        "RecipeComponent": {
            "product": [
                1071
            ],
            "warehouse": [
                1463
            ],
            "quantity": [
                11
            ],
            "wasteQuantity": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "RecipeComponentInput": {
            "productId": [
                1
            ],
            "warehouseId": [
                1
            ],
            "quantity": [
                11
            ],
            "wasteQuantity": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "RecipeManagementMethod": {},
        "RecipeOverlappingError": {
            "recipes": [
                1113
            ],
            "__typename": [
                1
            ]
        },
        "RecipeWithBreakdown": {
            "recipe": [
                1113
            ],
            "startDate": [
                361
            ],
            "endDate": [
                361
            ],
            "warehouse": [
                1463
            ],
            "breakdown": [
                1114
            ],
            "__typename": [
                1
            ]
        },
        "ReconcileAllPayrollInventoryPurchasesInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "ReconcileInventoryPurchasesFromBillsInput": {
            "billItemsToReconcile": [
                191
            ],
            "__typename": [
                1
            ]
        },
        "ReconcileInventoryPurchasesFromBusinessEventsInput": {
            "businessEventItemsToReconcile": [
                204
            ],
            "__typename": [
                1
            ]
        },
        "ReconcileInventoryPurchasesFromPayrollsInput": {
            "payrollItemsToReconcile": [
                1055
            ],
            "__typename": [
                1
            ]
        },
        "ReconcileInventoryPurchasesFromTransactionsInput": {
            "transactionItemsToReconcile": [
                1317
            ],
            "__typename": [
                1
            ]
        },
        "ReconciliationSummary": {
            "totalInReport": [
                33
            ],
            "isGreen": [
                5
            ],
            "inBooks": [
                33
            ],
            "unfulfilledInBooks": [
                33
            ],
            "voided": [
                33
            ],
            "restocked": [
                33
            ],
            "excluded": [
                33
            ],
            "totalInDb": [
                33
            ],
            "fulfilled": [
                33
            ],
            "fulfilledNotSold": [
                33
            ],
            "soldNotFulfilled": [
                33
            ],
            "zeroNet": [
                33
            ],
            "zeroNetFulfilled": [
                33
            ],
            "zeroNetNotSold": [
                33
            ],
            "zeroNetNotFulfilled": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "RecordTax1099VendorGroupIdentifierInput": {
            "id": [
                8
            ],
            "type": [
                1307
            ],
            "isGroup": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "RecordTax1099VendorGroupInput": {
            "companyId": [
                8
            ],
            "identifiers": [
                1128
            ],
            "isDone": [
                5
            ],
            "disqualifyReason": [
                1302
            ],
            "resetDisqualifyReason": [
                5
            ],
            "file": [
                1305
            ],
            "resetFile": [
                5
            ],
            "finaloopComment": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "RecurrenceSettings": {
            "recurrence": [
                808
            ],
            "totalRecurrences": [
                33
            ],
            "cutOffDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "RecurrenceSettingsInput": {
            "recurrence": [
                808
            ],
            "totalRecurrences": [
                33
            ],
            "cutOffDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "RecurringPayment": {
            "yearly": [
                999
            ],
            "monthly": [
                999
            ],
            "yearlyDiscount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "RefreshSkuAmbiguityInput": {
            "companyId": [
                8
            ],
            "productId": [
                1
            ],
            "skus": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "RelatedParty": {
            "id": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "RemoveDataRestrictionInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "RemoveUserArgs": {
            "email": [
                1
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "RemoveUserResponse": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ReplaceBankAccountLocksByTypeInput": {
            "companyId": [
                8
            ],
            "bankAccountId": [
                8
            ],
            "locks": [
                104
            ],
            "lockType": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ReplaceBankAccountLocksInput": {
            "companyId": [
                8
            ],
            "bankAccountId": [
                8
            ],
            "locks": [
                105
            ],
            "__typename": [
                1
            ]
        },
        "Report": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ReportedBusinessBankTransactionsAccountInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "ReportSyncStatus": {},
        "ReportType": {},
        "RestockingAction": {
            "quantity": [
                33
            ],
            "id": [
                1
            ],
            "date": [
                361
            ],
            "type": [
                1
            ],
            "salesChannel": [
                1465
            ],
            "fulfillmentDate": [
                361
            ],
            "sku": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Role": {},
        "RootConditionOperator": {},
        "Sale": {
            "id": [
                8
            ],
            "saleType": [
                1
            ],
            "companyId": [
                8
            ],
            "source": [
                1173
            ],
            "externalSourceUrl": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "integrationAccountId": [
                8
            ],
            "integrationAccountName": [
                1
            ],
            "currency": [
                340
            ],
            "displayName": [
                1
            ],
            "statuses": [
                1174
            ],
            "postedAt": [
                361
            ],
            "createdAt": [
                361
            ],
            "updatedAt": [
                361
            ],
            "deletedAt": [
                361
            ],
            "publishedAt": [
                361
            ],
            "lineItems": [
                1152
            ],
            "shipping": [
                1228
            ],
            "discounts": [
                408
            ],
            "taxes": [
                1308
            ],
            "taxFixedAmount": [
                11
            ],
            "adjustments": [
                1149
            ],
            "payments": [
                1176
            ],
            "credits": [
                1176
            ],
            "refunds": [
                1176
            ],
            "paymentIndications": [
                1176
            ],
            "refundIndications": [
                1176
            ],
            "customer": [
                350
            ],
            "customerId": [
                8
            ],
            "shippingAddress": [
                344
            ],
            "billingAddress": [
                344
            ],
            "integrationTags": [
                1
            ],
            "integrationNote": [
                1
            ],
            "finaloopNote": [
                1
            ],
            "customerNote": [
                1
            ],
            "accounting": [
                4
            ],
            "voidedAt": [
                361
            ],
            "voidedBy": [
                1148
            ],
            "summary": [
                1175
            ],
            "__typename": [
                1
            ]
        },
        "SaleActor": {},
        "SaleAdjustment": {
            "id": [
                8
            ],
            "amount": [
                11
            ],
            "currency": [
                340
            ],
            "amountInHomeCurrency": [
                11
            ],
            "type": [
                34
            ],
            "postedAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "SaleInput": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "balanceAsOfDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "SaleInternalInput": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "SaleLineItem": {
            "id": [
                8
            ],
            "quantity": [
                11
            ],
            "unitPrice": [
                11
            ],
            "currency": [
                340
            ],
            "type": [
                1155
            ],
            "integrationInfo": [
                666
            ],
            "product": [
                1071
            ],
            "sku": [
                1
            ],
            "description": [
                1
            ],
            "discounts": [
                408
            ],
            "subtotal": [
                11
            ],
            "totalAmount": [
                11
            ],
            "salesChannel": [
                1160
            ],
            "salesChannelId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "SaleLineItemInput": {
            "id": [
                8
            ],
            "quantity": [
                11
            ],
            "unitPrice": [
                11
            ],
            "type": [
                1155
            ],
            "currency": [
                340
            ],
            "salesChannelName": [
                1
            ],
            "productId": [
                8
            ],
            "sku": [
                1
            ],
            "description": [
                1
            ],
            "discounts": [
                409
            ],
            "taxRate": [
                11
            ],
            "externalSourceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SaleLineItemTaxInfo": {
            "taxableAmount": [
                11
            ],
            "taxRate": [
                11
            ],
            "totalTaxAmount": [
                11
            ],
            "isTaxInclusive": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SaleLineItemType": {},
        "SaleOrder": {
            "id": [
                8
            ],
            "saleType": [
                1
            ],
            "companyId": [
                8
            ],
            "source": [
                1173
            ],
            "externalSourceUrl": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "integrationAccountId": [
                8
            ],
            "integrationAccountName": [
                1
            ],
            "currency": [
                340
            ],
            "displayName": [
                1
            ],
            "statuses": [
                1174
            ],
            "postedAt": [
                361
            ],
            "createdAt": [
                361
            ],
            "updatedAt": [
                361
            ],
            "deletedAt": [
                361
            ],
            "publishedAt": [
                361
            ],
            "lineItems": [
                1152
            ],
            "shipping": [
                1228
            ],
            "discounts": [
                408
            ],
            "taxes": [
                1308
            ],
            "taxFixedAmount": [
                11
            ],
            "adjustments": [
                1149
            ],
            "payments": [
                1176
            ],
            "credits": [
                1176
            ],
            "refunds": [
                1176
            ],
            "paymentIndications": [
                1176
            ],
            "refundIndications": [
                1176
            ],
            "customer": [
                350
            ],
            "customerId": [
                8
            ],
            "shippingAddress": [
                344
            ],
            "billingAddress": [
                344
            ],
            "integrationTags": [
                1
            ],
            "integrationNote": [
                1
            ],
            "finaloopNote": [
                1
            ],
            "customerNote": [
                1
            ],
            "accounting": [
                4
            ],
            "voidedAt": [
                361
            ],
            "voidedBy": [
                1148
            ],
            "summary": [
                1175
            ],
            "__typename": [
                1
            ]
        },
        "SalesByTypeAndDateInput": {
            "saleType": [
                1177
            ],
            "companyId": [
                8
            ],
            "from": [
                361
            ],
            "to": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannel": {
            "accountRole": [
                1
            ],
            "integrationType": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "currency": [
                1
            ],
            "displayName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelDailySalesData": {
            "salesChannel": [
                1158
            ],
            "salesData": [
                1164
            ],
            "soldSkusSummary": [
                1168
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelInfo": {
            "id": [
                8
            ],
            "name": [
                1
            ],
            "source": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "isCogsAffecting": [
                5
            ],
            "isSalesAffecting": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelInput": {
            "accountRole": [
                1
            ],
            "integrationType": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "currency": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelMonthlyBreakdown": {
            "date": [
                361
            ],
            "summary": [
                1169
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelSalesCogs": {
            "hasAdjustments": [
                5
            ],
            "salesChannel": [
                1158
            ],
            "summary": [
                1169
            ],
            "monthlyBreakdown": [
                1162
            ],
            "amount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelSalesData": {
            "date": [
                361
            ],
            "unitsSold": [
                33
            ],
            "unitsRestocked": [
                33
            ],
            "unitsGiveaway": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelsByCompanyAndIntegrationInput": {
            "companyId": [
                8
            ],
            "integrationAccountId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelsByCompanyAndIntegrationResponse": {
            "id": [
                8
            ],
            "name": [
                1
            ],
            "isSalesAffecting": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelsInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelSkuSalesSummary": {
            "sku": [
                1
            ],
            "unitsSold": [
                33
            ],
            "unitsRestocked": [
                33
            ],
            "unitsGiveaway": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelSkuSummary": {
            "sku": [
                1
            ],
            "fulfilled": [
                33
            ],
            "restocked": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "SalesChannelTemplate": {
            "id": [
                8
            ],
            "name": [
                1
            ],
            "source": [
                1
            ],
            "isCogsAffecting": [
                5
            ],
            "isSalesAffecting": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SalesData": {
            "recipes": [
                1113
            ],
            "assembledByRecipeMap": [
                81
            ],
            "assembledByProductMap": [
                81
            ],
            "salesChannels": [
                1163
            ],
            "deadInventory": [
                364
            ],
            "giveaway": [
                603
            ],
            "donation": [
                411
            ],
            "waste": [
                1527
            ],
            "warehouseSalesData": [
                1487
            ],
            "salesAdjustments": [
                1089
            ],
            "deadInventoryAdjustments": [
                1081
            ],
            "giveawayAdjustments": [
                1085
            ],
            "donationAdjustments": [
                1083
            ],
            "appliedCosts": [
                1075
            ],
            "__typename": [
                1
            ]
        },
        "SalesInternalInput": {
            "ids": [
                8
            ],
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "SaleSource": {},
        "SaleStatus": {},
        "SaleTotals": {
            "subtotal": [
                68
            ],
            "subtotalAfterItemDiscounts": [
                68
            ],
            "totalBeforeTaxes": [
                68
            ],
            "total": [
                68
            ],
            "shipping": [
                68
            ],
            "payments": [
                68
            ],
            "refunds": [
                68
            ],
            "credits": [
                68
            ],
            "adjustments": [
                68
            ],
            "taxes": [
                68
            ],
            "globalDiscounts": [
                68
            ],
            "itemDiscounts": [
                68
            ],
            "totalDiscounts": [
                68
            ],
            "unpaidBalance": [
                68
            ],
            "balanceAsOf": [
                361
            ],
            "balanceAsOfAmount": [
                68
            ],
            "latestFinancialTimestamp": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "SaleTransaction": {
            "id": [
                8
            ],
            "integrationInfo": [
                666
            ],
            "amount": [
                11
            ],
            "postedAt": [
                361
            ],
            "currency": [
                340
            ],
            "amountInHomeCurrency": [
                11
            ],
            "type": [
                1321
            ],
            "finaloopPaymentGateway": [
                1
            ],
            "originalPaymentGateway": [
                1
            ],
            "paymentGatewayVariant": [
                1
            ],
            "isIndication": [
                5
            ],
            "on_BankTxSaleTransaction": [
                179
            ],
            "on_IntegrationSaleTransaction": [
                668
            ],
            "on_PaymentGroupTransaction": [
                1029
            ],
            "__typename": [
                1
            ]
        },
        "SaleType": {},
        "ScheduleAccountingReportExportInput": {
            "companyId": [
                8
            ],
            "reportType": [
                1143
            ],
            "isEnabled": [
                5
            ],
            "byMonth": [
                5
            ],
            "fractions": [
                5
            ],
            "withMerchantsBreakdown": [
                5
            ],
            "fromDate": [
                361
            ],
            "toDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "ScheduleAccountingReportExportResponse": {
            "reportType": [
                1143
            ],
            "isEnabled": [
                5
            ],
            "byMonth": [
                5
            ],
            "fractions": [
                5
            ],
            "withMerchantsBreakdown": [
                5
            ],
            "fromDate": [
                361
            ],
            "toDate": [
                361
            ],
            "syncMostUpdatedReport": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ScheduleAtuIntegrationTransfersResultProcessingInput": {
            "companyId": [
                8
            ],
            "integrationTransferIds": [
                8
            ],
            "askTheUserResult": [
                772
            ],
            "__typename": [
                1
            ]
        },
        "ScheduleAtuIntegrationTransfersResultProcessingResult": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ScheduleAtuTransactionsResultProcessingInput": {
            "companyId": [
                8
            ],
            "transactionIds": [
                8
            ],
            "askTheUserResult": [
                772
            ],
            "__typename": [
                1
            ]
        },
        "ScheduleAtuTransactionsResultProcessingResult": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SchedulePurchasesValidationInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "SearchCustomersInput": {
            "companyId": [
                8
            ],
            "searchTerm": [
                1
            ],
            "source": [
                349
            ],
            "limit": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "SearchMerchantsInput": {
            "companyId": [
                8
            ],
            "searchTerm": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SearchVendorsTransactionPriorityInput": {
            "companyId": [
                8
            ],
            "searchTerm": [
                1
            ],
            "merchantType": [
                879
            ],
            "subType": [
                1457
            ],
            "__typename": [
                1
            ]
        },
        "SearchVendorsTransactionsPriorityResult": {
            "vendors": [
                1445
            ],
            "counts": [
                1456
            ],
            "__typename": [
                1
            ]
        },
        "Section179Deduction": {
            "date": [
                361
            ],
            "amount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "Section179DeductionInput": {
            "date": [
                1
            ],
            "amount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "SendPoEmailError": {
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SendPoEmailInput": {
            "companyId": [
                1
            ],
            "id": [
                1
            ],
            "to": [
                1
            ],
            "from": [
                1
            ],
            "cc": [
                1
            ],
            "bcc": [
                1
            ],
            "subject": [
                1
            ],
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SendPoEmailResponse": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SendPoEmailResult": {
            "on_SendPoEmailResponse": [
                1193
            ],
            "on_SendPoEmailError": [
                1191
            ],
            "__typename": [
                1
            ]
        },
        "SetBankAccountsLatestEventIdsInput": {
            "bankAccountEventIds": [
                100
            ],
            "__typename": [
                1
            ]
        },
        "SetFiscalYearDataInput": {
            "companyId": [
                8
            ],
            "month": [
                33
            ],
            "day": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "SetFulfillmentSettingsInput": {
            "companyId": [
                8
            ],
            "settings": [
                665
            ],
            "__typename": [
                1
            ]
        },
        "SetFulfillmentSettingsResponse": {
            "settings": [
                664
            ],
            "__typename": [
                1
            ]
        },
        "SetInventoryUsagesInput": {
            "companyId": [
                8
            ],
            "usageTypes": [
                716
            ],
            "__typename": [
                1
            ]
        },
        "SetOrderFinaloopNoteInput": {
            "companyId": [
                8
            ],
            "orderId": [
                8
            ],
            "finaloopNote": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SetOrderFinaloopTagsInput": {
            "companyId": [
                8
            ],
            "orderId": [
                8
            ],
            "finaloopTags": [
                468
            ],
            "__typename": [
                1
            ]
        },
        "SetPendingWarehouseOpeningBalancesInput": {
            "companyId": [
                8
            ],
            "date": [
                361
            ],
            "items": [
                1203
            ],
            "__typename": [
                1
            ]
        },
        "SetPendingWarehouseOpeningBalancesItemInput": {
            "productId": [
                1
            ],
            "warehouseId": [
                1
            ],
            "sourceId": [
                1
            ],
            "pendingId": [
                1
            ],
            "quantity": [
                33
            ],
            "costPerUnit": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "SetPendingWarehouseOpeningBalancesWithCsvInput": {
            "companyId": [
                8
            ],
            "date": [
                361
            ],
            "url": [
                1
            ],
            "filename": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SetProductAdjustmentOperation": {},
        "SetProductAdjustmentsInput": {
            "companyId": [
                8
            ],
            "finaloopProductId": [
                1
            ],
            "salesDataAdjustments": [
                1212
            ],
            "deadInventoryAdjustments": [
                1209
            ],
            "giveawayAdjustments": [
                1211
            ],
            "donationAdjustments": [
                1210
            ],
            "appliedCosts": [
                1208
            ],
            "__typename": [
                1
            ]
        },
        "SetProductAdjustmentsResponse": {
            "appliedCosts": [
                1073
            ],
            "newDeadInventoryAdjustments": [
                1080
            ],
            "__typename": [
                1
            ]
        },
        "SetProductAppliedCostInput": {
            "operation": [
                1205
            ],
            "asOfDate": [
                361
            ],
            "appliedCost": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "SetProductDeadInventoryAdjustmentInput": {
            "id": [
                1
            ],
            "operation": [
                1205
            ],
            "finaloopProductId": [
                1
            ],
            "date": [
                361
            ],
            "unitsDeadInventory": [
                11
            ],
            "warehouseId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SetProductDonationAdjustmentInput": {
            "operation": [
                1205
            ],
            "finaloopProductId": [
                1
            ],
            "date": [
                361
            ],
            "unitsDonated": [
                33
            ],
            "warehouseId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SetProductGiveawayAdjustmentInput": {
            "operation": [
                1205
            ],
            "finaloopProductId": [
                1
            ],
            "date": [
                361
            ],
            "unitsGiveaway": [
                33
            ],
            "warehouseId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SetProductSalesDataAdjustmentInput": {
            "operation": [
                1205
            ],
            "salesChannel": [
                1161
            ],
            "date": [
                361
            ],
            "unitsSold": [
                33
            ],
            "unitsRestocked": [
                33
            ],
            "warehouseId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SetPurchaseOrderBusinessEventInput": {
            "companyId": [
                8
            ],
            "purchaseOrderId": [
                1
            ],
            "publishedEventIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SetSettingsArgs": {
            "companyId": [
                1
            ],
            "rangedManagementMethod": [
                1111
            ],
            "__typename": [
                1
            ]
        },
        "SetUserName": {
            "email": [
                1
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SetUserNameResponse": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SetWarehouseOpeningBalanceInput": {
            "warehouseId": [
                1
            ],
            "companyId": [
                8
            ],
            "productId": [
                1
            ],
            "quantity": [
                33
            ],
            "costPerUnit": [
                11
            ],
            "date": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "ShareType": {},
        "Shipment": {
            "id": [
                8
            ],
            "shipmentDate": [
                361
            ],
            "arrival": [
                1221
            ],
            "contents": [
                1225
            ],
            "displayName": [
                1
            ],
            "vendor": [
                1445
            ],
            "notes": [
                1
            ],
            "attachments": [
                1223
            ],
            "purchaseOrder": [
                1100
            ],
            "cost": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "ShipmentAction": {
            "quantity": [
                33
            ],
            "id": [
                1
            ],
            "date": [
                361
            ],
            "type": [
                1
            ],
            "purchaseOrder": [
                1100
            ],
            "directCost": [
                11
            ],
            "indirectCosts": [
                895
            ],
            "__typename": [
                1
            ]
        },
        "ShipmentArrival": {
            "arrivalDate": [
                361
            ],
            "warehouse": [
                1463
            ],
            "__typename": [
                1
            ]
        },
        "ShipmentArrivalInput": {
            "arrivalDate": [
                361
            ],
            "warehouseId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "ShipmentAttachment": {
            "id": [
                8
            ],
            "filename": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ShipmentAttachmentInput": {
            "filename": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ShipmentContents": {
            "id": [
                8
            ],
            "lineItem": [
                404
            ],
            "quantity": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "ShipmentContentsInput": {
            "lineItemId": [
                8
            ],
            "quantity": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "ShipmentInput": {
            "id": [
                8
            ],
            "shipmentDate": [
                361
            ],
            "arrival": [
                1222
            ],
            "contents": [
                1226
            ],
            "displayName": [
                1
            ],
            "vendorId": [
                8
            ],
            "notes": [
                1
            ],
            "attachments": [
                1224
            ],
            "__typename": [
                1
            ]
        },
        "ShippingLineItem": {
            "id": [
                8
            ],
            "quantity": [
                11
            ],
            "unitPrice": [
                11
            ],
            "currency": [
                340
            ],
            "type": [
                1155
            ],
            "description": [
                1
            ],
            "discounts": [
                408
            ],
            "subtotal": [
                11
            ],
            "totalAmount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyCsvBalance": {
            "companyId": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "fromDate": [
                361
            ],
            "toDate": [
                361
            ],
            "balances": [
                1250
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyItemsReconciliationInput": {
            "startTime": [
                361
            ],
            "endTime": [
                361
            ],
            "integrationAccountId": [
                1
            ],
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyItemsReconciliationOrderData": {
            "orderId": [
                1
            ],
            "url": [
                1
            ],
            "shopifySalesChannel": [
                1
            ],
            "salesChannel": [
                1
            ],
            "quantity": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyItemsReconciliationOrdersDiffInput": {
            "companyId": [
                8
            ],
            "startTime": [
                361
            ],
            "endTime": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyItemsReconciliationOrdersDiffResult": {
            "ordersData": [
                1234
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyItemsReconciliationOrdersDiffResultData": {
            "orderId": [
                8
            ],
            "reportQuantity": [
                33
            ],
            "entitiesQuantity": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyItemsReconciliationOrdersInput": {
            "startTime": [
                361
            ],
            "endTime": [
                361
            ],
            "integrationAccountId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "companyId": [
                8
            ],
            "sku": [
                1
            ],
            "itemType": [
                1251
            ],
            "salesChannel": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyItemsReconciliationOrdersResponse": {
            "ordersData": [
                1231
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyItemsReconciliationResponse": {
            "integrationAccountId": [
                1
            ],
            "data": [
                1248
            ],
            "reportEndTime": [
                361
            ],
            "reportPath": [
                1
            ],
            "summary": [
                1127
            ],
            "productItemSoldData": [
                1249
            ],
            "productStatus": [
                1245
            ],
            "unknownSkuQuantity": [
                33
            ],
            "unknownSkuZeroPriceQuantity": [
                33
            ],
            "recognizeZeroNetCogsAsGiveaways": [
                5
            ],
            "isNotArtificiallyFulfilled": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyItemsValidationBlacklistSalesChannelInput": {
            "companyId": [
                8
            ],
            "integrationAccountId": [
                1
            ],
            "salesChannel": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyItemsValidationBlacklistSkuInput": {
            "companyId": [
                8
            ],
            "integrationAccountId": [
                1
            ],
            "sku": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyOrderSourceData": {
            "financialStatus": [
                1
            ],
            "fulfillmentStatus": [
                1
            ],
            "gqlId": [
                1
            ],
            "orderValidationData": [
                1241
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyOrderValidationData": {
            "sales": [
                1243
            ],
            "refunds": [
                1242
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyOrderValidationRefund": {
            "id": [
                1
            ],
            "refundAmount": [
                11
            ],
            "giftCardAmount": [
                11
            ],
            "giftCardDiscountAmount": [
                11
            ],
            "shippingAmount": [
                11
            ],
            "taxAmount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyOrderValidationSale": {
            "id": [
                1
            ],
            "saleAmount": [
                11
            ],
            "giftCardAmount": [
                11
            ],
            "giftCardDiscountAmount": [
                11
            ],
            "taxAmount": [
                11
            ],
            "dutyAmount": [
                11
            ],
            "discountAmount": [
                11
            ],
            "shippingAmount": [
                11
            ],
            "tipAmount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyProduct": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "finaloopId": [
                1
            ],
            "lineOfBusinessId": [
                1
            ],
            "sku": [
                1
            ],
            "price": [
                11
            ],
            "displayName": [
                1
            ],
            "imageUrl": [
                1
            ],
            "listedChannels": [
                1158
            ],
            "appliedCosts": [
                1073
            ],
            "dailySalesData": [
                1159,
                {
                    "timeRange": [
                        1079,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "totalSold": [
                33
            ],
            "avgCostPerUnit": [
                11
            ],
            "productUrl": [
                1
            ],
            "productVariantId": [
                1
            ],
            "canEditLatestAppliedCost": [
                5
            ],
            "isFractional": [
                5
            ],
            "isHidden": [
                5
            ],
            "salesData": [
                1171,
                {
                    "timeRange": [
                        1079,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "salesData2": [
                1171,
                {
                    "timeRange": [
                        1079,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "rawRecipes": [
                1113,
                {
                    "timeRange": [
                        1079,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "componentOfProducts": [
                1071
            ],
            "isAmbiguousSku": [
                5
            ],
            "analyticsOverview": [
                1072,
                {
                    "input": [
                        69
                    ]
                }
            ],
            "warehousesAnalyticsOverview": [
                1098,
                {
                    "input": [
                        69
                    ]
                }
            ],
            "tags": [
                1
            ],
            "isCreatedManually": [
                5
            ],
            "isDataSyncing": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyReconciliationProductsStatus": {
            "unitsSoldDiff": [
                33
            ],
            "unitsRestockedDiff": [
                33
            ],
            "unitsGiveawayDiff": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyReportDateLimitsInput": {
            "integrationAccountId": [
                8
            ],
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyReportDateLimitsResponse": {
            "dateRange": [
                362
            ],
            "failureType": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "ShopifySkuReconciliationData": {
            "sku": [
                1
            ],
            "isBlacklisted": [
                5
            ],
            "excludedSalesChannelsData": [
                427
            ],
            "includedSalesChannelsData": [
                637
            ],
            "summary": [
                1127
            ],
            "productItemSoldData": [
                1249
            ],
            "productStatus": [
                1245
            ],
            "unknownSkuQuantity": [
                33
            ],
            "unknownSkuZeroPriceQuantity": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "ShopifySkuReconciliationProductItemSoldData": {
            "unitsSold": [
                33
            ],
            "unitsSoldWithZeroPrice": [
                33
            ],
            "unitsRestocked": [
                33
            ],
            "unitsGiveaway": [
                33
            ],
            "ambiguousSkuQuantity": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyTimezoneBalance": {
            "timezone": [
                1
            ],
            "grossAmount": [
                11
            ],
            "netAmount": [
                11
            ],
            "taxAmount": [
                11
            ],
            "discountAmount": [
                11
            ],
            "shippingAmount": [
                11
            ],
            "refundAmount": [
                11
            ],
            "totalAmount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "ShopifyValidationItemType": {},
        "SignedDownloadUrlInput": {
            "companyId": [
                1
            ],
            "s3Url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SignWorksheetRowInput": {
            "companyId": [
                1
            ],
            "year": [
                33
            ],
            "name": [
                1
            ],
            "amount": [
                11
            ],
            "comment": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SignWorksheetRowInputV2": {
            "companyId": [
                1
            ],
            "name": [
                1
            ],
            "amount": [
                11
            ],
            "comment": [
                1
            ],
            "periodDates": [
                1064
            ],
            "__typename": [
                1
            ]
        },
        "SimpleMoneyDirection": {},
        "SimulatedLoanFailure": {
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SimulatedLoanIrrelevantEvent": {
            "event": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SimulatedLoanSummary": {
            "on_SimulatedLoanIrrelevantEvent": [
                1257
            ],
            "on_SimulatedLoanUpdate": [
                1259
            ],
            "on_SimulatedLoanFailure": [
                1256
            ],
            "__typename": [
                1
            ]
        },
        "SimulatedLoanUpdate": {
            "createdLoan": [
                1260
            ],
            "deletedLoan": [
                1261
            ],
            "__typename": [
                1
            ]
        },
        "SimulatedLoanUpdateCreate": {
            "type": [
                833
            ],
            "isShortTerm": [
                5
            ],
            "date": [
                361
            ],
            "amount": [
                11
            ],
            "lender": [
                898
            ],
            "__typename": [
                1
            ]
        },
        "SimulatedLoanUpdateDelete": {
            "loanId": [
                1
            ],
            "type": [
                833
            ],
            "isShortTerm": [
                5
            ],
            "date": [
                361
            ],
            "amount": [
                11
            ],
            "lender": [
                898
            ],
            "__typename": [
                1
            ]
        },
        "SimulateTransactionUpdatedClassification": {
            "businessEvent": [
                1
            ],
            "amount": [
                11
            ],
            "pairedEntityId": [
                1
            ],
            "pairedEntityType": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SimulateTransactionUpdatedInput": {
            "companyId": [
                8
            ],
            "transactionId": [
                1
            ],
            "businessEvent": [
                1
            ],
            "merchantId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SimulateTransactionUpdatedInput2": {
            "companyId": [
                8
            ],
            "transactionId": [
                1
            ],
            "classifications": [
                1262
            ],
            "merchantId": [
                1
            ],
            "merchantType": [
                814
            ],
            "__typename": [
                1
            ]
        },
        "SkuValidationData": {
            "sku": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "unitsSold": [
                33
            ],
            "unitsRestocked": [
                33
            ],
            "unitsGiveaway": [
                33
            ],
            "unitsSoldWithZeroPrice": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "SortByMerchants": {},
        "SortDirection": {},
        "SpecificDate": {
            "date": [
                361
            ],
            "amount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "SpecificDateInput": {
            "date": [
                1
            ],
            "amount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "SplitProductsInput": {
            "companyId": [
                1
            ],
            "finaloopProductIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SplitProductsV2": {
            "productIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SplitTax1099VendorGroupsInput": {
            "companyId": [
                8
            ],
            "groupIds": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "SpreadOverTime": {
            "fromDate": [
                361
            ],
            "toDate": [
                361
            ],
            "amount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "SpreadOverTimeInput": {
            "fromDate": [
                1
            ],
            "toDate": [
                1
            ],
            "amount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "SpreadType": {},
        "StopImportingDataForBankAccountInput": {
            "bankAccountId": [
                8
            ],
            "companyId": [
                8
            ],
            "stopImportFromDate": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "StopImportingDataForBankAccountNewInput": {
            "bankAccountId": [
                8
            ],
            "companyId": [
                8
            ],
            "stopImportFromDate": [
                1
            ],
            "newOwner": [
                107
            ],
            "__typename": [
                1
            ]
        },
        "Store": {
            "id": [
                8
            ],
            "name": [
                1
            ],
            "homeCurrency": [
                340
            ],
            "__typename": [
                1
            ]
        },
        "StoreDateForLockingInput": {
            "companyId": [
                1
            ],
            "year": [
                33
            ],
            "name": [
                1
            ],
            "dateForLocking": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "StoreDateForLockingInputV2": {
            "companyId": [
                1
            ],
            "name": [
                1
            ],
            "dateForLocking": [
                361
            ],
            "periodDates": [
                1064
            ],
            "__typename": [
                1
            ]
        },
        "StoreOrderLineItemType": {},
        "StoreOrderTransactionMatchType": {},
        "StorePaymentV2": {
            "id": [
                8
            ],
            "source": [
                684
            ],
            "externalSourceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "StripeCustomerIdsByCompanyIdsInput": {
            "companyIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "StripeCustomerIdsResponse": {
            "companyAndStripeIds": [
                265
            ],
            "__typename": [
                1
            ]
        },
        "SubmitTransactionsFileToBankTransactionsUploadInput": {
            "companyId": [
                8
            ],
            "bankTransactionsUploadId": [
                1
            ],
            "bucket": [
                1
            ],
            "key": [
                1
            ],
            "processorId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SuccessResponse": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SuccessResult": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SuggestBankTransactionClassificationInput": {
            "companyId": [
                8
            ],
            "bankTransactionId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "SuggestBankTransactionClassificationResult": {
            "isSuccessful": [
                5
            ],
            "tag": [
                225
            ],
            "reason": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SuspectedBankAccountDuplication": {
            "id": [
                8
            ],
            "companyId": [
                1
            ],
            "bankAccountId1": [
                1
            ],
            "bankAccountId2": [
                1
            ],
            "liveConnectionBankAccountId": [
                1
            ],
            "readyForMergingAt": [
                361
            ],
            "hubspotNotificationSentAt": [
                361
            ],
            "duplicateCheckStartedAt": [
                361
            ],
            "duplicateCheckCompletedAt": [
                361
            ],
            "duplicateCheckResult": [
                418
            ],
            "mergeStartedAt": [
                361
            ],
            "mergeCompletedAt": [
                361
            ],
            "mergeResult": [
                888
            ],
            "fileLocationBucket": [
                1
            ],
            "fileLocationKey": [
                1
            ],
            "mergedBy": [
                22
            ],
            "mergedById": [
                1
            ],
            "totalMergedTransactionsChunks": [
                33
            ],
            "currentlyMergedTransactionsChunks": [
                33
            ],
            "createdAt": [
                361
            ],
            "updatedAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "SwitchCompanyArgs": {
            "companyId": [
                1
            ],
            "companyIdToSwitchTo": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "SwitchCompanyResponse": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SyncCompanyEventName": {},
        "SyncCompanyInput": {
            "eventName": [
                1294
            ],
            "body": [
                278
            ],
            "__typename": [
                1
            ]
        },
        "SyncGtmSourceInput": {
            "companyId": [
                8
            ],
            "gtmSource": [
                614
            ],
            "__typename": [
                1
            ]
        },
        "SyncInventoryIntegrationFulfillmentSettingInput": {
            "companyId": [
                8
            ],
            "source": [
                1090
            ],
            "isEnabled": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "SyncReportInput": {
            "companyId": [
                8
            ],
            "reportType": [
                1143
            ],
            "__typename": [
                1
            ]
        },
        "SyncStripeOffersInput": {
            "subscriptionProductId": [
                1
            ],
            "transitionFeeProductId": [
                1
            ],
            "catchupProductId": [
                1
            ],
            "couponCode": [
                1
            ],
            "isStripeTest": [
                5
            ],
            "description": [
                1
            ],
            "title": [
                1
            ],
            "tier": [
                905
            ],
            "__typename": [
                1
            ]
        },
        "SyncStripeTier1Offer": {
            "yearlySubscriptionPriceId": [
                1
            ],
            "monthlySubscriptionPriceId": [
                1
            ],
            "transitionFeePriceId": [
                1
            ],
            "catchupPriceId": [
                1
            ],
            "isStripeTest": [
                5
            ],
            "description": [
                1
            ],
            "title": [
                1
            ],
            "couponCode": [
                1
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Tax1099Vendor": {
            "vendor": [
                1445
            ],
            "type": [
                1307
            ],
            "totalPayments": [
                11
            ],
            "businessEvents": [
                1
            ],
            "sources": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Tax1099VendorDisqualifyReason": {},
        "Tax1099VendorGroup": {
            "id": [
                8
            ],
            "groupName": [
                1
            ],
            "isGroup": [
                5
            ],
            "type": [
                1307
            ],
            "vendors": [
                1301
            ],
            "totalPayments": [
                11
            ],
            "businessEvents": [
                1
            ],
            "sources": [
                1
            ],
            "disqualifyReason": [
                1302
            ],
            "file": [
                1304
            ],
            "markedAsDoneAt": [
                361
            ],
            "finaloopComment": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Tax1099VendorGroupFile": {
            "url": [
                1
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Tax1099VendorGroupFileInput": {
            "url": [
                1
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "Tax1099VendorGroupsByType": {
            "type": [
                1307
            ],
            "vendorGroups": [
                1303
            ],
            "__typename": [
                1
            ]
        },
        "Tax1099VendorType": {},
        "TaxInfo": {
            "amount": [
                11
            ],
            "description": [
                1
            ],
            "isTaxInclusive": [
                5
            ],
            "createdAt": [
                361
            ],
            "updatedAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "TikTokProduct": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "finaloopId": [
                1
            ],
            "lineOfBusinessId": [
                1
            ],
            "sku": [
                1
            ],
            "price": [
                11
            ],
            "displayName": [
                1
            ],
            "imageUrl": [
                1
            ],
            "listedChannels": [
                1158
            ],
            "appliedCosts": [
                1073
            ],
            "dailySalesData": [
                1159,
                {
                    "timeRange": [
                        1079,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "totalSold": [
                33
            ],
            "avgCostPerUnit": [
                11
            ],
            "asin": [
                1
            ],
            "canEditLatestAppliedCost": [
                5
            ],
            "isFractional": [
                5
            ],
            "isHidden": [
                5
            ],
            "salesData": [
                1171,
                {
                    "timeRange": [
                        1079,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "salesData2": [
                1171,
                {
                    "timeRange": [
                        1079,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "rawRecipes": [
                1113,
                {
                    "timeRange": [
                        1079,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "componentOfProducts": [
                1071
            ],
            "isAmbiguousSku": [
                5
            ],
            "analyticsOverview": [
                1072,
                {
                    "input": [
                        69
                    ]
                }
            ],
            "warehousesAnalyticsOverview": [
                1098,
                {
                    "input": [
                        69
                    ]
                }
            ],
            "tags": [
                1
            ],
            "isCreatedManually": [
                5
            ],
            "isDataSyncing": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "TimeRange": {
            "fromUTC": [
                361
            ],
            "toUTC": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "TimeRangeInput": {
            "fromUTC": [
                361
            ],
            "toUTC": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "ToggleOrdersZeroNetBalanceInput": {
            "companyId": [
                1
            ],
            "isZeroNetOrdersSupported": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ToggleRecognizeZeroNetOrdersAsGiveawaysInput": {
            "companyId": [
                8
            ],
            "shouldRecognizeZeroNetCogsAsGiveaways": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ToggleZeroNetBalanceInput": {
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "Topup": {
            "id": [
                8
            ],
            "companyId": [
                1
            ],
            "amount": [
                11
            ],
            "amountInUsd": [
                11
            ],
            "currency": [
                1
            ],
            "eventTime": [
                361
            ],
            "deletedAt": [
                361
            ],
            "integrationType": [
                684
            ],
            "pairedBankTransaction": [
                147
            ],
            "type": [
                683
            ],
            "classification": [
                672
            ],
            "integrationAccountId": [
                8
            ],
            "integrationAccountName": [
                1
            ],
            "pairedCompanyAffiliate": [
                245
            ],
            "link": [
                1
            ],
            "uploadedFileId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "TransactionDescription": {
            "description": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "TransactionItemToReconcile": {
            "companyId": [
                8
            ],
            "transactionId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "TransactionKind": {},
        "TransactionPaymentType": {},
        "TransactionsImportStatus": {
            "stopImportFromDate": [
                1
            ],
            "changedBy": [
                1
            ],
            "changedAt": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "TransactionType": {},
        "TransactionUpdatedInput": {
            "companyId": [
                8
            ],
            "transactionId": [
                1
            ],
            "actorType": [
                22
            ],
            "__typename": [
                1
            ]
        },
        "TransitionFeeInput": {
            "stripeId": [
                1
            ],
            "price": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "TriggerCOGSFromScratchInput": {
            "companyId": [
                8
            ],
            "managementMethod": [
                851
            ],
            "productIds": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UnclassifyIntegrationTransferInput": {
            "ids": [
                8
            ],
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "UnconnectedAccount": {
            "vendor": [
                1445
            ],
            "__typename": [
                1
            ]
        },
        "UnidentifiedBankTransactionParty": {
            "on_BankAccount": [
                95
            ],
            "on_UnknownTransactionBank": [
                1335
            ],
            "on_UnknownTransactionAccount": [
                1334
            ],
            "on_UnconnectedAccount": [
                1326
            ],
            "on_Vendor": [
                1445
            ],
            "on_TransactionDescription": [
                1316
            ],
            "__typename": [
                1
            ]
        },
        "UnidentifiedBankTransactionsGroup": {
            "transactions": [
                147
            ],
            "payer": [
                1327
            ],
            "payee": [
                1327
            ],
            "groupId": [
                8
            ],
            "isProcessing": [
                5
            ],
            "action": [
                1331
            ],
            "tooltipText": [
                1
            ],
            "type": [
                1330
            ],
            "__typename": [
                1
            ]
        },
        "UnidentifiedBankTransactionsGroupsInput": {
            "companyId": [
                8
            ],
            "fromDate": [
                361
            ],
            "toDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "UnidentifiedBankTransactionsGroupType": {},
        "UnidentifiedTransfersGroupAction": {},
        "UnknownIntegrationTransferAccount": {
            "dummy": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UnknownIntegrationTransferBank": {
            "dummy": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UnknownTransactionAccount": {
            "vendor": [
                1445
            ],
            "__typename": [
                1
            ]
        },
        "UnknownTransactionBank": {
            "vendor": [
                1445
            ],
            "__typename": [
                1
            ]
        },
        "UnMarkOrdersAsOvercollectedInput": {
            "companyId": [
                8
            ],
            "orderIds": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "UnMarkOrdersAsOvercollectedResponse": {
            "orders": [
                988
            ],
            "__typename": [
                1
            ]
        },
        "UnMarkOrdersAsUncollectibleInput": {
            "companyId": [
                8
            ],
            "orderIds": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "UnMarkOrdersAsUncollectibleResponse": {
            "orders": [
                988
            ],
            "__typename": [
                1
            ]
        },
        "UnsignWorksheetRowInput": {
            "companyId": [
                1
            ],
            "year": [
                33
            ],
            "name": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UnsignWorksheetRowInputV2": {
            "companyId": [
                1
            ],
            "name": [
                1
            ],
            "periodDates": [
                1064
            ],
            "__typename": [
                1
            ]
        },
        "UnvoidOrdersInput": {
            "companyId": [
                8
            ],
            "orderIds": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "UnvoidOrdersResponse": {
            "orders": [
                988
            ],
            "failedIds": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "UpdateAppliedCostFromIntegrationInput": {
            "appliedCosts": [
                71
            ],
            "companyId": [
                8
            ],
            "source": [
                1
            ],
            "impactOnBooksSince": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "UpdateBankAccountBalanceAdjustmentInput": {
            "companyId": [
                8
            ],
            "bankAccountId": [
                8
            ],
            "balanceAdjustment": [
                11
            ],
            "balanceAdjustmentDate": [
                1
            ],
            "actorType": [
                96
            ],
            "actorId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateBankAccountClosingBalanceInput": {
            "companyId": [
                8
            ],
            "bankAccountId": [
                8
            ],
            "closingBalance": [
                11
            ],
            "closingBalanceDate": [
                1
            ],
            "actorType": [
                98
            ],
            "actorId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateBankAccountInput": {
            "companyId": [
                8
            ],
            "id": [
                8
            ],
            "currentBalance": [
                11
            ],
            "lastUpdateTime": [
                361
            ],
            "isHistoricalUpdateCompleted": [
                5
            ],
            "rawData": [
                772
            ],
            "shouldUpdateAccountDetailsOnNextUpdate": [
                5
            ],
            "status": [
                119
            ],
            "forwardTransactionsToBankAccountId": [
                1
            ],
            "hasNotifiedUponNoHistoricalUpdate": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "UpdateBankAccountsMergeStatusInput": {
            "companyId": [
                8
            ],
            "suspectedDuplicationId": [
                8
            ],
            "fileLocationBucket": [
                1
            ],
            "fileLocationKey": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateBankReconciliationRequestMessageInput": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "message": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateBankTransactionMemoInput": {
            "companyId": [
                8
            ],
            "bankTransactionId": [
                8
            ],
            "text": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateBooksStartTimeArgs": {
            "companyId": [
                1
            ],
            "booksStartTime": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "UpdateCommentsInput": {
            "companyId": [
                1
            ],
            "year": [
                33
            ],
            "name": [
                1
            ],
            "comment": [
                1
            ],
            "attachmentUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateCommentsInputV2": {
            "companyId": [
                1
            ],
            "name": [
                1
            ],
            "comment": [
                1
            ],
            "attachmentUrl": [
                1
            ],
            "periodDates": [
                1064
            ],
            "__typename": [
                1
            ]
        },
        "UpdateCompanyAccountingMethodArgs": {
            "companyId": [
                1
            ],
            "accountingMethod": [
                348
            ],
            "flow": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateCompanyAffiliateInput": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "name": [
                1
            ],
            "isForeignResident": [
                5
            ],
            "holdingsInPercentage": [
                11
            ],
            "status": [
                629
            ],
            "__typename": [
                1
            ]
        },
        "UpdateCompanyPayingStatusInput": {
            "companyId": [
                1
            ],
            "isPaying": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "UpdateCompanyTierInput": {
            "companyId": [
                1
            ],
            "tier": [
                279
            ],
            "__typename": [
                1
            ]
        },
        "UpdateCompanyVendorExternalSourceIdVendorInput": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "externalSourceId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateCustomerArgs": {
            "id": [
                1
            ],
            "name": [
                1
            ],
            "companyName": [
                1
            ],
            "externalSourceId": [
                1
            ],
            "source": [
                349
            ],
            "email": [
                1
            ],
            "phoneNumber": [
                1
            ],
            "billingAddress": [
                28
            ],
            "shippingAddress": [
                28
            ],
            "__typename": [
                1
            ]
        },
        "UpdatedBankAccountReconciliation": {
            "id": [
                8
            ],
            "companyId": [
                1
            ],
            "transactionsFileNames": [
                1
            ],
            "statementFileNames": [
                1
            ],
            "balance": [
                11
            ],
            "balanceAtIso": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdatedLoans": {
            "loans": [
                787
            ],
            "__typename": [
                1
            ]
        },
        "UpdateEmployeesOfficerStatusChange": {
            "employeeId": [
                8
            ],
            "isOfficer": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "UpdateEmployeesOfficerStatusInput": {
            "companyId": [
                8
            ],
            "changes": [
                1362
            ],
            "__typename": [
                1
            ]
        },
        "UpdateEstimatedProjectedRevenueInput": {
            "companyId": [
                1
            ],
            "estimatedProjectedRevenue": [
                425
            ],
            "__typename": [
                1
            ]
        },
        "UpdateGlobalVendorLogo": {
            "id": [
                8
            ],
            "logoUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateGlobalVendorLogoInput": {
            "id": [
                8
            ],
            "logoUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateInstitutionInput": {
            "id": [
                8
            ],
            "name": [
                1
            ],
            "preferredProvider": [
                653
            ],
            "isMajor": [
                1
            ],
            "logoUrl": [
                1
            ],
            "websiteUrl": [
                1
            ],
            "csvInstructionsLink": [
                1
            ],
            "pdfInstructionsLink": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateInstitutionMappingInput": {
            "id": [
                8
            ],
            "source": [
                652
            ],
            "externalSourceId": [
                1
            ],
            "externalSourceName": [
                1
            ],
            "institutionId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateInternalLoanInput": {
            "companyId": [
                8
            ],
            "loanId": [
                8
            ],
            "data": [
                835
            ],
            "__typename": [
                1
            ]
        },
        "UpdateInventoryPurchaseResponse": {
            "on_InventoryPurchase": [
                689
            ],
            "on_InventoryPurchaseUpdateOutOfDateError": [
                713
            ],
            "__typename": [
                1
            ]
        },
        "UpdateInvoicePaymentGroupInput": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "postedAt": [
                361
            ],
            "invoices": [
                1009
            ],
            "bankTransactions": [
                1004
            ],
            "expenses": [
                1007
            ],
            "__typename": [
                1
            ]
        },
        "UpdateInvoicePaymentGroupResponse": {
            "paymentGroup": [
                747
            ],
            "__typename": [
                1
            ]
        },
        "UpdateLoanAttachmentInput": {
            "id": [
                8
            ],
            "fileName": [
                1
            ],
            "url": [
                1
            ],
            "type": [
                793
            ],
            "__typename": [
                1
            ]
        },
        "UpdateLoanAttachmentsInput": {
            "companyId": [
                8
            ],
            "loanId": [
                8
            ],
            "attachments": [
                1373
            ],
            "__typename": [
                1
            ]
        },
        "UpdateLoanInput": {
            "companyId": [
                8
            ],
            "loanId": [
                8
            ],
            "data": [
                834
            ],
            "__typename": [
                1
            ]
        },
        "UpdateLoanManualAccountingEventInput": {
            "companyId": [
                8
            ],
            "loanId": [
                8
            ],
            "accountingEventId": [
                8
            ],
            "amount": [
                11
            ],
            "memo": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateLoansInput": {
            "loans": [
                1378
            ],
            "data": [
                835
            ],
            "__typename": [
                1
            ]
        },
        "UpdateLoansInputLoan": {
            "companyId": [
                8
            ],
            "loanId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "UpdateManualAssemblyInput": {
            "companyId": [
                8
            ],
            "id": [
                1
            ],
            "productId": [
                1
            ],
            "displayName": [
                1
            ],
            "date": [
                361
            ],
            "warehouseId": [
                1
            ],
            "unitsToAssemble": [
                33
            ],
            "recipe": [
                1394
            ],
            "__typename": [
                1
            ]
        },
        "UpdateManualAssemblyResult": {
            "on_ManualAssembly": [
                852
            ],
            "on_RecipeCirclesFoundError": [
                1115
            ],
            "__typename": [
                1
            ]
        },
        "UpdateManualLoanEventInput": {
            "companyId": [
                8
            ],
            "loanId": [
                8
            ],
            "loanEventId": [
                8
            ],
            "eventDefinitionId": [
                8
            ],
            "amount": [
                11
            ],
            "memo": [
                1
            ],
            "postedAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "UpdateManualLoanEventInput2": {
            "companyId": [
                8
            ],
            "loanId": [
                8
            ],
            "loanEventId": [
                8
            ],
            "eventDefinitionId": [
                8
            ],
            "amount": [
                11
            ],
            "memo": [
                1
            ],
            "postedAt": [
                361
            ],
            "recurrenceSettings": [
                1131
            ],
            "spreadInput": [
                327
            ],
            "__typename": [
                1
            ]
        },
        "UpdateManualLoanEventResult": {
            "loan": [
                787
            ],
            "__typename": [
                1
            ]
        },
        "UpdateManualLoanEventsResult": {
            "loan": [
                787
            ],
            "__typename": [
                1
            ]
        },
        "UpdateOnboardingStatusInput": {
            "companyId": [
                8
            ],
            "scope": [
                906
            ],
            "step": [
                1
            ],
            "status": [
                910
            ],
            "__typename": [
                1
            ]
        },
        "UpdateOrderEntitiesInput": {
            "entityType": [
                926
            ],
            "companyId": [
                1
            ],
            "entityIds": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "UpdateOrderGroupInput": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "postedAt": [
                361
            ],
            "orders": [
                983
            ],
            "bankTransactions": [
                177
            ],
            "expenses": [
                936
            ],
            "__typename": [
                1
            ]
        },
        "UpdateOrderGroupResponse": {
            "orderGroup": [
                932
            ],
            "__typename": [
                1
            ]
        },
        "UpdateOrderPaymentGroupInput": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "postedAt": [
                361
            ],
            "orders": [
                1018
            ],
            "bankTransactions": [
                1004
            ],
            "expenses": [
                1007
            ],
            "__typename": [
                1
            ]
        },
        "UpdateOrderPaymentGroupResponse": {
            "paymentGroup": [
                961
            ],
            "__typename": [
                1
            ]
        },
        "UpdatePayrollSettingsInput": {
            "companyId": [
                8
            ],
            "withCategoryMapping": [
                5
            ],
            "categoryMappingMode": [
                1044
            ],
            "categoryMappings": [
                1043
            ],
            "__typename": [
                1
            ]
        },
        "UpdateProductFractionalInput": {
            "companyId": [
                1
            ],
            "productId": [
                1
            ],
            "isFractional": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "UpdateProductInput": {
            "companyId": [
                1
            ],
            "productId": [
                1
            ],
            "displayName": [
                1
            ],
            "imageUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateRecipeInput": {
            "companyId": [
                8
            ],
            "id": [
                1
            ],
            "from": [
                361
            ],
            "to": [
                361
            ],
            "isAssembledOnDemand": [
                5
            ],
            "productId": [
                1
            ],
            "warehouseId": [
                1
            ],
            "assemblyCost": [
                11
            ],
            "components": [
                1118
            ],
            "managementMethod": [
                1119
            ],
            "displayName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateRecipeResult": {
            "on_Recipe": [
                1113
            ],
            "on_RecipeCirclesFoundError": [
                1115
            ],
            "on_RecipeOverlappingError": [
                1120
            ],
            "__typename": [
                1
            ]
        },
        "UpdateSharedGoogleDriveFolder": {
            "companyId": [
                8
            ],
            "folderId": [
                8
            ],
            "folderName": [
                1
            ],
            "folderUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateUserDetailsArgs": {
            "id": [
                8
            ],
            "email": [
                1
            ],
            "name": [
                1
            ],
            "auth0Id": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateUserDetailsResponse": {
            "user": [
                1421
            ],
            "__typename": [
                1
            ]
        },
        "UpdateUserRolesForCompanyArgs": {
            "email": [
                1
            ],
            "roles": [
                1
            ],
            "companyId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateUserRolesForCompanyResponse": {
            "success": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "UpdateVendorInput": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "source": [
                1455
            ],
            "name": [
                1
            ],
            "logoUrl": [
                1
            ],
            "labels": [
                1
            ],
            "subTypes": [
                1457
            ],
            "is1099Contractor": [
                5
            ],
            "externalSourceId": [
                1
            ],
            "contactEmail": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpdateWarehouseFieldsInput": {
            "name": [
                1
            ],
            "phone": [
                1
            ],
            "city": [
                1
            ],
            "province": [
                1
            ],
            "country": [
                1
            ],
            "zip": [
                1
            ],
            "address": [
                1
            ],
            "isDefault": [
                5
            ],
            "isActive": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "UpdateWarehouseInput": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "updateFields": [
                1402
            ],
            "__typename": [
                1
            ]
        },
        "UpdateWarehousesDefaultsInput": {
            "companyId": [
                8
            ],
            "defaults": [
                1489
            ],
            "__typename": [
                1
            ]
        },
        "UpdateWarehouseTransferInput": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "serialNumber": [
                33
            ],
            "displayName": [
                1
            ],
            "transit": [
                1525
            ],
            "lineItems": [
                1513
            ],
            "costLineItems": [
                1508
            ],
            "attachments": [
                1501
            ],
            "notes": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UploadBankStatementInput": {
            "companyId": [
                8
            ],
            "bankAccountId": [
                8
            ],
            "s3Bucket": [
                1
            ],
            "s3Key": [
                1
            ],
            "fileName": [
                1
            ],
            "fileType": [
                114
            ],
            "system": [
                138
            ],
            "subSystem": [
                136
            ],
            "validationProvider": [
                137
            ],
            "__typename": [
                1
            ]
        },
        "UploadBankStatementResponse": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "bankAccount": [
                95
            ],
            "providerValidationResults": [
                145
            ],
            "__typename": [
                1
            ]
        },
        "UpsertBankTransactionLocalRuleInput": {
            "companyId": [
                8
            ],
            "bankTransactionId": [
                8
            ],
            "classification": [
                156
            ],
            "moneyDirection": [
                1255
            ],
            "merchant": [
                873
            ],
            "shouldAlwaysApplyMerchant": [
                5
            ],
            "shouldAlwaysApplyClassification": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "UpsertCompanyAddressInput": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "isDefault": [
                5
            ],
            "deactivatedAt": [
                361
            ],
            "name": [
                1
            ],
            "source": [
                1
            ],
            "email": [
                1
            ],
            "phone": [
                1
            ],
            "line1": [
                1
            ],
            "line2": [
                1
            ],
            "city": [
                1
            ],
            "country": [
                1
            ],
            "province": [
                1
            ],
            "provinceCode": [
                1
            ],
            "zip": [
                1
            ],
            "finaloopSubjectId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpsertCustomerAddressArgs": {
            "id": [
                1
            ],
            "customerId": [
                1
            ],
            "companyId": [
                1
            ],
            "line1": [
                1
            ],
            "line2": [
                1
            ],
            "city": [
                1
            ],
            "country": [
                1
            ],
            "countryCode": [
                1
            ],
            "province": [
                1
            ],
            "provinceCode": [
                1
            ],
            "zip": [
                1
            ],
            "source": [
                349
            ],
            "integrationAccountId": [
                1
            ],
            "isBilling": [
                5
            ],
            "isShipping": [
                5
            ],
            "deletedAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "UpsertCustomerV2Args": {
            "id": [
                1
            ],
            "affiliatedEntityId": [
                1
            ],
            "companyId": [
                1
            ],
            "name": [
                1
            ],
            "email": [
                1
            ],
            "source": [
                349
            ],
            "integrationAccountId": [
                1
            ],
            "billingAddress": [
                345
            ],
            "shippingAddress": [
                345
            ],
            "billingIsSameAsShipping": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "UpsertGlobalVendorInput": {
            "id": [
                8
            ],
            "name": [
                1
            ],
            "source": [
                1455
            ],
            "websiteUrl": [
                1
            ],
            "labels": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpsertHolderCapitalInvestmentInput": {
            "id": [
                8
            ],
            "holderId": [
                8
            ],
            "companyId": [
                8
            ],
            "amount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "UpsertHolderShareInput": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "holderId": [
                8
            ],
            "type": [
                1218
            ],
            "series": [
                1
            ],
            "amount": [
                11
            ],
            "parValue": [
                11
            ],
            "quantity": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "UpsertIntegrationCustomerArgs": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "name": [
                1
            ],
            "email": [
                1
            ],
            "source": [
                349
            ],
            "integrationAccountId": [
                1
            ],
            "finaloopSubjectId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpsertIntegrationInvoiceInput": {
            "companyId": [
                8
            ],
            "companyAddress": [
                657
            ],
            "shippingAddress": [
                661
            ],
            "billingAddress": [
                661
            ],
            "customer": [
                662
            ],
            "payments": [
                670
            ],
            "refunds": [
                670
            ],
            "paymentIndications": [
                670
            ],
            "refundIndications": [
                670
            ],
            "externalSourceId": [
                8
            ],
            "externalSourceUrl": [
                1
            ],
            "source": [
                669
            ],
            "integrationAccountId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "displayName": [
                1
            ],
            "postedAt": [
                361
            ],
            "dueDate": [
                361
            ],
            "taxFixedAmount": [
                11
            ],
            "lineItems": [
                667
            ],
            "discounts": [
                663
            ],
            "customerNote": [
                1
            ],
            "issueInvoice": [
                5
            ],
            "voidedAt": [
                361
            ],
            "deletedAt": [
                361
            ],
            "currency": [
                340
            ],
            "__typename": [
                1
            ]
        },
        "UpsertInvoiceInput": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "companyAddressId": [
                8
            ],
            "customerId": [
                8
            ],
            "shippingAddressId": [
                8
            ],
            "billingAddressId": [
                8
            ],
            "displayName": [
                1
            ],
            "postedAt": [
                361
            ],
            "dueDate": [
                361
            ],
            "taxFixedAmount": [
                11
            ],
            "lineItems": [
                1153
            ],
            "discounts": [
                409
            ],
            "paymentMethodIds": [
                8
            ],
            "customerNote": [
                1
            ],
            "issueInvoice": [
                5
            ],
            "source": [
                1173
            ],
            "currency": [
                340
            ],
            "__typename": [
                1
            ]
        },
        "UpsertInvoiceSettingsInput": {
            "companyId": [
                8
            ],
            "logoImageLocation": [
                1
            ],
            "currentAutoInvoiceNumber": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "UpsertPaymentMethodInput": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "type": [
                1033
            ],
            "isDefault": [
                5
            ],
            "deletedAt": [
                361
            ],
            "instructions": [
                1
            ],
            "bankName": [
                1
            ],
            "accountNumber": [
                1
            ],
            "routingNumber": [
                1
            ],
            "swift": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UpsertSalesChannelInput": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "integrationAccountId": [
                1
            ],
            "source": [
                1
            ],
            "name": [
                1
            ],
            "isSalesAffecting": [
                5
            ],
            "isCogsAffecting": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "User": {
            "id": [
                8
            ],
            "email": [
                1
            ],
            "auth0Id": [
                1
            ],
            "name": [
                1
            ],
            "companies": [
                1427
            ],
            "permissions": [
                1065
            ],
            "globalRole": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UserAttachTransactionsToExistingLoanInput": {
            "companyId": [
                8
            ],
            "loanId": [
                8
            ],
            "transactions": [
                88
            ],
            "__typename": [
                1
            ]
        },
        "UserAttachTransactionsToNewLoanData": {
            "lenderId": [
                1
            ],
            "amount": [
                11
            ],
            "postedAt": [
                361
            ],
            "direction": [
                804
            ],
            "isShortTerm": [
                5
            ],
            "type": [
                833
            ],
            "userActionStatus": [
                837
            ],
            "__typename": [
                1
            ]
        },
        "UserAttachTransactionsToNewLoanInput": {
            "companyId": [
                8
            ],
            "transactions": [
                88
            ],
            "merchant": [
                817
            ],
            "newLoanData": [
                1423
            ],
            "__typename": [
                1
            ]
        },
        "UserByEmailArgs": {
            "email": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UserByEmailResponse": {
            "user": [
                1421
            ],
            "__typename": [
                1
            ]
        },
        "UserCompanies": {
            "company": [
                241
            ],
            "roles": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "UserFollowUp": {
            "type": [
                838
            ],
            "data": [
                772
            ],
            "__typename": [
                1
            ]
        },
        "UserFollowUpInput": {
            "type": [
                838
            ],
            "data": [
                772
            ],
            "__typename": [
                1
            ]
        },
        "UserInvitation": {
            "id": [
                8
            ],
            "email": [
                1
            ],
            "companyId": [
                8
            ],
            "roles": [
                1
            ],
            "invitedByUserId": [
                1
            ],
            "invitationCode": [
                1
            ],
            "acceptedAt": [
                361
            ],
            "cancelledBy": [
                1
            ],
            "cancelledAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "UserInvitationArgs": {
            "invitationCode": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UserInvitationResponse": {
            "userInvitation": [
                1435
            ],
            "__typename": [
                1
            ]
        },
        "UserInvitationsByEmailArgs": {
            "email": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UserInvitationsByEmailResponse": {
            "userInvitations": [
                1435
            ],
            "__typename": [
                1
            ]
        },
        "UserInvitationWithCompany": {
            "id": [
                8
            ],
            "email": [
                1
            ],
            "company": [
                241
            ],
            "roles": [
                1
            ],
            "invitedByUserId": [
                1
            ],
            "invitationCode": [
                1
            ],
            "acceptedAt": [
                361
            ],
            "cancelledBy": [
                1
            ],
            "cancelledAt": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "UserManualProduct": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "finaloopId": [
                1
            ],
            "lineOfBusinessId": [
                1
            ],
            "sku": [
                1
            ],
            "price": [
                11
            ],
            "displayName": [
                1
            ],
            "imageUrl": [
                1
            ],
            "listedChannels": [
                1158
            ],
            "appliedCosts": [
                1073
            ],
            "dailySalesData": [
                1159,
                {
                    "timeRange": [
                        1079,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "totalSold": [
                33
            ],
            "avgCostPerUnit": [
                11
            ],
            "canEditLatestAppliedCost": [
                5
            ],
            "isFractional": [
                5
            ],
            "isHidden": [
                5
            ],
            "salesData": [
                1171,
                {
                    "timeRange": [
                        1079,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "salesData2": [
                1171,
                {
                    "timeRange": [
                        1079,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "rawRecipes": [
                1113,
                {
                    "timeRange": [
                        1079,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "componentOfProducts": [
                1071
            ],
            "isAmbiguousSku": [
                5
            ],
            "analyticsOverview": [
                1072,
                {
                    "input": [
                        69
                    ]
                }
            ],
            "warehousesAnalyticsOverview": [
                1098,
                {
                    "input": [
                        69
                    ]
                }
            ],
            "tags": [
                1
            ],
            "isDataSyncing": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "UserRole": {
            "id": [
                1
            ],
            "name": [
                1
            ],
            "description": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UserRolesResponse": {
            "userRoles": [
                1437
            ],
            "__typename": [
                1
            ]
        },
        "UsersByEmailsArgs": {
            "emails": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "UsersByEmailsResponse": {
            "users": [
                1421
            ],
            "__typename": [
                1
            ]
        },
        "ValidationResult": {
            "companyId": [
                1
            ],
            "integrationType": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "currency": [
                1
            ],
            "accountRoles": [
                1
            ],
            "accountingMethod": [
                1
            ],
            "resultSet": [
                1442
            ],
            "__typename": [
                1
            ]
        },
        "ValidationResultSet": {
            "createdAt": [
                361
            ],
            "sampleDate": [
                361
            ],
            "sampleValue": [
                11
            ],
            "currentValue": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "ValidationsArgs": {
            "integrationType": [
                1
            ],
            "accountRole": [
                1
            ],
            "resultsLimit": [
                33
            ],
            "payingCompaniesOnly": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "ValueType": {},
        "Vendor": {
            "id": [
                8
            ],
            "integrationType": [
                684
            ],
            "name": [
                1
            ],
            "companyId": [
                8
            ],
            "externalSourceId": [
                1
            ],
            "websiteUrl": [
                1
            ],
            "logoUrl": [
                1
            ],
            "source": [
                1455
            ],
            "labels": [
                1
            ],
            "contactEmail": [
                1
            ],
            "is1099Contractor": [
                5
            ],
            "type": [
                1458
            ],
            "subType": [
                1457
            ],
            "affiliatedEntityId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "VendorByAffiliatedEntityIdInput": {
            "affiliatedEntityId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "VendorByIdInput": {
            "id": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "VendorByNameInput": {
            "name": [
                1
            ],
            "companyId": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "VendorByPaymentGatewayInput": {
            "paymentGateway": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "VendorByPaymentGatewayResponse": {
            "vendor": [
                1445
            ],
            "__typename": [
                1
            ]
        },
        "VendorNotExistError": {
            "message": [
                1
            ],
            "id": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "VendorsByIntegrationTypeInput": {
            "integrationType": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "VendorsInput": {
            "searchTerm": [
                1
            ],
            "limit": [
                33
            ],
            "companyId": [
                8
            ],
            "subTypes": [
                1457
            ],
            "__typename": [
                1
            ]
        },
        "VendorsNotificationInfoInput": {
            "subTypes": [
                1457
            ],
            "purchaseOrderId": [
                1
            ],
            "purchaseOrderGroup": [
                1
            ],
            "logrocketSessionUrl": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "VendorSource": {},
        "VendorsTransactionsCount": {
            "id": [
                1
            ],
            "amount": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "VendorSubType": {},
        "VendorType": {},
        "VoidOrderSettings": {
            "restrictedPaymentGateways": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "VoidOrdersInput": {
            "companyId": [
                8
            ],
            "orderIds": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "VoidOrdersResponse": {
            "orders": [
                988
            ],
            "failedIds": [
                8
            ],
            "__typename": [
                1
            ]
        },
        "WalmartProduct": {
            "id": [
                1
            ],
            "companyId": [
                1
            ],
            "finaloopId": [
                1
            ],
            "lineOfBusinessId": [
                1
            ],
            "sku": [
                1
            ],
            "price": [
                11
            ],
            "displayName": [
                1
            ],
            "imageUrl": [
                1
            ],
            "listedChannels": [
                1158
            ],
            "appliedCosts": [
                1073
            ],
            "dailySalesData": [
                1159,
                {
                    "timeRange": [
                        1079,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "totalSold": [
                33
            ],
            "avgCostPerUnit": [
                11
            ],
            "canEditLatestAppliedCost": [
                5
            ],
            "walmartId": [
                1
            ],
            "productUrl": [
                1
            ],
            "isFractional": [
                5
            ],
            "isHidden": [
                5
            ],
            "salesData": [
                1171,
                {
                    "timeRange": [
                        1079,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "salesData2": [
                1171,
                {
                    "timeRange": [
                        1079,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "rawRecipes": [
                1113,
                {
                    "timeRange": [
                        1079,
                        "ProductDataTimeRangeInput!"
                    ]
                }
            ],
            "componentOfProducts": [
                1071
            ],
            "isAmbiguousSku": [
                5
            ],
            "analyticsOverview": [
                1072,
                {
                    "input": [
                        69
                    ]
                }
            ],
            "warehousesAnalyticsOverview": [
                1098,
                {
                    "input": [
                        69
                    ]
                }
            ],
            "tags": [
                1
            ],
            "isCreatedManually": [
                5
            ],
            "isDataSyncing": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "Warehouse": {
            "id": [
                8
            ],
            "companyId": [
                8
            ],
            "name": [
                1
            ],
            "source": [
                1491
            ],
            "phone": [
                1
            ],
            "city": [
                1
            ],
            "province": [
                1
            ],
            "country": [
                1
            ],
            "zip": [
                1
            ],
            "address": [
                1
            ],
            "isDeletable": [
                5
            ],
            "isActive": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseAction": {
            "quantity": [
                33
            ],
            "id": [
                1
            ],
            "date": [
                361
            ],
            "type": [
                1
            ],
            "on_AdjustmentRestockingAction": [
                32
            ],
            "on_CommonAction": [
                239
            ],
            "on_FulfillmentAction": [
                473
            ],
            "on_ManualAssemblyAction": [
                853
            ],
            "on_OnDemandAssemblyAction": [
                911
            ],
            "on_OpeningBalanceAction": [
                914
            ],
            "on_RestockingAction": [
                1144
            ],
            "on_ShipmentAction": [
                1220
            ],
            "on_WarehouseTransferArrivalAction": [
                1498
            ],
            "on_WarehouseTransferShippingAction": [
                1516
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseActionSalesChannel": {
            "integrationType": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseCreateError": {
            "message": [
                1
            ],
            "type": [
                1467
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseCreateErrorTypeEnum": {},
        "WarehouseCreateResult": {
            "on_Warehouse": [
                1463
            ],
            "on_WarehouseCreateError": [
                1466
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseFilter": {
            "type": [
                1470
            ],
            "warehouseId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseFilterType": {},
        "WarehouseIncomingInventoryBreakdownFiltersInput": {
            "hasUnavailable": [
                5
            ],
            "searchText": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseIncomingInventoryBreakdownInput": {
            "companyId": [
                8
            ],
            "productId": [
                1
            ],
            "warehouseId": [
                1
            ],
            "sort": [
                1494
            ],
            "limit": [
                33
            ],
            "offset": [
                33
            ],
            "filters": [
                1471
            ],
            "date": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseIncomingInventoryBreakdownResult": {
            "incomingBatches": [
                639
            ],
            "totalBatches": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseIncomingInventoryOverviewInput": {
            "companyId": [
                8
            ],
            "productId": [
                1
            ],
            "date": [
                361
            ],
            "fromDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseIncomingInventoryOverviewResult": {
            "warehouse": [
                1463
            ],
            "analytics": [
                641
            ],
            "inTransit": [
                33
            ],
            "openingBalance": [
                1477
            ],
            "totalBatches": [
                33
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseMovements": {
            "warehouse": [
                1463
            ],
            "movements": [
                893
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseOpeningBalance": {
            "id": [
                8
            ],
            "warehouse": [
                1463
            ],
            "product": [
                1071
            ],
            "quantity": [
                33
            ],
            "costPerUnit": [
                11
            ],
            "date": [
                361
            ],
            "isMissingUserInput": [
                5
            ],
            "attachmentId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseOpeningBalanceErrorItem": {
            "rowNumber": [
                33
            ],
            "cellNumber": [
                33
            ],
            "errorType": [
                1479
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseOpeningBalanceErrorItemType": {},
        "WarehouseOpeningBalanceItem": {
            "product": [
                1071
            ],
            "warehouseOpeningBalance": [
                1477
            ],
            "pendingWarehouseOpeningBalance": [
                1059
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseOpeningBalanceOverviewResponse": {
            "count": [
                33
            ],
            "pendingCount": [
                33
            ],
            "missingDataCount": [
                33
            ],
            "totalCost": [
                11
            ],
            "minQuantity": [
                11
            ],
            "maxQuantity": [
                11
            ],
            "minCostPerUnit": [
                11
            ],
            "maxCostPerUnit": [
                11
            ],
            "lastPendingChange": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseOpeningBalancesCsvTemplateResponse": {
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseOpeningBalanceSort": {
            "field": [
                1484
            ],
            "direction": [
                1267
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseOpeningBalanceSortField": {},
        "WarehouseOpeningBalancesResponse": {
            "items": [
                1480
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseResult": {
            "data": [
                1463
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseSalesData": {
            "warehouse": [
                1463
            ],
            "salesChannels": [
                1163
            ],
            "deadInventory": [
                364
            ],
            "giveaway": [
                603
            ],
            "donation": [
                411
            ],
            "waste": [
                1527
            ],
            "salesAdjustments": [
                1089
            ],
            "deadInventoryAdjustments": [
                1081
            ],
            "giveawayAdjustments": [
                1085
            ],
            "donationAdjustments": [
                1083
            ],
            "__typename": [
                1
            ]
        },
        "WarehousesDefaults": {
            "integrationType": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "warehouse": [
                1463
            ],
            "__typename": [
                1
            ]
        },
        "WarehousesDefaultsInput": {
            "integrationType": [
                1
            ],
            "integrationAccountName": [
                1
            ],
            "integrationAccountId": [
                1
            ],
            "warehouseId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "WarehousesDefaultsResult": {
            "companyId": [
                8
            ],
            "defaults": [
                1488
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseSource": {},
        "WarehousesProductPurchasesBreakdownSortDirection": {},
        "WarehousesProductPurchasesBreakdownSortField": {},
        "WarehousesProductPurchasesBreakdownSortInput": {
            "field": [
                1493
            ],
            "direction": [
                1492
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTimeRangeInput": {
            "startDate": [
                361
            ],
            "endDate": [
                361
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransfer": {
            "id": [
                8
            ],
            "serialNumber": [
                33
            ],
            "displayName": [
                1
            ],
            "transit": [
                1524
            ],
            "lineItems": [
                1510
            ],
            "costLineItems": [
                1507
            ],
            "attachments": [
                1500
            ],
            "status": [
                1523
            ],
            "notes": [
                1
            ],
            "deletedAt": [
                361
            ],
            "productsCost": [
                11
            ],
            "transferCost": [
                11
            ],
            "totalCost": [
                11
            ],
            "isHistorical": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferArrival": {
            "date": [
                361
            ],
            "warehouse": [
                1463
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferArrivalAction": {
            "quantity": [
                33
            ],
            "id": [
                1
            ],
            "date": [
                361
            ],
            "type": [
                1
            ],
            "warehouseTransfer": [
                1496
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferArrivalInput": {
            "date": [
                361
            ],
            "warehouseId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferAttachment": {
            "filename": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferAttachmentInput": {
            "filename": [
                1
            ],
            "url": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferBatchHistoryItem": {
            "action": [
                1464
            ],
            "landedCost": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferBreakdownBatch": {
            "quantity": [
                11
            ],
            "history": [
                1502
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferBreakdownItem": {
            "action": [
                1464
            ],
            "quantity": [
                11
            ],
            "landedCost": [
                11
            ],
            "batches": [
                1503
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferCostAllocation": {
            "costLineItemId": [
                1
            ],
            "costPerUnit": [
                11
            ],
            "lastUnitCost": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferCostAllocationInput": {
            "costLineItemId": [
                1
            ],
            "costPerUnit": [
                11
            ],
            "lastUnitCost": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferCostLineItem": {
            "id": [
                1
            ],
            "type": [
                1509
            ],
            "amount": [
                11
            ],
            "vendor": [
                1445
            ],
            "isManuallyAllocated": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferCostLineItemInput": {
            "id": [
                1
            ],
            "type": [
                1509
            ],
            "amount": [
                11
            ],
            "vendorId": [
                1
            ],
            "isManuallyAllocated": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferCostLineItemType": {},
        "WarehouseTransferLineItem": {
            "id": [
                1
            ],
            "product": [
                1071
            ],
            "quantity": [
                33
            ],
            "lineItemIdx": [
                33
            ],
            "costAllocations": [
                1505
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferLineItemFifoCost": {
            "directCost": [
                11
            ],
            "indirectCosts": [
                1512
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferLineItemFifoIndirectCost": {
            "type": [
                646
            ],
            "amount": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferLineItemInput": {
            "id": [
                1
            ],
            "productId": [
                1
            ],
            "quantity": [
                33
            ],
            "lineItemIdx": [
                33
            ],
            "costAllocations": [
                1506
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferOverviewItem": {
            "status": [
                1523
            ],
            "count": [
                33
            ],
            "totalCost": [
                11
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferShipping": {
            "date": [
                361
            ],
            "warehouse": [
                1463
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferShippingAction": {
            "quantity": [
                33
            ],
            "id": [
                1
            ],
            "date": [
                361
            ],
            "type": [
                1
            ],
            "warehouseTransfer": [
                1496
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferShippingInput": {
            "date": [
                361
            ],
            "warehouseId": [
                1
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransfersOverview": {
            "items": [
                1514
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransfersResponse": {
            "warehouseTransfers": [
                1496
            ],
            "hasNext": [
                5
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransfersSortDirection": {},
        "WarehouseTransfersSortField": {},
        "WarehouseTransfersSortInput": {
            "field": [
                1521
            ],
            "direction": [
                1520
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferStatus": {},
        "WarehouseTransferTransit": {
            "shipping": [
                1515
            ],
            "arrival": [
                1497
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferTransitInput": {
            "shipping": [
                1517
            ],
            "arrival": [
                1499
            ],
            "__typename": [
                1
            ]
        },
        "WarehouseTransferWithBreakdown": {
            "warehouseTransfer": [
                1496
            ],
            "date": [
                361
            ],
            "items": [
                1504
            ],
            "__typename": [
                1
            ]
        },
        "WasteCogs": {
            "amount": [
                11
            ],
            "quantity": [
                11
            ],
            "monthlyBreakdown": [
                1528
            ],
            "__typename": [
                1
            ]
        },
        "WasteMonthlyBreakdown": {
            "date": [
                361
            ],
            "quantity": [
                11
            ],
            "__typename": [
                1
            ]
        }
    }
}