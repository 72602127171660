import type { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { Chip, Stack, Tooltip, Typography, IconButton, styled } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Paper from '@mui/material/Paper';

import { getMonetaryColumnDescription } from '../../../shared';
import { ConfigService } from '../../../services/config';
import { BankTransaction, BankTransactionSource, PaypalSpecificData, EnrichedBankTransaction } from '../../../types';
import { DateExplorerLink } from '../../DataExplorerLink';
import { UTCFormat } from '../../../shared/time-utils';
import { BusinessMeaningChip } from '../../BusinessMeaningChip';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const accountNameColumn: GridColDef<BankTransaction> = {
  field: 'bankAccountName',
  headerName: 'Bank Account',
  width: 250,
  sortable: false,
  filterable: false,
  valueGetter: (params: GridValueGetterParams) => {
    const { bankAccount } = params.row;

    return bankAccount.fullName;
  },
};

const originalDescriptionColumn: GridColDef<BankTransaction> = {
  field: 'description',
  headerName: 'Description',
  width: 600,
  sortable: false,
  filterable: false,
  renderCell: (params) => {
    const { description } = params.row;
    if (description.length <= 80) return description;

    return (
      <Tooltip title={description}>
        <span>{description}</span>
      </Tooltip>
    );
  },
};

const dateColumn: GridColDef<BankTransaction> = {
  field: 'date',
  headerName: 'Date',
  width: 100,
  sortable: false,
  filterable: false,
  valueGetter: (params: GridValueGetterParams) =>
    UTCFormat(params.row.postedAt, ConfigService.getInstance().getOrFail('DATE_FORMAT')),
};

const amountColumn: GridColDef<BankTransaction> = {
  field: 'amount',
  headerName: 'Amount',
  sortable: false,
  filterable: false,
  width: 140,
  renderCell: (params) => {
    const { amount, amountInUsd, currency } = params.row;

    const sign = amount > 0 ? '' : '-';

    return `${sign}${getMonetaryColumnDescription(amount, amountInUsd, currency, currency !== 'USD')}`;
  },
};

const amountInUsdColumn: GridColDef<BankTransaction> = {
  field: 'amountInUsd',
  headerName: 'Amount in USD',
  sortable: false,
  filterable: false,
  width: 140,
  renderCell: (params) => params.row.amountInUsd,
};

const merchantColumn: GridColDef<BankTransaction> = {
  field: 'merchantName',
  headerName: 'Merchant',
  sortable: false,
  filterable: false,
  width: 140,
  valueGetter: (params: GridValueGetterParams) => {
    const { merchant } = params.row;

    return merchant?.name || '';
  },
};

const originalVendorNameColumn: GridColDef<BankTransaction> = {
  field: 'originalVendorName',
  headerName: 'Paypal Original Vendor Name',
  sortable: false,
  filterable: false,
  resizable: true,
  width: 300,
  valueGetter: (params: GridValueGetterParams) => {
    const { sourceSpecificData, source } = params.row;

    if (source !== BankTransactionSource.Paypal) {
      return;
    }

    return (sourceSpecificData as PaypalSpecificData).vendorName;
  },
};

const linkColumn: GridColDef = {
  field: 'link',
  headerName: 'Link',
  sortable: false,
  filterable: false,
  width: 30,
  renderCell: (params: GridRenderCellParams) => {
    return params.row.pairedEntityId && <DateExplorerLink bankTransactionId={params.row.pairedEntityId} />;
  },
};

const classificationsColumn: GridColDef = {
  field: 'classifications',
  headerName: 'Classifications',
  sortable: false,
  filterable: false,
  width: 350,
  renderCell: (params: GridRenderCellParams) => {
    const bankTransaction: EnrichedBankTransaction = params.row;
    const businessEventClassifications = bankTransaction.businessEvent?.classifications;
    return (
      <Stack>
        {businessEventClassifications?.map(
          ({ businessMeaning, classificationText, classificationTagText, businessEvent, amount, askTheUserResult }) =>
            businessMeaning ? (
              <>
                <div>
                  <BusinessMeaningChip
                    businessEventRuleType={bankTransaction.classificationMetadata?.businessEventRuleType}
                    businessEventRuleId={bankTransaction.classificationMetadata?.businessEventRuleId}
                    description={
                      businessEventClassifications.length === 1 ? businessMeaning : `${businessMeaning} (${amount})`
                    }
                  />
                </div>
                {(classificationText || classificationTagText) && (
                  <Tooltip title={classificationText || classificationTagText || ''} placement="right">
                    <Typography noWrap maxWidth="30ch">
                      {classificationText || classificationTagText}
                    </Typography>
                  </Tooltip>
                )}
                {askTheUserResult && (
                  <Tooltip title={JSON.stringify(askTheUserResult)} placement="right">
                    <Typography noWrap maxWidth="30ch">
                      {JSON.stringify(askTheUserResult)}
                    </Typography>
                  </Tooltip>
                )}
              </>
            ) : (
              <Chip
                label={businessEventClassifications.length === 1 ? businessEvent : `${businessEvent} (${amount})`}
                variant="outlined"
              />
            ),
        )}
      </Stack>
    );
  },
};

const pairedEntityColumn: GridColDef = {
  field: 'pairedLink',
  headerName: 'Paired entity',
  sortable: false,
  filterable: false,
  width: 100,
  renderCell: (params: GridRenderCellParams) => {
    const bankTransaction = params.row;
    const pairedClassification = bankTransaction.businessEvent?.classifications.find((cl: any) => {
      return cl.pairedEntityId && cl.pairedEntity;
    });

    if (!pairedClassification || !pairedClassification.pairedEntity) return null;

    const { pairedEntityType, pairedEntity, pairedEntityId } = pairedClassification;

    return (
      <Tooltip
        title={
          <>
            <Stack spacing={2}>
              <Item>Paired {pairedEntityType}</Item>
              <Item>ID: {pairedEntityId}</Item>
              <Item>Description: {pairedEntity.description}</Item>
              <Item>Amount: {pairedEntity.amount}</Item>
              <Item>Amount in USD: {pairedEntity.amountInUsd}</Item>
              <Item>
                Merchant:{' '}
                {pairedEntity.merchant?.name || pairedEntity.vendor?.name || pairedEntity.integrationType || 'Unknown'}
              </Item>
            </Stack>
          </>
        }
      >
        <IconButton>
          <OpenInNewIcon />
        </IconButton>
      </Tooltip>
    );
  },
};

export const bankTransactionDisplayColumns: GridColDef[] = [
  accountNameColumn,
  originalDescriptionColumn,
  dateColumn,
  amountColumn,
  amountInUsdColumn,
  merchantColumn,
  classificationsColumn,
];

export const bankTransactionWithPaypalColumns = [
  accountNameColumn,
  originalVendorNameColumn,
  dateColumn,
  amountColumn,
  amountInUsdColumn,
  classificationsColumn,
];

export const bankTransactionWithPairedColumns = [...bankTransactionDisplayColumns, pairedEntityColumn];
export const bankTransactionWithPaypalPairedColumns = [
  ...bankTransactionWithPaypalColumns,
  pairedEntityColumn,
  linkColumn,
];
