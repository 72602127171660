import { BusinessMeaning, EnrichedBankTransaction } from '../../../types';
import { memo, ReactElement, useLayoutEffect, useMemo, useState } from 'react';
import { resolveMoneyDirection, useBusinessMeanings } from '../../../shared';
import { BusinessMeaningSelector } from '../../BusinessMeaningSelector';
import { ContentSelector } from './ContentSelector';
import { CategorizationResult } from '../index';
import { PairedEntityType, PairingType } from '../../../__generated-global__';

export interface BusinessEventSelectionProps {
  companyId: string;
  bankTransaction: EnrichedBankTransaction;
  categorizationResult: CategorizationResult;
  onBusinessEventSelectionChange: (result: BusinessEventSelectionResult) => void;
  onShouldApplyGlobalRuleChange?: (shouldApplyGlobalRule: boolean) => void;
  isDisabled: boolean;
}

export interface BusinessEventSelectionResult {
  businessMeanings: { businessMeaning: BusinessMeaning; amount: number; description?: string }[];
  shouldApplyAlways: boolean;
  pairedEntityId?: string;
  pairedEntityType?: PairedEntityType;
  pairingType?: PairingType;
}

const bannedBusinessEvents = new Set([
  'ask-the-user-always',
  'bill-paid',
  'manual-invoice-payment-received',
  'invoice-payment-received-in-bank',
]);

export const BusinessEventSelection = memo(
  ({
    bankTransaction,
    onBusinessEventSelectionChange,
    onShouldApplyGlobalRuleChange,
    categorizationResult,
    isDisabled,
  }: BusinessEventSelectionProps): ReactElement => {
    const { businessMeanings } = useBusinessMeanings();

    const [currentBusinessMeaning, setCurrentBusinessMeaning] = useState<BusinessMeaning | undefined>();

    const handleBusinessMeaningChange = ([businessMeaning]: BusinessMeaning[]) => {
      setCurrentBusinessMeaning(businessMeaning);
    };

    useLayoutEffect(() => {
      if (businessMeanings == null) return;

      const businessEventClassifications = bankTransaction.businessEvent?.classifications;

      if (businessEventClassifications && businessEventClassifications?.length > 1) {
        const splitBusinessMeaning = businessMeanings?.forClassification.find(({ id }) => id === 'split');
        setCurrentBusinessMeaning(splitBusinessMeaning);
        return;
      }

      const seekedBusinessEvent = businessEventClassifications?.[0]?.businessEvent;
      if (seekedBusinessEvent == null) return;

      setCurrentBusinessMeaning(businessMeanings?.forClassification.find(({ id }) => id === seekedBusinessEvent));
    }, [bankTransaction, setCurrentBusinessMeaning, businessMeanings]);

    const bankTransactionMoneyDirection = resolveMoneyDirection(bankTransaction);
    const selectableBusinessMeanings = useMemo(
      () =>
        (businessMeanings?.forClassification || []).filter((businessMeaning) => {
          const { moneyDirection: businessMeaningMoneyDirection } = businessMeaning;

          return (
            !bannedBusinessEvents.has(businessMeaning.id) &&
            (businessMeaningMoneyDirection === 'ALL' ||
              businessMeaningMoneyDirection === bankTransactionMoneyDirection) &&
            (categorizationResult.merchantResult.merchant?.id || !businessMeaning.vendorRequired)
          );
        }),
      [bankTransactionMoneyDirection, businessMeanings, categorizationResult],
    );

    return (
      <>
        <BusinessMeaningSelector
          businessMeanings={selectableBusinessMeanings}
          isLoading={businessMeanings == null}
          onChange={handleBusinessMeaningChange}
          value={currentBusinessMeaning ? [currentBusinessMeaning?.id] : []}
          isDisabled={isDisabled}
        />

        {businessMeanings != null && (
          <ContentSelector
            bankTransaction={bankTransaction}
            selectableBusinessMeanings={selectableBusinessMeanings}
            currentBusinessMeaning={currentBusinessMeaning}
            categorizationResult={categorizationResult}
            onBusinessEventSelectionChange={onBusinessEventSelectionChange}
            onShouldApplyGlobalRuleChange={onShouldApplyGlobalRuleChange}
            isDisabled={isDisabled}
          />
        )}
      </>
    );
  },
);
