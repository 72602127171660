import { BankAccount } from '../types';
import { useQuery } from '@tanstack/react-query';
import { BankAccountsService } from '../services/bank-accounts';

export interface UseBankAccountsResult {
  bankAccounts?: BankAccount[] | null;
  isLoading: boolean;
}

export const useBankAccounts = (companyId?: string): UseBankAccountsResult => {
  const { data: bankAccounts, isLoading } = useQuery(
    ['bankAccounts', companyId],
    () => BankAccountsService.getInstance().getBankAccountsPage(companyId!),
    {
      enabled: companyId != null,
      select: (data) => data.items,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: false,
    },
  );

  return { bankAccounts, isLoading };
};
