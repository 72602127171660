import { BankAccountType } from '../../types';
import { BankAccountStatementRequestStatus } from '../../types/BankAccountStatementRequest';

export interface DownloadBankStatementUrlRequest {
  statementDateMs: number;
  fileName: string;
  companyId: string;
  bankAccountId: string;
}

export enum StatementsTypes {
  OpeningAndClosingBalances = 'OPENING_AND_CLOSING_BALANCES',
  OpeningBalances = 'START_OF_YEAR_BALANCE',
  ClosingBalances = 'END_OF_YEAR_BALANCE',
  TransactionsBackfill = 'TRANSACTIONS_BACKFILL',
  InternalTransferTransactionMatch = 'INTERNAL_TRANSFER_TRANSACTION_MATCH',
}

export interface BankStatementsRequestProps {
  cursor?: string;
  companyId?: string;
  statementId?: string;
  status?: BankAccountStatementRequestStatus;
  bankAccountType?: BankAccountType;
  isConfirmed?: boolean;
  type?: StatementsTypes;
}
