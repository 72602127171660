import { z } from 'zod';

const dateString = z.string().regex(/^\d{4}-\d{2}-\d{2}$/);
const transactionTuple = z
  .tuple([dateString, z.string(), z.number().nullable(), z.number().nullable()])
  .refine(
    ([date, description, spent, received]) => spent != null || received != null,
    'Either Money Spent or Money Received must be defined!',
  )
  .refine(
    ([date, description, spent, received]) => !(spent != null && received != null),
    'Money Spent and Money Received cannot be defined at the same time!',
  );

export const transactionsStructure = z.array(transactionTuple);
export type UploadedTransaction = z.infer<typeof transactionTuple>;

export type ManualBankTransaction = {
    date: string;
    description: string;
    moneySpent: number;
    moneyReceived: number;
}
