import React from 'react';
import ReactDOM from 'react-dom';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { Auth0Provider, AppState } from '@auth0/auth0-react';
import { createBrowserHistory } from 'history';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { BugsnagErrorBoundary } from './services/bugsnag';

import 'react-toastify/dist/ReactToastify.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

const history = createBrowserHistory();

const onRedirectCallback = (appState: AppState | undefined): void => {
  history.replace((appState && appState.returnTo) || window.location.pathname);
};

const queryClient = new QueryClient();

const ErrorView = () => <div>Something went wrong!</div>;

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      <QueryClientProvider client={queryClient}>
        <HistoryRouter history={history}>
          <BugsnagErrorBoundary FallbackComponent={ErrorView}>
            <App />
          </BugsnagErrorBoundary>
        </HistoryRouter>
      </QueryClientProvider>
    </Auth0Provider>
    <ToastContainer autoClose={false} />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
