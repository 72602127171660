import { ReconciliationStatus } from '../../../types';

export const reconciliationStatusOptions = [
  {
    value: ReconciliationStatus.Reconciled,
    name: 'Reconciled',
  },
  {
    value: ReconciliationStatus.NotReconciled,
    name: 'Not Reconciled',
  }
];
