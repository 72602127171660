export default {
    "scalars": [
        0,
        1,
        3,
        5,
        6,
        14,
        15
    ],
    "types": {
        "Date": {},
        "BalanceTransactionType": {},
        "Merchant": {
            "name": [
                3
            ],
            "__typename": [
                3
            ]
        },
        "String": {},
        "BankTransaction": {
            "id": [
                5
            ],
            "source": [
                3
            ],
            "amountInUsd": [
                6
            ],
            "description": [
                3
            ],
            "merchant": [
                2
            ],
            "__typename": [
                3
            ]
        },
        "ID": {},
        "Float": {},
        "BalanceTransaction": {
            "id": [
                3
            ],
            "companyId": [
                3
            ],
            "amount": [
                6
            ],
            "amountInUsd": [
                6
            ],
            "currency": [
                3
            ],
            "eventTime": [
                0
            ],
            "integrationType": [
                3
            ],
            "pairedBankTransaction": [
                4
            ],
            "on_Payout": [
                8
            ],
            "on_Topup": [
                9
            ],
            "__typename": [
                3
            ]
        },
        "Payout": {
            "id": [
                3
            ],
            "companyId": [
                3
            ],
            "amount": [
                6
            ],
            "amountInUsd": [
                6
            ],
            "currency": [
                3
            ],
            "eventTime": [
                0
            ],
            "integrationType": [
                3
            ],
            "pairedBankTransaction": [
                4
            ],
            "__typename": [
                3
            ]
        },
        "Topup": {
            "id": [
                3
            ],
            "companyId": [
                3
            ],
            "amount": [
                6
            ],
            "amountInUsd": [
                6
            ],
            "currency": [
                3
            ],
            "eventTime": [
                0
            ],
            "integrationType": [
                3
            ],
            "pairedBankTransaction": [
                4
            ],
            "__typename": [
                3
            ]
        },
        "BalanceTransactionsSort": {
            "closeToAmount": [
                6
            ],
            "closeToDate": [
                0
            ],
            "__typename": [
                3
            ]
        },
        "TimeRange": {
            "from": [
                0
            ],
            "to": [
                0
            ],
            "__typename": [
                3
            ]
        },
        "GetBalanceTransactionsArgs": {
            "companyId": [
                3
            ],
            "amountInUsd": [
                6
            ],
            "eventTimeRange": [
                11
            ],
            "sort": [
                10
            ],
            "__typename": [
                3
            ]
        },
        "Query": {
            "getPayouts": [
                8,
                {
                    "args": [
                        12,
                        "GetBalanceTransactionsArgs!"
                    ]
                }
            ],
            "getTopups": [
                9,
                {
                    "args": [
                        12,
                        "GetBalanceTransactionsArgs!"
                    ]
                }
            ],
            "__typename": [
                3
            ]
        },
        "UserToken": {},
        "Boolean": {}
    }
}