import { Divider, Stack } from '@mui/material';
import { keyBy } from 'lodash';
import { type ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import type { SetRequired } from 'type-fest';

import { BankTransactionsService } from '../../services/bank-transactions';
import { resolveMoneyDirection, useBusinessMeanings, useCompanies } from '../../shared';
import { useBankAccounts } from '../../shared/use-bank-accounts';
import { useTransactionUpdatedChannel } from '../../shared/useTransactionUpdatedChannel';
import {
  BankTransaction,
  type EnrichedBankTransaction,
  EnrichedBankTransactionBusinessEventClassification,
} from '../../types';
import { BankTransactionsFilterChange, BankTransactionsFilters } from './BankTransactionsFilters';
import { BankTransactionsTable } from './BankTransactionsTable';
import { Search } from './Search';
import { useQueryStringFilters } from './useQueryStringFilters';
import { useClassificationTags } from '../../shared/use-classification-tags';

const BANK_TRANSACTION_PAGE_SIZE = 100;

export function BankTransactions(): ReactElement {
  const [enrichedBankTransactions, setEnrichedBankTransactions] = useState<EnrichedBankTransaction[]>([]);
  const [isLoadingBankTransactions, setIsLoadingBankTransactions] = useState<boolean>(false);
  const [hasMoreBankTransactionPages, setHasMoreBankTransactionPages] = useState(false);
  const [filters, setFilters] = useState<BankTransactionsFilterChange>({});
  const [query, setQuery] = useState<string>('');
  useQueryStringFilters({ filters, setFilters, query, setQuery });

  const onQueryChange = useCallback((query: string) => setQuery(query), [setQuery]);

  const { businessMeanings } = useBusinessMeanings();
  const businessMeaningDictionary = useMemo(() => keyBy(businessMeanings?.forClassification, 'id'), [businessMeanings]);
  const { classificationTags } = useClassificationTags();
  const classificationTagDictionary = useMemo(() => keyBy(classificationTags, 'id'), [classificationTags]);

  const { id: companyId } = filters?.company || {};

  const { bankAccounts } = useBankAccounts(companyId);
  const bankAccountsDictionary = useMemo(() => keyBy(bankAccounts, 'id'), [bankAccounts]);

  const {
    data: bankTransactionData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery(
    ['bank-transactions', filters, query],
    (context) =>
      BankTransactionsService.getInstance().getBankTransactionPage({
        bankAccountId: filters?.bankAccount?.id,
        businessEvent: filters?.businessMeaning?.map((businessMeaning) => businessMeaning.id),
        companyId: companyId!,
        cursor: context.pageParam,
        endDate: filters?.endDate,
        limit: BANK_TRANSACTION_PAGE_SIZE,
        startDate: filters?.startDate,
        enrichmentRuleType: filters?.enrichmentRuleType,
        businessEventRuleType: filters?.businessEventRuleType,
        // vendorId: filters?.merchant?.id,
        source: filters?.source,
        description: query,
        withAskTheUserResults: true,
        merchantName: filters?.merchant?.name,
      }),
    {
      enabled: !!companyId,
      getNextPageParam: (lastPage) =>
        lastPage.items.length < BANK_TRANSACTION_PAGE_SIZE ? undefined : lastPage.cursor,
      refetchOnWindowFocus: false,
      refetchInterval: false,
    },
  );

  if (!isLoadingBankTransactions && (isFetchingNextPage || isLoading)) {
    setIsLoadingBankTransactions(true);
  }

  useEffect(() => {
    const bankTransactions: EnrichedBankTransaction[] =
      bankTransactionData?.pages.flatMap((page, i) =>
        page.items.map((bankTransaction, j) => {
          const newTransaction: EnrichedBankTransaction = {
            ...bankTransaction,
            ...(bankTransaction.merchant?.id && {
              merchant: {
                ...bankTransaction.merchant,
                merchantSubtype: bankTransaction.merchant?.merchantSubtype,
              },
            }),
            businessEvent: bankTransaction.businessEvent
              ? {
                  ...bankTransaction.businessEvent,
                  classifications: bankTransaction.businessEvent.classifications?.map((classification) => {
                    return {
                      ...classification,
                      classificationTagText: classification.classificationTagId
                        ? classificationTagDictionary[classification.classificationTagId]?.name
                        : undefined,
                      businessMeaning: businessMeaningDictionary[classification.businessEvent || '']?.name || null,
                    };
                  }) as EnrichedBankTransactionBusinessEventClassification[],
                }
              : undefined,
            resolvedMoneyDirection: resolveMoneyDirection(bankTransaction),
            isUpdating:
              !!enrichedBankTransactions[i * BANK_TRANSACTION_PAGE_SIZE + j]?.isUpdating ||
              !!bankTransaction.applyingClassificationJobId,
          };

          return newTransaction;
        }),
      ) || [];

    setEnrichedBankTransactions(bankTransactions);
    setIsLoadingBankTransactions(!!isFetchingNextPage);
    setHasMoreBankTransactionPages(!!hasNextPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankTransactionData, businessMeaningDictionary, bankAccountsDictionary, classificationTagDictionary]);

  const handleBankTransactions = (
    updatedBankTransactions: SetRequired<Partial<EnrichedBankTransaction>, 'id'>[],
    isUpdating: boolean,
    isFullUpdate = false,
  ) => {
    const enrichedBankTransactionsDictionary = keyBy(enrichedBankTransactions, 'id');

    updatedBankTransactions = updatedBankTransactions.filter(({ id }) => !!enrichedBankTransactionsDictionary[id]);

    const updatedBankTransactionDictionary = keyBy(updatedBankTransactions, 'id');

    setEnrichedBankTransactions((enrichedBankTransactions) =>
      enrichedBankTransactions.map((bankTransaction) => {
        const updatedBankTransaction = updatedBankTransactionDictionary[bankTransaction.id];

        if (isFullUpdate) {
          if (!updatedBankTransaction) return bankTransaction;
          return {
            ...(updatedBankTransaction as BankTransaction),
            businessEvent: updatedBankTransaction?.businessEvent
              ? {
                  ...updatedBankTransaction.businessEvent,
                  classifications: updatedBankTransaction.businessEvent.classifications?.map((classification) => ({
                    ...classification,
                    businessMeaning: businessMeaningDictionary[classification.businessEvent || '']?.name || null,
                  })) as EnrichedBankTransactionBusinessEventClassification[],
                }
              : undefined,
            isUpdating,
          };
        }

        return {
          ...bankTransaction,
          ...updatedBankTransaction,
          businessEvent: updatedBankTransaction?.businessEvent
            ? {
                ...updatedBankTransaction.businessEvent,
                classifications: updatedBankTransaction.businessEvent.classifications?.map((classification) => ({
                  ...classification,
                  businessMeaning: businessMeaningDictionary[classification.businessEvent || '']?.name || null,
                })) as EnrichedBankTransactionBusinessEventClassification[],
              }
            : bankTransaction.businessEvent,
          isUpdating: updatedBankTransaction
            ? isUpdating
            : bankTransaction.isUpdating || !!bankTransaction.applyingClassificationJobId,
        };
      }),
    );
  };

  const handleBankTransactionsUpdated = (
    updatedBankTransactions: SetRequired<Partial<EnrichedBankTransaction>, 'id'>[],
    isFullUpdate = false,
  ) => {
    return handleBankTransactions(updatedBankTransactions, false, isFullUpdate);
  };

  const handleBankTransactionsUpdating = (
    updatedBankTransactions: SetRequired<Partial<EnrichedBankTransaction>, 'id'>[],
  ): void => {
    return handleBankTransactions(updatedBankTransactions, true);
  };

  const handleBankTransactionsDeleted = (
    bankTransactions: SetRequired<Partial<EnrichedBankTransaction>, 'id'>[],
  ): void => {
    setEnrichedBankTransactions(
      enrichedBankTransactions.filter(({ id }) => !bankTransactions.some(({ id: id2 }) => id === id2)),
    );
  };

  const handleBankTransactionsExcluded = (
    bankTransactions: SetRequired<Partial<EnrichedBankTransaction>, 'id'>[],
  ): void => {
    setEnrichedBankTransactions(
      enrichedBankTransactions.filter(({ id }) => !bankTransactions.some(({ id: id2 }) => id === id2)),
    );
  };

  useTransactionUpdatedChannel(companyId, ({ data }) => {
    console.log(data);
    const { bankTransaction } = data.payload;
    setEnrichedBankTransactions((enrichedBankTransactions) => {
      if (!enrichedBankTransactions.some(({ id }) => id === bankTransaction.id)) {
        console.log('filtering out bank transaction', bankTransaction.id);
        return enrichedBankTransactions;
      }

      return enrichedBankTransactions.map((enrichedBankTransaction) => {
        if (bankTransaction.id === enrichedBankTransaction.id) {
          return {
            ...bankTransaction,
            businessEvent: bankTransaction.businessEvent
              ? {
                  ...bankTransaction.businessEvent,
                  classifications: bankTransaction.businessEvent.classifications?.map((classification) => ({
                    ...classification,
                    businessMeaning: businessMeaningDictionary[classification.businessEvent || '']?.name || null,
                  })) as EnrichedBankTransactionBusinessEventClassification[],
                }
              : undefined,
            isUpdating: false,
          };
        }

        return enrichedBankTransaction;
      });
    });
  });

  const { companies } = useCompanies();
  const company = companies?.find((c) => c.id === companyId);

  const restrictionDate = company?.dataRestrictionTime ? new Date(company?.dataRestrictionTime) : undefined;

  return (
    <Stack spacing={2}>
      <BankTransactionsFilters onChange={setFilters} value={filters} />
      <Search onChange={onQueryChange} query={query} />
      {restrictionDate && <h5>Restriction date: {restrictionDate.toISOString()}</h5>}
      <Divider sx={{ marginTop: 2 }} />
      <BankTransactionsTable
        bankTransactions={enrichedBankTransactions}
        companyId={companyId}
        hasMorePages={hasMoreBankTransactionPages}
        isLoadingBankTransactions={isLoadingBankTransactions}
        onBankTransactionsUpdated={handleBankTransactionsUpdated}
        onBankTransactionsUpdating={handleBankTransactionsUpdating}
        onBankTransactionsDeleted={handleBankTransactionsDeleted}
        onBankTransactionsExcluded={handleBankTransactionsExcluded}
        onPageRequest={fetchNextPage}
      />
    </Stack>
  );
}
