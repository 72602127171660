import { memo, type ReactElement, useMemo } from 'react';
import { keyBy } from 'lodash';

import type { BankAccount } from '../types';
import { Autocomplete } from '../ui/Autocomplete';

export interface BankAccountSelectorProps {
  bankAccounts: BankAccount[];
  isLoading?: boolean;
  isDisabled?: boolean;
  onChange?: (bankAccount: BankAccount | undefined) => void;
  value?: string | null;
}

export const BankAccountSelector = memo((props: BankAccountSelectorProps): ReactElement => {
  const { bankAccounts, value } = props;

  const bankAccountsDictionary = useMemo(() => keyBy(bankAccounts, 'id'), [bankAccounts]);

  const val = bankAccountsDictionary[value || ''];

  return (
    <Autocomplete
      isDisabled={props.isDisabled}
      isLoading={props.isLoading}
      label="Bank Account / Credit Card"
      onChange={(value) => props.onChange?.(value[0])}
      optionEqualityFn={(bankAccount0: BankAccount, bankAccount1: BankAccount) => bankAccount0.id === bankAccount1.id}
      optionLabelFn={(bankAccount: BankAccount) =>
        `${bankAccount.fullName.trim()} (ID: ${bankAccount.id.substring(0, 6)}...)`
      }
      options={props.bankAccounts}
      value={val ? [val] : []}
    />
  );
});
